export const useStyles = () => ({
  paper: {
    marginLeft: 10,
    marginRight: 10,
    color: 'rgba(0, 0, 0, 0.87)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minWidth: '0px',
    overflowWrap: 'break-word',
    backgroundColor: 'rgb(255, 255, 255)',
    backgroundClip: 'border-box',
    border: '0px solid rgba(0, 0, 0, 0.125)',
    borderRadius: ' 0.75rem',
    boxShadow:
      'rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem',
    overflow: 'visible',
  },
  box: {
    padding: '16px',
    opacity: 1,
    background: 'transparent',
    color: 'rgb(52, 71, 103)',
    boxShadow: 'none',
  },
  box_title: {
    marginLeft: '16px',
    marginTop: '-16px',
    lineHeight: 0,
    opacity: 1,
    background: 'transparent',
    color: 'rgb(52, 71, 103)',
    boxShadow: 'none',
  },
  typo_title: {
    margin: '0px',
    fontSize: '1rem',
    lineHeight: '1.625',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    letterSpacing: '0.0075em',
    opacity: '1',
    textTransform: 'capitalize',
    verticalAlign: 'unset',
    textDecoration: 'none',
    color: 'rgb(52, 71, 103)',
    fontWeight: '600',
  },
  box_avatars: {
    display: 'flex',
    opacity: 1,
    background: 'transparent',
    color: 'rgb(52, 71, 103)',
    boxShadow: 'none',
  },
  avatar: {
    display: 'flex',
    '-moz-box-align': 'center',
    alignTtems: 'center',
    '-moz-box-pack': 'center',
    justifyContent: 'center',
    flexShrink: 0,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1,
    borderRadius: ' 50%',
    overflow: 'hidden',
    userSelect: 'none',
    transition: '200ms ease-in-out',
    background: 'transparent',
    color: 'rgb(255, 255, 255)',
    fontWeight: 400,
    width: ' 1.5rem',
    height: '1.5rem',
    fontSize: '0.75rem',
    border: '0.125rem solid rgb(255, 255, 255)',
    cursor: 'pointer',
    position: 'relative',
  },
  typo_upper: {
    margin: '-8px 0px 0px auto',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '1.25rem',
    fontWeight: 400,
    lineHeight: '1.625',
    letterSpacing: '0.00938em',
    opacity: 1,
    textTransform: 'none',
    verticalAlign: 'unset',
    textDecoration: 'none',
    color: 'rgb(123, 128, 154)',
    alignSelf: 'flex-start',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  box_content: {
    marginTop: '16px',
    marginBottom: '16px',
    lineHeight: 1,
    opacity: 1,
    background: 'transparent',
    color: 'rgb(52, 71, 103)',
    boxShadow: 'none',
  },
  typo_content: {
    margin: '0px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '0.875rem',
    lineHeight: 1.5,
    letterSpacing: '0.02857em',
    opacity: 1,
    textTransform: 'none',
    verticalAlign: 'unset',
    textDecoration: 'none',
    color: 'rgb(123, 128, 154)',
    fontWeight: 300,
  },
  divider: {
    flexshrink: '0',
    borderWidth: '0px 0px medium',
    borderStyle: 'solid solid none',
    borderColor: 'rgba(0, 0, 0, 0.12) rgba(0, 0, 0, 0.12) currentcolor',
    backgroundColor: 'transparent',
    backgroundImage:
      'linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.4), rgba(52, 71, 103, 0)) !important',
    height: '0.0625rem',
    margin: '1rem 0px',
    opacity: 0.25,
  },
  box_bottom: {
    display: 'flex',
    '-moz-box-pack': 'justify',
    justifyContent: 'space-between',
    '-moz-box-align': 'center',
    alignItems: 'center',
    opacity: 1,
    background: 'transparent',
    color: 'rgb(52, 71, 103)',
    boxShadow: 'none',
  },
  box_bottom_text: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 0,
    opacity: 1,
    background: 'transparent',
    color: 'rgb(52, 71, 103)',
    boxShadow: 'none',
  },
  typo_bottom_up: {
    margin: '0px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '0.875rem',
    lineHeight: '1.5',
    letterSpacing: '0.02857em',
    opacity: 1,
    textTransform: 'none',
    verticalAlign: 'unset',
    textDecoration: 'none',
    color: 'rgb(52, 71, 103)',
    fontWeight: 600,
  },
  typo_bottom_down: {
    margin: '0px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '0.875rem',
    lineHeight: '1.5',
    letterSpacing: '0.02857em',
    opacity: 1,
    textTransform: 'none',
    verticalAlign: 'unset',
    textDecoration: 'none',
    color: 'rgb(123, 128, 154)',
    fontWeight: 400,
  },
  icon: {
    userSelect: 'none',
    width: '1em',
    height: '1em',
    overflow: 'hidden',
    display: 'inline-block',
    textAlign: 'center',
    flexShrink: 0,
    cursor: 'pointer',
    fontWeight: 700,
  },
});
