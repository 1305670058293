import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { QueryCenters } from 'graphql/Units/utils_centers';

import { useMakeStyles } from '../Styles';
import { GetData } from 'helpers';
import SearchAutocomplete from '../SearchAutocomplete';

const SearchCentersInputs = props => {
  let { style, external_options, holder, disabled } = props;
  if (external_options === undefined || !external_options) {
    external_options = [];
  }
  const { classes } = useMakeStyles();

  const [name, setCenterName] = React.useState('');
  const [center, setCenter] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState(external_options);
  let loading = open && options.length === 0;
  if (external_options.length) {
    loading = false;
    if (options.length !== external_options.length) {
      setOptions(external_options);
    }
  }
  if (holder) {
    holder.getSelectedCenter = () => center;
    holder.resetCenter = () => {
      setCenter(null);
      setCenterName('');
    };
  }
  React.useEffect(() => {
    if (!loading || external_options.length) {
      return undefined;
    }

    (async () => {
      QueryCenters(name, 0, 5)
        .then(res => {
          const data = GetData(res);
          const {
            centers: { ok, errors, centers },
          } = data;

          if (ok) {
            if (centers.length === 0) {
              centers.push({ name: 'Empty' });
            }
          } else {
            throw errors;
          }
          setOptions(centers);
        })
        .catch(error => {
          console.log({ error });
          setOptions([]);
        });
    })();
  }, [loading, name]);
  const handelCenterNameChanged = event => {
    const name = event.target.value;
    //
    if (options.length) {
      const here = options.filter(x =>
        x.name.toUpperCase().startsWith(name.toUpperCase())
      );
      //
      if (!here.length || !name.length) {
        setOptions([]);
      }
    }
    setCenterName(name);
  };
  const handleAddCenter = center => {
    setCenter(center);
    props.handleAddCenter(center);
  };
  const handleSetCenter = center => {
    setCenter(center);
    if (props.handleSetCenter) {
      props.handleSetCenter(center);
    }
  };
  if (props.no_icon) {
    return (
      <SearchAutocomplete
        clean_output={props.clean_output}
        disabled={disabled}
        handelSearchNameChanged={handelCenterNameChanged}
        handleAddResultObject={handleAddCenter}
        label="Center"
        loading={loading}
        name={name}
        no_icon
        open={open}
        options={options}
        placeholder={props.placeholder}
        selected={center}
        setObject={handleSetCenter}
        setObjectName={setCenterName}
        setOpen={setOpen}
        setOptions={setOptions}
      />
    );
  }
  return (
    <Paper style={{ padding: 0, margin: 0 }}>
      <Grid className={classes.root} container style={style}>
        <Grid item xs={1}>
          <SearchIcon className={classes.icon} />
        </Grid>
        <Grid item xs>
          <SearchAutocomplete
            clean_output={props.clean_output}
            disabled={disabled}
            handelSearchNameChanged={handelCenterNameChanged}
            handleAddResultObject={handleAddCenter}
            label="Center"
            loading={loading}
            name={name}
            open={open}
            options={options}
            placeholder={props.placeholder}
            selected={center}
            setObject={handleSetCenter}
            setObjectName={setCenterName}
            setOpen={setOpen}
            setOptions={setOptions}
            variant="standard"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

SearchCentersInputs.propTypes = {
  className: PropTypes.string,
  clean_output: PropTypes.bool.isRequired,
  external_options: PropTypes.array.isRequired,
  handleSetCenter: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  style: PropTypes.object,
};

SearchCentersInputs.defaultProps = {
  placeholder: 'Search Centers...',
  handleSetCenter: null,
  no_icon: false,
  external_options: [],
  clean_output: true,
  disabled: false,
};

export default SearchCentersInputs;
