import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  CircularProgress,
  circularProgressClasses,
  Grid,
  IconButton,
  // LinearProgress,
  // linearProgressClasses,
  Paper,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { GridTwoLevelTypo } from '../components';
function FacebookCircularProgress({ width, color, ...props }) {
  if (width === undefined) width = '100%';
  if (color === undefined) color = '#1a90ff';
  return (
    <Box sx={{ position: 'relative', width, height: '100%' }}>
      <CircularProgress
        size={40}
        sx={theme => ({
          color: theme.palette.grey[200],
          ...theme.applyStyles('dark', {
            color: theme.palette.grey[800],
          }),
        })}
        thickness={4}
        variant="determinate"
        {...props}
        value={100}
      />
      <CircularProgress
        disableShrink
        size={40}
        sx={theme => ({
          color,
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
          ...theme.applyStyles('dark', {
            color: '#308fe8',
          }),
        })}
        thickness={4}
        variant="determinate"
        {...props}
      />
    </Box>
  );
}
class AssignmentProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartOptions: {},
      data: [
        {
          color: '#8e24aa',
          progress: 72,
          tasks: '120 Tasks',
          name: 'User Experience Design',
        },
        {
          color: '#64dd17',
          progress: 48,
          tasks: '32 Tasks',
          name: 'Basic fundamentals',
        },
        {
          color: '#ff1744',
          progress: 15,
          tasks: '182 Tasks',
          name: 'React Native components',
        },
        {
          color: '#2196f3',
          progress: 24,
          tasks: '56 Tasks',
          name: 'Basic of music theory',
        },
      ],
    };
  }

  render() {
    const { data } = this.state;
    // const {
    //   // fullScreen,
    //   //  height, width
    // } = this.props;

    return (
      <Paper sx={{ margin: 1 }}>
        <CardHeader
          action={
            <IconButton aria-label="settings" size="large">
              <MoreVertIcon />
            </IconButton>
          }
          title={
            <Typography
              component="span"
              sx={{
                flex: '1 1 auto',
                margin: 0,
                fontFamily: '"__Inter_5475bd","__Inter_Fallback_5475bd"',
                fontWeight: 500,
                fontSize: '1.125rem',
                lineHeight: 1.5556,
                color: 'var(--mui-palette-text-primary)',
                display: 'block',
              }}
              variant="h5">
              Assignment progress
            </Typography>
          }
        />
        <CardContent sx={{ padding: 1, paddingTop: 0, paddingBottom: 0 }}>
          {/* <Grid
            container
            direction="row"
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}> */}
          {data.map((item, index) => {
            const { progress, name, tasks } = item; // name, tasks
            return (
              <Grid
                container
                direction="row"
                key={index}
                sx={{
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}>
                <Grid
                  item
                  style={{ maxWidth: 58 }}
                  sx={{
                    position: 'relative',
                  }}
                  xs={2}>
                  <FacebookCircularProgress
                    color={item.color}
                    size={50}
                    value={progress}
                  />
                  <Typography
                    component="p"
                    sx={{
                      position: 'absolute',
                      fontWeight: 500,
                      margin: 0,
                      fontFamily: '"__Inter_5475bd","__Inter_Fallback_5475bd"',
                      fontSize: '0.9375rem',
                      lineHeight: 1.46667,
                      // color: 'var(--mui-palette-text-primary)',
                      top: '50%' /* Move 50% down from the top */,
                      left: '50%' /* Move 50% right from the left */,
                      transform:
                        'translate(-55%, -60%)' /* Adjust to center the element itself */,
                      maxWidth: 60,
                    }}
                    variant="body1">
                    {`${progress}%`}
                  </Typography>
                </Grid>
                <GridTwoLevelTypo subtitle={tasks} title={name} />
                <Grid
                  container
                  direction="row"
                  item
                  sx={{
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                  xs>
                  <Button
                    sx={{
                      display: 'inline-flex',
                      '-moz-box-align': 'center',
                      alignItems: 'center',
                      '-moz-box-pack': 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      boxSizing: 'border-box',
                      backgroundColor: 'transparent',
                      outline: '0px',
                      margin: '0px',
                      cursor: 'pointer',
                      userSelect: 'none',
                      appearance: 'none',
                      textDecoration: 'none',
                      fontFamily: '"__Inter_5475bd","__Inter_Fallback_5475bd"',
                      fontWeight: 500,
                      lineHeight: 1.46667,
                      textTransform: 'none',
                      // minWidth: '64px',
                      borderRadius: 'var(--mui-shape-borderRadius)',
                      transition:
                        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
                      color: 'var(--mui-palette-secondary-main)',
                      borderColor: 'var(--mui-palette-secondary-main)',
                      fontSize: '22px',
                      padding: '0.4375rem',
                      width: 38,
                      height: 38,
                      '--mui-shape-borderRadius': '6px',
                      '--mui-palette-secondary-main': '#8A8D93',
                      '&.MuiButton-root': {
                        border: '1px solid',
                        minWidth: 10,
                        padding: 2,
                      },
                    }}>
                    <KeyboardArrowRightIcon />
                  </Button>
                </Grid>
              </Grid>
            );
          })}
          {/* </Grid> */}
        </CardContent>
      </Paper>
    );
  }
}

AssignmentProgress.propTypes = {
  classes: PropTypes.object,
};

export default AssignmentProgress;
