import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_PROJECT_ACTIVITIES = gql`
  query(
    $page: Int
    $limit: Int
    $mode: Int
    $idProject: Int
    $datestart: String
    $dateend: String
    $fullHistory: Boolean
  ) {
    ProjectActivities(
      page: $page
      limit: $limit
      mode: $mode
      idProject: $idProject
      datestart: $datestart
      dateend: $dateend
      fullHistory: $fullHistory
    ) {
      ok
      activities {
        id
        activity
        icon
        idFolder
        idFolderContent
        folder_content {
          idFolderContent
          idFileObject
          idSeries
          idFolder
        }
        Project {
          id
          name
        }
        User {
          id
          FullName
        }
        updatedAt
      }
      total
      errors {
        path
        message
      }
    }
  }
`;

export const QueryProjectActivities = variables => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PROJECT_ACTIVITIES),
    variables,
  });
};
