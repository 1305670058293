import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { Subscription } from '@apollo/client/react/components';
import { IsInvalid } from 'helpers';

const SUBSCRIPTION_DOCKER_PROCESS_FINISHED = gql`
  subscription($ids: [LongLong!]!) {
    processFinished(ids: $ids) {
      id
      idUser
      idFolder
      idFileObject
      idSeries
      idFolderContent
      operation
      status
      docker_id

      user {
        id
        FullName
      }
      createdAt
      updatedAt
    }
  }
`;

class SubsDockerProcessFinished extends Component {
  constructor(props) {
    super(props);
  }
  onSubscriptionDataNewProcess = ondata => {
    if (IsInvalid(ondata)) return;
    const { subscriptionData } = ondata;
    if (IsInvalid(subscriptionData)) return;
    const { loading, data } = subscriptionData;
    if (!loading && data) {
      const { processFinished } = data;
      this.props.handleProcessFinished(processFinished);
    }
  };
  render() {
    let { children, ids } = this.props;
    // console.log('SubsDockerProcessFinished render', { children, ids });
    return (
      <Subscription
        fetchPolicy={'no-cache'}
        onSubscriptionData={this.onSubscriptionDataNewProcess}
        shouldResubscribe
        subscription={SUBSCRIPTION_DOCKER_PROCESS_FINISHED}
        variables={{ ids }}>
        {({ loading, error }) => {
          // console.log('Subscription Render Props:', {
          //   children,
          //   loading,
          //   error,
          // });
          if (!children) {
            return null;
          }
          return React.Children.map(children, function(child) {
            return React.cloneElement(child, {
              loading,
              error,
            });
          });
        }}
      </Subscription>
    );
  }
}

SubsDockerProcessFinished.propTypes = {
  handleProcessFinished: PropTypes.func,
};
SubsDockerProcessFinished.defaultProps = {
  handleProcessFinished: () => '',
  ids: [],
};

export default SubsDockerProcessFinished;
