import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { ButtonLoad } from 'components/Buttons';

class DialogText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }
  handleChange = event => {
    this.setState({ value: event.target.value });
  };
  render() {
    const { title, description, open, handleClose, loading, name } = this.props;
    const { value } = this.state;
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
          <TextField
            autoFocus
            fullWidth
            id="name"
            InputLabelProps={{
              shrink: true,
            }}
            label={this.props.title_text}
            margin="dense"
            onChange={this.handleChange}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                this.props.onClick(value);
              }
            }}
            required
            value={value}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ButtonLoad
            handleClick={() => this.props.onClick(value)}
            loading={loading}
            name={name}
            transform
            variant="text"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

DialogText.propTypes = {
  open: PropTypes.bool,
};
DialogText.defaultProps = {
  onClick: () => '',
  onTextChanged: () => '',
  loading: false,
  open: false,
  name: 'Accept',
  description: '',
  title_text: '',
};
export default DialogText;
