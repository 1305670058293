import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@mui/material';
import { TableGrid } from 'components';
import {
  columns_series,
  columns_files,
  ToolBarElementsActions,
  EditElementInformation,
} from './components';
import { QueryFiles } from 'graphql/Folders';
import { QuerySeries } from 'graphql/Series';
import { ServerErrorsString, GetData } from 'helpers';
import { rowsPerPageBig } from 'helpers';

class Elements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'series',
      selected: [],
      series: [],
      files: [],
      loading: false,
      total: 0,
      page: 0,
      limit: 50,
      open_edit: false,
      init: false,
    };
    this.holder = {
      getFilter: null,
    };
  }
  componentDidMount() {
    if (this.props.isActive) this.handleQuery();
  }
  componentDidUpdate() {
    const { init } = this.state;
    if (this.props.isActive && !init) this.handleQuery();
  }
  handleQueryFiles = (name, newPage, newLimit, view) => {
    let { loading, page, limit } = this.state;
    if (loading) return;
    let clean = true;
    if (newPage !== undefined && newPage !== null) {
      page = newPage;
      clean = true;
    }
    if (newLimit) {
      limit = newLimit;
      clean = true;
    }
    if (view === undefined || !view) {
      view = this.state.view;
    }
    this.setState({ loading: true, view, selected: [], files: [] });
    const setting = {
      idUser: null,
      idProject: null,
      FileDate: null,
    };
    if (this.holder.getFilter) {
      const filters = this.holder.getFilter();
      if (filters.idUser) setting.idUser = filters.idUser;
      if (filters.SeriesDate) setting.FileDate = filters.SeriesDate;
      if (filters.idProject) setting.idProject = filters.idProject;
    }
    (async () => {
      QueryFiles(name, page, limit, setting)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, files, total } = data.filesAtUser;
          if (ok) {
            this.setState({
              loading: false,
              files,
              page,
              limit,
              total,
              series: clean ? [] : this.state.series,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleQuerySeries = (name, newPage, newLimit, view) => {
    let { loading, page, limit } = this.state;
    if (loading) return;
    let clean = true;
    if (newPage !== undefined && newPage !== null) {
      page = newPage;
      clean = true;
    }
    if (newLimit) {
      limit = newLimit;
      clean = true;
    }
    if (view === undefined || !view) {
      view = this.state.view;
    }
    this.setState({ loading: true, view, selected: [], series: [] });
    const setting = {
      idUser: null,
      nifti: null,
      page,
      limit,
    };
    let SeriesDate = null;
    if (this.holder.getFilter) {
      const filters = this.holder.getFilter();
      if (filters.idUser) setting.idUser = filters.idUser;
      if (filters.idProject) setting.id_projects = [filters.idProject];
      if (filters.SeriesDate) SeriesDate = filters.SeriesDate;
      if (filters.sequence)
        setting.id_sequences = [filters.sequence.idSequence];
      if (filters.nifti) setting.nifti = filters.nifti;
    }
    (async () => {
      QuerySeries(name, setting, SeriesDate)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, Series, Total } = data.Series;
          if (ok) {
            this.setState({
              loading: false,
              series: Series,
              page,
              limit,
              total: Total,
              selected: [],
              files: clean ? [] : this.state.files,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handlePageChange = page => {
    this.handleQuery(null, page, this.state.limit);
  };
  handleRowsPerPageChange = limit => {
    this.handleQuery(null, 0, limit);
  };
  handleChangeName = event => {
    event.preventDefault();
    this.handleQuery(event.target.value);
  };
  handleQuery = (name, newPage, newLimit) => {
    const { view } = this.state;
    if (view === 'files') {
      this.handleQueryFiles(name, newPage, newLimit);
    } else {
      this.handleQuerySeries(name, newPage, newLimit);
    }
  };
  handleViews = (event, view) => {
    const { series, files } = this.state;
    if (view === 'files' && !files.length) {
      this.setState({ name: '' });
      this.handleQueryFiles(null, null, null, view);
    } else if (view === 'series' && !series.length) {
      this.setState({ name: '' });
      this.handleQuerySeries(null, null, null, view);
    } else {
      /* empty */
    }
  };
  render() {
    if (!this.props.isActive) return null;
    const { height } = this.props;
    const { selected, view } = this.state;
    let columns = [];
    let rows = [];
    if (view === 'files') {
      columns = columns_files;
      rows = this.state.files;
    } else {
      columns = columns_series;
      rows = this.state.series;
    }
    if (this.state.open_edit) {
      return (
        <EditElementInformation
          handleAddError={this.props.handleAddError}
          handleAddSuccess={this.props.handleAddSuccess}
          handleClose={() => this.setState({ open_edit: false })}
          height={height}
          selected={selected}
        />
      );
    }
    return (
      <Grid
        container
        rowSpacing={1}
        style={{ padding: 4, paddingBottom: 0, paddingTop: 3 }}>
        <Grid item xs={12}>
          <ToolBarElementsActions
            handleAddError={this.props.handleAddError}
            handleAddSuccess={this.props.handleAddSuccess}
            handleChangeName={this.handleChangeName}
            handleOpenEdit={() => this.setState({ open_edit: true, name: '' })}
            handleReloadTable={() => this.handleQuery()}
            handleViews={this.handleViews}
            holder={this.holder}
            loading={this.state.loading}
            selected={selected}
            view={view}
          />
        </Grid>
        <Grid item sx={{ padding: 2, minHeight: 400 }} xs={12}>
          <Paper>
            <TableGrid
              checkboxSelection
              dense
              external_selected={this.state.selected}
              headers={columns}
              height={height - 260}
              idRow={view === 'files' ? 'idFileObject' : 'idSeries'}
              loading={this.state.loading}
              onPageChange={newPage => this.handlePageChange(newPage)}
              onRowsPerPageChange={limit => this.handleRowsPerPageChange(limit)}
              onSelectedChanged={selected => this.setState({ selected })}
              paginationMode="server"
              rowCount={this.state.total}
              rows={rows}
              rowsPerPage={this.state.limit}
              rowsPerPageOptions={rowsPerPageBig}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

Elements.propTypes = {
  classes: PropTypes.object,
};

export default Elements;
