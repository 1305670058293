import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Badge,
  IconButton,
  Divider,
} from '@mui/material';
import { TableSeriesVariableMaps, DialogQuestion } from 'components';
import { QuerySeriesTypeMaps } from 'graphql/Mapping/utils_mapping';
import { GetData, ServerErrorsString, SelectedArray } from 'helpers';
import {
  EditSeriesVariableMap,
  runDeleteSelected,
  SeriesMapFilters,
} from './components';
import ToolBarMapping from '../ToolBarMapping';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MainTableToolbar } from 'components/Tables/components';
import { withStyles } from 'tss-react/mui';
import { blue } from '@mui/material/colors';
import { lighten } from '@mui/material/styles';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const useStyles = theme => ({
  root: {
    margin: 0,
    '&.MuiAccordionSummary-content': { margin: 0 },
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(blue[500], 0.85),
  },
});
class SeriesVariableMaps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_delete: false,
      open_create: false,
      open_edit: false,
      loading: false,
      page: 0,
      limit: 50,
      selected: [],
      mappings: [],
      total_filter: 0,
      expanded: false,
      init: false,
    };
    this.holder = {
      getFilterMap: null,
    };
  }
  componentDidMount() {
    if (this.props.isActive) this.runQuerySeriesTypeMaps();
  }
  componentDidUpdate() {
    const { init } = this.state;
    if (this.props.isActive && !init) this.runQuerySeriesTypeMaps();
  }
  runQuerySeriesTypeMaps = (newPage = null, newLimit = null) => {
    let { loading, page, limit } = this.state;
    if (loading) {
      return;
    }
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    this.setState({ loading: true });
    let input = null;
    if (this.holder.getFilterMap) {
      input = this.holder.getFilterMap();
    }
    (async () => {
      QuerySeriesTypeMaps({ input, page, limit })
        .then(res => {
          const data = GetData(res);
          const { ok, mappings, total, errors } = data.seriesTypeMaps;
          if (ok) {
            this.setState({
              mappings,
              total,
              loading: false,
              page,
              limit,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log('catch-UsersProjects', error);
          this.setState({ types: [], loading: false, page, limit, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState({ selected: this.state.mappings });
    } else {
      this.setState({ selected: [] });
    }
  };
  handleSelectedClick = (event, index, row) => {
    event.preventDefault();
    const { selected } = this.state;
    const selectedIndex = selected
      ? selected.map(x => x.id).indexOf(row.id)
      : 0;
    const newSelected = SelectedArray(selected, row, selectedIndex);
    this.setState({ selected: newSelected });
    return newSelected.length;
  };
  handleOpenEdit = () => {
    const { selected, open_edit } = this.state;
    if (selected && selected.length > 0) {
      this.setState({ open_edit: !open_edit });
    }
  };
  handleUpdatedMapping = to_edit => {
    if (!to_edit) return;
    let { selected, mappings } = this.state;
    let index = selected.map(x => x.id).indexOf(to_edit.id);
    if (index !== -1) selected[index] = { ...to_edit };
    index = mappings.map(x => x.id).indexOf(to_edit.id);
    if (index !== -1) mappings[index] = { ...to_edit };
    this.setState({ selected, mappings });
  };
  handleDeleteSelected = () => {
    this.setState({ open_delete: !this.state.open_delete });
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.runQuerySeriesTypeMaps(newPage, this.state.limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.runQuerySeriesTypeMaps(0, +event.target.value);
  };
  render() {
    if (!this.props.isActive) return null;
    let { classes, height } = this.props;
    const { open_edit, open_delete, selected, total_filter } = this.state;
    if (open_edit) {
      return (
        <EditSeriesVariableMap
          handleAddError={this.props.handleAddError}
          handleCloseEdit={this.handleOpenEdit}
          handleUpdatedMapping={this.handleUpdatedMapping}
          mappings={selected}
        />
      );
    }
    if (height) {
      height = height - 90;
      if (this.state.expanded) {
        height = height - 70;
      }
    }
    let ComponentFilter = null;
    if (total_filter) {
      ComponentFilter = (
        <IconButton>
          <Badge
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            badgeContent={total_filter}
            color="secondary">
            <FilterAltIcon sx={{ color: 'green' }} />
          </Badge>
        </IconButton>
      );
    }
    return (
      <div style={{ padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ToolBarMapping
              disabled={this.state.deleting}
              handleAddError={this.props.handleAddError}
              handleClearSelected={() => this.setState({ selected: [] })}
              handleDeleteSelected={this.handleDeleteSelected}
              handleOpenCreate={() => this.setState({ open_create: true })}
              handleOpenEdit={this.handleOpenEdit}
              handleReload={this.runQuerySeriesTypeMaps}
              loading={this.state.loading}
              selected={selected}
            />
          </Grid>
          <Grid item xs={12}>
            <Accordion
              disableGutters
              expanded={this.state.expanded}
              onChange={() =>
                this.setState({ expanded: !this.state.expanded })
              }>
              <AccordionSummary
                aria-controls="panel1a-content"
                classes={{ content: classes.root }}
                // className={clsx(classes.root, {
                //   [classes.highlight]: selected.length > 0,
                // })}
                expandIcon={<ExpandMoreIcon />}
                id="panel1a-header">
                {ComponentFilter}
                <MainTableToolbar
                  hide_reload
                  loading={this.state.deleting}
                  loading_table={this.state.loading}
                  title="Series Variable Type Mapping"
                  transform
                />
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <SeriesMapFilters
                  handleAddError={this.props.handleAddError}
                  holder={this.holder}
                  onFilterChanged={total_filter =>
                    this.setState({ total_filter })
                  }
                />
              </AccordionDetails>
            </Accordion>
            <Divider />
            <TableSeriesVariableMaps
              deleting={this.state.deleting}
              disable_splice
              handleChangePage={this.handleChangePage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleClearSelected={() => this.setState({ selected: [] })}
              handleDeleteSelected={this.handleDeleteSelected}
              handleReloadTable={this.runQuerySeriesTypeMaps}
              handleSelectAllClick={this.handleSelectAllClick}
              handleSelectedClick={this.handleSelectedClick}
              height={height}
              hide_header
              limit={this.state.limit}
              loading={this.state.loading}
              mappings={this.state.mappings}
              nowrap
              page={this.state.page}
              selected={selected}
              total={this.state.total}
            />
          </Grid>
          <DialogQuestion
            button_name="Delete"
            handleAction={() => runDeleteSelected(this)}
            handleClose={() => this.setState({ open_delete: false })}
            message="This action cannot be undone. Do you want to continue?"
            open={open_delete}
            title="Delete Selected Mappings"
          />
        </Grid>
      </div>
    );
  }
}

SeriesVariableMaps.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(SeriesVariableMaps, useStyles);
