import React from 'react';
import PropTypes from 'prop-types';
import { SettingPorts } from '../components';
import ConfigImageAlgoth from './ConfigImageAlgoth';

class SettingImageAlgoth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { block } = this.props;
    return (
      <div>
        <ConfigImageAlgoth {...this.props} />
        <SettingPorts
          block={block}
          handleSettingChanged={this.props.handleSettingChanged}
          view={this.props.view}
        />
      </div>
    );
  }
}

SettingImageAlgoth.propTypes = {
  classes: PropTypes.object,
};

export default SettingImageAlgoth;
