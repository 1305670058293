import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Chip } from '@mui/material';

class TabsCustom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      value,
      handleChange,
      active,
      pending,
      blocked,
      all,
    } = this.props;
    return (
      <Tabs
        className={classes.tabs}
        onChange={handleChange}
        scrollButtons="auto"
        TabIndicatorProps={{
          className: classes.tabIndicator,
        }}
        TabScrollButtonProps={{
          className: classes.tabScrollButton,
        }}
        // TabsProps={{
        //   className: classes.tabsContainer,
        // }}
        value={value}
        variant="scrollable">
        <Tab
          className={classes.tab}
          icon={<Chip label={all} size="small" />}
          iconPosition="end"
          label="All"
        />
        <Tab
          className={classes.tab}
          icon={<Chip label={active} size="small" />}
          iconPosition="end"
          label="Active"
        />
        <Tab
          className={classes.tab}
          icon={<Chip label={pending} size="small" />}
          iconPosition="end"
          label="Pending"
        />
        <Tab
          className={classes.tab}
          icon={<Chip label={blocked} size="small" />}
          iconPosition="end"
          label="Blocked"
        />
      </Tabs>
    );
  }
}

TabsCustom.propTypes = {
  classes: PropTypes.object,
};

export default TabsCustom;
