import { IsInvalidParam, getCityCountry } from 'helpers';
import { IsInvalid, FormatTime } from 'helpers';
export const useStyles = () => ({
  root: {},
  button_group: {
    height: 33,
  },
  tabs: {
    margin: 0,
    padding: 0,
    minHeight: 30,
  },
  tab: {
    minHeight: 30,
    paddingTop: 0,
    paddingBottom: 0,
    textTransform: 'none',
  },
});
export const columns_encryp_patients = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'patient_consent',
    headerName: 'Consent',
    width: 75,
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return 'No';
      if (IsInvalid(params.getValue)) return 'No';
      const patient_consent = params.getValue(params.id, 'patient_consent');
      if (IsInvalid(patient_consent)) return 'No';
      return patient_consent.type;
    },
  },
  {
    field: 'PatientID',
    headerName: 'PID',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'NamePrefix',
    headerName: 'Prefix',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'NameSuffix',
    headerName: 'Suffix',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'GivenName',
    headerName: 'GivenName',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'MiddleName',
    headerName: 'MiddleName',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'FamilyName',
    headerName: 'FamilyName',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'PatientName',
    headerName: 'PatientName',
    width: 75,
    align: 'left',
    edit: true,
  },

  {
    field: 'PatientSex',
    headerName: 'Sex',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'address',
    headerName: 'Address',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const address = params.getValue(params.id, 'address');
      if (IsInvalid(address)) return '';
      const city = getCityCountry(address.city, address.country);
      return IsInvalid(city) ? '' : city;
    },
  },
  {
    field: 'PatientBirthDate',
    headerName: 'BirthDate',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'PatientBirthTime',
    headerName: 'BirthTime',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'PatientAge',
    headerName: 'Age',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'PatientSize',
    headerName: 'Height (mts)',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'PatientWeight',
    headerName: 'Weight (Kg)',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'Occupation',
    headerName: 'Occupation',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'OtherPatientNames',
    headerName: 'OtherPatientNames',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'EthnicGroup',
    headerName: 'EthnicGroup',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'UniqueKeyIdentifier',
    headerName: 'Unique Key',
    width: 75,
    align: 'left',
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 222,
    type: 'datetime',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      const value = params.getValue(params.id, 'updatedAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 222,
    type: 'datetime',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      const value = params.getValue(params.id, 'updatedAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
export let columns_encryp_map = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'idPatient',
    headerName: 'ID Patient',
    width: 75,
    align: 'left',
  },
  {
    field: 'patient',
    headerName: 'Patient Name',
    width: 75,
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      const value = params.getValue(params.id, 'patient');
      if (IsInvalid(value)) return '';
      return value.PatientName;
    },
  },
  {
    field: 'tag_name',
    headerName: 'Tag',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'tag_value',
    headerName: 'Value',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'encryption_key',
    headerName: 'Encryption Key',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'timepoint',
    headerName: 'Timepoint',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'idProject',
    headerName: 'ID Project',
    width: 75,
    align: 'left',
  },
  {
    field: 'project',
    headerName: 'Project',
    width: 75,
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      const value = params.getValue(params.id, 'project');
      if (IsInvalid(value)) return '';
      return value.name;
    },
  },
  { field: 'idUser', headerName: 'ID User', width: 75, align: 'left' },
  {
    field: 'user',
    headerName: 'User',
    width: 75,
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      const value = params.getValue(params.id, 'user');
      if (IsInvalid(value)) return '';
      return value.FullName;
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 222,
    type: 'datetime',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'updatedAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 222,
    type: 'datetime',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
