export const drawCircle = (
  ctx,
  centerX,
  centerY,
  number = '0',
  circleRadius = 8
) => {
  // Calculate center position and dimensions

  // Calculate font size (proportional to circle radius)
  const fontSize = Math.round(circleRadius * 1.25);
  ctx.save();
  // Set shadow
  ctx.shadowColor = 'rgba(0, 0, 0, 0.2)';
  ctx.shadowBlur = 2;
  ctx.shadowOffsetX = 2;
  ctx.shadowOffsetY = 2;

  // Draw circle
  ctx.beginPath();
  ctx.arc(centerX, centerY, circleRadius, 0, Math.PI * 2);
  ctx.fillStyle = '#bdbdbd';
  ctx.fill();

  // Draw text
  ctx.shadowColor = 'transparent'; // Remove shadow for text
  ctx.fillStyle = 'white';
  ctx.font = `bold ${fontSize}px Arial`;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';

  // Add a small vertical adjustment to account for font metrics
  const verticalOffset = fontSize * 0.05; // Slight upward adjustment
  ctx.fillText(number, centerX, centerY + verticalOffset);
  ctx.restore();
};
