import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_LOAD_PROJECT = gql`
  query($projectId: Int!) {
    loadProject(projectId: $projectId) {
      ok
      errors {
        path
        message
      }
      Project {
        id
        name
        type
        category {
          id
          code
          name
        }
        membership {
          idMember
          member_setting {
            id
            idProject
            idUser
            access_level
          }
        }
        admin {
          id
          firstName
          lastName
          FullName
        }
        teams {
          id
          name
        }
        members {
          idMember
          idUser
          idCenterTeam
          user {
            id
            FullName
          }
          center {
            id
            name
          }
          team {
            id
            name
          }
          createdAt
          updatedAt
        }
        ProjectFolder {
          idFolder
          name
        }
        description
        avatar
        status
        createdAt
        updatedAt
      }
    }
  }
`;
export const LoadProject = projectId => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_LOAD_PROJECT),
    variables: {
      projectId,
    },
  });
};

const QUERY_PROJECTS = gql`
  query($name: String, $page: Int, $limit: Int) {
    projects(name: $name, page: $page, limit: $limit) {
      ok
      total
      projects {
        id
        name
        type
        num_studies
        category {
          id
          code
          name
        }
        membership {
          idMember
          member_setting {
            id
            idProject
            idUser
            access_level
          }
        }
        admin {
          id
          firstName
          lastName
          FullName
        }
        members {
          idMember
          idUser
          idCenterTeam
          user {
            id
            FullName
          }
          center {
            id
            name
          }
          team {
            id
            name
          }
          createdAt
          updatedAt
        }
        notifications {
          id
          emails
          messages
          uploads
          chat
          updatedAt
        }
        ProjectFolder {
          idFolder
          name
        }
        description
        avatar
        status
        createdAt
        updatedAt
      }
      errors {
        path
        message
      }
    }
  }
`;

export const QueryMyProjectsFull = (name, page, limit) => {
  const variables = {};
  if (typeof name !== 'undefined' && name !== '') {
    variables.name = name;
  }
  if (typeof page !== 'undefined') {
    variables.page = page;
  }
  if (typeof limit !== 'undefined' && limit) {
    variables.limit = limit;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PROJECTS),
    variables,
  });
};

const QUERY_LIST_PROJECTS = gql`
  query($name: String, $page: Int, $limit: Int) {
    projects(name: $name, page: $page, limit: $limit) {
      ok
      total
      projects {
        id
        name
        type
        ProjectFolder {
          idFolder
          name
        }
      }
      errors {
        path
        message
      }
    }
  }
`;

export const QueryMyProjectsSmall = (name, page, limit) => {
  const variables = {};
  if (typeof name !== 'undefined' && name !== '') {
    variables.name = name;
  }
  if (typeof page !== 'undefined') {
    variables.page = page;
  }
  if (typeof limit !== 'undefined' && limit) {
    variables.limit = limit;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_LIST_PROJECTS),
    variables,
  });
};

const GET_PROJECT_ACTIVITIES = gql`
  query($id: LongLong!, $name: String, $page: Int, $limit: Int) {
    ActivitiesAtProject(id: $id, name: $name, page: $page, limit: $limit) {
      ok
      total
      activities {
        id

        activity
        icon

        Project {
          name
        }
        User {
          FullName
        }
        updatedAt
      }
      errors {
        path
        message
      }
    }
  }
`;

export const ActivitiesAtProject = (id, name, page, limit) => {
  const variables = { id };
  if (typeof name !== 'undefined') {
    variables.name = '';
  }
  if (typeof page !== 'undefined') {
    variables.page = page;
  }
  if (typeof limit !== 'undefined') {
    variables.limit = limit;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(GET_PROJECT_ACTIVITIES),
    variables,
  });
};

const GET_PROJECT_OBJECTS = gql`
  query(
    $idProject: LongLong!
    $setting: InputQueryProject
    $page: Int
    $limit: Int
  ) {
    projectObjects(
      idProject: $idProject
      setting: $setting
      page: $page
      limit: $limit
    ) {
      ok
      total
      objects {
        id

        folder {
          idFolder
          idParent
          name
          Size
          Owner {
            id
            FullName
          }
          Rights {
            idRight
            write
            download
            delete
            read
            full
            createdAt
            updatedAt
          }
        }
        folder_content {
          idFolderContent
          Owner {
            id
            FullName
          }
          Series {
            idSeries
            idStudy
            SeriesDescription
            Thumbnail
            Size
            createdAt
            updatedAt
          }
          File {
            idFileObject
            original_name
            mimetype
            Size
            Thumbnail
            updatedAt
            createdAt
          }
          Rights {
            idRight
            write
            download
            delete
            read
            full
            createdAt
            updatedAt
          }
        }
        multi_object {
          idObject
          Owner {
            id
            FullName
          }
          Series {
            idSeries
            idStudy
            SeriesDescription
            Thumbnail
            Size
            createdAt
            updatedAt
          }
          File {
            idFileObject
            original_name
            mimetype
            Size
            Thumbnail
            updatedAt
            createdAt
          }
          Rights {
            idRight
            write
            download
            delete
            read
            full
            createdAt
            updatedAt
          }
        }

        updatedAt
        createdAt
      }
      errors {
        path
        message
      }
    }
  }
`;

export const ObjectsAtProject = (idProject, setting, name, page, limit) => {
  const variables = { idProject, setting };
  if (typeof name !== 'undefined') {
    variables.name = '';
  }
  if (typeof page !== 'undefined') {
    variables.page = page;
  }
  if (typeof limit !== 'undefined') {
    variables.limit = limit;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(GET_PROJECT_OBJECTS),
    variables,
  });
};
const QUERY_OBJECTS_PROJECT_FOLDER = gql`
  query(
    $idProject: LongLong!
    $idFolder: LongLong!
    $setting: InputQueryProject
    $page: Int
    $limit: Int
  ) {
    projectObjectFolder(
      idProject: $idProject
      idFolder: $idFolder
      setting: $setting
      page: $page
      limit: $limit
    ) {
      ok
      total
      objects {
        id

        folder {
          idFolder
          idParent
          name
          Size
          Owner {
            id
            FullName
          }
          Rights {
            idRight
            write
            download
            delete
            read
            full
            createdAt
            updatedAt
          }
        }
        folder_content {
          idFolderContent
          idFolder
          Owner {
            id
            FullName
          }
          Series {
            idSeries
            idStudy
            SeriesDescription
            Thumbnail
            Size
            createdAt
            updatedAt
          }
          File {
            idFileObject
            original_name
            mimetype
            Size
            Thumbnail
            updatedAt
            createdAt
          }
          Rights {
            idRight
            write
            download
            delete
            read
            full
            createdAt
            updatedAt
          }
        }
        multi_object {
          idObject
          Owner {
            id
            FullName
          }
          Series {
            idSeries
            idStudy
            SeriesDescription
            Thumbnail
            Size
            createdAt
            updatedAt
          }
          File {
            idFileObject
            original_name
            mimetype
            Size
            Thumbnail
            updatedAt
            createdAt
          }
          Rights {
            idRight
            write
            download
            delete
            read
            full
            createdAt
            updatedAt
          }
        }

        updatedAt
        createdAt
      }
      errors {
        path
        message
      }
    }
  }
`;
export const ObjectsAtProjectFolder = (
  idProject,
  idFolder,
  setting,
  name,
  page,
  limit
) => {
  const variables = { idProject, idFolder, setting };
  if (typeof name !== 'undefined') {
    variables.name = '';
  }
  if (typeof page !== 'undefined') {
    variables.page = page;
  }
  if (typeof limit !== 'undefined') {
    variables.limit = limit;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_OBJECTS_PROJECT_FOLDER),
    variables,
  });
};

const MUTATION_REMOVE_PROJECT_OBJECTS = gql`
  mutation($objects: [ProjectObjectInput!]!) {
    removeProjectObjects(objects: $objects) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const RemoveProjectObjects = objects => {
  const variables = { objects };

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_PROJECT_OBJECTS),
    variables,
  });
};

const QUERY_PROJECTS_MEMBERS = gql`
  query($idProject: LongLong!, $name: String, $page: Int, $limit: Int) {
    projectsMembers(
      idProject: $idProject
      name: $name
      page: $page
      limit: $limit
    ) {
      ok
      errors {
        message
      }
      members {
        idMember
        idUser
        user {
          id
          FullName
          email
          avatar
          address {
            country
          }
          last_login
          createdAt
          updatedAt
        }
        center {
          id
          name
        }
        team {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const MembersAtProject = (idProject, name, page, limit) => {
  const variables = { idProject };
  if (name !== undefined && name) {
    variables.name = name;
  }
  if (page !== undefined && limit !== undefined) {
    variables.page = page;
    variables.limit = limit;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PROJECTS_MEMBERS),
    variables,
  });
};

const MUTATION_PROJECT_DETAILS = gql`
  mutation($id: LongLong!, $input: InprojectProjectDetails!) {
    updateProjectDetails(id: $id, input: $input) {
      ok
      project {
        id
        name
        category {
          id
          code
          name
        }
        updatedAt
      }
      errors {
        path
        message
      }
    }
  }
`;

export const UpdateProjectDetails = variables => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_PROJECT_DETAILS),
    variables,
  });
};

const MUTATION_PROJECT_NOTIFICATIONS = gql`
  mutation($id: LongLong!, $notifications: InputNotification!) {
    updateProjectNotifications(id: $id, notifications: $notifications) {
      ok
      notifications {
        id
        emails
        messages
        uploads
        chat
        updatedAt
      }
      errors {
        path
        message
      }
    }
  }
`;

export const UpdateProjectNotifications = (id, notifications) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_PROJECT_NOTIFICATIONS),
    variables: {
      id,
      notifications,
    },
  });
};

const MUTATION_PROJECT_ADD_MEMBERS = gql`
  mutation($idProject: LongLong!, $membersIds: [Int!]!) {
    AddProjectMembers(idProject: $idProject, membersIds: $membersIds) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const AddProjectMembers = (idProject, membersIds) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_PROJECT_ADD_MEMBERS),
    variables: {
      idProject,
      membersIds,
    },
  });
};

const MUTATION_PROJECT_REMOVE_MEMBERS = gql`
  mutation($idProject: LongLong!, $membersIds: [Int!], $emails: [String!]) {
    RemoveProjectMembers(
      idProject: $idProject
      membersIds: $membersIds
      emails: $emails
    ) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const RemoveProjectMembers = (idProject, membersIds, emails) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_PROJECT_REMOVE_MEMBERS),
    variables: {
      idProject,
      membersIds,
      emails,
    },
  });
};
const QUERY_PROJECT_SITES = gql`
  query($idProject: LongLong!, $name: String, $page: Int, $limit: Int) {
    unitsAtProject(
      idProject: $idProject
      name: $name
      page: $page
      limit: $limit
    ) {
      idUnit
      Admin {
        id
        FullName
        email
      }
      name
      description
      country
      city
      createdAt
      updatedAt
    }
  }
`;

export const unitsAtProject = (idProject, name, page, limit) => {
  const variables = { idProject };
  if (name !== undefined && name) {
    variables.name = name;
  }
  if (page !== undefined && limit !== undefined) {
    variables.page = page;
    variables.limit = limit;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PROJECT_SITES),
    variables,
  });
};
const QUERY_PROJECT_CENTERS = gql`
  query($idProject: LongLong!, $name: String, $page: Int, $limit: Int) {
    projectCenters(
      idProject: $idProject
      name: $name
      page: $page
      limit: $limit
    ) {
      ok
      total
      project_centers {
        id
        idCenter
        idProject
        idFolder
        code
        folder_name
        short_name
        folder {
          idFolder
          name
        }
        center {
          id
          idAdmin
          admin {
            id
            FullName
          }
          name
          address {
            city
            country
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      errors {
        path
        message
      }
    }
  }
`;
export const QueryProjectCenter = (idProject, name, page, limit) => {
  const variables = { idProject };
  if (name !== undefined && name) {
    variables.name = name;
  }
  if (page !== undefined && limit !== undefined) {
    variables.page = page;
    variables.limit = limit;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PROJECT_CENTERS),
    variables,
  });
};
const MUTATION_ADD_PROJECT_CENTERS = gql`
  mutation(
    $idProject: LongLong!
    $ids: [LongLong!]
    $codes: [InputProjectCenter!]
  ) {
    addCentersToProject(idProject: $idProject, ids: $ids, codes: $codes) {
      ok
      errors {
        path
        message
      }
      project_centers {
        id
        idCenter
        idProject
        code
        center {
          id
          idAdmin
          admin {
            id
            FullName
          }
          name
          address {
            city
            country
          }
          createdAt
          updatedAt
        }
        updatedAt
      }
    }
  }
`;
export const MutationAddProjectCenter = (idProject, ids, codes) => {
  console.log('MutationAddProjectCenter', { idProject, ids, codes });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_PROJECT_CENTERS),
    variables: {
      idProject,
      ids,
      codes,
    },
  });
};
const MUTATION_DELETE_PROJECT_CENTERS = gql`
  mutation($idProject: LongLong!, $ids: [LongLong!]!) {
    removeCenterFromProject(idProject: $idProject, ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDeleteProjectCenter = (idProject, ids) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_PROJECT_CENTERS),
    variables: {
      idProject,
      ids,
    },
  });
};

const MUTATION_DELETE_PROJECT_TEAMS = gql`
  mutation($idProject: LongLong!, $ids: [LongLong!]!) {
    removeTeamsFromProjects(idProject: $idProject, ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDeleteProjectTeams = (idProject, ids) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_PROJECT_TEAMS),
    variables: {
      idProject,
      ids,
    },
  });
};
const MUTATION_ADD_PROJECT_TEAMS = gql`
  mutation($idProject: LongLong!, $ids: [InputProjectTeam!]!) {
    addTeamsToProject(idProject: $idProject, ids: $ids) {
      ok
      errors {
        path
        message
      }
      total
      pc_teams {
        id

        idCenterTeam
        idProject

        members
        center_team {
          center {
            id
            name
            admin {
              id
              FullName
            }
            address {
              city
              country
            }
          }
          team {
            id
            name
            admin {
              id
              FullName
            }
            avatar
            createdAt
            updatedAt
          }
        }
        project {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationAddProjectTeams = (idProject, ids) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_PROJECT_TEAMS),
    variables: {
      idProject,
      ids,
    },
  });
};
const QUERY_PROJECT_TEAMS = gql`
  query($idProject: LongLong!, $name: String, $page: Int, $limit: Int) {
    projectTeams(
      idProject: $idProject
      name: $name
      page: $page
      limit: $limit
    ) {
      ok
      errors {
        path
        message
      }
      total
      pc_teams {
        id

        idCenterTeam
        idProject

        members
        center_team {
          id
          center {
            id
            name
            admin {
              id
              FullName
            }
            address {
              city
              country
            }
          }
          team {
            id
            name
            admin {
              id
              FullName
            }
            avatar
            createdAt
            updatedAt
          }
        }
        project {
          id
          name
        }

        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryProjectTeams = (idProject, name, page, limit) => {
  const variables = { idProject };
  if (name !== undefined && name) {
    variables.name = name;
  }
  if (page !== undefined && limit !== undefined) {
    variables.page = page;
    variables.limit = limit;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PROJECT_TEAMS),
    variables,
  });
};

const QUERY_PROJECT_SETTING = gql`
  query($idProject: LongLong!, $page: Int, $limit: Int) {
    projectSetting(idProject: $idProject, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      project_setting {
        id

        idProject
        private_folder
        delete_folder
        storage

        file_size
        email
        secondary_emails
        patient_code
        patient_name_id

        project {
          id
          name
        }
        member_settings {
          id

          idProject
          idUser

          access_level
          email_notification
          upload_notification

          user {
            id
            FullName
            avatar
          }

          updatedAt
          createdAt
        }
        updatedAt
        createdAt
      }
    }
  }
`;

export const QueryProjectSettings = (idProject, page, limit) => {
  console.log('QueryProjectSettings', { idProject });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PROJECT_SETTING),
    variables: {
      idProject,
      page,
      limit,
    },
  });
};
const MUTATION_UPDATE_PROJECT_SETTING = gql`
  mutation($setting: InputProjectSetting!) {
    updateProjectSetting(setting: $setting) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationUpdateProjectSettings = setting => {
  console.log('MutationUpdateProjectSettings', { setting });
  delete setting.updatedAt;
  delete setting.createdAt;
  delete setting.project;
  delete setting.member_settings;
  console.log('MutationUpdateProjectSettings', { setting });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_PROJECT_SETTING),
    variables: {
      setting,
    },
  });
};
const MUTATION_DELETE_PROJECT_MEMBER_SETTING = gql`
  mutation($ids: [LongLong!]!) {
    deleteProjectMemberSetting(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationDeleteProjectMembersSettings = ids => {
  console.log('MutationDeleteProjectMembersSettings', { ids });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_PROJECT_MEMBER_SETTING),
    variables: {
      ids,
    },
  });
};

const MUTATION_UPDATE_PROJECT_MEMBER_SETTING = gql`
  mutation($members: [InputProjectMemberSetting!]!) {
    updateProjectMemberSetting(members: $members) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationUpdateProjectMemberSettings = members => {
  console.log('MutationUpdateProjectMemberSettings', { members });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_PROJECT_MEMBER_SETTING),
    variables: {
      members: members.map(x => {
        const {
          id,
          idProject,
          idUser,
          access_level,
          email_notification,
          upload_notification,
        } = x;
        return {
          id,
          idProject,
          idUser,
          access_level,
          email_notification,
          upload_notification,
        };
      }),
    },
  });
};
const QUERY_PROJECT_MEMBERS_SETTING = gql`
  query($idProject: LongLong!, $name: String, $page: Int, $limit: Int) {
    projectMemberSetting(
      idProject: $idProject
      name: $name
      page: $page
      limit: $limit
    ) {
      ok
      errors {
        path
        message
      }
      total
      member_settings {
        id

        idProject
        idUser

        access_level
        email_notification
        upload_notification

        user {
          id
          FullName
          avatar
        }

        updatedAt
        createdAt
      }
    }
  }
`;
export const QueryProjectMembersSettings = (idProject, name, page, limit) => {
  console.log('QueryProjectMembersSettings', { idProject, name, page, limit });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PROJECT_MEMBERS_SETTING),
    variables: {
      idProject,
      name,
      page,
      limit,
    },
  });
};
const MUTATION_CREATE_PROJECT_MEMBER_SETTING = gql`
  mutation($members: [InputProjectMemberSetting!]!) {
    createProjectMemberSetting(members: $members) {
      ok
      errors {
        path
        message
      }
      total
      member_settings {
        id

        idProject
        idUser

        access_level
        email_notification
        upload_notification

        user {
          id
          FullName
          avatar
        }

        updatedAt
        createdAt
      }
    }
  }
`;
export const MutationCreateProjectMemberSettings = members => {
  console.log('MutationCreateProjectMemberSettings', { members });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_CREATE_PROJECT_MEMBER_SETTING),
    variables: {
      members: members.map(x => {
        const { idProject, idUser } = x;
        return {
          idProject,
          idUser,
        };
      }),
    },
  });
};
const MUTATION_UPDATE_PROJECT_CENTERS = gql`
  mutation($inputs: [InputProjectCenter!]!) {
    updateProjectCenters(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      total
      project_centers {
        id
        idCenter
        idProject
        code
        folder_name
        short_name
        folder {
          idFolder
          name
        }
        center {
          id
          idAdmin
          admin {
            id
            FullName
          }
          name
          address {
            city
            country
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const MutationUpdateProjectCenters = inputs => {
  console.log('MutationUpdateProjectCenters', { inputs });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_PROJECT_CENTERS),
    variables: {
      inputs,
    },
  });
};
const MUTATION_DELETE_PROJECT = gql`
  mutation($ids: [LongLong!]!) {
    deleteProjects(ids: $ids) {
      ok
      errors {
        path
        message
      }
      failed
    }
  }
`;

export const MutationDeleteProjects = ids => {
  console.log('MutationDeleteProjects', { ids });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_PROJECT),
    variables: {
      ids,
    },
  });
};

const QUERY_USER_PROJECTS = gql`
  query($idUser: LongLong!, $name: String, $page: Int, $limit: Int) {
    userProjects(idUser: $idUser, name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      projects {
        id
        name
        category {
          id
          code
          name
        }
        admin {
          id
          FullName
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryUserProjects = (idUser, name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_USER_PROJECTS),
    variables: {
      idUser,
      name,
      page,
      limit,
    },
  });
};
