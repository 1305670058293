/* eslint-disable react/jsx-key */
import React from 'react';
import { Divider, Paper, Tab, Tabs } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import PropTypes from 'prop-types';

import {
  Patients,
  Elements,
  Logs,
  Projects,
  RegistrationRequests,
  Teams,
  Units,
  Mapping,
} from './components';
import { getParams, getTabLabel } from 'helpers';
import { TabPanel } from 'components';

const useStyles = () => ({
  root: {
    display: 'flex',
  },
  channels: {
    width: '40%',
    height: '100%',
    overflowY: 'scroll',
    flexGrow: 1,
  },
  content: {
    height: '100%',
    overflowY: 'scroll',
    flexGrow: 1,
  },
});
class Management extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        { name: 'Patients', TabComponent: Patients },
        { name: 'Elements', TabComponent: Elements },
        { name: 'Teams', TabComponent: Teams },
        { name: 'Projects', TabComponent: Projects },
        {
          name: 'Registration Requests',
          TabComponent: RegistrationRequests,
        },
        { name: 'Units', TabComponent: Units },
        { name: 'Logs', TabComponent: Logs },
        { name: 'Mapping', TabComponent: Mapping },
      ],
      error: '',
    };
  }

  handleTabValue = (event, newValue) => {
    event.preventDefault();
    const { history } = this.props;
    const { tabs } = this.state;
    newValue = parseInt(newValue, 10);
    if (newValue >= 0 && newValue < tabs.length) {
      history.push(`/management/${getTabLabel(tabs[newValue].name)}`);
    } else {
      history.push('/management/patients');
    }
  };
  render() {
    const { classes, height, isDesktop, history, match, user } = this.props;
    const { tabs } = this.state;
    let { item, level_1 } = getParams(this.props);
    const index = tabs.map(x => getTabLabel(x.name)).indexOf(item);
    let tabValue = 0;
    let teamId = undefined;
    if (index !== -1) {
      tabValue = index;
    }
    if (index === this.state.tabs.map(x => x.name).indexOf('Teams')) {
      teamId = level_1;
    }
    tabValue = parseInt(tabValue, 10);
    return (
      <React.Fragment>
        <Paper square>
          <Tabs
            indicatorColor="primary"
            onChange={this.handleTabValue}
            scrollButtons="auto"
            textColor="primary"
            value={tabValue}
            variant="scrollable">
            {tabs.map((tab, index) => {
              return <Tab key={`tab-${index}`} label={tab.name} />;
            })}
          </Tabs>
          <Divider />
        </Paper>
        <div style={{ width: '100%' }}>
          <div className={classes.tab_content}>
            {tabs.map((tab, index) => {
              const { TabComponent } = tab;
              return (
                <TabPanel
                  className={classes.tab_panel}
                  index={index}
                  key={`management-tab-panel-${index}`}
                  value={tabValue}>
                  <TabComponent
                    handleAddError={this.props.handleAddError}
                    handleAddSuccess={this.props.handleAddSuccess}
                    height={height}
                    history={history}
                    isActive={index === tabValue}
                    isDesktop={isDesktop}
                    match={match}
                    teamId={teamId}
                    user={user}
                  />
                </TabPanel>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Management.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(Management, useStyles);
