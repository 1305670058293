import React, { useState } from 'react';
// import { TrendingUp } from 'lucide-react';
import Chart from 'react-apexcharts';
import { Card, Typography, Grid } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const SalesMetricsCard = ({ fullScreen }) => {
  const [activeQuarter, setActiveQuarter] = useState(0);
  // const [labelQuarter, setLabelQuarter] = useState('1 Quarter');
  console.log({ activeQuarter });
  const chartOptions = {
    chart: {
      type: 'donut',
      width: '100%',
      height: '100%',
      selection: {
        enabled: false, // Disable selection behavior (removes borders on click)
      },
      animations: {
        enabled: false, // Disable animations
      },
      events: {
        click: () => {},
        dataPointSelection: (event, chartContext, config) => {
          console.log(config.w.config.labels[config.dataPointIndex]);
        },
        dataPointMouseEnter: (event, chartContext, config) => {
          const label = config.w.config.labels[config.dataPointIndex];
          const value = config.w.config.series[config.dataPointIndex];
          console.log({ label, value });
          setActiveQuarter(config.dataPointIndex);
          // setLabelQuarter(chartOptions.series[config.dataPointIndex] + '%');
        },
        dataPointMouseLeave: () => {
          setActiveQuarter(0);
          // setLabelQuarter('1 Quarter');
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false, // Hide legends
    },
    states: {
      hover: {
        filter: {
          type: 'none', // Disable hover effects
        },
      },
      active: {
        filter: {
          type: 'none', // Disable active (click) effects
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '72%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '16px',
              fontWeight: 900,
              color: '#111827',
              offsetY: 0,
              formatter: function() {
                return chartOptions.series[activeQuarter] + '%';
              },
            },
            value: {
              show: true,
              fontSize: '12px',
              color: '#6b7280',
              offsetY: 0,
              formatter: function() {
                return `${activeQuarter + 1} Quarter`;
              },
            },
            total: {
              show: true,
              fontSize: '16px',
              fontWeight: 900,
              formatter: function() {
                return `${activeQuarter + 1} Quarter`;
              },
            },
          },
        },
      },
    },
    // fill: {
    //   colors: undefined,
    //   opacity: 0.9,
    //   type: 'solid',
    //   pattern: {
    //     // style: 'verticalLines',
    //     // width: 6,
    //     // height: 6,
    //     strokeWidth: 8,
    //   },
    // },
    stroke: {
      width: 5, // Defines the gap between slices
      colors: ['#fff'],
    },
    // plotOptions: {
    //   radialBar: {
    //     hollow: {
    //       size: '60%',
    //       margin: 0,
    //     },
    // dataLabels: {
    //   show: true,
    //   name: {
    //     show: true,
    //     fontSize: '12px',
    //     color: '#6b7280',
    //     offsetY: 5,
    //   },
    //   value: {
    //     show: true,
    //     fontSize: '16px',
    //     fontWeight: 600,
    //     color: '#111827',
    //     offsetY: -5,
    //     formatter: function() {
    //       return chartOptions.series[activeQuarter] + '%';
    //     },
    //   },
    //   total: {
    //     show: true,
    //     label: `Q${activeQuarter + 1}`,
    //   },
    // },
    //   track: {
    //     background: '#e5e7eb',
    //     margin: 0,
    //   },
    // },
    // },
    colors: ['#6366f1', '#ec4899', '#3b82f6', '#f59e0b'],
    labels: ['Q1', 'Q2', 'Q3', 'Q4'],
    series: [28, 22, 40, 10],
  };
  // const state = {
  //   series: [44, 55, 41, 17, 15],
  //   options: {
  //     chart: {
  //       type: 'donut',
  //     },
  //     responsive: [
  //       {
  //         breakpoint: 480,
  //         options: {
  //           chart: {
  //             width: 200,
  //           },
  //           legend: {
  //             position: 'bottom',
  //           },
  //         },
  //       },
  //     ],
  //   },
  // };
  return (
    <Card
      sx={{
        p: 2, // padding
        paddingTop: 1,
        paddingBottom: 1,
        borderRadius: 2,
        maxWidth: '24rem', // max-width: 384px (24rem in Tailwind)
        backgroundColor: 'white',
        boxShadow: 1, // equivalent to shadow-sm
        width: '100%', // w-full
        border: '1px solid #e0e0e0',
      }}>
      <Grid container>
        <Grid item xs={6}>
          <div className="flex flex-col justify-between">
            <div className="flex flex-col gap-1">
              <Typography
                sx={{
                  margin: 0,
                  fontFamily: '"__Inter_5475bd","__Inter_Fallback_5475bd"',
                  fontWeight: 900,
                  fontSize: '1.5rem',
                  lineHeight: 1.5556,
                  color: 'var(--mui-palette-text-primary)',
                  '--mui-palette-text-primary':
                    'rgb(var(--mui-mainColorChannels-light) / 0.9)',
                  '--mui-mainColorChannels-light': '46 38 61',
                }}
                variant="h5">
                Total Sales
              </Typography>
              <Typography
                component="p"
                sx={{
                  margin: 0,
                  fontFamily: '"__Inter_5475bd","__Inter_Fallback_5475bd"',
                  fontWeight: 500,
                  fontSize: '0.9375rem',
                  lineHeight: 1.46667,
                  color: 'var(--mui-palette-text-secondary)',
                  '--mui-palette-text-secondary':
                    'rgb(var(--mui-mainColorChannels-light) / 0.7)',
                  '--mui-mainColorChannels-light': '46 38 61',
                }}
                variant="body1">
                Calculated in last 7 days
              </Typography>
            </div>
            <div className="flex items-baseline justify-between">
              <div className="space-y-1">
                <Typography
                  sx={{
                    margin: 0,
                    fontFamily: '"__Inter_5475bd","__Inter_Fallback_5475bd"',
                    fontWeight: 700,
                    fontSize: '1.5rem',
                    lineHeight: 1.58334,
                    color: 'var(--mui-palette-text-primary)',
                    '--mui-palette-text-primary':
                      'rgb(var(--mui-mainColorChannels-light) / 0.9)',
                    '--mui-mainColorChannels-light': '46 38 61',
                  }}
                  variant="h4">
                  $25,980
                </Typography>
                <Grid
                  container
                  direction="row"
                  sx={{
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}>
                  <KeyboardArrowUpIcon sx={{ color: '#56CA00' }} />
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: '20px',
                      color: '#56CA00',
                    }}>
                    15.6%
                  </span>
                </Grid>
              </div>

              <div className="w-24 h-24" />
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%', // Adjust container height as needed
              width: '100%', // Full width
              maxWidth: '500px', // Set max width for the container
              margin: '0 auto',
            }}>
            <Chart
              // height="auto"
              options={chartOptions}
              series={chartOptions.series}
              type="donut"
              width={fullScreen ? 190 : 160}
            />
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default SalesMetricsCard;
