import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { GetData, rowsPerPageBig, ServerErrorsString } from 'helpers';
import { TableGrid } from 'components';
import { ToolBarAnonyRules, columns } from './components';
import { QueryAnonymizationRules } from 'graphql/Anonymization';

class TabAnonymizationRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      loading: false,
      rules: [],
      rows_edited: [],
      page: 0,
      limit: 100,
    };
  }
  componentDidMount() {
    this.queryAnonymizationRules();
  }
  queryAnonymizationRules = (name = null) => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryAnonymizationRules(name)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, rules } = data.anonymizationRules;
          if (ok) {
            this.setState({ loading: false, rules });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handelAddRules = newRules => {
    let { rules } = this.state;
    if (!rules.length) {
      rules = newRules;
    } else {
      for (let i = 0; i < newRules.length; i++) {
        const { tag } = newRules[i];
        const index = rules.map(x => x.tag).indexOf(tag);
        if (index === -1) rules.push(newRules[i]);
      }
    }
    this.setState({ rules });
  };
  render() {
    const { height } = this.props;
    const { loading } = this.state;
    return (
      <Grid container>
        <Grid item xs={12}>
          <ToolBarAnonyRules
            handelAddRules={this.handelAddRules}
            handleAddError={this.props.handleAddError}
            handleClearSelected={() => this.setState({ selected: [] })}
            handleDeleteSelected={this.handleDeleteSelected}
            handleReloadTable={search => this.queryAnonymizationRules(search)}
            loading={loading}
            rows_edited={this.state.rows_edited}
            selected={this.state.selected}
            user={this.props.user}
          />
        </Grid>
        <Grid item sx={{ paddingTop: 1 }} xs={12}>
          <TableGrid
            // checkboxSelection
            dense
            disableSelectionOnClick
            external_edited={this.state.rows_edited}
            external_selected={this.state.selected}
            grid
            headers={columns}
            height={height - 240}
            limit={this.state.limit}
            loading={loading}
            onRowEditedChanged={rows_edited => this.setState({ rows_edited })}
            onSelectedChanged={selected => this.setState({ selected })}
            page={this.state.page}
            rows={this.state.rules}
            rowsPerPage={this.state.limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </Grid>
      </Grid>
    );
  }
}

TabAnonymizationRules.propTypes = {
  classes: PropTypes.object,
  height: PropTypes.number,
};

export default TabAnonymizationRules;
