import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  Divider,
  // IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import Draggable from 'react-draggable';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

class CardBlocks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      position: { x: 0, y: 0 },
      normalview: true,
      minWidth: 300,
      maxWidth: 345,
    };
  }
  handleClick = (event, button) => {
    event.preventDefault();
    const action = button[0];
    let { normalview, minWidth, maxWidth } = this.state;

    if (action === 'close') this.props.onActionClick();
    else if (action === 'full_screen') {
      minWidth = this.props.width;
      maxWidth = this.props.width;
      normalview = false;
    } else {
      minWidth = 300;
      maxWidth = 345;
      normalview = true;
    }
    this.setState({ normalview, minWidth, maxWidth });
    this.props.setState({ normalview, minWidth, maxWidth });
  };
  render() {
    const { height, children } = this.props;
    let style = { minHeight: 490 };
    if (height < 500) style = { ...style, height: height - 200 };
    let { normalview, minWidth, maxWidth } = this.state;
    let position = this.props.position;
    if (!normalview) {
      style = { ...style, height: height - 60, zIndex: 1100 };
      position = { x: 0, y: 0 };
      minWidth = this.props.width;
      maxWidth = this.props.width;
    }
    return (
      <Draggable
        handle=".MuiCardHeader-root"
        onDrag={this.props.onDrag}
        onDragEnd={this.props.onDragEnd}
        position={position}>
        <Card
          ref={this.props.cardRef}
          sx={{
            position: 'fixed',
            minWidth: minWidth,
            maxWidth: maxWidth,
            top: normalview ? 170 : 60,
            left: normalview ? this.props.width - 310 : 0,
            backgroundColor: '#ffffff',
            borderRadius: '15px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            paddingBottom: 0,
            ...style,
          }}>
          <CardHeader
            action={
              // <IconButton
              //   aria-label={`add_blocks_card-${this.props.title}`}
              //   onClick={this.props.onActionClick}
              //   size="small">
              //   {this.props.action_icon}
              // </IconButton>
              <ToggleButtonGroup
                aria-label="Small sizes"
                exclusive={false}
                onChange={this.handleClick}
                size="small">
                <ToggleButton
                  disabled={this.state.normalview}
                  key="close_full_screen"
                  value="close_full_screen">
                  <CloseFullscreenIcon />
                </ToggleButton>
                <ToggleButton
                  disabled={!this.state.normalview}
                  key="full_screen"
                  value="full_screen">
                  <FullscreenIcon />
                </ToggleButton>
                <ToggleButton key="close" value="close">
                  <CloseIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            }
            sx={{
              cursor: this.props.disable_dragging ? 'default' : 'move',
              '& .MuiCardHeader-title': {
                fontSize: '20px', // Adjust font size
                fontWeight: 'bold', // Adjust font weight
                userSelect: 'none',
                pointerEvents: 'none',
              },
            }}
            title={this.props.title}
          />
          <Divider />
          {React.Children.map(children, function(child) {
            return React.cloneElement(child, {
              height: style.height ? style.height : 490,
            });
          })}
        </Card>
      </Draggable>
    );
  }
}

CardBlocks.propTypes = {
  classes: PropTypes.object,
};
CardBlocks.defaultProps = {
  width: undefined,
  height: undefined,
  action_icon: <CancelIcon />,
  onActionClick: () => '',
  onDrag: () => '',
  onDragEnd: () => '',
  cardRef: null,
  disable_dragging: false,
  position: { x: 0, y: 0 },
  title: '',
};

export default CardBlocks;
