import { getBlockConfig } from '../../utils_svg';
import { Block } from '../base';
import { drawCircle } from '../functions';

class CompleteBlock extends Block {
  constructor(
    x,
    y,
    self,
    width = 200,
    height = 30,
    config = getBlockConfig('Completed')
  ) {
    const contentHeight = 0; // 40px for top, 60px for bottom
    const bottomHeight = 0;
    super(x, y, width, height, config, contentHeight, bottomHeight, 240, 40);
    this.addPort('Input files', false); // Output port
  }
  drawContent(ctx) {
    super.drawContent(ctx);
    const { scaleX, scaleY } = this.getScalingFactor();
    const padding = 20 * scaleX;
    const topSeparatorY = this.y + this.topHeight; // Y-coordinate of the top separator
    // const bottomSeparatorY = this.y + this.height - this.bottomHeight * scaleY; // Y-coordinate of the bottom separator

    // Calculate position for "MRI Segmentation" title
    const titleSeparatorY = topSeparatorY + this.topHeight;
    const titleY = titleSeparatorY - 15 * scaleY; // Moved down 5 pixels from previous position

    // Draw "MRI Segmentation" title
    ctx.font = `bold ${14 * scaleY}px Arial`;
    ctx.fillStyle = '#000';
    ctx.fillText('Location: Folder', this.x + padding, titleY);
  }
  drawBase(ctx, config = {}) {
    super.drawBase(ctx, config);
    drawCircle(ctx, this.x + this.width - 15, this.y + 15, 0, 10);
  }
  draw(ctx, config = {}) {
    this.drawBase(ctx, config);
    this.drawPorts(ctx);
    this.drawSelection(ctx);
  }
}
export default CompleteBlock;
