/* eslint-disable quotes */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneAllIcon from '@mui/icons-material/DoneAll';
const fontFamily =
  ' "Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

const fontFamilyInter =
  ' "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"';

import { styled } from '@mui/material/styles';
import { FormatBytes, FormatTime } from 'helpers';
const w_size = 16;
const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: w_size,
  height: w_size,
  boxShadow:
    'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
    ...theme.applyStyles('dark', {
      backgroundColor: '#30404d',
    }),
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
    ...theme.applyStyles('dark', {
      background: 'rgba(57,75,89,.5)',
    }),
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
    backgroundColor: '#394b59',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
  }),
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: w_size,
    height: w_size,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});
class UploadRight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploaded: 0,
      progress: 0,
      selected: {
        src: '/images/activity/unknown.png',
        color: 'rgb(189 189 189/ 0.5)',
      },
      type: 'none',
    };
    const { holder } = props;
    if (holder) {
      holder.startUpload = () => this.handleUpload();
    }
  }
  handleImageType = event => {
    const type = event.target.value;
    let selected = {
      src: '/images/activity/unknown.png',
      color: 'rgb(189 189 189 / 0.3)',
    };
    if (type === 'dicom') {
      selected = {
        src: '/images/icons/dcm.png',
        color: 'rgb(189 189 189/ 0.5)',
      };
    } else if (type === 'nifti') {
      selected = {
        src: '/images/icons/nii.png',
        color: 'rgb(189 189 189 / 0.3)',
      };
    }
    this.setState({ type, selected, uploaded: 0, progress: 0 });
    this.props.setState({ disabled_all: type === 'none' ? true : false });
  };
  handleOnClick = () => {
    const { file } = this.props;
    this.props.onDelete(file);
    this.setState({
      uploaded: 0,
      progress: 0,
      selected: {
        src: '/images/activity/unknown.png',
        color: 'rgb(189 189 189/ 0.5)',
      },
      type: 'none',
    });
  };
  handleUpload = () => {
    this.startProgress();
  };
  componentWillUnmount() {
    // Clear the interval when the component unmounts
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
  startProgress = () => {
    console.log('startProgress');
    this.intervalId = setInterval(() => {
      this.setState(prevState => {
        if (prevState.progress >= 100) {
          clearInterval(this.intervalId); // Stop the interval when progress reaches 100
          this.props.handleFinshed();
          return { progress: 100 };
        }
        return {
          progress: prevState.progress + 1,
          uploaded: prevState.uploaded + 13,
        };
      });
    }, 100); // Update every 100ms
  };
  render() {
    const { selected, type, progress, uploaded } = this.state;
    const { file } = this.props;
    const radio = {
      '&.MuiRadio-root': {
        padding: '2px', // adjust this value
        paddingLeft: '9px',
        paddingRight: '6px',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '16px', // custom size for the radio icon
      },
    };
    let name = '';
    let size = '';
    let lastModified = undefined;
    if (file) {
      name = file.name;
      size = file.size;
      lastModified = file.lastModified;
    }
    return (
      <Paper sx={{ width: '100%', height: '100%', padding: 2, minHeight: 180 }}>
        <Grid container>
          <Grid
            container
            direction="row"
            item
            sx={{
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
            xs={12}>
            <Grid item xs={3}>
              <Avatar
                src={selected.src}
                sx={{
                  width: '80%',
                  height: '50%',
                  minHeight: 60,
                  backgroundColor: selected.color,
                  p: '2px',
                }}
                variant="rounded"
              />
            </Grid>
            <Grid
              container
              direction="row"
              item
              sx={{
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
              xs>
              <Grid
                container
                direction="row"
                item
                sx={{
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
                xs={12}>
                <IconButton disabled={!file} onClick={this.handleOnClick}>
                  <DeleteIcon sx={{ color: file ? 'red' : 'gray' }} />
                </IconButton>
              </Grid>
              <Grid
                container
                direction="row"
                item
                sx={{
                  margin: '2px 0px 2px',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
                xs={12}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      width: '100%',
                      fontFamily,
                      fontWeight: 600,
                      fontSize: '0.875rem',
                      lineHeight: 1.57143,
                      overflow: 'hidden',
                      display: ' -webkit-box',
                      textOverflow: 'ellipsis',
                      '-webkit-line-clamp': 2,
                      '-moz-box-orient': 'vertical',
                      height: '32px',
                    }}>
                    {name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      '-moz-box-align': 'center',
                      alignItems: 'center',
                      maxWidth: '100%',
                      fontWeight: 400,
                      fontSize: '0.75rem',
                      lineHeight: 1.5,
                      color: '#919EAB',
                      width: '99%',
                    }}>
                    {size !== '' ? (
                      <>
                        <div style={{ minWidth: 40, padding: 0, margin: 0 }}>
                          {FormatBytes(size)}
                        </div>
                        <Box
                          sx={{
                            marginLeft: '6px',
                            marginRight: '6px',
                            width: '2px',
                            height: '2px',
                            flexShrink: 0,
                            borderRadius: '50%',
                            backgroundColor: 'currentcolor',
                          }}
                        />
                      </>
                    ) : null}
                    <Typography
                      sx={{
                        margin: '0px',
                        fontFamily,
                        fontWeight: 400,
                        fontSize: '0.75rem',
                        lineHeight: 1.5,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '99%',
                        maxWidth: '99%',
                      }}>
                      {FormatTime(lastModified, 'MMM Do, YYYY')}
                      {/* 16 Nov 2024 5:10 pm */}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            item
            sx={{
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
            xs={12}>
            <Grid item xs={6}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  sx={{
                    fontFamily: fontFamilyInter,
                    fontWeight: 500,
                    fontSize: '0.875rem',
                    lineHeight: 1.57,
                  }}>
                  File Type
                </FormLabel>
                <RadioGroup
                  aria-labelledby="selection-file"
                  name="row-radio-buttons-group"
                  onChange={this.handleImageType}
                  row
                  value={type}>
                  <FormControlLabel
                    control={<Radio size="small" sx={radio} />}
                    label="None"
                    size="small"
                    style={{ marginRight: 4 }}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '12px', // or whatever size you want
                        marginRight: 0,
                      },
                    }}
                    value="none"
                  />
                  <FormControlLabel
                    control={<Radio size="small" sx={radio} />}
                    label="DICOM"
                    size="small"
                    style={{ marginRight: 4 }}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '12px', // or whatever size you want
                        marginRight: 0,
                      },
                    }}
                    value="dicom"
                  />
                  <FormControlLabel
                    control={<Radio size="small" sx={radio} />}
                    label="NIfTI"
                    style={{ marginRight: 0, maringLeft: '15px' }}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '12px', // or whatever size you want
                      },
                    }}
                    value="nifti"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checkedIcon={<BpCheckedIcon />}
                    size="small"
                    sx={{
                      padding: 0,
                      '&.MuiButtonBase-root.MuiCheckbox-root': {
                        padding: '2px',
                        paddingLeft: '8px',
                      },
                    }}
                  />
                }
                label="Image is Cropped"
                style={{ marginRight: 0 }}
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: '12px', // or whatever size you want
                    maring: 0,
                    padding: 0,
                  },
                }}
                value="cropped"
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            item
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            xs={12}>
            {progress ? (
              <>
                <LinearProgress
                  sx={{
                    minWidth: progress >= 100 ? '82%' : '88%',
                    // [`&.${linearProgressClasses.colorPrimary}`]: {
                    //   backgroundColor: 'red',
                    // },
                    [`& .${linearProgressClasses.bar}`]: {
                      backgroundColor: progress >= 100 ? 'green' : undefined,
                    },
                  }}
                  value={progress}
                  variant="determinate"
                />
                {progress >= 100 ? (
                  <DoneAllIcon sx={{ color: 'green' }} />
                ) : null}
                <Typography
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    '-moz-box-align': 'center',
                    alignItems: 'center',
                    maxWidth: '100%',
                    fontWeight: 400,
                    fontSize: '0.75rem',
                    lineHeight: 1.5,
                    color: '#919EAB',
                    width: '10%',
                  }}>
                  {FormatBytes(uploaded)}
                </Typography>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

UploadRight.propTypes = {
  classes: PropTypes.object,
};

export default UploadRight;
