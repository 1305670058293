import {
  QueryAimCardCases,
  MutationUploadAimCardAll,
  MutationRemoveAimCardCases,
  QueryAimCardPatients,
  MutationUpdateAimCardCase,
  MutationAddAimCardCase,
  MutationAddClinicalPatients
} from 'graphql/DataClinics';


export const QueryAimCard = (table, name, page, limit, search_query, anonymize) => {
  if (table === 'aim_card_cases') {
    return QueryAimCardCases(name, search_query, page, limit);
  } else if (table === 'aim_card_patient') {
    return QueryAimCardPatients(page, limit, anonymize);
  } else {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      resolve({
        data: { data: 1 },
      });
    });
  }
};

export const GetResponse = (table, data) => {
 
  if (table === 'aim_card_cases') {
    const { ok, errors, cases, total } = data.aimCardCases;
    return { ok, errors, total, rows: cases };
  } else if (table === 'aim_card_patient') {
    const { ok, errors, patients, total } = data.aimCardPatients;
    return { ok, errors, total, rows: patients };
  } else {
    return { ok: true, errors: null, total: 0, rows: [] };
  }
};

export const MutationRemoveAimCard = (table, ids) => {
  if (table === 'aim_card_cases') {
    return MutationRemoveAimCardCases(ids);
  } else {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      resolve({
        data: { data: 1 },
      });
    });
  }
};
export const GetRemoveResponse = (table, data) => {
  let reply = {
    ok: false,
    errors: [{ path: 'Invalid', message: 'Invalid table' }],
  };
  if (table === 'aim_card_cases') {
    reply = data.deleteSleepCases;
  }
  return reply;
};

export const MutationUploadAimCard = (table, file, handleProgress) => {
  return MutationUploadAimCardAll(file, handleProgress);
};
export const GetUploadResponse = (table, data) => {
  const { ok, errors, total, cases } = data.uploadSleep;
  return { ok, errors, total, new_rows: cases };
};

export const MutationAimCardDataUpdate = (table) => {
  if (table === 'aim_card_patient') {
    return MutationUpdateAimCardCase();
  }
};

export const MutationAddAimCard = (table, inputs) => {
  if (table === 'aim_card_cases') {
    return MutationAddAimCardCase(inputs);
  }  else if (table === 'aim_card_patient') {
    return MutationAddClinicalPatients(inputs, 'aim_card');
  } else {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      resolve({
        data: { data: 1 },
      });
    });
  }
};

export const GetAddResponse = (table, data) => {
  if (table === 'ssr_cases') {
    const { ok, errors, cases, total } = data.addAidCardCase;
    return { ok, errors, total, rew_rows: cases };
  } else if (table === 'aid_card_patient') {
    const { ok, errors, patients, total } = data.addPatientsClinics;
    return { ok, errors, total, rew_rows: patients };
  } else {
    return { ok: true, errors: null, total: 0, rew_rows: [] };
  }
};