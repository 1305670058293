import React from 'react';
import PropTypes from 'prop-types';
import { Grid, SvgIcon } from '@mui/material';
function parseSVGPaths(svgString) {
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(
    `<svg>${svgString}</svg>`,
    'image/svg+xml'
  );
  const paths = svgDoc.querySelectorAll('path');
  return Array.from(paths).map(path => ({
    d: path.getAttribute('d') || '',
    fill: path.getAttribute('fill') || 'none',
    stroke: path.getAttribute('stroke') || 'none',
    opacity: path.getAttribute('opacity') || '1',
    strokeWidth: path.getAttribute('stroke-width') || '1',
    strokeLinecap: path.getAttribute('stroke-linecap') || 'butt',
    strokeLinejoin: path.getAttribute('stroke-linejoin') || 'miter',
  }));
}
class AddBlockItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { item, isDragging, draggedItem, index } = this.props;
    const iw = 24;
    const ih = 24;
    let scale = iw / 32;
    let pathData = [];
    const { icon } = item;
    if (icon) {
      pathData = parseSVGPaths(icon.path);
      if (icon.size) {
        scale = iw / icon.size.width;
      }
      // console.log({ pathData });
    }
    if (isDragging && draggedItem.index === index) return null;
    if (draggedItem === item) {
      // console.log({ top, left });
    }
    return (
      <Grid
        container
        direction="row"
        sx={{
          padding: 0,
          margin: 0,
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}>
        <Grid item sx={{ width: iw, height: ih }}>
          {icon ? (
            <SvgIcon
              preserveAspectRatio="xMidYMid meet"
              sx={{ width: iw, height: ih }} // Set specific width and height
              viewBox={`0 0 ${iw} ${ih}`} // Adjust if your paths require a different scale
            >
              <g transform={`scale(${scale}) translate(0, 0)`}>
                {pathData.map((path, index) => (
                  <path
                    d={path.d}
                    fill={icon.fill ? icon.fill : '#FF5733'}
                    key={index}
                    opacity={path.opacity}
                    stroke={icon.stroke ? icon.stroke : '#3498db'}
                    strokeLinecap={path.strokeLinecap}
                    strokeLinejoin={path.strokeLinejoin}
                    strokeWidth={path.strokeWidth}
                  />
                ))}
              </g>
            </SvgIcon>
          ) : (
            <i className={`icon ${item.iconClass} w-6 h-6 mr-2`} />
          )}
        </Grid>
        <Grid item sx={{ paddingLeft: 1 }}>
          <span className="font-medium">{item.label}</span>
        </Grid>
      </Grid>
    );
  }
}

AddBlockItem.propTypes = {
  classes: PropTypes.object,
};

export default AddBlockItem;
