import {
  MutationAddEpilepsyCases,
  MutationAddEpilepsyResearchStudies,
  MutationAddEpilepsyResections,
  MutationAddEpilepsyOutcomes,
  MutationRemoveEpilepsyCases,
  MutationRemoveEpilepsyResearchStudies,
  MutationRemoveEpilepsyResections,
  MutationRemoveEpilepsyOutcomes,
  QueryEpilepsyCases,
  QueryEpilepsyResearchStudies,
  QueryEpilepsyResections,
  QueryEpilepsyOutcomes,
  MutationUploadEpilepsyAll,
  QueryEpilepsyPatients,
  MutationAddClinicalPatients,
} from 'graphql/DataClinics';

import * as searches from 'graphql/DataClinics/epilepsy/utils_search';

export const QueryEpilepsy = (table, name, page, limit, search_query, anonymize) => {
  if (table === 'epilepsy_case') {
    return QueryEpilepsyCases(name, search_query, page, limit);
  } else if (table === 'epilepsy_research_study') {
    return QueryEpilepsyResearchStudies(name, search_query, page, limit);
  } else if (table === 'epilepsy_resection') {
    return QueryEpilepsyResections(name, search_query, page, limit);
  } else if (table === 'epilepsy_outcome') {
    return QueryEpilepsyOutcomes(name, search_query, page, limit);
  } else if (table === 'patient') {
    return QueryEpilepsyPatients(page, limit, anonymize);
  } else if (table === 'all') {
    return QueryEpilepsyCases(name, search_query, page, limit);
  } else {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      resolve({
        data: { data: 1 },
      });
    });
  }
};
export const GetResponse = (table, data) => {
  if (table === 'epilepsy_case') {
    const { ok, errors, cases, total } = data.epilepsyCases;
    return { ok, errors, total, rows: cases };
  } else if (table === 'epilepsy_research_study') {
    const {
      ok,
      errors,
      research_studies,
      total,
    } = data.epilepsyResearchStudies;
    return { ok, errors, total, rows: research_studies };
  } else if (table === 'epilepsy_resection') {
    const { ok, errors, resections, total } = data.epilepsyResections;
    return { ok, errors, total, rows: resections };
  } else if (table === 'epilepsy_outcome') {
    const { ok, errors, outcomes, total } = data.epilepsyOutcomes;
    return { ok, errors, total, rows: outcomes };
  } else if (table === 'patient') {
    const { ok, errors, patients, total } = data.epilepsyPatients;
    return { ok, errors, total, rows: patients };
  } else if (table === 'all') {
    const { ok, errors, cases, total } = data.epilepsyCases;
    return { ok, errors, total, rows: cases };
  } else {
    return { ok: true, errors: null, total: 0, rows: [] };
  }
};
export const MutationRemoveEpilepsy = (table, ids) => {
  if (table === 'epilepsy_case') {
    return MutationRemoveEpilepsyCases(ids);
  } else if (table === 'epilepsy_research_study') {
    return MutationRemoveEpilepsyResearchStudies(ids);
  } else if (table === 'epilepsy_resection') {
    return MutationRemoveEpilepsyResections(ids);
  } else if (table === 'epilepsy_outcome') {
    return MutationRemoveEpilepsyOutcomes(ids);
  } else {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      resolve({
        data: { data: 1 },
      });
    });
  }
};
export const GetRemoveResponse = (table, data) => {
  let reply = {
    ok: false,
    errors: [{ path: 'Invalid', message: 'Invalid table' }],
  };
  if (table === 'epilepsy_case') {
    reply = data.deleteEpilepsyCases;
  } else if (table === 'epilepsy_research_study') {
    reply = data.deleteEpilepsyResearchStudies;
  } else if (table === 'epilepsy_resection') {
    reply = data.deleteEpilepsyResections;
  } else if (table === 'epilepsy_outcome') {
    reply = data.deleteEpilepsyOutcomes;
  }
  return reply;
};
export const MutationAddEpilepsy = (table, inputs) => {
  if (table === 'epilepsy_case') {
    return MutationAddEpilepsyCases(inputs);
  } else if (table === 'epilepsy_research_study') {
    return MutationAddEpilepsyResearchStudies(inputs);
  } else if (table === 'epilepsy_resection') {
    return MutationAddEpilepsyResections(inputs);
  } else if (table === 'epilepsy_outcome') {
    return MutationAddEpilepsyOutcomes(inputs);
  } else if (table === 'patient') {
    return MutationAddClinicalPatients(inputs, 'epilepsy');
  } else {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      resolve({
        data: { data: 1 },
      });
    });
  }
};

export const GetAddResponse = (table, data) => {
  if (table === 'epilepsy_case') {
    const { ok, errors, cases, total } = data.addEpilepsyCases;
    return { ok, errors, total, rew_rows: cases };
  } else if (table === 'epilepsy_research_study') {
    const {
      ok,
      errors,
      research_studies,
      total,
    } = data.addEpilepsyResearchStudies;
    return { ok, errors, total, rew_rows: research_studies };
  } else if (table === 'epilepsy_resection') {
    const { ok, errors, resections, total } = data.addEpilepsyResections;
    return { ok, errors, total, rew_rows: resections };
  } else if (table === 'epilepsy_outcome') {
    const { ok, errors, outcomes, total } = data.addEpilepsyOutcomes;
    return { ok, errors, total, rew_rows: outcomes };
  } else if (table === 'patient') {
    const { ok, errors, patients, total } = data.addPatientsClinics;
    return { ok, errors, total, rew_rows: patients };
  } else {
    return { ok: true, errors: null, total: 0, rew_rows: [] };
  }
};

export const MutationUploadEpilepsy = (table, file, handleProgress) => {
  return MutationUploadEpilepsyAll(file, handleProgress);
};
export const GetUploadResponse = (table, data) => {
  let { ok, errors, total, cases } = data.uploadEpilepsy;
  return { ok, errors, total, new_rows: cases };
};

export const searchEpilepsyTables = (text, table, page, limit) => {
  const functionName = `Search${table.charAt(0).toUpperCase() +
    table.slice(1)}`;
  const searchEpilepsyFunction = searches[functionName];
  // const functionsArray = Object.values(searches);
  // const searchEpilepsyFunction = functionsArray.find(
  //   func => func.name === functionName
  // );
  return searchEpilepsyFunction(text, page, limit, table);
};
