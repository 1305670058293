import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
// import { TextArea } from 'components/Texts';

class ProjectDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: '',
      set: false,
    };
    const { holder } = props;
    if (typeof holder !== 'undefined') {
      holder.getDescription = () => this.state.description;
    }
  }

  setFromProps = () => {
    const {
      project: { description },
    } = this.props;
    if (typeof description === 'undefined') return;
    this.setState({ description, set: true });
  };

  componentDidMount() {
    this.setFromProps();
  }

  componentDidUpdate() {
    if (!this.state.set) {
      this.setFromProps();
    }
  }

  handleChange = event => {
    event.persist();
    this.setState({ [event.target.name]: event.target.value });
    const {
      project: { description },
    } = this.props;
    if (typeof description === 'undefined') return;
    this.props.handleChange('description', description !== event.target.value);
  };

  render() {
    const { description } = this.state;
    const { admin } = this.props;
    return (
      <>
        {/* <TextArea
          disabled={!admin}
          fullWidth
          label="Project Description"
          name="description"
          onChange={this.handleChange}
          placeholder="Add a brief description of this project"
          value={description}
        /> */}
        <TextField
          disabled={!admin}
          fullWidth
          id="project-description"
          InputLabelProps={{
            shrink: true,
          }}
          label="Project Description"
          margin="normal"
          maxRows={4}
          multiline
          name="description"
          onChange={this.handleChange}
          placeholder="Add a brief description of this project"
          value={description}
          variant="outlined"
        />
      </>
    );
  }
}

ProjectDescription.propTypes = {
  classes: PropTypes.object,
};
ProjectDescription.defaultProps = {
  admin: false,
};

export default ProjectDescription;
