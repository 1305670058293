import { short_patients } from '../../PatientClinics/components';

const parseIntegerValue = (row, value, field) => {
  let parsedValue = parseInt(value);
  if (!isNaN(parsedValue)) {
    row[field] = parsedValue;
  } else {
    row[field] = 0;
  }
  return row;
};

export const cases = [
  { field: 'idCase', headerName: 'Idcase', width: 100, align: 'left' },
  {
    field: 'arrival_date',
    headerName: 'Arrival Date',
    width: 157,
    align: 'left',
    type: 'date',
    edit: true,
  },
  {
    field: 'event_type',
    headerName: 'Event Type',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'onset_known',
    headerName: 'Onset Known',
    width: 75,
    align: 'left',
    type: 'boolean',
  },
  {
    field: 'onset_time',
    headerName: 'Onset Time',
    width: 222,
    align: 'left',
    type: 'datetime',
  },
  {
    field: 'wakeup_time',
    headerName: 'Wakeup Time',
    width: 222,
    align: 'left',
    type: 'datetime',
    edit: true,
  },
  { field: 'referral', headerName: 'Referral', width: 75, align: 'left' },
  {
    field: 'referring_unitcenter',
    headerName: 'Referring Unitcenter',
    width: 75,
    align: 'left',
  },
  {
    field: 'transport',
    headerName: 'Transport',
    width: 75,
    align: 'left',
    type: 'categories',
    edit: true,
    options: ['Ambulance', 'Taxi', 'Friends/Family', 'Other'],
  },
  { field: 'living_pre', headerName: 'Living Pre', width: 75, align: 'left' },
  {
    field: 'disability_pre',
    headerName: 'Disability Pre',
    width: 75,
    align: 'left',
  },
  {
    field: 'stroke_syndrome',
    headerName: 'Stroke Syndrome',
    width: 75,
    align: 'left',
  },
  {
    field: 'nih_admission',
    headerName: 'Nih Admission',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'nih_admission');
    },
  },
  {
    field: 'gcs_admission',
    headerName: 'Gcs Admission',
    width: 75,
    align: 'left',
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'gcs_admission');
    },
  },
  {
    field: 'bp_syst',
    headerName: 'Bp Syst',
    width: 75,
    align: 'left',
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'bp_syst');
    },
  },
  {
    field: 'bp_diast',
    headerName: 'Bp Diast',
    width: 75,
    align: 'left',
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'bp_diast');
    },
  },
  {
    field: 'sequences',
    headerName: 'Sequences',
    width: 75,
    edit: false,
    align: 'left',
    // editerGetter: (row, value) => {
    //   row['tot_files'] = parseInt(value);
    //   return row;
    // },
  },
  {
    field: 'tot_files',
    headerName: 'Total files',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_files'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_series',
    headerName: 'Total series',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_series'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_edfs',
    headerName: 'Total edfs',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_edfs'] = parseInt(value);
      return row;
    },
  },
];

export const encounter = [
  { field: 'idCase', headerName: 'Idcase', width: 75, align: 'left' },
  {
    field: 'followimage_type',
    headerName: 'Followimage Type',
    width: 75,
    align: 'left',
  },
  {
    field: 'followimage_result',
    headerName: 'Followimage Result',
    width: 75,
    align: 'left',
  },
  {
    field: 'follow_mra',
    headerName: 'Follow Mra',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'follow_cta',
    headerName: 'Follow Cta',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'follow_ultrasound',
    headerName: 'Follow Ultrasound',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'follow_dsa',
    headerName: 'Follow Dsa',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'follow_tte',
    headerName: 'Follow Tte',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'follow_tee',
    headerName: 'Follow Tee',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'follow_holter',
    headerName: 'Follow Holter',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'follow_holter_length',
    headerName: 'Follow Holter Length',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'follow_holter_length');
    },
  },
  {
    field: 'mca',
    headerName: 'Mca',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'aca',
    headerName: 'Aca',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'pca',
    headerName: 'Pca',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'vertebrobasilar',
    headerName: 'Vertebrobasilar',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'ocular',
    headerName: 'Ocular',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'etiology_toast',
    headerName: 'Etiology Toast',
    width: 75,
    align: 'left',
  },
  {
    field: 'etiology_toast_other',
    headerName: 'Etiology Toast Other',
    width: 75,
    align: 'left',
  },
  { field: 'etiology', headerName: 'Etiology', width: 75, align: 'left' },
  {
    field: 'etiology_other',
    headerName: 'Etiology Other',
    width: 75,
    align: 'left',
  },
  {
    field: 'stroke_pre',
    headerName: 'Stroke Pre',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'tia_pre',
    headerName: 'Tia Pre',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'ich_pre',
    headerName: 'Ich Pre',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'hypertension',
    headerName: 'Hypertension',
    width: 75,
    align: 'left',
    type: 'boolean',
  },
  {
    field: 'diabetes',
    headerName: 'Diabetes',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'hyperlipidemia',
    headerName: 'Hyperlipidemia',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'smoking',
    headerName: 'Smoking',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'atrialfib',
    headerName: 'Atrialfib',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'chd',
    headerName: 'Chd',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'prostheticvalves',
    headerName: 'Prostheticvalves',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'lowoutput',
    headerName: 'Lowoutput',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'pad',
    headerName: 'Pad',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'sleep_average',
    headerName: 'Sleep Average',
    width: 75,
    align: 'left',
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'sleep_average');
    },
  },
  {
    field: 'sleep_lastnight',
    headerName: 'Sleep Lastnight',
    width: 75,
    align: 'left',
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'sleep_lastnight');
    },
  },
  {
    field: 'snoring',
    headerName: 'Snoring',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'daytime_sleepiness',
    headerName: 'Daytime Sleepiness',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'glucose',
    headerName: 'Glucose',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'glucose');
    },
  },
  {
    field: 'cholesterol_total',
    headerName: 'Cholesterol Total',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'cholesterol_total');
    },
  },
  {
    field: 'cholesterol_ldl',
    headerName: 'Cholesterol Ldl',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'cholesterol_ldl');
    },
  },
  {
    field: 'creatinine_ldl',
    headerName: 'Creatinine Ldl',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'creatinine_ldl');
    },
  },
  {
    field: 'nih_24h',
    headerName: 'Nih 24H',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'nih_24h');
    },
  },
  {
    field: 'ich_sympt_hospital',
    headerName: 'Ich Sympt Hospital',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'ich_sympt_hosp_date',
    headerName: 'Ich Sympt Hosp Date',
    width: 157,
    align: 'left',
    type: 'date',
  },
  {
    field: 'stroke_hospital',
    headerName: 'Stroke Hospital',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'stroke_hospital_date',
    headerName: 'Stroke Hospital Date',
    width: 157,
    align: 'left',
    type: 'date',
  },
  {
    field: 'angioedema',
    headerName: 'Angioedema',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'seizure_hospital',
    headerName: 'Seizure Hospital',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'seizure_hosp_date',
    headerName: 'Seizure Hosp Date',
    width: 157,
    align: 'left',
    type: 'date',
  },
  {
    field: 'decompression',
    headerName: 'Decompression',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'decompression_date',
    headerName: 'Decompression Date',
    width: 157,
    align: 'left',
    type: 'date',
  },
  {
    field: 'death_hospital',
    headerName: 'Death Hospital',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'death_hospital_date',
    headerName: 'Death Hospital Date',
    width: 157,
    align: 'left',
    type: 'date',
  },
  {
    field: 'death_hospital_cause',
    headerName: 'Death Hospital Cause',
    width: 75,
    align: 'left',
  },
  {
    field: 'cea_symptomatic',
    headerName: 'Cea Symptomatic',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'cea_symptomatic_date',
    headerName: 'Cea Symptomatic Date',
    width: 157,
    align: 'left',
    type: 'date',
  },
  {
    field: 'cas_symptomatic',
    headerName: 'Cas Symptomatic',
    width: 75,
    align: 'left',
    type: 'boolean',
  },
  {
    field: 'cas_symptomatic_date',
    headerName: 'Cas Symptomatic Date',
    width: 157,
    align: 'left',
    type: 'date',
  },
  {
    field: 'endorevasc_other',
    headerName: 'Endorevasc Other',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'surgrevasc_other',
    headerName: 'Surgrevasc Other',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'revasc_other_date',
    headerName: 'Revasc Other Date',
    width: 157,
    align: 'left',
    type: 'date',
  },
  {
    field: 'revasc_other_specify',
    headerName: 'Revasc Other Specify',
    width: 75,
    align: 'left',
  },
  {
    field: 'pfo_closure',
    headerName: 'Pfo Closure',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'pfo_closure_date',
    headerName: 'Pfo Closure Date',
    width: 157,
    align: 'left',
    type: 'date',
  },
  {
    field: 'discharge_destinat',
    headerName: 'Discharge Destinat',
    width: 75,
    align: 'left',
  },
  {
    field: 'discharge_date',
    headerName: 'Discharge Date',
    width: 157,
    align: 'left',
    type: 'date',
  },
  {
    field: 'duration_hospital',
    headerName: 'Duration Hospital',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'duration_hospital');
    },
  },
  {
    field: 'hosp_comment',
    headerName: 'Hospital Comment',
    width: 75,
    align: 'left',
  },
];

export const followup = [
  { field: 'idCase', headerName: 'Idcase', width: 75, align: 'left' },
  {
    field: 'followup_date',
    headerName: 'Followup Date',
    width: 157,
    align: 'left',
    type: 'date',
    edit: true,
  },
  {
    field: 'followup_mode',
    headerName: 'Followup Mode',
    width: 75,
    align: 'left',
  },
  {
    field: 'mrs_90days',
    headerName: 'Mrs 90Days',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'mrs_90days');
    },
  },
  {
    field: 'nih_90days',
    headerName: 'Nih 90Days',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'nih_90days');
    },
  },
  {
    field: 'stroke_followup',
    headerName: 'Stroke Followup',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'stroke_followup_date',
    headerName: 'Stroke Followup Date',
    width: 157,
    align: 'left',
    type: 'date',
  },
  {
    field: 'ich_followup',
    headerName: 'Ich Followup',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'ich_followup_date',
    headerName: 'Ich Followup Date',
    width: 157,
    align: 'left',
    type: 'date',
  },
  {
    field: 'seizure_followup',
    headerName: 'Seizure Followup',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'seizure_followup_date',
    headerName: 'Seizure Followup Date',
    width: 157,
    align: 'left',
    type: 'date',
  },
  {
    field: 'death_followup',
    headerName: 'Death Followup',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'death_followup_date',
    headerName: 'Death Followup Date',
    width: 157,
    align: 'left',
    type: 'date',
  },
  {
    field: 'death_followup_cause',
    headerName: 'Death Followup Cause',
    width: 75,
    align: 'left',
  },
  {
    field: 'followup_comment',
    headerName: 'Followup Comment',
    width: 75,
    align: 'left',
  },
];

export const treatment = [
  { field: 'idCase', headerName: 'Idcase', width: 75, align: 'left' },
  {
    field: 'aspirin_pre',
    headerName: 'Aspirin Pre',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'clopidogrel_pre',
    headerName: 'Clopidogrel Pre',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'prasugrel_pre',
    headerName: 'Prasugrel Pre',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'ticagrelor_pre',
    headerName: 'Ticagrelor Pre',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'dipyridamole_pre',
    headerName: 'Dipyridamole Pre',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'vka_pre',
    headerName: 'Vka Pre',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'vka_inr',
    headerName: 'Vka Inr',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'vka_inr');
    },
  },
  {
    field: 'rivaroxaban_pre',
    headerName: 'Rivaroxaban Pre',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'dabigatran_pre',
    headerName: 'Dabigatran Pre',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'apixaban_pre',
    headerName: 'Apixaban Pre',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'edoxaban_pre',
    headerName: 'Edoxaban Pre',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'parenteralanticg_pre',
    headerName: 'Parenteralanticg Pre',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'antihypertensive_pre',
    headerName: 'Antihypertensive Pre',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'antilipid_pre',
    headerName: 'Antilipid Pre',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'hormone_pre',
    headerName: 'Hormone Pre',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'firstimage_type',
    headerName: 'Firstimage Type',
    width: 75,
    align: 'left',
  },
  {
    field: 'firstimage_time',
    headerName: 'Firstimage Time',
    width: 175,
    align: 'left',
    type: 'date',
    edit: true,
  },
  {
    field: 'door_image_time',
    headerName: 'Door Image Time',
    width: 175,
    align: 'left',
    type: 'time',
    edit: true,
  },
  {
    field: 'firstimage_result',
    headerName: 'Firstimage Result',
    width: 75,
    align: 'left',
  },
  {
    field: 'perfusion_type',
    headerName: 'Perfusion Type',
    width: 75,
    align: 'left',
  },
  {
    field: 'perfusion_result',
    headerName: 'Perfusion Result',
    width: 75,
    align: 'left',
  },
  {
    field: 'firstangio_type',
    headerName: 'Firstangio Type',
    width: 75,
    align: 'left',
  },
  {
    field: 'firstangio_result',
    headerName: 'Firstangio Result',
    width: 75,
    align: 'left',
  },
  {
    field: 'treat_antiplatelet',
    headerName: 'Treat Antiplatelet',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'treat_anticoagulant',
    headerName: 'Treat Anticoagulant',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'treat_ivt',
    headerName: 'Treat Ivt',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'ivt_start',
    headerName: 'Ivt Start',
    width: 157,
    align: 'left',
    type: 'date',
  },
  {
    field: 'rtpa_dose',
    headerName: 'Rtpa Dose',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'rtpa_dose');
    },
  },
  {
    field: 'onset_treat_time',
    headerName: 'Onset Treat Time',
    width: 157,
    align: 'left',
    type: 'date',
  },
  {
    field: 'door_treat_time',
    headerName: 'Door Treat Time',
    width: 157,
    align: 'left',
    type: 'date',
  },
  {
    field: 'treat_iat',
    headerName: 'Treat Iat',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'iat_start',
    headerName: 'Iat Start',
    width: 157,
    align: 'left',
    type: 'date',
  },
  {
    field: 'onset_iat_time',
    headerName: 'Onset Iat Time',
    width: 157,
    align: 'left',
    type: 'date',
  },
  {
    field: 'door_iat_time',
    headerName: 'Door Iat Time',
    width: 157,
    align: 'left',
    type: 'date',
  },
  { field: 'anesthesia', headerName: 'Anesthesia', width: 75, align: 'left' },
  {
    field: 'iat_rtpa',
    headerName: 'Iat Rtpa',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'iat_rtpa_dose',
    headerName: 'Iat Rtpa Dose',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'iat_rtpa_dose');
    },
  },
  {
    field: 'iat_uk',
    headerName: 'Iat Uk',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'iat_uk_dose',
    headerName: 'Iat Uk Dose',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'iat_uk_dose');
    },
  },
  {
    field: 'iat_mech',
    headerName: 'Iat Mech',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'iat_stentretriever',
    headerName: 'Iat Stentretriever',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'iat_aspiration',
    headerName: 'Iat Aspiration',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'iat_distalretriever',
    headerName: 'Iat Distalretriever',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'iat_balloon',
    headerName: 'Iat Balloon',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'iat_stentintracran',
    headerName: 'Iat Stentintracran',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'iat_stentextracran',
    headerName: 'Iat Stentextracran',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'iat_mech_other',
    headerName: 'Iat Mech Other',
    width: 75,
    align: 'left',
  },
  {
    field: 'iat_end',
    headerName: 'Iat End',
    width: 157,
    align: 'left',
    type: 'date',
  },
  {
    field: 'treat_study',
    headerName: 'Treat Study',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'hospitalisation',
    headerName: 'Hospitalisation',
    width: 75,
    align: 'left',
  },
  {
    field: 'other_unitcenter',
    headerName: 'Other Unitcenter',
    width: 75,
    align: 'left',
  },
  {
    field: 'other_unitcenter_rsn',
    headerName: 'Other Unitcenter Rsn',
    width: 75,
    align: 'left',
  },
  {
    field: 'other_unit_datetime',
    headerName: 'Other Unit Datetime',
    width: 157,
    align: 'left',
    type: 'date',
  },
  {
    field: 'other_unit_dido',
    headerName: 'Other Unit Dido',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'not_monitored_rsn',
    headerName: 'Not Monitored Rsn',
    width: 75,
    align: 'left',
  },
  {
    field: 'admisstreat_comment',
    headerName: 'Admisstreat Comment',
    width: 75,
    align: 'left',
  },
  {
    field: 'sys_fu_plan_date',
    headerName: 'Sys Fu Plan Date',
    width: 157,
    align: 'left',
    type: 'date',
  },
  {
    field: 'sys_noac_isp',
    headerName: 'Sys Noac Isp',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
];

export const procedure = [
  { field: 'idCase', headerName: 'Idcase', width: 75, align: 'left' },
  {
    field: 'bridging_regime',
    headerName: 'Bridging Regime',
    width: 75,
    align: 'left',
  },
  {
    field: 'bridging_regime_oth',
    headerName: 'Bridging Regime Oth',
    width: 75,
    align: 'left',
  },
  {
    field: 'rtpa_score',
    headerName: 'RTPA Score',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'rtpa_score');
    },
  },
  { field: 'conc_medi', headerName: 'Conc Medi', width: 75, align: 'left' },
  {
    field: 'antithrombotic',
    headerName: 'Antithrombotic',
    width: 75,
    align: 'left',
  },
  {
    field: 'antithrombotic_oth',
    headerName: 'Antithrombotic Oth',
    width: 75,
    align: 'left',
  },
  {
    field: 'recanalization',
    headerName: 'Recanalization',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'antithrombotic_start',
    headerName: 'Antithrombotic Start',
    width: 157,
    align: 'left',
  },
  {
    field: 'ct_denseartery',
    headerName: 'CT Denseartery',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'ct_leukoaraiosis',
    headerName: 'CT Leukoaraiosis',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'ct_oldinfarct',
    headerName: 'CT Oldinfarct',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'ct_earlyinfarctsings',
    headerName: 'CT Earlyinfarctsings',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'ct_aspects',
    headerName: 'CT Aspects',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'occ_none',
    headerName: 'OCC None',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'occ_ica_extra',
    headerName: 'OCC ICA Extra',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'occ_ica_intra',
    headerName: 'OCC ICA Intra',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'occ_ica_t',
    headerName: 'OCC ICA T',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'occ_mca_m1',
    headerName: 'OCC MCA M1',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'occ_mca_m2',
    headerName: 'OCC MCA M2',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'occ_mca_m3_m4',
    headerName: 'OCC MCA M3 M4',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'occ_ba_proximal',
    headerName: 'OCC BA Proximal',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'occ_ba_middle',
    headerName: 'OCC BA Middle',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'occ_ba_distal',
    headerName: 'OCC BA Distal',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'occ_aca_a1',
    headerName: 'OCC ACA A1',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'occ_aca_a2',
    headerName: 'OCC ACA A2',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'occ_va',
    headerName: 'OCC VA',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'occ_pca_p1',
    headerName: 'OCC PCA P1',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'occ_pca_p2',
    headerName: 'OCC PCA P2',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'occ_pica',
    headerName: 'OCC PICA',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'occ_suca',
    headerName: 'OCC SUCA',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'occ_aica',
    headerName: 'OCC AICA',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'occ_other',
    headerName: 'OCC Other',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'occ_other_spec',
    headerName: 'OCC Other Spec',
    width: 75,
    align: 'left',
  },
  {
    field: 'occ_determined_by',
    headerName: 'OCC Determined By',
    width: 75,
    align: 'left',
  },
  {
    field: 'occ_determined_oth',
    headerName: 'OCC Determined Oth',
    width: 75,
    align: 'left',
  },
  {
    field: 'occ_core',
    headerName: 'OCC Core',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'occ_core');
    },
  },
  {
    field: 'occ_penumbra',
    headerName: 'OCC Penumbra',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'occ_penumbra');
    },
  },
  {
    field: 'lab_inr',
    headerName: 'Lab INR',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'lab_inr');
    },
  },
  {
    field: 'lab_aptt',
    headerName: 'Lab APTT',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'lab_aptt');
    },
  },
  {
    field: 'lab_tt',
    headerName: 'Lab TT',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'lab_tt');
    },
  },
  {
    field: 'lab_riv',
    headerName: 'Lab Riv',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'lab_riv');
    },
  },
  {
    field: 'lab_dab',
    headerName: 'Lab Dab',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'lab_dab');
    },
  },
  {
    field: 'lab_afxa',
    headerName: 'Lab Afxa',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'lab_afxa');
    },
  },
  {
    field: 'lab_platelets',
    headerName: 'Lab Platelets',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'lab_platelets');
    },
  },
  {
    field: 'lab_haem',
    headerName: 'Lab Haem',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'lab_haem');
    },
  },
  {
    field: 'lab_crp',
    headerName: 'Lab CRP',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'lab_crp');
    },
  },
  {
    field: 'lab_calcium',
    headerName: 'Lab Calcium',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'lab_calcium');
    },
  },
  {
    field: 'lab_albumin',
    headerName: 'Lab Albumin',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'lab_albumin');
    },
  },
  {
    field: 'lab_uric_acid',
    headerName: 'Lab Uric Acid',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'lab_uric_acid');
    },
  },
  {
    field: 'lab_triglycerides',
    headerName: 'Lab Triglycerides',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'lab_triglycerides');
    },
  },
  {
    field: 'lab_hdl',
    headerName: 'Lab HDL',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'lab_hdl');
    },
  },
  {
    field: 'lab_hba1c',
    headerName: 'Lab HBA1C',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'lab_hba1c');
    },
  },
  {
    field: 'lab_tropinin_t_hs',
    headerName: 'Lab Tropinin T HS',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'lab_tropinin_t_hs');
    },
  },
  {
    field: 'lab_d_dimer',
    headerName: 'Lab D Dimer',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'lab_d_dimer');
    },
  },
  {
    field: 'reperfusion_time',
    headerName: 'Reperfusion Time',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'reperfusion_time');
    },
  },
  {
    field: 'stroke_reper_time',
    headerName: 'Stroke Reper Time',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'stroke_reper_time');
    },
  },
  {
    field: 'door_reper_time',
    headerName: 'Door Reper Time',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'door_reper_time');
    },
  },
  {
    field: 'ivt_reper_time',
    headerName: 'IVT Reper Time',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'ivt_reper_time');
    },
  },
  {
    field: 'iat_reper_time',
    headerName: 'IAT Reper Time',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'iat_reper_time');
    },
  },
  {
    field: 'ed_none',
    headerName: 'ED None',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'ed_solitaire',
    headerName: 'ED Solitaire',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'ed_trevo',
    headerName: 'ED Trevo',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'ed_3d_separator',
    headerName: 'ED 3D Separator',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'ed_preset',
    headerName: 'ED Preset',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'ed_capture',
    headerName: 'ED Capture',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'ed_merci',
    headerName: 'ED Merci',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'ed_other',
    headerName: 'ED Other',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'ed_other_spec',
    headerName: 'ED Other Spec',
    width: 75,
    align: 'left',
  },
  {
    field: 'alo_acc',
    headerName: 'ALO ACC',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'alo_aci_prox',
    headerName: 'ALO ACI Prox',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'alo_aci_dist',
    headerName: 'ALO ACI Dist',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'alo_m1',
    headerName: 'ALO M1',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'alo_m2',
    headerName: 'ALO M2',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'alo_m3',
    headerName: 'ALO M3',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'alo_a1',
    headerName: 'ALO A1',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'alo_av_prox',
    headerName: 'ALO AV Prox',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'alo_av_dist',
    headerName: 'ALO AV Dist',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'alo_ba_prox',
    headerName: 'ALO BA Prox',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'alo_ba_dist',
    headerName: 'ALO BA Dist',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'alo_p1',
    headerName: 'ALO P1',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'alo_p2',
    headerName: 'ALO P2',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'alo_other',
    headerName: 'ALO Other',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'alo_other_spec',
    headerName: 'ALO Other Spec',
    width: 75,
    align: 'left',
  },
  {
    field: 'recanal_before',
    headerName: 'Recanal Before',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'recanal_after',
    headerName: 'Recanal After',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'pat_alone_before',
    headerName: 'Pat Alone Before',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'pat_independ_before',
    headerName: 'Pat Independ Before',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'pat_lift_arms_adm',
    headerName: 'Pat Lift Arms ADM',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'pat_walk_adm',
    headerName: 'Pat Walk ADM',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'pat_gcs_normal',
    headerName: 'Pat GCS Normal',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'dragon_prestroke',
    headerName: 'Dragon Prestroke',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'dragon_age',
    headerName: 'Dragon Age',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'dragon_glucose',
    headerName: 'Dragon Glucose',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'dragon_ott',
    headerName: 'Dragon OTT',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'dragon_nihss',
    headerName: 'Dragon NIHSS',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'dragon_score',
    headerName: 'Dragon Score',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'dragon_score');
    },
  },
  {
    field: 'sedan_glucose',
    headerName: 'Sedan Glucose',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'sedan_age',
    headerName: 'Sedan Age',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'sedan_nihss',
    headerName: 'Sedan NIHSS',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'sedan_score',
    headerName: 'Sedan Score',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'sedan_score');
    },
  },
  {
    field: 'fu_timi',
    headerName: 'FU Timi',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'fu_any_ich',
    headerName: 'FU Any ICH',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'fu_deterioration',
    headerName: 'FU Deterioration',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'fu_sympt_ich',
    headerName: 'FU Sympt ICH',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'sympt_ecass',
    headerName: 'Sympt ECASS',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'sympt_ninds',
    headerName: 'Sympt NINDS',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'sympt_sits',
    headerName: 'Sympt SITS',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'ich_proact2',
    headerName: 'ICH PROACT2',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'bleeding_other',
    headerName: 'Bleeding Other',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  {
    field: 'fu_barthel_90d',
    headerName: 'FU Barthel 90D',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'fu_barthel_90d');
    },
  },
  {
    field: 'fu_nihss_90d',
    headerName: 'FU NIHSS 90D',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'fu_nihss_90d');
    },
  },
  {
    field: 'fu_epi_90d',
    headerName: 'FU Epi 90D',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'fu_mrs_1y',
    headerName: 'FU Mrs 1Y',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'fu_mrs_1y');
    },
  },
  {
    field: 'thrombo_comments',
    headerName: 'Thrombo Comments',
    width: 75,
    align: 'left',
  },
];

export const biomarker = [
  { field: 'idCase', headerName: 'Idcase', width: 75, align: 'left' },
  {
    field: 'infarct_core',
    headerName: 'infarct_core',
    width: 157,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      row['infarct_core'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'penumbra',
    headerName: 'penumbra',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'ich_volume',
    headerName: 'ich_volume',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'mrs_90days');
    },
  },
  {
    field: 'aspects',
    headerName: 'Nih 90Days',
    width: 75,
    align: 'left',
    edit: true,
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'nih_90days');
    },
  },
  {
    field: 'large_vessel_occlusion',
    headerName: 'large_vessel_occlusion',
    width: 120,
    align: 'left',
    edit: true,
  },
  {
    field: 'wmh_volume',
    headerName: 'wmh_volume',
    width: 157,
    align: 'left',
    edit: true,
  },
  {
    field: 'atrophy',
    headerName: 'atrophy',
    width: 75,
    align: 'left',
    edit: true,
  },
];

export const getColumnList = () => {
  let columns = null;

  const new_patients = short_patients.map(x => {
    return { key: 'pat_' + x.field, value: x.headerName, parent: 'Patient' };
  });
  const new_cases = cases.map(x => {
    return { key: 'case_' + x.field, value: x.headerName, parent: 'Case' };
  });
  const new_encounters = encounter.map(x => {
    return { key: 'enc_' + x.field, value: x.headerName, parent: 'Encounter' };
  });
  const new_followup = followup.map(x => {
    return { key: 'fol_' + x.field, value: x.headerName, parent: 'Follow up' };
  });
  const new_treatment = treatment.map(x => {
    return {
      key: 'treat_' + x.field,
      value: x.headerName,
      parent: 'Treatment',
    };
  });
  const new_procedures = procedure.map(x => {
    return {
      key: 'thr_' + x.field,
      value: x.headerName,
      parent: 'Thrombolysis Procedure',
    };
  });

  const new_biomarker = biomarker.map(x => {
    return { key: 'bio_' + x.field, value: x.headerName, parent: 'Imaging Biomarker' };
  });

  columns = new_patients.concat(
    new_cases,
    new_encounters,
    new_treatment,
    new_followup,
    new_procedures,
    new_biomarker
  );
  columns = columns.filter(item => item.value !== 'Idcase');
  return columns;
};
