import moment from 'moment';
import { IsInvalid } from './utils';

export const IsToday = createdAt => {
  return moment(parseInt(createdAt, 10)).isSame(moment(), 'day');
};
export const IsYesterday = createdAt => {
  return moment(parseInt(createdAt, 10)).isSame(
    moment().subtract(1, 'day'),
    'day'
  );
};
export const IsOlder = createdAt => {
  if (IsToday(createdAt) || IsYesterday(createdAt)) {
    return false;
  }
  return true;
};
export const GetDateTimeValueOf = time => {
  if (typeof time !== 'number') {
    const i = time.indexOf('T');
    const j = time.indexOf('Z');
    const length = time.length;
    // console.log({ time, i, j, length });
    if (i !== -1 && j !== -1) {
      const parts = time.split('T');
      const parts_time = parts[1].split('.');
      time = moment(
        parts[0] + ' ' + parts_time[0],
        'YYYY-MM-DD HH:mm:ss'
      ).valueOf();
    } else {
      if (length === 8) {
        time = moment(time, 'YYYYMMDD').valueOf();
      } else if (length === 14) {
        time = moment(time, 'YYYYMMDDHHmmss').valueOf();
      }
    }
  }
  return parseInt(time, 10);
};
export const FormatTime = (time, format) => {
  if (IsInvalid(time) || time === '') return '';
  if (typeof time === 'undefined' || !time) {
    time = '';
  }
  if (typeof time !== 'number') {
    time = GetDateTimeValueOf(time);
  } else {
    time = parseInt(time, 10);
  }
  if (format === 'FromNow') {
    return moment(time).fromNow();
  } else if (format === 'calendar') {
    return moment(time).calendar();
  } else if (format === 'moment') {
    return moment(time);
  }
  return moment(time).format(format);
};
