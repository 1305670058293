import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import {
  EditAnatomicalRegion,
  EditImageFocus,
  EditPathology,
  EditSequence,
  EditSide,
} from 'views/DataImages/components/ViewDetails/components';

class SeriesMappingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (!this.props.isActive) return null;
    const { height_content } = this.props;
    const height_card = 380;
    return (
      <Grid
        alignItems="flex-start"
        container
        direction="row"
        justifyContent="space-between"
        pt={0}
        spacing={2}
        style={{
          width: '100%',
          overflow: 'auto',
          height: height_content - 5,
          paddingTop: 0,
          marginTop: 0,
          marginLeft: 0,
          marginRight: 0,
          paddingRight: 40,
        }}>
        <Grid item lg={3} md={4} sm={6} sx={{ minWidth: 310 }} xs={12}>
          <EditSide
            handleAddError={this.props.handleAddError}
            height={height_card}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} sx={{ minWidth: 310 }} xs={12}>
          <EditPathology
            handleAddError={this.props.handleAddError}
            height={height_card}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} sx={{ minWidth: 310 }} xs={12}>
          <EditImageFocus
            handleAddError={this.props.handleAddError}
            height={height_card}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} sx={{ minWidth: 310 }} xs={12}>
          <EditSequence
            handleAddError={this.props.handleAddError}
            height={height_card}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} sx={{ minWidth: 310 }} xs={12}>
          <EditAnatomicalRegion
            handleAddError={this.props.handleAddError}
            height={height_card}
          />
        </Grid>
      </Grid>
    );
  }
}

SeriesMappingDetails.propTypes = {
  classes: PropTypes.object,
};

export default SeriesMappingDetails;
