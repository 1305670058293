import moment from 'moment';
import { getCityCountry } from 'helpers';
import { IsInvalid } from 'helpers';

export const short_patients = [
  { field: 'PatientID', headerName: 'PatientID' },
  // { field: 'idUser', headerName: 'idUser' },
  //{ field: 'idAddress', headerName: 'idAddress' },
  { field: 'GivenName', headerName: 'GivenName' },
  { field: 'MiddleName', headerName: 'MiddleName' },
  { field: 'FamilyName', headerName: 'FamilyName' },
  { field: 'NamePrefix', headerName: 'Prefix' },
  { field: 'NameSuffix', headerName: 'Suffix' },
  { field: 'PatientSex', headerName: 'Sex' },
  { field: 'address', headerName: 'Address' },
  { field: 'PatientEthnicity', headerName: 'Ethnicity' },
  { field: 'patientLastActivity', headerName: 'Past Activity' },
  { field: 'patientSAPStatus', headerName: 'SAP Status' },
  { field: 'patientRegistrationDate', headerName: 'Registration Date' },
  { field: 'PatientBirthDate', headerName: 'BirthDate' },
  { field: 'PatientAge', headerName: 'Age' },
  { field: 'PatientSize', headerName: 'Height' },
  { field: 'PatientWeight', headerName: 'Weight' },
  { field: 'PatientWaist', headerName: 'Waist' },
  { field: 'PatientHip', headerName: 'Hip' },
  { field: 'bmi', headerName: 'BMI' },
  { field: 'UniqueKeyIdentifier', headerName: 'UniqueKeyIdentifier' },
  { field: 'patientDeathDate', headerName: 'Date of death' },
  { field: 'patientLastVitalityCheck', headerName: 'Last Vitality Check' },
  { field: 'patientVitalStatus', headerName: 'Vitality Status' },
  {
    field: 'tot_stroke_files',
    headerName: 'Total stroke files',
  },
  {
    field: 'tot_stroke_series',
    headerName: 'Total stroke series',
  },
  {
    field: 'tot_stroke_edfs',
    headerName: 'Total stroke edfs',
  },
  {
    field: 'tot_sleep_files',
    headerName: 'Total sleep files',
  },
  {
    field: 'tot_sleep_series',
    headerName: 'Total sleep series',
  },
  {
    field: 'tot_sleep_edfs',
    headerName: 'Total sleep edfs',
  },
  {
    field: 'tot_epilepsy_files',
    headerName: 'Total epilepsy files',
  },
  {
    field: 'tot_epilepsy_series',
    headerName: 'Total epilepsy series',
  },
  {
    field: 'tot_epilepsy_edfs',
    headerName: 'Total epilepsy edfs',
  },
  {
    field: 'tot_ms_files',
    headerName: 'Total ms files',
  },
  {
    field: 'tot_ms_series',
    headerName: 'Total ms series',
  },
  {
    field: 'tot_ms_edfs',
    headerName: 'Total ms edfs',
  },
  {
    field: 'isStroke',
    headerName: 'Stroke Patient',
  },
  {
    field: 'isSleep',
    headerName: 'Sleep Patient',
  },
  {
    field: 'isEpilepsy',
    headerName: 'Epilepsy Patient',
  },
  {
    field: 'isMS',
    headerName: 'MS Patient',
  },
];
export const header_patients = [
  {
    field: 'PatientID',
    headerName: 'PatientID',
    example: '1234567890',
    width: 75,
    align: 'left',
    edit: true,
    required: true,
  },
  {
    field: 'NamePrefix',
    headerName: 'Prefix',
    example: 'Mr.',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'NameSuffix',
    headerName: 'Suffix',
    example: 'Jr.',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'GivenName',
    headerName: 'GivenName',
    example: 'John',
    width: 75,
    align: 'left',
    edit: true,
    required: true,
  },
  {
    field: 'MiddleName',
    headerName: 'MiddleName',
    example: 'Tyler',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'FamilyName',
    headerName: 'FamilyName',
    example: 'Doe',
    width: 75,
    align: 'left',
    required: true,
    edit: true,
  },
  {
    field: 'PatientName',
    headerName: 'PatientName',
    example: 'John Tyler, Doe',
    width: 75,
    align: 'left',
    edit: true,
    derived: true,
  },
  {
    field: 'PatientSex',
    headerName: 'Sex',
    example: 'm',
    width: 75,
    align: 'left',
    required: true,
    edit: true,
  },
  {
    field: 'address',
    headerName: 'Address',
    example: 'Street 23, 1111 Town, Country',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const address = params.getValue(params.id, 'address');
      if (IsInvalid(address)) return '';
      const city = getCityCountry(address.city, address.country);
      return IsInvalid(city) ? '' : city;
    },
    type: 'int',
    editerGetter: (row, value) => {
      let city = '';
      let country = '';
      if (value && value !== '') {
        const parts = value.spli(',');
        if (parts.length >= 1) country = parts[0];
        if (parts.length > 1) city = parts[1];
      } else {
        country = value;
      }
      row = {
        ...row,
        address: {
          country,
          city,
        },
      };
      return row;
    },
    derived: true,
  },
  {
    field: 'PatientEthnicity',
    headerName: 'Ethnicity',
    example: 'Caucasian',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'patientLastActivity',
    headerName: 'Past Activity',
    example: '2017-12-19 09:30:00',
    width: 157,
    align: 'left',
    type: 'date',
    edit: true,
  },
  {
    field: 'patientSAPStatus',
    headerName: 'SAP Status',
    example: 'aktiv',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'patientRegistrationDate',
    headerName: 'Registration Date',
    example: '2015-11-20 10:30:00',
    width: 157,
    align: 'left',
    type: 'date',
    edit: true,
  },
  {
    field: 'PatientBirthDate',
    headerName: 'BirthDate',
    example: '18/11/2000',
    width: 157,
    align: 'left',
    type: 'date',
    required: true,
    edit: true,
  },
  {
    field: 'PatientAge',
    headerName: 'Age',
    example: '56',
    width: 75,
    type: 'int',
    align: 'left',
    edit: true,
  },
  {
    field: 'PatientSize',
    headerName: 'Height (cm)',
    example: '175',
    type: 'int',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'PatientWeight',
    headerName: 'Weight (Kg)',
    type: 'int',
    example: '70',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'PatientWaist',
    headerName: 'Waist (cm)',
    example: '75',
    width: 75,
    align: 'left',
    type: 'int',
    edit: true,
  },
  {
    field: 'PatientHip',
    headerName: 'Hip (cm)',
    example: '68',
    edit: true,
    type: 'int',
    width: 75,
    align: 'left',
  },
  {
    field: 'bmi',
    headerName: 'BMI',
    example: '22.9',
    width: 75,
    align: 'left',
    type: 'int',
    derived: true,
    editerGetter: (row, value) => {
      let parsedValue = parseInt(value);
      if (!isNaN(parsedValue)) {
        row['bmi'] = parseFloat(value);
      } else {
        row['bmi'] = 0;
      }
      return row;
    },
  },
  {
    field: 'patientDeathDate',
    headerName: 'Date of Death',
    example: '25/11/2020',
    width: 157,
    align: 'left',
    type: 'date',
    edit: true,
  },
  {
    field: 'patientLastVitalityCheck',
    headerName: 'Last vitality check',
    example: '12/5/2020',
    width: 157,
    align: 'left',
    type: 'date',
    edit: true,
  },
  {
    field: 'patientVitalStatus',
    headerName: 'Vitality status',
    example: 'leben (ZAS)',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'tot_stroke_files',
    headerName: 'Total stroke files',
    example: '0',
    width: 75,
    edit: false,
    align: 'left',
    type: 'int',
    editerGetter: (row, value) => {
      row['tot_stroke_files'] = parseInt(value);
      return row;
    },
    derived: true,
  },
  {
    field: 'tot_stroke_series',
    headerName: 'Total stroke series',
    example: '0',
    width: 75,
    edit: false,
    align: 'left',
    type: 'int',
    editerGetter: (row, value) => {
      row['tot_stroke_series'] = parseInt(value);
      return row;
    },
    derived: true,
  },
  {
    field: 'tot_stroke_edfs',
    headerName: 'Total stroke edfs',
    example: '0',
    width: 75,
    edit: false,
    align: 'left',
    derived: true,
    type: 'int',
    editerGetter: (row, value) => {
      row['tot_stroke_edfs'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_sleep_files',
    headerName: 'Total sleep files',
    example: '0',
    width: 75,
    edit: false,
    derived: true,
    align: 'left',
    type: 'int',
    editerGetter: (row, value) => {
      row['tot_sleep_files'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_sleep_series',
    headerName: 'Total sleep series',
    example: '0',
    width: 75,
    edit: false,
    derived: true,
    align: 'left',
    type: 'int',
    editerGetter: (row, value) => {
      row['tot_sleep_series'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_sleep_edfs',
    headerName: 'Total sleep edfs',
    example: '0',
    width: 75,
    edit: false,
    derived: true,
    align: 'left',
    type: 'int',
    editerGetter: (row, value) => {
      row['tot_sleep_edfs'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_epilepsy_files',
    headerName: 'Total epilepsy files',
    example: '0',
    width: 75,
    edit: false,
    derived: true,
    align: 'left',
    type: 'int',
    editerGetter: (row, value) => {
      row['tot_epilepsy_files'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_epilepsy_series',
    headerName: 'Total epilepsy series',
    example: '0',
    width: 75,
    edit: false,
    derived: true,
    align: 'left',
    type: 'int',
    editerGetter: (row, value) => {
      row['tot_epilepsy_series'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_epilepsy_edfs',
    headerName: 'Total epilepsy edfs',
    example: '0',
    width: 75,
    edit: false,
    derived: true,
    align: 'left',
    type: 'int',
    editerGetter: (row, value) => {
      row['tot_epilepsy_edfs'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_ms_files',
    headerName: 'Total ms files',
    example: '0',
    width: 75,
    edit: false,
    derived: true,
    align: 'left',
    type: 'int',
    editerGetter: (row, value) => {
      row['tot_ms_files'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_ms_series',
    headerName: 'Total ms series',
    example: '0',
    width: 75,
    edit: false,
    derived: true,
    align: 'left',
    type: 'int',
    editerGetter: (row, value) => {
      row['tot_ms_series'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_ms_edfs',
    headerName: 'Total ms edfs',
    example: '0',
    width: 75,
    edit: false,
    derived: true,
    align: 'left',
    type: 'int',
    editerGetter: (row, value) => {
      row['tot_ms_edfs'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'isStroke',
    headerName: 'Stroke Patient',
    width: 75,
    align: 'left',
    type: 'boolean',
  },
  {
    field: 'isSleep',
    headerName: 'Sleep Patient',
    width: 75,
    align: 'left',
    type: 'boolean',
  },
  {
    field: 'isEpilepsy',
    headerName: 'Epilepsy Patient',
    width: 75,
    align: 'left',
    type: 'boolean',
  },
  {
    field: 'isMS',
    headerName: 'MS Patient',
    width: 75,
    align: 'left',
    type: 'boolean',
  },
];

export const getEmptyRow = () => {
  let row = {};
  for (let i = 0; i < header_patients.length; i++) {
    const key = header_patients[i].field;
    let value = '';
    if (key === 'createdAt' || key === 'updatedAt') {
      value = `${moment().valueOf()}`;
    }
    row = {
      ...row,
      [key]: value,
    };
    if (header_patients[i].editerGetter) {
      row = header_patients[i].editerGetter(row, '');
    }
  }
  return row;
};

export const isEmpty = object => {
  const keys = Object.keys(object);
  let add = 0;
  for (let i = 0; i < keys.length; i++) {
    const value = object[keys[i]];
    if (IsInvalid(value) || value === '') add++;
  }
  return add === keys.length;
};

export const getColumnList = () => {
  let new_patients = short_patients.map(x => {
    return { key: x.field, value: x.headerName, parent: 'Patient' };
  });
  // new_patients = new_patients; //.concat(last);

  return new_patients;
};
