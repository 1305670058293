import { createBoxTitle, createLine, getConfig } from '../../utils_blocks';
import Port from './Port';
class Block {
  constructor(
    x,
    y,
    width,
    height,
    config,
    contentHeight,
    bottomHeight,
    defaultWidth,
    defaultHeight,
    color_borde
  ) {
    this.id = config.id;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.color = config.color;
    this.color_borde = color_borde;
    this.icon = config.icon;
    this.title = config.label;
    this.contentHeight = contentHeight || height - 100; // Default if not provided
    this.bottomHeight = bottomHeight || 60; // Default if not provided
    this.inPorts = [];
    this.outPorts = [];
    this.isDragging = false;
    this.dragStartX = 0;
    this.dragStartY = 0;
    this.selected = false;
    this.defaultWidth = defaultWidth;
    this.defaultHeight = defaultHeight;
    const scaleY = this.height / this.defaultHeight;
    this.topHeight = 40 * scaleY;
  }
  // Function to calculate scaling factor based on block dimensions
  getScalingFactor() {
    const scaleX = this.width / this.defaultWidth;
    const scaleY = this.height / this.defaultHeight;
    return { scaleX, scaleY };
  }
  setTitle(title) {
    this.title = title;
  }
  getTitleID = () => {
    return `${this.title} (${this.x}x${this.y})`;
  };
  isOutputPort(port) {
    return this.outPorts.findIndex(p => p == port) >= 0;
  }
  isInputPort(port) {
    return this.inPorts.findIndex(p => p == port) >= 0;
  }
  isPortInside(port) {
    if (!port) return false;
    return this.isPointInside(port.x, port.y);
  }
  getIndexLeft(port) {
    return this.inPorts.findIndex(p => p == port);
  }
  getIndexRight(port) {
    return this.outPorts.findIndex(p => p == port);
  }
  isSavedBlock() {
    if (this.id === `${this.id} (${this.x}x${this.y})`) {
      return true;
    }
    return false;
  }
  isConnected() {
    for (let i = 0; i < this.inPorts.length; i++) {
      if (this.inPorts[i].getLines().length) return true;
    }
    for (let i = 0; i < this.outPorts.length; i++) {
      if (this.outPorts[i].getLines().length) return true;
    }
    return false;
  }
  setPortVisible = (ports, visible) => {
    ports.forEach(p => {
      console.log({ p });
      if (p.type === 'Input') {
        const i = this.inPorts.findIndex(port => port.id === p.id);
        if (i !== -1) {
          this.inPorts[i].visible = visible;
          if (!visible) this.disconnectLineAtPort(this.inPorts[i]);
        }
      } else {
        const i = this.outPorts.findIndex(port => port.id === p.id);
        if (i !== -1) {
          this.outPorts[i].visible = visible;
          if (!visible) this.disconnectLineAtPort(this.outPorts[i]);
        }
      }
    });
  };
  disconnectBlock() {
    let all_lines = [];
    for (let i = 0; i < this.inPorts.length; i++) {
      const lines = this.inPorts[i].getLines();
      all_lines = all_lines.concat(lines);
    }
    for (let i = 0; i < this.outPorts.length; i++) {
      const lines = this.outPorts[i].getLines();
      all_lines = all_lines.concat(lines);
    }
    for (let i = 0; i < all_lines.length; i++) {
      const line = all_lines[i];
      this.disconnectLine(line);
    }
    return all_lines;
  }
  connectLineToPort(line, port) {
    // console.log('connectLineToPort');
    let index = this.getIndexLeft(port);
    if (index >= 0) port.connectArrivedLine(line);
    else {
      index = this.getIndexRight(port);
      if (index >= 0) port.connectStartLine(line);
      else return false;
    }
    return true;
  }
  connectLine(line, index_port, isRight) {
    // console.log('connectLine');
    const port = this.getPort(index_port, isRight);
    if (port && isRight) port.connectStartLine(line);
    else if (port) port.connectArrivedLine(line);
  }
  disconnectLineAtPort(port) {
    let index = this.getIndexLeft(port); //is input port
    if (index < 0) index = this.getIndexRight(port); //is input port
    if (index < 0) return;
    const lines = port.getLines();
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      this.disconnectLine(line);
    }
  }
  disconnectLine(line) {
    for (let i = 0; i < this.inPorts.length; i++) {
      const endPort = this.inPorts[i];
      //console.log(`${this.id} - port in:`, port);
      if (endPort.isLineHere(line)) {
        const startPort = line.getStartPort();
        if (startPort) startPort.disconnectLine(line);
        endPort.disconnectLine(line);
      } else console.log('line not in inputs');
    }
    for (let i = 0; i < this.outPorts.length; i++) {
      const startPort = this.outPorts[i];
      //console.log(`${this.id} - port out:`, port);
      if (startPort.isLineHere(line)) {
        const endPort = line.getEndPort();
        if (endPort) endPort.disconnectLine(line);
        startPort.disconnectLine(line);
      } else console.log('line not in outputs');
    }
  }
  getPortsOn() {
    const ports = this.getPorts();
    return ports.filter(p => p.visible);
  }
  getPortsOff() {
    const ports = this.getPorts();
    return ports.filter(p => !p.visible);
  }
  getPorts() {
    return [
      ...this.inPorts.map(x => ({ ...x, type: 'Input' })),
      ...this.outPorts.map(x => ({ ...x, type: 'OutPut' })),
    ];
  }
  getPort(index, isRight = false) {
    let ports = this.inPorts;
    if (isRight) ports = this.outPorts;
    if (index >= 0 && index < ports.length) return ports[index];
    return null;
  }
  addPort(name, isRight, moveY = 20, color = null) {
    const { scaleY } = this.getScalingFactor();
    if (!color) color = this.color;
    const portX = isRight ? this.x + this.width : this.x;
    const portY = this.y + moveY * scaleY; // Adjusted to align with the middle of the title text
    const port = new Port(Math.round(portX), Math.round(portY), color, this);
    port.name = name;
    if (isRight) this.outPorts.push(port);
    else this.inPorts.push(port);
  }
  getConnectPort(line) {
    for (let i = 0; i < this.inPorts.length; i++) {
      const port = this.inPorts[i];
      //console.log(`${this.id} - port in:`, port);
      if (port.isLineHere(line)) return port;
    }
    for (let i = 0; i < this.outPorts.length; i++) {
      const port = this.outPorts[i];
      //console.log(`${this.id} - port out:`, port);
      if (port.isLineHere(line)) return port;
    }
    return null;
  }
  getPortOnMouseOver(x, y) {
    for (let i = 0; i < this.inPorts.length; i++) {
      const port = this.inPorts[i];
      //console.log(`${this.id} - port in:`, port, x, y);
      if (port.isMouseOver(x, y)) return port;
    }
    // console.log(`${this.id}`, { b: this.outPorts });
    for (let i = 0; i < this.outPorts.length; i++) {
      const port = this.outPorts[i];
      const here = port.isMouseOver(x, y);
      // console.log(`${this.id} - port out:`, i, here);
      if (here) return port;
    }
    return null;
  }
  drawBase(ctx) {
    const { scaleX, scaleY } = this.getScalingFactor();

    // Set shadow properties
    ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
    ctx.shadowBlur = 6; // Shadow remains unchanged
    ctx.shadowOffsetY = 3; // Shadow remains unchanged

    // Draw main block background with shadow
    ctx.fillStyle = '#fff';
    ctx.beginPath();
    ctx.roundRect(
      this.x, // Keep x position unchanged
      this.y, // Keep y position unchanged
      this.width, // Already scaled, keep as is
      this.height, // Already scaled, keep as is
      10 * scaleX // Adjust corner radius based on scaleX
    );
    ctx.fill();

    // Reset shadow
    ctx.shadowColor = 'transparent';
    ctx.shadowBlur = 0;
    ctx.shadowOffsetY = 0;

    // Draw separators
    ctx.strokeStyle = '#e0e0e0';
    ctx.lineWidth = 1; // Line width remains unchanged

    // Top separator
    ctx.beginPath();
    ctx.moveTo(this.x, this.y + this.topHeight); // Scale only the y-offset for height
    ctx.lineTo(this.x + this.width, this.y + this.topHeight); // Keep width but adjust height scaling
    ctx.stroke();

    // Draw border separately without shadow
    let line_width = 0.5;
    let line_color = this.color_borde;
    if (this.selected) {
      line_color = this.color;
      line_width = 2;
    }
    ctx.strokeStyle = line_color;
    ctx.lineWidth = line_width; // Line width remains unchanged
    ctx.beginPath();
    ctx.roundRect(
      this.x, // Keep x position unchanged
      this.y, // Keep y position unchanged
      this.width, // Already scaled
      this.height, // Already scaled
      10 * scaleX // Adjust corner radius based on scaleX
    );
    ctx.stroke();
    ctx.lineWidth = 0.5; // Line width remains unchanged

    const config_box = getConfig(this.x, this.y);
    config_box.font = `bold ${16 * scaleX}px Arial`; // Scale font size based on scaleX
    config_box.scaleX = scaleX;
    config_box.scaleY = scaleY;
    // let text_title = `${this.title} (${this.x}x${this.y})`;
    createBoxTitle(ctx, this.title, config_box, this.icon);
  }

  draw(ctx, config = {}) {
    this.drawBase(ctx, config);
    this.drawContent(ctx);
    this.drawBottom(ctx);
    this.drawSelection(ctx);
  }
  drawPorts(ctx) {
    // Draw ports
    this.inPorts.forEach(port => {
      if (port.visible) port.draw(ctx); // Draw ports with scaling applied
    });
    this.outPorts.forEach(port => {
      if (port.visible) port.draw(ctx); // Draw ports with scaling applied
    });
  }
  drawLines(ctx, blocks = [], full = false) {
    this.outPorts.forEach(port => {
      if (port.visible) port.drawLines(ctx, blocks);
    });
    if (!full) return;
    this.inPorts.forEach(port => {
      if (port.visible) port.drawLines(ctx, blocks);
    });
  }
  drawContent(ctx) {
    // To be implemented by subclasses
    this.drawPorts(ctx);
  }

  drawBottom(ctx, draw_line = true) {
    const { scaleY } = this.getScalingFactor();
    // To be implemented by subclasses
    // Bottom separator
    const postSeparatorY =
      this.y + this.topHeight + this.contentHeight * scaleY;
    // Draw separators
    if (draw_line) {
      const config = getConfig(this.x, postSeparatorY, '#e0e0e0');
      config.font = `bold ${13 * scaleY}px Arial`;
      config.width = this.width;
      config.lineWidth = 1;
      createLine(ctx, config);
    }
    return postSeparatorY;
  }

  drawSelection(ctx) {
    if (this.selected) {
      ctx.strokeStyle = '#4285F4';
      ctx.lineWidth = 2;
      ctx.setLineDash([5, 5]);
      ctx.strokeRect(this.x - 5, this.y - 5, this.width + 10, this.height + 10);
      ctx.setLineDash([]);
    }
  }

  isPointInside(x, y) {
    return (
      x >= this.x &&
      x <= this.x + this.width &&
      y >= this.y &&
      y <= this.y + this.height
    );
  }

  startDrag(mouseX, mouseY) {
    this.dragOffset = {
      x: mouseX - this.x,
      y: mouseY - this.y,
    };
  }

  drag(mouseX, mouseY) {
    const newX = Math.round(mouseX - this.dragOffset.x);
    const newY = Math.round(mouseY - this.dragOffset.y);
    const dx = newX - this.x;
    const dy = newY - this.y;

    this.x = newX;
    this.y = newY;

    // Update port location
    this.inPorts.forEach(port => {
      port.x += dx;
      port.y += dy;
    });
    this.outPorts.forEach(port => {
      port.x += dx;
      port.y += dy;
    });
  }
}

export default Block;
