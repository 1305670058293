import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { useMakeStyles } from '../Styles';
import { GetData } from 'helpers';
import SearchAutocomplete from '../SearchAutocomplete';
import { QueryAIModels } from 'graphql/Dockers';

const SearchAIModels = props => {
  let { style, external_options, external_selected } = props;
  if (external_options === undefined || !external_options) {
    external_options = [];
  }
  const { classes } = useMakeStyles();

  const [name, setSelectedName] = React.useState('');
  const [selected, setSelected] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState(external_options);
  let loading = open && options.length === 0;
  if (external_options.length) {
    loading = false;
    if (options.length !== external_options.length) {
      setOptions(external_options);
    }
  }
  if (external_selected && selected && selected.id !== external_selected.id) {
    setSelected(external_selected);
  }
  React.useEffect(() => {
    if (!loading || external_options.length) {
      return undefined;
    }

    (async () => {
      QueryAIModels(name, 0, 5)
        .then(res => {
          const data = GetData(res);
          const { ok, ai_models, errors } = data.dockerAIModels;
          if (ok) {
            if (ai_models.length === 0) {
              ai_models.push({ name: 'None found' });
            }
          } else {
            throw errors;
          }
          setOptions(ai_models);
        })
        .catch(error => {
          console.log({ error });
          setOptions([]);
        });
    })();
  }, [loading, name]);
  const handelProjectNameChanged = event => {
    const name = event.target.value;
    if (options.length) {
      const here = options.filter(x =>
        x.name.toUpperCase().startsWith(name.toUpperCase())
      );
      if (!here.length || !name.length) {
        setOptions([]);
      }
    }
    setSelectedName(name);
  };
  const handleAddSelected = selected => {
    setSelected(selected);
    props.handleAddSelected(selected);
  };
  const handleSetSelected = selected => {
    setSelected(selected);
    if (props.handleSetSelected) {
      props.handleSetSelected(selected);
    }
  };
  if (props.no_icon) {
    return (
      <SearchAutocomplete
        clean_output={props.clean_output}
        handelSearchNameChanged={handelProjectNameChanged}
        handleAddResultObject={handleAddSelected}
        label={props.label}
        loading={loading}
        name={name}
        no_icon
        open={open}
        options={options}
        placeholder={props.placeholder}
        selected={selected}
        setOpen={setOpen}
        setOptions={setOptions}
        setSelected={handleSetSelected}
        setSelectedName={setSelectedName}
      />
    );
  }
  return (
    <Paper className={classes.root} style={style}>
      <SearchIcon className={classes.icon} />
      <SearchAutocomplete
        clean_output={props.clean_output}
        handelSearchNameChanged={handelProjectNameChanged}
        handleAddResultObject={handleAddSelected}
        label={props.label}
        loading={loading}
        name={name}
        open={open}
        options={options}
        placeholder={props.placeholder}
        selected={selected}
        setOpen={setOpen}
        setOptions={setOptions}
        setSelected={handleSetSelected}
        setSelectedName={setSelectedName}
      />
    </Paper>
  );
};

SearchAIModels.propTypes = {
  className: PropTypes.string,
  clean_output: PropTypes.bool.isRequired,
  external_options: PropTypes.array.isRequired,
  external_selected: PropTypes.object,
  handleAddSelected: PropTypes.func,
  handleSetSelected: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  style: PropTypes.object,
};

SearchAIModels.defaultProps = {
  placeholder: 'Search AI Models...',
  handleAddSelected: null,
  handleSetSelected: null,
  no_icon: false,
  external_options: [],
  clean_output: true,
  external_selected: null,
  label: 'AI Models',
};

export default SearchAIModels;
