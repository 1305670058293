/* eslint-disable react/react-in-jsx-scope */
import AddBlockItem from './AddBlockItem';

const AddBlockItemFake = ({ isDragging, draggedItem, state, props }) => {
  const { mouseX, mouseY } = props;
  return (
    <div
      className={`bg-gray-200 rounded-lg p-4 text-left border-2 flex items-center ${
        isDragging && draggedItem
          ? 'border-gray-400 opacity-50'
          : 'border-transparent'
      }`}
      style={{
        position: 'absolute',
        pointerEvents: 'none', // Prevent mouse events on this div
        opacity: 0.7, // Make it slightly transparent
        zIndex: 5000, // Bring it to front
        left: mouseX - state.deltaX, // Position it at the mouse X
        top: mouseY - state.deltaY, // Position it at the mouse Y
        borderColor: '#ccc',
        backgroundColor: '#f2f2f2',
        borderRadius: '8px',
        padding: '8px',
        paddingLeft: '15px',
        minHeight: 38,
        minWidth: '270px',
      }}>
      <AddBlockItem
        draggedItem={draggedItem}
        isDragging={false} // Indicate that it is being dragged
        item={draggedItem} // Use draggedItem for rendering
      />
    </div>
  );
};
export default AddBlockItemFake;
