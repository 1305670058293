import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardContent, Typography } from '@mui/material';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import MaterialUiTabs from './MaterialUiTabs';

class ConfigDataset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.view !== 'Configuration') return null;
    return (
      <>
        <Card sx={{ height: '100%', margin: 1, marginTop: 0 }}>
          <CardContent>
            <InsightsRoundedIcon />
            <Typography
              component="h2"
              gutterBottom
              sx={{ fontWeight: '600' }}
              variant="subtitle2">
              Explore your data
            </Typography>
            <Typography
              sx={{ color: 'text.secondary', fontSize: 12, mb: '8px' }}>
              Using the load dataset button, select the dataset that you want to
              use in the block.
            </Typography>
            <Button
              color="primary"
              endIcon={<ChevronRightRoundedIcon />}
              size="small"
              style={{ backgroundColor: 'black', textTransform: 'none' }}
              variant="contained">
              Load dataset
            </Button>
          </CardContent>
        </Card>
        <MaterialUiTabs {...this.props} />
      </>
    );
  }
}

ConfigDataset.propTypes = {
  classes: PropTypes.object,
};

export default ConfigDataset;
