import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import { ProjectsItem } from './components';
import { CreateProject, ProjectsToolBar } from 'components';
import useStyles from '../../../utils/MainPageStyles';
import { QueryMyProjectsFull } from 'graphql/Projects';
import { GetData, ServerErrorsString } from 'helpers';

class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      loading: false,
      empty: false,
      projects: [],
      page: 0,
      limit: 100,
      open_createproject: false,
      init: false,
    };
  }

  componentDidMount() {
    if (this.props.isActive) this.udpateProjects();
  }
  componentDidUpdate() {
    const { init } = this.state;
    if (this.props.isActive && !init) this.udpateProjects();
  }
  udpateProjects = () => {
    const { loading, name, page, limit } = this.state;
    if (loading) return;
    this.setState({ loading: true, error: '' });
    (async () => {
      QueryMyProjectsFull(name, page, limit)
        .then(res => {
          const data = GetData(res);
          const { projects: ReplyProjects } = data;
          if (ReplyProjects) {
            const { ok, projects } = ReplyProjects;
            if (ok) {
              this.setState({
                loading: false,
                empty: !projects.length,
                projects,
                init: true,
              });
            } else {
              throw projects.errors;
            }
          } else {
            throw Error('Unknown error');
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            empty: true,
            error: ServerErrorsString(error),
            init: true,
          });
        });
    })();
  };
  handleViewProject = project => {
    if (typeof project === 'undefined') {
      this.setState({ error: 'Invalid project' });
      return;
    }
    const { id } = project;
    this.props.history.push(`/project_management/${id}/overview`);
  };
  handleCreate = () => {
    const { open_createproject } = this.state;
    this.setState({ open_createproject: !open_createproject });
  };
  handleCloseCreate = finished => {
    if (finished) {
      this.udpateProjects();
    }
    this.setState({ open_createproject: false });
  };

  render() {
    if (!this.props.isActive) return null;
    const { classes, height } = this.props;
    const { projects, loading, open_createproject } = this.state;
    return (
      <React.Fragment>
        <ProjectsToolBar
          handleCreate={this.handleCreate}
          reload={this.udpateProjects}
          sub_title="Projects"
          title="MANAGEMENT"
        />
        <CreateProject
          handleAddError={this.props.handleAddError}
          handleCloseCreate={this.handleCloseCreate}
          open={open_createproject}
        />
        <div style={{ height: height - 285, overflow: 'auto' }}>
          <div className={classes.paper}>
            {loading ? (
              <Grid
                alignItems="center"
                container
                item
                justifyContent="center"
                spacing={3}
                style={{ minHeight: '50%' }}
                xs={12}>
                <CircularProgress />
              </Grid>
            ) : (
              projects.map((project, index) => (
                <ProjectsItem
                  handleViewProject={this.handleViewProject}
                  key={`projects-item-${index}`}
                  project={project}
                />
              ))
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Projects.propTypes = {
  history: PropTypes.object,
};
export default withStyles(Projects, useStyles);
