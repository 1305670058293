import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonReloadDelete,
  DialogDeleteWarning,
  SearchText,
} from 'components';
import { Button, ButtonGroup, Grid } from '@mui/material';
import { ServerErrorsString, GetData, IsInvalid, getParamsUrl } from 'helpers';
import SaveIcon from '@mui/icons-material/Save';
import { withRouter } from 'react-router-dom';
import {
  MutationDeleteSharedLinks,
  MutationUpdateSharedLinks,
} from 'graphql/Syncs';
import { getPrivateAccess } from './Styles';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import OpenInNewOffIcon from '@mui/icons-material/OpenInNewOff';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { HTTP_ADDRESS_WEB } from 'config';

class ToolbarShared extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_delete: false,
      deleting: false,
      saving: false,
    };
  }
  handleSave = () => {
    const { saving } = this.state;
    if (saving) return;
    const { rows_edited, shared_links } = this.props;
    this.setState({ saving: true });
    const inputs = rows_edited.map(x => {
      return {
        idLink: x.idLink,
        code: x.code,
        private_access: getPrivateAccess(x.private_access),
      };
    });
    (async () => {
      MutationUpdateSharedLinks(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateSharedLinks;
          if (ok) {
            this.setState({ saving: false });
            this.props.setState({
              shared_links: shared_links.map(x => {
                const index = rows_edited.map(x => x.idLink).indexOf(x.idLink);
                if (index === -1) return x;
                return { ...rows_edited[index] };
              }),
              rows_edited: [],
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleDeleteSelected = () => {
    const { deleting } = this.state;
    const { selected, rows_edited, shared_links } = this.props;
    if (deleting || !selected.length) return;
    this.setState({ deleting: true });
    const inputs = selected.map(x => {
      return {
        idLink: x.idLink,
        code: x.code,
      };
    });
    (async () => {
      MutationDeleteSharedLinks(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteSharedLinks;
          if (ok) {
            this.setState({ deleting: false, open_delete: false });
            this.props.setState({
              shared_links: shared_links.filter(x => {
                const index = selected.map(y => y.idLink).indexOf(x.idLink);
                if (index === -1) return true;
                return false;
              }),
              rows_edited: rows_edited.filter(x => {
                const index = selected.map(y => y.idLink).indexOf(x.idLink);
                if (index === -1) return true;
                return false;
              }),
              selected: [],
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  getLink = link => {
    if (IsInvalid(link)) return null;
    const { code, idFolder } = link;
    let type = 'folder';
    if (IsInvalid(idFolder)) {
      type = 'file';
    }
    return this.getAddress(`shared/${type}/${code}`);
  };
  getAddress = text => {
    let text_link = `${HTTP_ADDRESS_WEB}/${text}`;
    let page = getParamsUrl(this.props);
    if (page) {
      let real_url = window.location.href;
      real_url = real_url.replace(page, '');
      if (HTTP_ADDRESS_WEB !== real_url) {
        text_link = `${real_url}/${text}`;
      }
    }
    return text_link;
  };
  render() {
    const { rows_edited, selected, row_clicked } = this.props;
    let save = rows_edited.length || this.state.saving;
    let idFolder = null;
    let text_link = null;
    let IconLink = <LinkOffIcon style={{ color: 'gray' }} />;
    let IconOpen = <OpenInNewOffIcon style={{ color: 'gray' }} />;
    if (row_clicked) {
      let { content } = row_clicked;
      if (content) {
        if (content.Folder) idFolder = content.Folder.idFolder;
      } else if (row_clicked.idFolder) idFolder = row_clicked.idFolder;
      text_link = this.getLink(row_clicked);
      if (text_link) {
        IconLink = <LinkIcon style={{ color: '#9c27b0' }} />;
      }
    }
    if (idFolder) IconOpen = <OpenInNewIcon style={{ color: 'green' }} />;
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          pb={1}
          pt={1}>
          <Grid item xs={3}>
            <ButtonGroup exclusive="false" size="small" value="center">
              <Button
                disabled={!rows_edited.length || this.state.saving}
                onClick={this.handleSave}>
                <SaveIcon style={{ color: save ? 'green' : 'gray' }} />
              </Button>
              <Button
                disabled={!idFolder}
                onClick={() => {
                  if (idFolder)
                    window.open(
                      this.getAddress(`explorer/folder/${idFolder}`),
                      '_blank'
                    );
                }}>
                {IconOpen}
              </Button>
              <Button
                disabled={!text_link}
                onClick={() => {
                  if (text_link) window.open(text_link, '_blank');
                }}>
                {IconLink}
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs>
            <SearchText
              handleSearchText={code => this.props.handleReload(code)}
              id="id-search-code-shared"
              loading={this.props.loading}
              placeholder="Search Shared by code..."
            />
          </Grid>
          <Grid item>
            <ButtonReloadDelete
              handleClearSelected={this.props.handleClearSelected}
              handleDeleteSelected={() => this.setState({ open_delete: true })}
              handleReloadTable={() => this.props.handleReload()}
              loading_table={this.props.loading}
              numSelected={selected.length}
              small
              tooltip="Delete Shared Objects"
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_delete: false })}
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text="You are about to delete the shared object, this action cannot be undone"
          title_text="Delete Selected Shared Objects"
        />
      </React.Fragment>
    );
  }
}

ToolbarShared.propTypes = {
  classes: PropTypes.object,
};

export default withRouter(ToolbarShared);
