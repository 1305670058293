import React from 'react';
import PropTypes from 'prop-types';
import { TableGrid } from 'components';
import { columns_encryp_map } from '../Styles';
import TabEncryptionPatientMapToolbar from './TabEncryptionPatientMapToolbar';
import { Grid } from '@mui/material';
import { ServerErrorsString, GetData, rowsPerPageBig, IsValid } from 'helpers';
import { QueryEncryptionMaps } from 'graphql/Anonymization';

class TabEncryptionPatientMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      loading: false,
      mappings: [],
      selected: [],
      rows_edited: [],
      page: 0,
      limit: 100,
      init: false,
      query: null,
    };
  }
  componentDidMount() {
    this.handleLoadEncryptedMaps();
  }
  componentDidUpdate() {
    if (!this.state.init) this.handleLoadEncryptedMaps();
  }
  handleLoadEncryptedMaps = (
    new_query = null,
    new_page = null,
    new_limit = null
  ) => {
    if (!this.props.isActive) return;
    const { loading } = this.state;
    if (loading) return;
    let { page, limit, query } = this.state;
    if (new_limit) {
      page = new_page;
      limit = new_limit;
    }
    if (new_query) {
      query = new_query;
    }
    if (IsValid(query)) {
      query.type = 'patient';
    }
    else {
      query = {type: 'patient'};
    }
    this.setState({ loading: true });
    console.log('@@ load', {query, page, limit});
    (async () => {
      QueryEncryptionMaps(query, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, mappings, total } = data.encryptionMaps;
          if (ok) {
            this.setState({
              loading: false,
              mappings,
              total,
              init: true,
              query,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleCreatedMappings = (new_mappings, clean_edit = false) => {
    console.log({ new_mappings, clean_edit });
    let { mappings, total, rows_edited } = this.state;
    for (let i = 0; i < new_mappings.length; i++) {
      const { id } = new_mappings[i];
      const index = mappings.map(x => x.id).indexOf(id);
      if (index === -1) {
        mappings.unshift(new_mappings[i]);
        total++;
      } else {
        mappings[index] = {
          ...mappings[index],
          ...new_mappings[i],
        };
      }
    }
    if (clean_edit) rows_edited = [];
    console.log('@@@@ mappings new', {mappings});
    this.setState({ mappings, total, rows_edited });
  };
  appendColumnResults = (mappings, table) => {
    console.log('@@ Append new column', mappings);
    
    const index = columns_encryp_map.findIndex(item => item['field'] === 'timepoint');
    if (columns_encryp_map[index+1].field !== 'idProject') {
      columns_encryp_map.splice(index+1, 1);
    }
    if (table === 'dicom_series' || table === 'dicom_sequences') {
      const element = {
        field: 'advanced_search_result',
        headerName: table === 'dicom_series' ? 'Amount Series' : 'Amount Sequences',
        width: 75,
        align: 'left',
      };
      columns_encryp_map.splice(index+1, 0, element);

      this.setState({mappings});
    }
  };
  render() {
    return (
      <Grid container>
        <Grid item xs={12}>
          <TabEncryptionPatientMapToolbar
            classes={this.props.classes}
            handleAddError={this.props.handleAddError}
            handleClearSelected={() => this.setState({ selected: [] })}
            handleCreatedMappings={this.handleCreatedMappings}
            handleReload={query => this.handleLoadEncryptedMaps(query)}
            handleResultColumn={mappings => this.appendColumnResults(mappings)}
            idProject={this.props.idProject}
            limit={this.state.limit}
            loading={this.state.loading}
            mappings={this.state.mappings}
            page={this.state.page}
            rows_edited={this.state.rows_edited}
            selected={this.state.selected}
            setState={state => this.setState(state)}
            total={this.state.total}
          />
        </Grid>
        <Grid item xs={12}>
          <TableGrid
            checkboxSelection
            dense
            disableSelectionOnClick
            external_selected={this.state.selected}
            headers={columns_encryp_map}
            height={this.props.height}
            loading={this.state.loading}
            onPageChange={page =>
              this.handleLoadEncryptedMaps(null, page, this.state.limit)
            }
            onRowClicked={row_highlight => this.setState({ row_highlight })}
            onRowEditedChanged={rows_edited => this.setState({ rows_edited })}
            onRowsPerPageChange={limit =>
              this.handleLoadEncryptedMaps(null, 0, limit)
            }
            onSelectedChanged={selected => this.setState({ selected })}
            page={this.state.page}
            paginationMode="server"
            rowCount={this.state.total}
            rows={this.state.mappings}
            rowsPerPage={this.state.limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </Grid>
      </Grid>
    );
  }
}

TabEncryptionPatientMap.propTypes = {
  classes: PropTypes.object,
};

export default TabEncryptionPatientMap;
