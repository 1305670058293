import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';
import { rowsPerPageBig, getLevels, IsInvalid } from 'helpers';
import { TableGrid } from 'components';
import { columns_jobs, columns_batches } from 'common';
import {
  ToolBarJobsActions,
  queryJobProcess,
  queryBatchProcess,
  useStyles,
  deleteFromJobToExecute,
  deleteBatchProcess,
  EditBatchJobs,
} from './components';
import { SubsBatchJobFinished, SubsJobFinished } from 'graphql/Subscriptions';
import { connect } from 'react-redux';
import { setNewJobProcess } from 'redux-store/actions/file_folder_manager';

const tab_names = ['input_jobs', 'batches', 'stopped', 'running'];
const to_skip = ['input_data', 'anonymization', 'analysis', 'block_pipeline'];

class TabBatchProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_edit_batch: false,
      deleting: false,
      loading: false,
      row_styles: [],
      clicked: '',
      selected: [],
      rows: [],
      idRow: 'id',
      columns: columns_jobs,
      page: 0,
      limit: 50,
      job_clicked: null,
    };
    this.holder = {
      setTabClicked: null,
    };
  }
  componentDidMount() {
    let { level_1, level_2 } = getLevels(this.props);
    if (to_skip.indexOf(level_1) !== -1) {
      return;
    }
    if (tab_names.indexOf(level_2) === -1) level_2 = 'input_jobs';
    this.handleViewChanged(level_2);
  }
  componentDidUpdate() {
    let { level_1, level_2 } = getLevels(this.props);
    if (to_skip.indexOf(level_1) !== -1) {
      return;
    }
    if (tab_names.indexOf(level_2) === -1) level_2 = 'input_jobs';
    if (level_2 && level_2 !== this.state.clicked) {
      if (this.holder.setTabClicked) this.holder.setTabClicked(level_2);
    }
    this.checkNewInputJobs();
  }
  checkNewInputJobs = () => {
    const { clicked } = this.state;
    if (clicked !== 'input_jobs') return;
    if (this.props.new_job_process) {
      queryJobProcess(this);
    }
  };
  handleViewChanged = clicked => {
    if (this.state.clicked === clicked) return;
    let state = { clicked, row_styles: [], selected: [] };
    if (clicked === 'input_jobs') {
      state = {
        ...state,
        columns: columns_jobs,
        idRow: 'id',
        rows: [],
      };
      queryJobProcess(this, state);
    } else if (clicked === 'batches') {
      state = {
        ...state,
        columns: columns_batches,
        idRow: 'id',
        rows: [],
        job_clicked: null,
      };
      this.setState(state);
      queryBatchProcess(this);
    } else {
      state = {
        ...state,
        columns: columns_batches,
        idRow: 'id',
        job_clicked: null,
      };
      this.setState(state);
    }
    console.log('handleViewChanged', { clicked });
    this.props.history.push(`/pipelines/batch_process/${clicked}`);
  };
  getRows = () => {
    let { clicked } = this.state;
    if (clicked === 'input_jobs' || clicked === 'batches') {
      return this.state.rows;
    } else if (clicked === 'stopped')
      return this.state.rows.filter(x => x.status === 'finished');
    else if (clicked === 'running')
      return this.state.rows.filter(x => x.status === 'running');
    else return [];
  };
  handleDeleteSelected = () => {
    console.log('handleDeleteSelected');
    const { clicked } = this.state;
    if (clicked === 'input_jobs') {
      deleteFromJobToExecute(this);
    } else if (clicked === 'batches') {
      deleteBatchProcess(this);
    } else {
      /* empty */
    }
  };
  handleProcessFailed = fails => {
    if (fails === undefined || !fails || !fails.length) {
      return;
    }
    const { row_styles, idRow } = this.state;
    for (let i = 0; i < fails.length; i++) {
      const value = fails[i][idRow];
      const index = row_styles.map(x => x[idRow]).indexOf(value);
      if (index === -1) {
        row_styles.push({
          [idRow]: value,
          style: {
            backgroundColor: '#ff8a80',
          },
        });
      }
    }
    this.props.handleAddWarning(fails.map(x => x.error).join('\n'));
    this.setState({ row_styles });
  };
  handleReloadTable = () => {
    const { clicked } = this.state;
    if (clicked === 'input_jobs') {
      queryJobProcess(this);
    } else if (clicked === 'batches') {
      queryBatchProcess(this);
    } else {
      /* empty */
    }
    this.setState({ job_clicked: null });
  };
  handleProcessExecuted = executed => {
    const { selected, rows } = this.state;
    for (let i = 0; i < executed.length; i++) {
      const { idSeries, idFileObject } = executed[i];
      let j = selected
        .map(x => `${x.idSeries}_${x.idFileObject}`)
        .indexOf(`${idSeries}_${idFileObject}`);
      if (j !== -1) {
        selected.splice(j, 1);
      }
      j = rows
        .map(x => `${x.idSeries}_${x.idFileObject}`)
        .indexOf(`${idSeries}_${idFileObject}`);
      if (j !== -1) {
        rows.splice(j, 1);
      }
    }
    this.setState({ selected, rows, job_clicked: null });
  };
  handleSaveSetting = setting => {
    const { batch, clicked } = this.state;
    if (clicked === 'batches') {
      if (IsInvalid(batch)) return [];
      let { rows, selected } = this.state;
      let index = rows.map(x => x.id).indexOf(batch.id);
      if (index !== -1) {
        rows[index] = {
          ...rows[index],
          setting,
        };
      }
      index = selected.map(x => x.id).indexOf(batch.id);
      if (index !== -1) {
        selected[index] = {
          ...selected[index],
          setting,
        };
      }
      this.setState({ rows, selected });
    } else if (clicked === 'stopped') {
      return this.state.rows.filter(x => x.status === 'finished');
    } else if (clicked === 'running') {
      return this.state.rows.filter(x => x.status === 'running');
    } else {
      return [];
    }
  };
  handleUpdateJobs = (idBatch, jobs) => {
    let { rows, selected } = this.state;
    let index = rows
      .map(x => x.id)
      .filter(x => x)
      .indexOf(idBatch);
    if (index !== -1) {
      rows[index].total_jobs = jobs.length;
    }
    index = selected
      .map(x => x.id)
      .filter(x => x)
      .indexOf(idBatch);
    if (index !== -1) {
      selected[index].total_jobs = jobs.length;
    }
    // console.log('handleUpdateJobs');
    this.setState({ rows, selected });
  };
  handleUpdateBatches = batches => {
    // console.log('handleUpdateBatches',{ batches });
    const { selected, rows } = this.state;
    for (let i = 0; i < batches.length; i++) {
      const { id } = batches[i];
      let index = selected.map(x => x.id).indexOf(id);
      if (index !== -1) selected[index] = { ...selected[index], ...batches[i] };
      index = rows.map(x => x.id).indexOf(id);
      if (index !== -1) rows[index] = { ...rows[index], ...batches[i] };
    }
    // console.log('handleUpdateBatches',{ rows, selected });
    this.setState({ rows, selected, job_clicked: null });
  };
  handleBatchJobFinished = job => {
    // console.log('handleBatchJobFinished', { job });
    const { selected } = this.state;
    const index = selected.map(x => x.id).indexOf(job.idBatch);
    if (index === -1) return;
    const { batch } = job;
    if (batch.status === 'waiting' && batch.jobs_running) {
      batch.status = 'running';
    }
    this.handleUpdateBatches([{ id: job.idBatch, ...batch }]);
  };
  handleRowClick = job_clicked => {
    const { clicked } = this.state;
    let idJob = null;
    if (job_clicked) idJob = job_clicked.id;
    console.log({ clicked, idJob, job_clicked });
    this.setState({ job_clicked });
  };
  handleJobFinished = update_job => {
    console.log({ update_job });
    const { clicked, rows } = this.state;
    if (clicked !== 'input_jobs') return;
    const index = rows.map(x => x.id).indexOf(update_job.id);
    if (index === -1) return;
    rows[index].status = update_job.status;
    this.setState({ rows, job_clicked: update_job });
  };
  handleUpdateJobProcess = job => {
    const { clicked, rows } = this.state;
    if (clicked !== 'input_jobs') return;
    const index = rows.map(x => x.id).indexOf(job.id);
    // console.log({ index, job, rows });
    if (index === -1) return;
    rows[index].status = job.status;
    rows[index].setting = job.setting;
    this.setState({ rows, job_clicked: job });
  };
  render() {
    if (!this.props.isActive) return null;
    const { height } = this.props;
    let {
      columns,
      idRow,
      open_edit_batch,
      clicked,
      selected,
      job_clicked,
    } = this.state;

    const rows = this.getRows();
    // console.log({ idRow, clicked, rows, columns });
    if (open_edit_batch) {
      return (
        <EditBatchJobs
          batches_with_jobs={selected}
          handleAddError={this.props.handleAddError}
          handleAddSuccess={this.props.handleAddSuccess}
          handleOpenBatchEdit={open_edit_batch =>
            this.setState({ open_edit_batch })
          }
          handleSaveSetting={this.handleSaveSetting}
          handleUpdateBatches={this.handleUpdateBatches}
          handleUpdateJobs={this.handleUpdateJobs}
          height={height}
        />
      );
    }
    let ComponentSubscription = null;
    let ComponentJobSubscription = null;
    if (clicked === 'input_jobs') {
      let idJob = -1;
      if (job_clicked) idJob = job_clicked.id;
      ComponentJobSubscription = (
        <SubsJobFinished
          handleJobFinished={this.handleJobFinished}
          ids={[idJob]}
        />
      );
    } else if (selected.length) {
      ComponentSubscription = (
        <SubsBatchJobFinished
          batch_ids={selected.map(x => x.id)}
          handleBatchJobFinished={this.handleBatchJobFinished}
        />
      );
    }

    return (
      <React.Fragment>
        {ComponentSubscription}
        {ComponentJobSubscription}
        <Grid container>
          <Grid item xs={12}>
            <ToolBarJobsActions
              batch={this.state.selected.length ? this.state.selected[0] : null}
              clicked={clicked}
              deleting={this.state.deleting}
              handleAddError={this.props.handleAddError}
              handleAddSuccess={this.props.handleAddSuccess}
              handleClearSelected={() => this.setState({ selected: [] })}
              handleDeleteSelected={this.handleDeleteSelected}
              handleOpenBatchEdit={open_edit_batch =>
                this.setState({ open_edit_batch })
              }
              handleProcessExecuted={this.handleProcessExecuted}
              handleProcessFailed={this.handleProcessFailed}
              handleReloadTable={this.handleReloadTable}
              handleUpdateBatches={this.handleUpdateBatches}
              handleUpdateJobProcess={this.handleUpdateJobProcess}
              handleViewChanged={this.handleViewChanged}
              holder={this.holder}
              job_clicked={this.state.job_clicked}
              loading={this.state.loading}
              selected={this.state.selected}
            />
          </Grid>
          <Grid item sx={{ paddingTop: 1 }} xs={12}>
            <TableGrid
              checkboxSelection
              dense
              disableSelectionOnClick
              external_selected={this.state.selected}
              headers={columns}
              height={height - 240}
              idRow={idRow}
              limit={this.state.limit}
              loading={this.state.loading}
              onRowClicked={this.handleRowClick}
              onSelectedChanged={selected => this.setState({ selected })}
              page={this.state.page}
              row_styles={this.state.row_styles}
              rows={rows}
              rowsPerPage={this.state.limit}
              rowsPerPageOptions={rowsPerPageBig}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

TabBatchProcess.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = state => {
  const { new_job_process } = state.manager;
  return {
    new_job_process,
  };
};
export default connect(mapStateToProps, {
  setNewJobProcess,
})(withStyles(TabBatchProcess, useStyles));
