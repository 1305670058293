import React from 'react';
import clsx from 'clsx';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @mui/material components
import { makeStyles } from 'tss-react/mui';

// core components
import styles from './Styles';

const useStyles = makeStyles()(styles);
const defaultProps = {
  medium: false,
  small: false,
};
const HomeMain = props => {
  props = { ...defaultProps, ...props };
  let windowScrollTop;
  if (window.innerWidth >= 768) {
    windowScrollTop = window.pageYOffset / 3;
  } else {
    windowScrollTop = 0;
  }
  const [transform, setTransform] = React.useState(
    'translate3d(0,' + windowScrollTop + 'px,0)'
  );
  React.useEffect(() => {
    if (window.innerWidth >= 768) {
      window.addEventListener('scroll', resetTransform);
    }
    return function cleanup() {
      if (window.innerWidth >= 768) {
        window.removeEventListener('scroll', resetTransform);
      }
    };
  });
  const resetTransform = () => {
    var windowScrollTop = window.pageYOffset / 3;
    setTransform('translate3d(0,' + windowScrollTop + 'px,0)');
  };
  const { filter, className, children, style, image, small, medium } = props;
  const { classes } = useStyles();
  const parallaxClasses = clsx({
    [classes.parallax]: true,
    [classes.filter]: filter,
    [classes.small]: small && !medium,
    [classes.medium]: !small && medium,
    [className]: className !== undefined,
  });
  return (
    <div
      className={parallaxClasses}
      style={{
        ...style,
        backgroundImage:
          'linear-gradient(rgba(12,12, 12, 0.5), rgba(5,5,5,0.5)), url(' +
          image +
          ')',
        transform: transform,
      }}>
      {children}
    </div>
  );
};

HomeMain.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  filter: PropTypes.bool,
  image: PropTypes.string,
  medium: PropTypes.bool,
  small: PropTypes.bool,
  style: PropTypes.object,
};

export default HomeMain;
