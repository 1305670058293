import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_USER_TEMP_ACCESS = gql`
  query($email: String!, $name_app: String!, $code: String!) {
    userTempAccess(email: $email, name_app: $name_app, code: $code) {
      ok
      errors {
        path
        message
      }
      access {
        code
        name_app
      }
    }
  }
`;
export const QueryUserTempAccess = (email, name_app, code) => {
  console.log('QueryUserTempAccess', { email, name_app, code });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_USER_TEMP_ACCESS),
    variables: {
      email,
      name_app,
      code,
    },
  });
};

const MUTATION_USER_TEMP_ACCESS = gql`
  mutation($email: String!, $name_app: String!) {
    createUserTempAccess(email: $email, name_app: $name_app) {
      ok
      errors {
        path
        message
      }
      access {
        code
        name_app
      }
    }
  }
`;
export const MutationUserTempAccess = (email, name_app) => {
  console.log('MutationUserTempAccess', { email, name_app });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_USER_TEMP_ACCESS),
    variables: {
      email,
      name_app,
    },
  });
};
