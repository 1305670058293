import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Button, Typography, Menu, MenuItem, Fade } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  style_button_clear,
  style_delete_button,
  style_menu_list,
  TextSearch,
} from '../styles';
import { SearchTransparent } from 'components';

class StackMiddle extends React.Component {
  constructor(props) {
    super(props);
    this.state = { anchorEl: null, menu_selected: 'Newest' };
  }
  handleClose = menu_selected => {
    const state = { anchorEl: null };
    if (menu_selected) state.menu_selected = menu_selected;
    this.setState(state);
    this.props.setState(state);
  };
  render() {
    const {
      classes,
      search,
      count_searchs,
      handleSearchClick,
      handleDelete,
    } = this.props;
    let { textsearchs, selected } = this.props;
    if (textsearchs === undefined) textsearchs = {};
    if (selected === undefined) selected = [];
    console.log('StackMiddle', {
      textsearchs,
    });
    const SearchComponent = (
      <SearchTransparent
        onChange={event => {
          this.props.setState({
            textsearchs: { ...textsearchs, [search]: event.target.value },
          });
        }}
        onKeyPress={event => {
          if (!event) return;
          if (event.key === 'Enter') {
            this.props.setState({ search: null });
          }
        }}
      />
    );
    return (
      <Stack className={classes.stack_main}>
        <Stack className={classes.stack_left}>
          <Button
            className={classes.button}
            onClick={() => handleSearchClick('email')}
            startIcon={
              <AddCircleOutlineIcon
                sx={{
                  display: 'inherit',
                  marginRight: '8px',
                  marginLeft: '-4px',
                }}
              />
            }>
            <span style={{ display: 'flex' }}>
              Email
              {textsearchs.email && search !== 'email' ? (
                <TextSearch text={textsearchs.email} />
              ) : null}
            </span>
            {search === 'email' && SearchComponent}
          </Button>
          <Button
            className={classes.button}
            onClick={() => handleSearchClick('phone')}
            startIcon={
              <AddCircleOutlineIcon
                sx={{
                  display: 'inherit',
                  marginRight: '8px',
                  marginLeft: '-4px',
                }}
              />
            }>
            <span style={{ display: 'flex' }}>
              Phone number
              {textsearchs.phone && search !== 'phone' ? (
                <TextSearch text={textsearchs.phone} />
              ) : null}
            </span>
            {search === 'phone' && SearchComponent}
          </Button>
          {count_searchs ? (
            <Button
              onClick={() => this.props.setState({ textsearchs: {} })}
              sx={style_button_clear}>
              Clear filters
            </Button>
          ) : null}
        </Stack>
        <Stack
          sx={{
            display: selected.length ? 'flex' : 'none',
            '-webkit-flex-direction': 'row',
            '-ms-flex-direction': 'row',
            flexDirection: 'row',
            gap: 'calc(2 * var(--mui-spacing))',
            '-webkit-align-items': 'center',
            '-webkit-box-align': 'center',
            '-ms-flex-align': 'center',
            alignItems: 'center',
            '--mui-spacing': '8px',
          }}>
          <Typography
            component="p"
            sx={{
              margin: 0,
              fontFamily:
                '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
              fontWeight: 600,
              fontSize: '0.875rem',
              lineHeight: '1.57',
              color: 'var(--mui-palette-text-secondary)',
              '--mui-palette-text-secondary': '#667085',
            }}
            variant="boby2">{`${selected.length} selected`}</Typography>
          <Button onClick={handleDelete} sx={style_delete_button}>
            Delete
          </Button>
        </Stack>
        <Button
          className={classes.button}
          endIcon={
            <ExpandMoreIcon
              sx={{
                display: 'inherit',
                marginRight: '8px',
                marginLeft: '-4px',
                paddingLeft: '2px',
              }}
            />
          }
          onClick={event => {
            this.setState({ anchorEl: event.currentTarget });
          }}
          style={{ paddingRight: '2px' }}>
          {this.state.menu_selected}
        </Button>

        <Menu
          anchorEl={this.state.anchorEl}
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          onClose={this.handleClose}
          open={Boolean(this.state.anchorEl)}
          sx={{
            '& .MuiPaper-root': {
              borderRadius: '10px',
              gap: '4px',
              padding: '8px',
              marginTop: '6px',
              transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)',
              backgroundColor: 'var(--mui-palette-background-paper)',
              color: 'var(--mui-palette-text-primary)',
              boxShadow: 'var(--Paper-shadow)',
              border: '1px solid var(--mui-palette-divider)',
              maxWidth: 'calc(100% - 32px)',
              maxHeight: 'calc(100% - 96px)',
              minWidth: '100px',
              minHeight: '16px',
              overflow: 'hidden auto',
              display: 'flex',
              flexDirection: 'column',
              backgroundImage: 'none',
              '--mui-palette-background-paper': '#ffffff',
              '--mui-palette-text-primary': '#212636',
              '--mui-palette-divider': '#dcdfe4',
              '--Paper-shadow': '0px 1px 2px rgba(255, 1, 1, 0.08)',
            },
          }}
          TransitionComponent={Fade}>
          {['Newest', 'Oldest'].map((item, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => this.handleClose(item)}
                selected={item === this.state.menu_selected}
                sx={style_menu_list}>
                {item}
              </MenuItem>
            );
          })}
        </Menu>
      </Stack>
    );
  }
}

StackMiddle.propTypes = {
  classes: PropTypes.object,
};

export default StackMiddle;
