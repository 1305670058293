import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography, Slider } from '@mui/material';
import { styled } from '@mui/material/styles';
const PrettoSlider = styled(Slider)(({ value, disabled }) => ({
  color: value > 0 ? '#52af77' : '#e0e0e0',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    display: disabled ? 'none' : undefined,
    height: 30,
    width: 30,
    backgroundColor: value > 0 ? '#fff' : '#eeeeee', //#eeeeee
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 20,
    height: 20,
    borderRadius: '50% 50% 50% 50%',
    backgroundColor: value > 0 ? '#52af77' : '#e0e0e0',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, 87%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
}));
class AaocaSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { value, files, disabled_all, full_run, active } = this.props;
    const disabled = disabled_all ? true : files.length ? false : true;
    return (
      <Paper
        sx={{
          p: 5,
          width: '100%',
          border: `1px solid ${active ? '#eceff1' : ''}`,
          borderRadius: '16px',
        }}>
        <Grid
          container
          direction="column"
          sx={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Typography>
            Choose the threshold for the Coronary Anomaly Detection Model.
          </Typography>
          <PrettoSlider
            aria-label="pretto slider"
            disabled={disabled || full_run}
            onChange={e => this.props.setState({ value: e.target.value })}
            value={value}
            valueLabelDisplay="on"
          />
          <Typography>
            The default value used in the trained and tested model is 0.5.
          </Typography>
          <Typography>
            If you know it is AAOCA, set the threshold to 1 to run the origin
            and risk scoring model.
          </Typography>
        </Grid>
      </Paper>
    );
  }
}

AaocaSetting.propTypes = {
  classes: PropTypes.object,
};

export default AaocaSetting;
