import React from 'react';
import PropTypes from 'prop-types';
import {
  // Avatar,
  // ListItemAvatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { blue, grey } from '@mui/material/colors';
import { withStyles } from 'tss-react/mui';

import { ButtonLoad } from 'components';
import { QueryLoadDiagrams } from 'graphql/Blocks';
import { GetData, IsInvalid, ServerErrorsString } from 'helpers';

const useStyle = () => ({
  circle: {
    width: '20px' /* 2 * radius */,
    height: '20px' /* 2 * radius */,
    backgroundColor: ' #808080',
    borderRadius: '50%',
    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',

    /* Center the text */
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    /* Scale text relative to circle size */
    fontWeight: 'bold',
    fontSize: 'calc(15px * 0.75)' /* 75% of radius for readable text */,
    color: 'white',
  },
});
class DialogLoadWorkflow extends React.Component {
  constructor(props) {
    super(props);
    const { diagram } = props;
    this.state = {
      diagrams: [],
      loading: false,
      diagram,
      total: 0,
    };
    const { holder } = props;
    if (holder) {
      holder.addDiagram = diagram => {
        if (IsInvalid(diagram)) return;
        const { diagrams } = this.state;
        const index = this.state.diagrams.findIndex(d => d.id === diagram.id);
        if (index === -1) {
          diagrams.unshift(diagram);
          this.setState({ diagrams });
        }
      };
      holder.setDiagram = diagram => this.setState({ diagram });
      holder.updateDiagram = diagram => {
        if (IsInvalid(diagram)) return;
        const { diagrams } = this.state;
        const index = diagrams.findIndex(d => d.id === diagram.id);
        if (index !== -1) diagrams[index] = diagram;
        this.setState({ diagrams });
      };
    }
  }
  componentDidMount() {
    this.handleLoadDiagrams();
  }
  componentDidUpdate() {
    const { total } = this.props;
    if (total !== undefined && total === this.state.total) {
      this.handleLoadDiagrams();
    }
  }
  handleListItemClick = diagram => {
    console.log({ diagram });
    this.setState({ diagram });
  };
  handleLoadDiagrams = () => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: false });
    (async () => {
      QueryLoadDiagrams(null)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, diagrams, total } = data.loadDiagrams;
          if (ok) {
            this.setState({ loading: false, diagrams, total });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { open, handleClose, loading, classes } = this.props;
    const { diagrams, diagram } = this.state;
    let diagram_name = null;
    if (diagram) {
      diagram_name = diagram.name;
    }
    return (
      <Dialog onClose={handleClose} open={open} sx={{ minWidth: 400 }}>
        <DialogTitle>Load New Workflow</DialogTitle>
        <DialogContent dividers sx={{ pt: 1, pb: 1 }}>
          <List
            sx={{ pt: 0, bgcolor: 'rgba(71, 98, 130, 0.2)', minHeight: 100 }}>
            {diagrams.map(dg => {
              const { name, total } = dg;
              return (
                <ListItem
                  disableGutters
                  key={name}
                  secondaryAction={
                    total ? (
                      <div style={{ padding: 0, paddingRight: 5 }}>
                        <div className={classes.circle}>{total}</div>{' '}
                      </div>
                    ) : null
                  }
                  sx={{
                    padding: 0,
                    minWidth: 200,
                    border:
                      diagram_name === name
                        ? `2px solid ${blue[600]}`
                        : `1px solid ${grey[300]}`,
                    borderRadius: 1,
                    backgroundColor:
                      diagram_name === name ? blue[50] : 'transparent',
                    '&:hover': {
                      backgroundColor:
                        diagram_name === name ? blue[50] : grey[100],
                    },
                  }}>
                  <ListItemButton
                    onClick={() => this.handleListItemClick(dg)}
                    sx={{
                      padding: 0,
                      margin: 0,
                      py: 0,
                      minHeight: 32,
                    }}>
                    <ListItemIcon
                      sx={{ paddingLeft: 2, color: 'inherit', minWidth: 45 }}>
                      <AccountTreeIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={name}
                      primaryTypographyProps={{
                        fontSize: 14,
                        fontWeight: 'medium',
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions style={{ padding: 0, margin: 0 }}>
          <Button onClick={handleClose}>Cancel</Button>
          <ButtonLoad
            handleClick={() => this.props.onClick(diagram)}
            loading={loading || this.props.loading}
            name="Load"
            transform
            variant="text"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

DialogLoadWorkflow.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};
DialogLoadWorkflow.defaultProps = {
  handleClose: () => '',
  open: false,
  loading: false,
  handleAddError: () => '',
  total: undefined,
  diagram: null,
};
export default withStyles(DialogLoadWorkflow, useStyle);
