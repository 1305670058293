/* eslint-disable quotes */
import React from 'react';
import { Box, Checkbox, Chip, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import BlockIcon from '@mui/icons-material/Block';

import moment from 'moment';

export const useStyles = theme => ({
  tabs: {
    overflow: 'hidden',
    minHeight: '48px',
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  tabIndicator: {
    position: 'absolute',
    bottom: '0px',
    width: '100%',
    transition: '300ms cubic-bezier(0.4, 0, 0.2, 1)',
    backgroundColor: theme.palette.primary.main,
    height: '4px',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
  },
  tabScrollButton: {
    overflow: 'auto hidden',
    scrollbarWidth: 'none',
    width: '20px',
  },
  tabsContainer: {
    position: 'relative',
    display: 'inline-block',
    flex: '1 1 auto',
    whiteSpace: 'nowrap',
    scrollbarWidth: 'none',
    overflow: 'auto hidden',
  },
  tab: {
    paddingInline: '8px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    outline: '0px',
    border: '0px',
    margin: '0px',
    borderRadius: '0px',
    cursor: 'pointer',
    userSelect: 'none',
    verticalAlign: 'middle',
    appearance: 'none',
    textDecoration: 'none',
    fontFamily:
      '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    fontWeight: '500',
    fontSize: '0.875rem',
    lineHeight: '1.25',
    maxWidth: '360px',
    position: 'relative',
    flexShrink: '0',
    padding: '9px 16px',
    overflow: 'hidden',
    whiteSpace: 'normal',
    textAlign: 'center',
    flexDirection: 'row',
    color: theme.palette.text.secondary,
    minWidth: 'auto',
    textTransform: 'none',
    minHeight: 'auto',
    '&.Mui-selected': {
      color: theme.palette.primary.main,
    },
  },
  chipWrapper: {
    maxWidth: '100%',
    fontFamily:
      '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    fontSize: '0.8125rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '24px',
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '12px',
    fontWeight: '500',
    // backgroundColor: 'var(--Chip-softBg)',
    // color: 'var(--Chip-softColor)',
    '--Chip-softBg': theme.palette.secondary[100],
    '--Chip-softColor': theme.palette.secondary[700],
    '--Chip-softDisabledBg': theme.palette.secondary[50],
    '--Chip-softDisabledColor': theme.palette.secondary[400],
    '--Chip-softHoverBg': theme.palette.secondary[200],
    '--Chip-softDeleteIconColor': theme.palette.secondary[700],
    '--Chip-softDeleteIconHoverColor': theme.palette.secondary[800],
  },
  stack_main: {
    display: 'flex',
    flexFlow: 'wrap',
    gap: 'calc(2 * var(--mui-spacing))',
    '-moz-box-align': 'center',
    alignItems: 'center',
    padding: '24px',
    paddingRight: '10px',
    paddingLeft: '10px',
    '--mui-spacing': '8px',
  },
  stack_left: {
    display: 'flex',
    flexFlow: 'wrap',
    gap: 'calc(2 * var(--mui-spacing))',
    '-moz-box-align': 'center',
    alignItems: 'center',
    flex: '1 1 auto',
    '--mui-spacing': '8px',
  },
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    outline: 0,
    margin: 0,
    cursor: 'pointer',
    userSelect: 'none',
    verticalAlign: 'middle',
    appearance: 'none',
    textDecoration: 'none',
    fontFamily:
      '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    fontWeight: 500,
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
    padding: '5px 15px',
    borderWidth: '1px',
    borderStyle: 'solid',
    backgroundColor: 'var(--variant-outlinedBg)',
    color: 'var(--variant-outlinedColor)',
    borderRadius: '8px',
    minHeight: 'var(--Button-minHeight)',
    minWidth: 'unset',
    textTransform: 'none',
    boxShadow: 'var(--mui-shadows-1)',
    borderColor: 'var(--Button-outlinedBorder)',
    fontSize: '0.875rem',
    lineHeight: '28px',
    '--Button-minHeight': '40x',
    '--Button-outlinedActiveBg': 'rgba(50 56 62 / 0.12)',
    '--Button-outlinedBorder': '#dde7ee',
    '--Button-outlinedHoverBg': 'rgba(50 56 62 / 0.04)',
    '--Content-margin': '0 auto',
    '--Content-maxWidth': '1440px',
    '--Content-padding': '24px 24px',
    '--Content-paddingX': '24px',
    '--Content-paddingY': '24px',
    '--Content-width': '100%',
    '--MainNav-height': '56px',
    '--MainNav-zIndex': '1000',
    '--MobileNav-width': '280px',
    '--MobileNav-zIndex': '1100',
    '--Paper-overlay': '<empty>',
    '--Paper-shadow': '0px 1px 2px rgba(0, 0, 0, 0.08)',
    '--SideNav-width': '260px',
    '--SideNav-zIndex': '1100',
    '--fontSize-2xl': '1.5rem',
    '--fontSize-lg': '1.125rem',
    '--fontSize-md': '1rem',
    '--fontSize-sm': '0.875rem',
    '--fontSize-xl': '1.25rem',
    '--fontSize-xs': '0.75rem',
    '--icon-fontSize-lg': '1.5rem',
    '--icon-fontSize-md': '1.25rem',
    '--icon-fontSize-sm': '1rem',
    '--maxWidth-lg': '1200px',
    '--maxWidth-md': '900px',
    '--maxWidth-sm': '600px',
    '--maxWidth-xl': '1440px',
    '--maxWidth-xs': '0',
    '--mui-font-body1':
      '400 1rem/1.5 "Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
    '--mui-font-body2':
      '400 0.875rem/1.57 "Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
    '--mui-font-button':
      '500 0.875rem/1.75 "Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
    '--mui-font-caption':
      '400 0.75rem/1.66 "Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
    '--mui-font-h1':
      '500 3.5rem/1.2 "Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
    '--mui-font-h2':
      '500 3rem/1.2 "Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
    '--mui-font-h3':
      '500 2.25rem/1.2 "Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
    '--mui-font-h4':
      '500 2rem/1.2 "Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
    '--mui-font-h5':
      '500 1.5rem/1.2 "Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
    '--mui-font-h6':
      '500 1.125rem/1.2 "Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
    '--mui-font-inherit': 'inherit inherit/inherit inherit',
    '--mui-font-overline':
      '500 0.75rem/2.5 "Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
    '--mui-font-subtitle1':
      '500 1rem/1.57 "Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
    '--mui-font-subtitle2':
      '500 0.875rem/1.57 "Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
    '--mui-opacity-inputPlaceholder': '0.42',
    '--mui-opacity-inputUnderline': '0.42',
    '--mui-opacity-switchTrack': '0.38',
    '--mui-opacity-switchTrackDisabled': '0.12',
    '--mui-palette-Alert-errorColor': 'rgb(99, 48, 44)',
    '--mui-palette-Alert-errorFilledBg': '#f04438',
    '--mui-palette-Alert-errorFilledColor': '#fff',
    '--mui-palette-Alert-errorIconColor': '#f04438',
    '--mui-palette-Alert-errorStandardBg': 'rgb(254, 241, 240)',
    '--mui-palette-Alert-infoColor': 'rgb(6, 76, 92)',
    '--mui-palette-Alert-infoFilledBg': '#04aad6',
    '--mui-palette-Alert-infoFilledColor': 'rgba(0, 0, 0, 0.87)',
    '--mui-palette-Alert-infoIconColor': '#04aad6',
    '--mui-palette-Alert-infoStandardBg': 'rgb(231, 248, 252)',
    '--mui-palette-Alert-successColor': 'rgb(18, 84, 73)',
    '--mui-palette-Alert-successFilledBg': '#15b79f',
    '--mui-palette-Alert-successFilledColor': 'rgba(0, 0, 0, 0.87)',
    '--mui-palette-Alert-successIconColor': '#15b79f',
    '--mui-palette-Alert-successStandardBg': 'rgb(234, 250, 247)',
    '--mui-palette-Alert-warningColor': 'rgb(102, 74, 12)',
    '--mui-palette-Alert-warningFilledBg': '#fb9c0c',
    '--mui-palette-Alert-warningFilledColor': 'rgba(0, 0, 0, 0.87)',
    '--mui-palette-Alert-warningIconColor': '#fb9c0c',
    '--mui-palette-Alert-warningStandardBg': 'rgb(255, 248, 232)',
    '--mui-palette-AppBar-defaultBg': '#f5f5f5',
    '--mui-palette-Avatar-defaultBg': '#565e73',
    '--mui-palette-Backdrop-bg': 'rgb(18, 22, 33, 0.8)',
    '--mui-palette-Button-inheritContainedBg': '#e0e0e0',
    '--mui-palette-Button-inheritContainedHoverBg': '#f5f5f5',
    '--mui-palette-Chip-defaultAvatarColor': '#616161',
    '--mui-palette-Chip-defaultBorder': '#bdbdbd',
    '--mui-palette-Chip-defaultIconColor': '#616161',
    '--mui-palette-FilledInput-bg': 'rgba(0, 0, 0, 0.06)',
    '--mui-palette-FilledInput-disabledBg': 'rgba(0, 0, 0, 0.12)',
    '--mui-palette-FilledInput-hoverBg': 'rgba(0, 0, 0, 0.09)',
    '--mui-palette-LinearProgress-errorBg': 'rgb(249, 183, 179)',
    '--mui-palette-LinearProgress-infoBg': 'rgb(159, 222, 239)',
    '--mui-palette-LinearProgress-primaryBg': 'rgb(195, 192, 255)',
    '--mui-palette-LinearProgress-secondaryBg': 'rgb(177, 179, 181)',
    '--mui-palette-LinearProgress-successBg': 'rgb(166, 227, 218)',
    '--mui-palette-LinearProgress-warningBg': 'rgb(253, 217, 162)',
    '--mui-palette-OutlinedInput-border': '#dcdfe4',
    '--mui-palette-Skeleton-bg': 'rgba(33 38 54 / 0.11)',
    '--mui-palette-Slider-errorTrack': 'rgb(249, 183, 179)',
    '--mui-palette-Slider-infoTrack': 'rgb(159, 222, 239)',
    '--mui-palette-Slider-primaryTrack': 'rgb(195, 192, 255)',
    '--mui-palette-Slider-secondaryTrack': 'rgb(177, 179, 181)',
    '--mui-palette-Slider-successTrack': 'rgb(166, 227, 218)',
    '--mui-palette-Slider-warningTrack': 'rgb(253, 217, 162)',
    '--mui-palette-SnackbarContent-bg': '#ffffff',
    '--mui-palette-SpeedDialAction-fabHoverBg': '#ffffff',
    '--mui-palette-StepConnector-border': '#bdbdbd',
    '--mui-palette-StepContent-border': '#bdbdbd',
    '--mui-palette-Switch-defaultColor': '#ffffff',
    '--mui-palette-Switch-defaultDisabledColor': '#f5f5f5',
    '--mui-palette-Switch-errorDisabledColor': 'rgb(249, 183, 179)',
    '--mui-palette-Switch-infoDisabledColor': 'rgb(159, 222, 239)',
    '--mui-palette-Switch-primaryDisabledColor': 'rgb(195, 192, 255)',
    '--mui-palette-Switch-secondaryDisabledColor': 'rgb(177, 179, 181)',
    '--mui-palette-Switch-successDisabledColor': 'rgb(166, 227, 218)',
    '--mui-palette-Switch-warningDisabledColor': 'rgb(253, 217, 162)',
    '--mui-palette-TableCell-border': '#dcdfe4',
    '--mui-palette-Tooltip-bg': 'rgba(10, 13, 20, 0.75)',
    '--mui-palette-action-activatedOpacity': '0.12',
    '--mui-palette-action-active': 'rgba(0, 0, 0, 0.54)',
    '--mui-palette-action-activeChannel': '0 0 0',
    '--mui-palette-action-disabled': 'rgba(0, 0, 0, 0.26)',
    '--mui-palette-action-disabledBackground': 'rgba(0, 0, 0, 0.06)',
    '--mui-palette-action-disabledOpacity': '0.38',
    '--mui-palette-action-focus': 'rgba(0, 0, 0, 0.12)',
    '--mui-palette-action-focusOpacity': '0.12',
    '--mui-palette-action-hover': 'rgba(0, 0, 0, 0.04)',
    '--mui-palette-action-hoverOpacity': '0.04',
    '--mui-palette-action-selected': 'rgba(0, 0, 0, 0.08)',
    '--mui-palette-action-selectedChannel': '0 0 0',
    '--mui-palette-action-selectedOpacity': '0.08',
    '--mui-palette-background-default': '#ffffff',
    '--mui-palette-background-defaultChannel': '255 255 255',
    '--mui-palette-background-level1': '#f9fafb',
    '--mui-palette-background-level2': '#f1f1f4',
    '--mui-palette-background-level3': '#dcdfe4',
    '--mui-palette-background-paper': '#ffffff',
    '--mui-palette-background-paperChannel': '255 255 255',
    '--mui-palette-common-background': '#fff',
    '--mui-palette-common-backgroundChannel': '255 255 255',
    '--mui-palette-common-black': '#000000',
    '--mui-palette-common-onBackground': '#000',
    '--mui-palette-common-onBackgroundChannel': '0 0 0',
    '--mui-palette-common-white': '#ffffff',
    '--mui-palette-divider': '#dcdfe4',
    '--mui-palette-dividerChannel': '220 223 228',
    '--mui-palette-error-100': '#fee4e2',
    '--mui-palette-error-200': '#ffcdc9',
    '--mui-palette-error-300': '#fdaaa4',
    '--mui-palette-error-400': '#f97970',
    '--mui-palette-error-50': '#fef3f2',
    '--mui-palette-error-500': '#f04438',
    '--mui-palette-error-600': '#de3024',
    '--mui-palette-error-700': '#bb241a',
    '--mui-palette-error-800': '#9a221a',
    '--mui-palette-error-900': '#80231c',
    '--mui-palette-error-950': '#460d09',
    '--mui-palette-error-activated': 'rgba(240 68 56 / 0.12)',
    '--mui-palette-error-contrastText': '#ffffff',
    '--mui-palette-error-contrastTextChannel': '#ffffff',
    '--mui-palette-error-dark': '#de3024',
    '--mui-palette-error-darkChannel': '222 48 36',
    '--mui-palette-error-hovered': 'rgba(240 68 56 / 0.04)',
    '--mui-palette-error-light': '#f97970',
    '--mui-palette-error-lightChannel': '249 121 112',
    '--mui-palette-error-main': '#f04438',
    '--mui-palette-error-mainChannel': '240 68 56',
    '--mui-palette-error-selected': 'rgba(240 68 56 / 0.08)',
    '--mui-palette-grey-100': '#f5f5f5',
    '--mui-palette-grey-200': '#eeeeee',
    '--mui-palette-grey-300': '#e0e0e0',
    '--mui-palette-grey-400': '#bdbdbd',
    '--mui-palette-grey-50': '#fafafa',
    '--mui-palette-grey-500': '#9e9e9e',
    '--mui-palette-grey-600': '#757575',
    '--mui-palette-grey-700': '#616161',
    '--mui-palette-grey-800': '#424242',
    '--mui-palette-grey-900': '#212121',
    '--mui-palette-grey-A100': '#f5f5f5',
    '--mui-palette-grey-A200': '#eeeeee',
    '--mui-palette-grey-A400': '#bdbdbd',
    '--mui-palette-grey-A700': '#616161',
    '--mui-palette-info-100': '#cff7fe',
    '--mui-palette-info-200': '#a4eefd',
    '--mui-palette-info-300': '#66e0fa',
    '--mui-palette-info-400': '#10bee8',
    '--mui-palette-info-50': '#ecfdff',
    '--mui-palette-info-500': '#04aad6',
    '--mui-palette-info-600': '#0787b3',
    '--mui-palette-info-700': '#0d6d91',
    '--mui-palette-info-800': '#145876',
    '--mui-palette-info-900': '#154964',
    '--mui-palette-info-950': '#082f44',
    '--mui-palette-info-activated': 'rgba(4 170 214 / 0.12)',
    '--mui-palette-info-contrastText': '#ffffff',
    '--mui-palette-info-contrastTextChannel': '#ffffff',
    '--mui-palette-info-dark': '#0787b3',
    '--mui-palette-info-darkChannel': '7 135 179',
    '--mui-palette-info-hovered': 'rgba(4 170 214 / 0.04)',
    '--mui-palette-info-light': '#10bee8',
    '--mui-palette-info-lightChannel': '16 190 232',
    '--mui-palette-info-main': '#04aad6',
    '--mui-palette-info-mainChannel': '4 170 214',
    '--mui-palette-info-selected': 'rgba(4 170 214 / 0.08)',
    '--mui-palette-neutral-100': '#f1f1f4',
    '--mui-palette-neutral-200': '#dcdfe4',
    '--mui-palette-neutral-300': '#b3b9c6',
    '--mui-palette-neutral-400': '#8a94a6',
    '--mui-palette-neutral-50': '#f9fafb',
    '--mui-palette-neutral-500': '#667085',
    '--mui-palette-neutral-600': '#565e73',
    '--mui-palette-neutral-700': '#434a60',
    '--mui-palette-neutral-800': '#313749',
    '--mui-palette-neutral-900': '#212636',
    '--mui-palette-neutral-950': '#121621',
    '--mui-palette-primary-100': '#dde3ff',
    '--mui-palette-primary-200': '#c2cbff',
    '--mui-palette-primary-300': '#9ca7ff',
    '--mui-palette-primary-400': '#7578ff',
    '--mui-palette-primary-50': '#ecf0ff',
    '--mui-palette-primary-500': '#635bff',
    '--mui-palette-primary-600': '#4e36f5',
    '--mui-palette-primary-700': '#432ad8',
    '--mui-palette-primary-800': '#3725ae',
    '--mui-palette-primary-900': '#302689',
    '--mui-palette-primary-950': '#1e1650',
    '--mui-palette-primary-activated': 'rgba(99 91 255 / 0.12)',
    '--mui-palette-primary-contrastText': '#ffffff',
    '--mui-palette-primary-contrastTextChannel': '#ffffff',
    '--mui-palette-primary-dark': '#4e36f5',
    '--mui-palette-primary-darkChannel': '78 54 245',
    '--mui-palette-primary-hovered': 'rgba(99 91 255 / 0.04)',
    '--mui-palette-primary-light': '#7578ff',
    '--mui-palette-primary-lightChannel': '117 120 255',
    '--mui-palette-primary-main': '#635bff',
    '--mui-palette-primary-mainChannel': '99 91 255',
    '--mui-palette-primary-selected': 'rgba(99 91 255 / 0.08)',
    '--mui-palette-secondary-100': '#f0f4f8',
    '--mui-palette-secondary-200': '#dde7ee',
    '--mui-palette-secondary-300': '#cdd7e1',
    '--mui-palette-secondary-400': '#9fa6ad',
    '--mui-palette-secondary-50': '#fbfcfe',
    '--mui-palette-secondary-500': '#636b74',
    '--mui-palette-secondary-600': '#555e68',
    '--mui-palette-secondary-700': '#32383e',
    '--mui-palette-secondary-800': '#202427',
    '--mui-palette-secondary-900': '#121517',
    '--mui-palette-secondary-950': '#090a0b',
    '--mui-palette-secondary-activated': 'rgba(50 56 62 / 0.12)',
    '--mui-palette-secondary-contrastText': '#ffffff',
    '--mui-palette-secondary-contrastTextChannel': '#ffffff',
    '--mui-palette-secondary-dark': '#202427',
    '--mui-palette-secondary-darkChannel': '32 36 39',
    '--mui-palette-secondary-hovered': 'rgba(50 56 62 / 0.04)',
    '--mui-palette-secondary-light': '#555e68',
    '--mui-palette-secondary-lightChannel': '85 94 104',
    '--mui-palette-secondary-main': '#32383e',
    '--mui-palette-secondary-mainChannel': '50 56 62',
    '--mui-palette-secondary-selected': 'rgba(50 56 62 / 0.08)',
    '--mui-palette-shadow': 'rgba(0, 0, 0, 0.08)',
    '--mui-palette-success-100': '#ccfbef',
    '--mui-palette-success-200': '#9af5e1',
    '--mui-palette-success-300': '#5fe9ce',
    '--mui-palette-success-400': '#2ed3b8',
    '--mui-palette-success-50': '#f0fdfa',
    '--mui-palette-success-500': '#15b79f',
    '--mui-palette-success-600': '#0e9382',
    '--mui-palette-success-700': '#107569',
    '--mui-palette-success-800': '#115e56',
    '--mui-palette-success-900': '#134e48',
    '--mui-palette-success-950': '#042f2c',
    '--mui-palette-success-activated': 'rgba(21 183 159 / 0.12)',
    '--mui-palette-success-contrastText': '#ffffff',
    '--mui-palette-success-contrastTextChannel': '#ffffff',
    '--mui-palette-success-dark': '#0e9382',
    '--mui-palette-success-darkChannel': '14 147 130',
    '--mui-palette-success-hovered': 'rgba(21 183 159 / 0.04)',
    '--mui-palette-success-light': '#2ed3b8',
    '--mui-palette-success-lightChannel': '46 211 184',
    '--mui-palette-success-main': '#15b79f',
    '--mui-palette-success-mainChannel': '21 183 159',
    '--mui-palette-success-selected': 'rgba(21 183 159 / 0.08)',
    '--mui-palette-text-disabled': '#8a94a6',
    '--mui-palette-text-primary': '#212636',
    '--mui-palette-text-primaryChannel': '33 38 54',
    '--mui-palette-text-secondary': '#667085',
    '--mui-palette-text-secondaryChannel': '102 112 133',
    '--mui-palette-warning-100': '#fff3c6',
    '--mui-palette-warning-200': '#ffe587',
    '--mui-palette-warning-300': '#ffd049',
    '--mui-palette-warning-400': '#ffbb1f',
    '--mui-palette-warning-50': '#fffaea',
    '--mui-palette-warning-500': '#fb9c0c',
    '--mui-palette-warning-600': '#de7101',
    '--mui-palette-warning-700': '#b84d05',
    '--mui-palette-warning-800': '#953b0b',
    '--mui-palette-warning-900': '#7b310c',
    '--mui-palette-warning-950': '#471701',
    '--mui-palette-warning-activated': 'rgba(251 156 12 / 0.12)',
    '--mui-palette-warning-contrastText': '#ffffff',
    '--mui-palette-warning-contrastTextChannel': '#ffffff',
    '--mui-palette-warning-dark': '#de7101',
    '--mui-palette-warning-darkChannel': '222 113 1',
    '--mui-palette-warning-hovered': 'rgba(251 156 12 / 0.04)',
    '--mui-palette-warning-light': '#ffbb1f',
    '--mui-palette-warning-lightChannel': '255 187 31',
    '--mui-palette-warning-main': '#fb9c0c',
    '--mui-palette-warning-mainChannel': '251 156 12',
    '--mui-palette-warning-selected': 'rgba(251 156 12 / 0.08)',
    '--mui-shadows-0': 'none',
    '--mui-shadows-1': '0px 1px 2px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-10': '0px 4px 18px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-11': '0px 4px 20px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-12': '0px 5px 22px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-13': '0px 5px 24px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-14': '0px 5px 26px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-15': '0px 6px 28px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-16': '0px 6px 30px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-17': '0px 6px 32px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-18': '0px 7px 34px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-19': '0px 7px 36px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-2': '0px 1px 5px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-20': '0px 8px 38px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-21': '0px 8px 40px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-22': '0px 8px 42px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-23': '0px 9px 44px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-24': '0px 9px 46px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-3': '0px 1px 8px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-4': '0px 1px 10px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-5': '0px 1px 14px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-6': '0px 1px 18px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-7': '0px 2px 16px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-8': '0px 3px 14px rgba(0, 0, 0, 0.08)',
    '--mui-shadows-9': '0px 3px 16px rgba(0, 0, 0, 0.08)',
    '--mui-shape-borderRadius': '8px',
    '--mui-spacing': '8px',
    '--mui-zIndex-appBar': '1100',
    '--mui-zIndex-drawer': '1200',
    '--mui-zIndex-fab': '1050',
    '--mui-zIndex-mobileStepper': '1000',
    '--mui-zIndex-modal': '1300',
    '--mui-zIndex-snackbar': '1400',
    '--mui-zIndex-speedDial': '1050',
    '--mui-zIndex-tooltip': '1500',
    '--toast-button-margin-end': '0',
    '--toast-button-margin-start': 'auto',
    '--toast-close-button-start': '0',
    '--toast-close-button-transform': 'translate(-35%, -35%)',
    '--toast-icon-margin-end': '4px',
    '--toast-icon-margin-start': '-3px',
    '--toast-svg-margin-end': '0px',
    '--toast-svg-margin-start': '-1px',
    '--variant-containedBg': '#32383e',
    '--variant-containedColor': '#ffffff',
    '--variant-outlinedBorder': 'rgba(50 56 62 / 0.5)',
    '--variant-outlinedColor': '#32383e',
    '--variant-textColor': '#32383e',
  },
});
export const cell = {
  backgroundColor: 'var(--mui-palette-background-level1)',
  fontFamily:
    '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  fontSize: '0.875rem',
  display: 'table-cell',
  verticalAlign: 'inherit',
  textAlign: 'left',
  padding: '16px',
  color: 'var(--mui-palette-text-primary)',
  lineHeight: '1.5rem',
  fontWeight: 500,
  borderBottom: '1px solid var(--mui-palette-TableCell-border)',
  width: '250px',
  minWidth: '250px',
  maxWidth: '250px',
  '--mui-palette-text-primary': '#212636',
  '--mui-palette-background-level1': '#f9fafb',
  '--mui-palette-TableCell-border': '#dcdfe4',
};
export const cell_check = {
  fontFamily:
    '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: 1.57,
  display: 'table-cell',
  verticalAlign: 'inherit',
  textAlign: 'left',
  color: 'var(--mui-palette-text-primary)',
  width: '48px',
  borderBottom:
    'var(--TableCell-borderWidth, 1px) solid var(--mui-palette-TableCell-border)',
  padding: '0px 0px 0px 24px',
  '--mui-palette-text-primary': '#212636',
  '--mui-palette-TableCell-border': '#dcdfe4',
};
export const cell_check_icon = {
  display: 'inline-flex',
  '-moz-box-align': 'center',
  alignItems: 'center',
  '-moz-box-pack': 'center',
  justifyContent: 'center',
  position: 'relative',
  boxSizing: 'border-box',
  backgroundColor: 'transparent',
  outline: '0px',
  border: '0px',
  margin: '0px',
  cursor: 'pointer',
  userSelect: 'none',
  verticalAlign: 'middle',
  appearance: 'none',
  textDecoration: 'none',
  color: 'var(--mui-palette-text-secondary)',
  borderRadius: '8px',
  padding: '0px',
  '&.Mui-checked': {
    color: 'var(--mui-palette-primary-main)',
  },
  '& .MuiSvgIcon-root': { fontSize: 28 },
  '--mui-palette-text-secondary': '#667085',
  '--mui-palette-primary-main': '#635bff',
};
export const cell_action = {
  ...cell,
  minWidth: 50,
  maxWidth: 60,
};

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 8,
  width: 24,
  height: 24,
  boxShadow:
    'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#ebf1f5',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#d3dbe0',
    ...theme.applyStyles('dark', {
      backgroundColor: '#30404d',
    }),
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
    ...theme.applyStyles('dark', {
      background: 'rgba(57,75,89,.5)',
    }),
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
    backgroundColor: '#394b59',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
  }),
  '--mui-palette-text-secondary': '#667085',
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: 'var(--mui-palette-primary-main)',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 24,
    height: 24,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#8f89fa',
  },
  '--mui-palette-primary-main': '#635bff',
});
const BpIntermediateIcon = styled('span')({
  borderRadius: 8,
  backgroundColor: '#ebf1f5',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 26,
    height: 26,
    padding: 0,
    backgroundImage:
      'url("data:image/svg+xml;charset=utf-8,%3Csvg fill%3D%22none%22 height%3D%2226%22 viewBox%3D%220 0 22 22%22 width%3D%2226%22 ' +
      'xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath clip-rule%3D%22evenodd%22 d%3D%22M8 2C4.68629 2 2 4.68629 2 8V16C2 ' +
      '19.3137 4.68629 22 8 22H16C19.3137 22 22 19.3137 22 16V8C22 4.68629 19.3137 2 16 2H8ZM8 4C5.79086 4 4 5.79086 4 8V16C4 18.2091 5.79086 ' +
      '20 8 20H16C18.2091 20 20 18.2091 20 16V8C20 5.79086 18.2091 4 16 4H8Z%22 fill%3D%22rgb(99, 91, 255)%22 fill-rule%3D%22evenodd%22%3E%3C%2Fpath%3E%3Cpath d%3D%22M8 12H16%22' +
      ' stroke%3D%22rgb(99, 91, 255)%22 stroke-linecap%3D%22round%22 stroke-linejoin%3D%22round%22 stroke-width%3D%223%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E")',
    content: '""',
    position: 'relative',
    top: -2,
    left: -2,
    zIndex: 2000,
  },
  'input:hover ~ &': {
    backgroundColor: '#d3dbe0',
  },
});
export const CheckBoxSyan = props => {
  return (
    <Checkbox
      checkedIcon={<BpCheckedIcon />}
      disableRipple
      icon={<BpIcon />}
      indeterminateIcon={<BpIntermediateIcon />}
      {...props}
    />
  );
};

export const getIconStatus = status => {
  if (status === 'Pending') {
    return <AccessTimeFilledIcon sx={{ fill: 'orange' }} />;
  } else if (status === 'Blocked') {
    return <BlockIcon sx={{ fill: 'red' }} />;
  } else {
    return (
      <CheckCircleIcon
        sx={{
          fill: 'var(--mui-palette-success-main)',
          '--mui-palette-success-main': '#15b79f',
        }}
      />
    );
  }
};

export const StackProgress = styled(Stack)(() => ({
  display: 'flex',
  flexFlow: 'wrap',
  gap: 'calc(2 * var(--mui-spacing))',
  '-moz-box-align': 'center',
  alignItems: 'center',
  flex: '1 1 auto',
  '--mui-spacing': '8px',
}));
export const TypoProgress = styled(Typography)(() => ({
  margin: 0,
  fontFamily:
    '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: 1.57,
  color: 'var(--mui-palette-text-secondary)',
  '--mui-palette-text-secondary': '#667085',
}));
export const ChipButtom = styled(Chip)(() => ({
  maxWidth: '100%',
  fontFamily:
    '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  fontSize: '0.8125rem',
  display: 'inline-flex',
  '-moz-box-align': 'center',
  alignItems: 'center',
  '-moz-box-pack': 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  transition:
    'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)',
  cursor: 'unset',
  outline: '0px',
  textDecoration: 'none',
  padding: '0px',
  verticalAlign: 'middle',
  boxSizing: 'border-box',
  height: '24px',
  backgroundColor: 'transparent',
  borderRadius: '12px',
  fontWeight: 500,
  borderColor: 'var(--mui-palette-secondary-200)',
  color: 'var(--mui-palette-secondary-900)',
  '--mui-palette-secondary-200': '#dde7ee',
  '--mui-palette-secondary-900': '#121517',
}));

export const TextSearch = ({ text }) => {
  return (
    <>
      :{' '}
      {
        <Box
          sx={{
            cursor: 'pointer',
            fontFamily:
              '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            fontWeight: 500,
            color: 'var(--mui-palette-primary-main)',
            '--variant-textColor': 'var(--mui-palette-secondary-main)',
            '--variant-outlinedColor': 'var(--mui-palette-secondary-main)',
            '--variant-outlinedBorder':
              'rgba(var(--mui-palette-secondary-mainChannel) / 0.5)',
            '--variant-containedColor':
              'var(--mui-palette-secondary-contrastText)',
            '--variant-containedBg': 'var(--mui-palette-secondary-main)',
            textFransform: 'none',
            '--Button-outlinedBorder': 'var(--mui-palette-secondary-200)',
            '--Button-outlinedHoverBg': 'var(--mui-palette-secondary-hovered)',
            '--Button-outlinedActiveBg':
              'var(--mui-palette-secondary-activated)',
            '--Button-minHeight': '40x',
            '--mui-palette-secondary-activated': 'rgba(50 56 62 / 0.12)',
            '--mui-palette-secondary-hovered': 'rgba(50 56 62 / 0.04)',
            '--mui-palette-secondary-200': '#dde7ee',
            '--mui-palette-secondary-main': '#32383e',
            '--mui-palette-secondary-contrastText': '#ffffff',
            '--mui-palette-secondary-mainChannel': '50 56 62',
            '--mui-palette-primary-main': '#635bff',
            fontSize: '0.875rem',
            lineHeight: '28px',
          }}>
          &nbsp;&nbsp;
          {text}
        </Box>
      }
    </>
  );
};

export const style_delete_button = {
  display: 'inline-flex',
  '-moz-box-align': 'center',
  alignItems: 'center',
  '-moz-box-pack': 'center',
  justifyContent: 'center',
  position: 'relative',
  boxSizing: 'border-box',
  outline: '0px',
  margin: '0px',
  cursor: 'pointer',
  userSelect: 'none',
  verticalAlign: 'middle',
  appearance: 'none',
  textDecoration: 'none',
  fontFamily:
    '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  fontWeight: 600,
  padding: '6px 16px',
  border: '0px',
  transition:
    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
  color: 'var(--variant-containedColor)',
  '--variant-textColor': 'var(--mui-palette-error-main)',
  '--variant-outlinedColor': 'var(--mui-palette-error-main)',
  '--variant-outlinedBorder':
    'rgba(var(--mui-palette-error-mainChannel) / 0.5)',
  '--variant-containedColor': 'var(--mui-palette-error-contrastText)',
  '--variant-containedBg': 'var(--mui-palette-error-main)',
  borderRadius: '8px',
  minHeight: 'var(--Button-minHeight)',
  minWidth: 'unset',
  textTransform: 'none',
  backgroundColor: 'var(--Button-containedBg)',
  backgroundImage: 'var(--Button-containedBgGradient)',
  boxShadow: 'var(--mui-shadows-1),var(--Button-containedStroke)',
  overflow: 'hidden',
  '--Button-containedBg': 'var(--mui-palette-error-dark)',
  '--Button-containedBgGradient':
    'linear-gradient(180deg, var(--mui-palette-error-main) 0%, var(--mui-palette-error-dark) 100%)',
  '--Button-containedStroke':
    'inset 0px 0px 0px 1px var(--mui-palette-error-dark),inset 0px 2px 0px 0px rgba(255, 255, 255, 0.16)',
  '--Button-minHeight': '40px',
  fontSize: '0.875rem',
  lineHeight: '28px',
  '--mui-palette-error-contrastText': '#ffffff',
  '--mui-palette-error-main': '#f04438',
  '--mui-palette-error-dark': '#de3024',
};

export const style_button_clear = {
  display: 'inline-flex',
  '-moz-box-align': 'center',
  alignItems: 'center',
  '-moz-box-pack': 'center',
  justifyContent: 'center',
  position: 'relative',
  boxSizing: 'border-box',
  outline: '0px',
  margin: '0px',
  cursor: 'pointer',
  userSelect: 'none',
  verticalAlign: 'middle',
  appearance: 'none',
  textDecoration: 'none',
  fontFamily:
    '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  fontWeight: 500,
  border: '0px',
  transition:
    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
  padding: '6px 8px',
  color: 'var(--variant-textColor)',
  backgroundColor: 'var(--variant-textBg)',
  '--variant-textColor': 'var(--mui-palette-primary-main)',
  '--variant-outlinedColor': 'var(--mui-palette-primary-main)',
  '--variant-outlinedBorder':
    'rgba(var(--mui-palette-primary-mainChannel) / 0.5)',
  '--variant-containedColor': 'var(--mui-palette-primary-contrastText)',
  '--variant-containedBg': 'var(--mui-palette-primary-main)',
  borderRadius: '8px',
  minHeight: 'var(--Button-minHeight)',
  minWidth: 'unset',
  textTransform: 'none',
  '--Button-textHoverBg': 'var(--mui-palette-primary-hovered)',
  '--Button-textActiveBg': 'var(--mui-palette-primary-activated)',
  '--Button-minHeight': '40x',
  fontSize: '0.875rem',
  lineHeight: '28px',
  '--mui-palette-primary-main': '#635bff',
  '--mui-palette-primary-activated': 'rgba(99 91 255 / 0.12)',
  '--mui-palette-primary-contrastText': '#ffffff',
  '--mui-palette-primary-hovered': 'rgba(99 91 255 / 0.04)',
};
export const style_menu_list = {
  backgroundColor: 'transparent',
  outline: '0px',
  border: '0px',
  margin: '0px',
  marginBottom: '5px',
  cursor: 'pointer',
  userSelect: 'none',
  verticalAlign: 'middle',
  appearance: 'none',
  color: 'inherit',
  fontFamily:
    '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: 1.5,
  display: 'flex',
  '-moz-box-pack': 'start',
  justifyContent: 'flex-start',
  '-moz-box-align': 'center',
  alignItems: 'center',
  position: 'relative',
  textDecoration: 'none',
  minHeight: '48px',
  padding: '6px 16px',
  boxSizing: 'border-box',
  whiteSpace: 'nowrap',
  borderRadius: '6px',
  gap: 'var(--ListItem-gap)',
  paddingBlock: '4px',
  paddingInline: '8px',
  '--ListItem-gap': '16px',
  '&.MuiMenuItem-root': {
    '&.Mui-selected': {
      backgroundColor:
        'rgba(var(--mui-palette-primary-mainChannel) / var(--mui-palette-action-selectedOpacity))',
      '--mui-palette-action-selectedOpacity': '0.08',
      '--mui-palette-primary-mainChannel': '99 91 255',
    },
    '&.Mui-selected:hover': {
      backgroundColor:
        'rgba(var(--mui-palette-primary-mainChannel) / var(--mui-palette-action-selectedOpacity))',
      '--mui-palette-action-selectedOpacity': '0.3',
      '--mui-palette-primary-mainChannel': '99 91 255',
    },
  },
  '&.MuiMenuItem-root:hover': {
    backgroundColor:
      'rgba(var(--mui-palette-primary-mainChannel) / var(--mui-palette-action-selectedOpacity))',
    '--mui-palette-action-selectedOpacity': '0.05',
    '--mui-palette-primary-mainChannel': '99 91 255',
  },
};

export const stableSort = (array, order) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    // Parse the date strings
    const date1 = moment(a[0].created, 'MMM DD, YYYY h:mm A');
    const date2 = moment(b[0].created, 'MMM DD, YYYY h:mm A');
    if (order === 'Newest') return date1.isBefore(date2);
    return date1.isAfter(date2);
  });
  return stabilizedThis.map(el => el[0]);
};
export const picker_style = {
  display: 'inline-flex',
  flexDirection: 'column',
  position: 'relative',
  minWidth: '0px',
  padding: '0px',
  margin: '0px',
  border: '0px',
  verticalAlign: 'top',
  width: '100%',
  '&.MuiInputBase-root': {
    fontFamily:
      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.4375em',
    color: 'var(--palette-text-primary)',
    boxSizing: 'border-box',
    cursor: 'text',
    display: 'inline-flex',
    '-moz-box-align': 'center',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    borderRadius: 'var(--shape-borderRadius)',
    paddingRight: '14px',
    '--palette-text-primary': '8px',
  },
};
