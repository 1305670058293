import React from 'react';
import PropTypes from 'prop-types';
import {
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Chart from 'react-apexcharts';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CircleIcon from '@mui/icons-material/Circle';
class Statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartOptions: {
        chart: {
          type: 'bar',
          height: '100%', // Scales chart height to container
          toolbar: {
            show: false, // Hides the toolbar
          },
        },
        series: [
          {
            name: 'Inflation',
            data: [3, 2, 1, 5, 4.0, 3, 3.8, 2],
          },
        ],
        xaxis: {
          show: false,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function() {
              return '';
            },
          },
        },
        yaxis: {
          min: -0.1, // Define the minimum value
          max: 5, // Define the maximum value
          labels: {
            show: false, // Hides the Y-axis values
          },
          axisBorder: {
            show: false, // Hides the Y-axis border line
          },
          axisTicks: {
            show: false, // Hides the ticks on Y-axis
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false, // Hides the grid lines for Y-axis
            },
          },
        },
        dataLabels: {
          enabled: false, // Removes values inside the bars
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 3,
            borderRadiusApplication: 'around',
            borderRadiusWhenStacked: 'last',
            columnWidth: '45%',
            barHeight: '80%',
            distributed: false,
            rangeBarOverlap: true,
            rangeBarGroupRows: false,
            hideZeroBarsWhenGrouped: false,
            isDumbbell: false,
            dumbbellColors: undefined,
            isFunnel: false,
            isFunnel3d: true,
            dataLabels: {
              position: 'top',
              maxItems: 100,
              hideOverflowingLabels: true,
              orientation: 'horizontal',
              enabled: true, // Enable data labels
              style: {
                colors: ['#000000'], // Default label color (set to black initially)
              },
              background: {
                enabled: false, // Disable background
              },
              total: {
                enabled: false,
                formatter: undefined,
                offsetX: 0,
                offsetY: 0,
                style: {
                  color: '#d500f9',
                  fontSize: '12px',
                  fontFamily: undefined,
                  fontWeight: 600,
                },
              },
            },
          },
        },
        fill: {
          type: 'gradient', // Enables gradient fill
          gradient: {
            type: 'horizontal', // Gradient direction (can be "vertical" or "horizontal")
            gradientToColors: ['#d500f9'], // End color
            shadeIntensity: 1, // Intensity of the gradient
            opacityFrom: 1, // Starting opacity
            opacityTo: 1, // Ending opacity
            stops: [0, 100], // Define gradient stops
            colorStops: [
              {
                offset: 0,
                color: '#6a1b9a', // Start color
                opacity: 1,
              },
              {
                offset: 100,
                color: '#d500f9', // End color
                opacity: 1,
              },
            ],
          },
        },
        stroke: {
          width: 0, // Removes any stroke around the bars for a cleaner look
        },
      },
      data: [
        {
          color: '#56CA00',
          name: 'Direct',
          amount: '86,471',
          percentage: '15%',
          up: false,
        },
        {
          color: '#7e57c2',
          name: 'Organic',
          amount: '57,484',
          percentage: '85%',
          up: true,
        },
        {
          color: 'orange',
          name: 'Referral',
          amount: '2,534',
          percentage: '48%',
          up: true,
        },
        {
          color: 'red',
          name: 'Mail',
          amount: '977',
          percentage: '36%',
          up: false,
        },
        {
          color: '#2196f3',
          name: 'Social',
          amount: '92',
          percentage: '55%',
          up: true,
        },
        {
          color: '#9e9e9e',
          name: 'Other',
          amount: '92',
          percentage: '12%',
          up: true,
        },
      ],
    };
  }

  render() {
    const { chartOptions, data } = this.state;
    const { fullScreen, height, width } = this.props;
    let graph_sx = null;
    if (fullScreen) graph_sx = { paddingTop: -20, marginBottom: 15 };
    return (
      <Paper sx={{ margin: 1 }}>
        <CardHeader
          action={
            <IconButton aria-label="settings" size="large">
              <MoreVertIcon />
            </IconButton>
          }
          title={
            <Typography component="span" sx={{ flex: '1 1 auto' }} variant="h5">
              Website Statistics
            </Typography>
          }
        />
        <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <Grid
            container
            direction="row"
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Grid container item xs={12}>
              <Grid
                item
                sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
                xs={6}>
                <Typography
                  sx={{
                    margin: 0,
                    fontFamily: '"__Inter_5475bd","__Inter_Fallback_5475bd"',
                    fontWeight: 500,
                    fontSize: '2.875rem',
                    lineHeight: 1.478261,
                    color: 'var(--mui-palette-text-primary)',
                    '--mui-palette-text-primary':
                      'rgb(var(--mui-mainColorChannels-light) / 0.9)',
                    '--mui-mainColorChannels-light': '46 38 61',
                  }}
                  variant="h1">
                  4,590
                </Typography>
                <Typography
                  component="p"
                  sx={{
                    margin: 0,
                    fontFamily: '"__Inter_5475bd","__Inter_Fallback_5475bd"',
                    fontWeight: 400,
                    fontSize: '0.8125rem',
                    lineHeight: 1.478261,
                    color: 'var(--mui-palette-text-primary)',
                    '--mui-palette-text-primary':
                      'rgb(var(--mui-mainColorChannels-light) / 0.9)',
                    '--mui-mainColorChannels-light': '46 38 61',
                  }}
                  variant="body2">
                  Total Traffic
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 110, // Adjust container height as needed
                    width: '100%', // Full width
                    maxWidth: '500px', // Set max width for the container
                    margin: '0 auto',
                    ...graph_sx,
                  }}>
                  <Chart
                    height={fullScreen ? 180 : 110}
                    options={chartOptions}
                    series={chartOptions.series}
                    type="bar"
                    width={fullScreen ? width / 3 : 150}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                height: fullScreen ? height - 510 : 110,
                overflowX: 'auto',
              }}
              xs={12}>
              <Table
                sx={{
                  inlineSize: '100%',
                  borderCollapse: 'collapse',
                  fontSize: '.875rem',
                  whiteSpace: 'nowrap',
                }}>
                <TableBody>
                  {data.map((row, index) => {
                    const { color, name, amount, percentage, up } = row;
                    let IconUp = KeyboardArrowDownIcon;
                    if (up) IconUp = KeyboardArrowUpIcon;
                    const cell = { paddingTop: 1.2, paddingBottom: 0.9 };
                    return (
                      <TableRow key={index}>
                        <TableCell
                          align="left"
                          sx={{ paddingLeft: 0, ...cell }}>
                          <Grid
                            container
                            direction="row"
                            sx={{
                              padding: 0,
                              margin: 0,
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                            }}>
                            <Grid item>
                              <CircleIcon
                                sx={{ color, width: 15, height: 15 }}
                              />
                            </Grid>
                            <Grid item>
                              <Typography
                                component="p"
                                sx={{
                                  marginLeft: 1,
                                  // margin: 0,
                                  fontFamily:
                                    '"__Inter_5475bd","__Inter_Fallback_5475bd"',
                                  fontWeight: 400,
                                  fontSize: '0.9375rem',
                                  // lineHeight: 1.46667,
                                  // color: 'var(--mui-palette-text-primary)',
                                }}
                                variant="body1">
                                {name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell align="right" sx={cell}>
                          {amount}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ paddingRight: 0, ...cell }}>
                          <Grid
                            container
                            direction="row"
                            sx={{
                              padding: 0,
                              margin: 0,
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                            }}>
                            <Typography component="p" variant="body1">
                              {percentage}
                            </Typography>
                            <IconUp sx={{ color: up ? '#56CA00' : 'red' }} />
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Paper>
    );
  }
}

Statistics.propTypes = {
  classes: PropTypes.object,
};

export default Statistics;
