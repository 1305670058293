import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadSingleFile } from 'common';

const QUERY_CLINCAL_PATIENTS = gql`
  query(
    $query_inputs: PatientClinicQuery
    $page: Int
    $limit: Int
    $anonymize: Int
  ) {
    patientsClinics(
      query_inputs: $query_inputs
      page: $page
      limit: $limit
      anonymize: $anonymize
    ) {
      ok
      errors {
        path
        message
      }
      total
      patients {
        id
        idUser
        idAddress

        user {
          FullName
        }

        FamilyName
        GivenName
        MiddleName
        NamePrefix
        NameSuffix

        PatientName

        PatientID

        PatientBirthDate
        PatientSex

        PatientAge
        PatientSize
        PatientWeight
        PatientWaist
        PatientHip
        bmi
        PatientEthnicity
        patientSAPStatus
        patientLastActivity
        patientRegistrationDate
        patientVitalStatus
        patientDeathDate
        patientLastVitalityCheck

        UniqueKeyIdentifier

        tot_sleep_files
        tot_epilepsy_files
        tot_stroke_files
        tot_ms_files

        tot_sleep_series
        tot_epilepsy_series
        tot_stroke_series
        tot_ms_series

        tot_sleep_edfs
        tot_epilepsy_edfs
        tot_stroke_edfs
        tot_ms_edfs

        isStroke
        isEpilepsy
        isSleep
        isMS

        address {
          country
          city
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryClinicalPatients = (query_inputs, page, limit, anonymize) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_CLINCAL_PATIENTS),
    variables: {
      query_inputs,
      page,
      limit,
      anonymize,
    },
  });
};

const MUTATION_ADD_CLINCAL_PATIENTS = gql`
  mutation($inputs: [InputPatientClinic!]!, $type: String!) {
    addPatientsClinics(inputs: $inputs, type: $type) {
      ok
      errors {
        path
        message
      }
      total
      patients {
        id
        idUser
        idAddress

        user {
          FullName
        }

        FamilyName
        GivenName
        MiddleName
        NamePrefix
        NameSuffix

        PatientName

        PatientID

        PatientBirthDate
        PatientSex
        PatientEthnicity
        patientSAPStatus
        patientLastActivity
        patientRegistrationDate

        PatientAge
        PatientSize
        PatientWeight
        PatientWaist
        PatientHip
        patientVitalStatus
        patientDeathDate
        patientLastVitalityCheck

        UniqueKeyIdentifier
        tot_sleep_files
        tot_epilepsy_files
        tot_stroke_files
        tot_ms_files

        tot_sleep_series
        tot_epilepsy_series
        tot_stroke_series
        tot_ms_series

        tot_sleep_edfs
        tot_epilepsy_edfs
        tot_stroke_edfs
        tot_ms_edfs
        address {
          country
          city
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationAddClinicalPatients = (inputs, type) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_CLINCAL_PATIENTS),
    variables: {
      inputs,
      type,
    },
  });
};
const MUTATION_REMOVE_CLINCAL_PATIENTS = gql`
  mutation($ids: [LongLong!]!, $type: String!) {
    deletePatientsClinics(ids: $ids, type: $type) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveClinicalPatients = (ids, type) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_CLINCAL_PATIENTS),
    variables: {
      ids,
      type,
    },
  });
};

const MUTATION_UPLOAD_CLINICAL_PATIENTS = gql`
  mutation($file: Upload!, $type: String!) {
    uploadPatientsClinics(file: $file, type: $type) {
      ok
      errors {
        path
        message
      }
      total
      patients {
        id
        idUser
        idAddress

        user {
          FullName
        }

        FamilyName
        GivenName
        MiddleName
        NamePrefix
        NameSuffix

        PatientName

        PatientID

        PatientBirthDate
        PatientSex
        PatientEthnicity
        patientSAPStatus
        patientLastActivity
        patientRegistrationDate

        PatientAge
        PatientSize
        PatientWeight
        PatientWaist
        PatientHip
        patientVitalStatus
        patientDeathDate
        patientLastVitalityCheck

        UniqueKeyIdentifier
        tot_sleep_files
        tot_epilepsy_files
        tot_stroke_files
        tot_ms_files

        tot_sleep_series
        tot_epilepsy_series
        tot_stroke_series
        tot_ms_series

        tot_sleep_edfs
        tot_epilepsy_edfs
        tot_stroke_edfs
        tot_ms_edfs
        address {
          country
          city
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationUploadClinicalPatients = (
  file,
  type,
  handleProgress = null
) => {
  return MutationUploadSingleFile(
    file,
    MUTATION_UPLOAD_CLINICAL_PATIENTS,
    handleProgress,
    type
  );
};

const SEARCH_SSR_PATIENTS = gql`
  query($phrase: String, $page: Int, $limit: Int) {
    searchPatientClinics(phrase: $phrase, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      patients {
        id
        idUser
        idAddress

        user {
          FullName
        }

        FamilyName
        GivenName
        MiddleName
        NamePrefix
        NameSuffix

        PatientName

        PatientID

        PatientBirthDate
        PatientSex
        PatientEthnicity
        patientSAPStatus
        patientLastActivity
        patientRegistrationDate

        PatientAge
        PatientSize
        PatientWeight
        PatientWaist
        PatientHip
        patientVitalStatus
        patientDeathDate
        patientLastVitalityCheck

        UniqueKeyIdentifier

        address {
          country
          city
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const QuerySearchClinicalPatients = (phrase, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(SEARCH_SSR_PATIENTS),
    variables: {
      phrase,
      page,
      limit,
    },
  });
};

const UPDATE_PATIENT_VITALITY = gql`
  mutation($page: Int, $limit: Int) {
    updatePatientVitality(page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      updatedPatients
    }
  }
`;

export const MutationPatientVitalityUpdate = (page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(UPDATE_PATIENT_VITALITY),
    variables: {
      page,
      limit,
    },
  });
}