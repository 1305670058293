/* eslint-disable quotes */
import { HTTP_ADDRESS_GRAPHQL } from './config_server';

export const GetSecurityPolicy = () => {
  let default_src = [];
  default_src.push('*.google-analytics.com');
  default_src.push('restcountries.com');
  default_src.push('update.medical-blocks.ch');
  default_src.push('*.medical-blocks.ch');
  default_src.push('*.medical-blocks.com');
  default_src.push('*.artorg.unibe.ch');
  default_src.push('https://maps.googleapis.com');
  default_src.push('*.analytics.google.com');
  default_src.push('*.google.ch');
  default_src.push('https://stats.g.doubleclick.net');

  let script_src = [];
  script_src.push('*.google-analytics.com');
  script_src.push('https://www.googletagmanager.com');
  script_src.push('https://ajax.googleapis.com');
  script_src.push('https://ssl.google-analytics.com');
  script_src.push('https://assets.zendesk.com');
  script_src.push('https://connect.facebook.net');
  script_src.push('https://update.medical-blocks.ch');
  script_src.push('https://maps.googleapis.com');
  script_src.push('https://maps.gstatic.com');
  script_src.push('*.analytics.google.com');
  script_src.push('*.googleapis.com');

  let img_src = [];
  img_src.push('*.google-analytics.com');
  img_src.push('https://ssl.google-analytics.com');
  img_src.push('https://s-static.ak.facebook.com');
  img_src.push('https://assets.zendesk.com');
  img_src.push('https://update.medical-blocks.ch');
  img_src.push('*.googletagmanager.com');
  img_src.push('https://upload.wikimedia.org');
  img_src.push('https://maps.gstatic.com');
  img_src.push('https://maps.googleapis.com');
  img_src.push('*.analytics.google.com');
  img_src.push('*.google.ch');
  img_src.push('https://stats.g.doubleclick.net');

  let style_src = [];
  style_src.push('https://assets.zendesk.com');
  style_src.push('https://update.medical-blocks.ch');
  style_src.push('*.googleapis.com');

  let font_src = [];
  font_src.push('https://fonts.gstatic.com');
  font_src.push('https://themes.googleusercontent.com');
  font_src.push('https://update.medical-blocks.ch');

  let frame_src = [];
  frame_src.push('https://player.vimeo.com');
  frame_src.push('https://assets.zendesk.com');
  frame_src.push('https://www.facebook.com');
  frame_src.push('https://s-static.ak.facebook.com');
  frame_src.push('https://tautt.zendesk.com');
  frame_src.push('https://update.medical-blocks.ch');
  frame_src.push('https://www.medical-blocks.ch');
  frame_src.push('https://mb-neuro.medical-blocks.ch');
  frame_src.push('*.medical-blocks.ch');
  console.log(`Meta - ${HTTP_ADDRESS_GRAPHQL}`);
  if (
    HTTP_ADDRESS_GRAPHQL.indexOf('insel.ch') !== -1 ||
    HTTP_ADDRESS_GRAPHQL.indexOf('161.62.108.107') !== -1||
    HTTP_ADDRESS_GRAPHQL.indexOf('161.62.108.115') !== -1
  ) {
    default_src.push('*.insel.ch');
    script_src.push('*.insel.ch');
    img_src.push('*.insel.ch');
    style_src.push('*.insel.ch');
    font_src.push('*.insel.ch');
    frame_src.push('*.insel.ch');
    if(HTTP_ADDRESS_GRAPHQL.indexOf('mb-neuro-02.') !== -1){
      default_src.push('161.62.108.115');
      default_src.push('ws://161.62.108.115/graphql');
      default_src.push('http://161.62.108.115/graphql');
      default_src.push('mb-api-02.insel.ch');
      default_src.push('ws://mb-api-02.insel.ch/graphql');
      default_src.push('http://mb-api-02.insel.ch/graphql');
    } else {
      default_src.push('161.62.108.107');
      default_src.push('ws://161.62.108.107/graphql');
      default_src.push('http://161.62.108.107/graphql');
      default_src.push('mb-api.insel.ch');
      default_src.push('ws://mb-api.insel.ch/graphql');
      default_src.push('http://mb-api.insel.ch/graphql');
    }
    console.log(' - mb-neuro (INSEL) - ');
  } else if (HTTP_ADDRESS_GRAPHQL.startsWith('https://api.medical-blocks')) {
    default_src.push('api.medical-blocks.ch');
    default_src.push('wss://api.medical-blocks.ch/graphql');
    default_src.push('https://api.medical-blocks.ch/graphql');

    script_src.push('https://www.medical-blocks.ch');
    script_src.push('https://www.medical-blocks.com');
    script_src.push('https://medical-blocks.ch');
    script_src.push('https://medical-blocks.com');
    script_src.push('https://api.medical-blocks.ch');
    script_src.push('https://api.medical-blocks.ch/graphql');

    img_src.push('*.artorg.unibe.ch');
    img_src.push('*.medical-blocks.ch');
    img_src.push('*.medical-blocks.com');
    img_src.push('https://www.medical-blocks.ch');
    img_src.push('https://www.medical-blocks.com');
    img_src.push('https://medical-blocks.ch');
    img_src.push('https://medical-blocks.com');
    img_src.push('https://api.medical-blocks.ch');

    style_src.push('https://www.medical-blocks.ch');
    style_src.push('https://www.medical-blocks.com');
    style_src.push('https://medical-blocks.ch');
    style_src.push('https://medical-blocks.com');
    style_src.push('https://api.medical-blocks.ch');
    style_src.push('https://api.medical-blocks.ch');
    style_src.push('https://api.medical-blocks.ch');

    font_src.push('*.medical-blocks.ch');
    font_src.push('*.medical-blocks.com');
    font_src.push('https://medical-blocks.ch');
    font_src.push('https://medical-blocks.com');
    font_src.push('https://api.medical-blocks.ch');

    frame_src.push('*.medical-blocks.ch');
    frame_src.push('*.medical-blocks.com');
    frame_src.push('https://medical-blocks.ch');
    frame_src.push('https://medical-blocks.com');
    frame_src.push('https://api.medical-blocks.ch');

    console.log(' - production (Medical-Blocks) - ');
  } else {
    default_src.push('mb-api.artorg.unibe.ch');
    default_src.push('https://mb-api.artorg.unibe.ch/graphql');
    default_src.push('https://mb-api.artorg.unibe.ch/download');
    default_src.push('wss://mb-api.artorg.unibe.ch/graphql');

    script_src.push('https://mb-neuro.artorg.unibe.ch/graphql');
    script_src.push('https://mb-neuro.artorg.unibe.ch');
    script_src.push('https://mb-api.artorg.unibe.ch');

    img_src.push('*.artorg.unibe.ch');
    img_src.push('https://mb-neuro.artorg.unibe.ch');
    img_src.push('https://mb-api.artorg.unibe.ch ');

    style_src.push('https://mb-neuro.artorg.unibe.ch');
    style_src.push('https://mb-api.artorg.unibe.ch');

    font_src.push('https://mb-neuro.artorg.unibe.ch');
    font_src.push('https://mb-api.artorg.unibe.ch');

    frame_src.push('https://mb-neuro.artorg.unibe.ch');
    frame_src.push('https://mb-api.artorg.unibe.ch');

    console.log(' - mb-neuro (ARTORG) - ');
  }
  let api_blocks = "default-src 'self' ";
  api_blocks += default_src.join(' ') + ';';
  api_blocks += " script-src 'self' 'unsafe-inline' 'unsafe-eval' ";
  api_blocks += script_src.join(' ') + ';';
  api_blocks += " img-src 'self' ";
  api_blocks += img_src.join(' ') + ' blob: data:;';
  api_blocks += "  style-src 'self' 'unsafe-inline' ";
  api_blocks += style_src.join(' ') + ';';
  api_blocks += " font-src 'self' ";
  api_blocks += font_src.join(' ') + ' data:; ';
  api_blocks += " frame-src 'self' ";
  api_blocks += frame_src.join(' ') + "  blob: ; object-src 'none'";
  return api_blocks;
};

export const IsLocalProduction = () => {
  if (!HTTP_ADDRESS_GRAPHQL.startsWith('http://localhost')) {
    console.log(' - production - ');
    return true;
  }
  console.log(' - development - ');
  return false;
};
