import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_AIMCARD_CASES = gql`
  query($name: String, $query_input: String, $page: Int, $limit: Int) {
    aimCardCases(name: $name, query_input: $query_input, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      cases {
        id
        idPatient
        case_id
        case_start_date
        case_types
        case_service_area
        case_start_type
        case_creation_reason
        case_illness_grade
        is_emergency_case

        createdAt
        updatedAt

        patient {
          id
          PatientID
        }
      }
    }
  }
`;

export const QueryAimCardCases = (name, query_input, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_AIMCARD_CASES),
    variables: {
      name,
      query_input,
      page,
      limit,
    },
  });
};

const MUTATION_UPDATE_AIMCARD_CASES = gql`
  mutation {
    updateAimCardCase {
      ok
      errors {
        path
        message
      }
      updatedCases
    }
  }
`;
export const MutationUpdateAimCardCase = () => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_AIMCARD_CASES),
    variables: {},
  });
};

const QUERY_AIMCARD_PATIENTS = gql`
  query($page: Int, $limit: Int, $anonymize: Int) {
    aimCardPatients(page: $page, limit: $limit, anonymize: $anonymize) {
      ok
      errors {
        path
        message
      }
      total
      patients {
        id
        idUser
        idAddress

        user {
          FullName
        }

        FamilyName
        GivenName
        MiddleName
        NamePrefix
        NameSuffix

        PatientName

        PatientID

        PatientBirthDate
        PatientSex

        PatientAge
        PatientSize
        PatientWeight
        PatientWaist
        PatientHip
        bmi
        PatientEthnicity
        patientSAPStatus
        patientLastActivity
        patientRegistrationDate
        patientVitalStatus
        patientDeathDate
        patientLastVitalityCheck

        UniqueKeyIdentifier
        tot_sleep_files
        tot_epilepsy_files
        tot_stroke_files
        tot_ms_files

        tot_sleep_series
        tot_epilepsy_series
        tot_stroke_series
        tot_ms_series

        tot_sleep_edfs
        tot_epilepsy_edfs
        tot_stroke_edfs
        tot_ms_edfs

        isStroke
        isEpilepsy
        isSleep
        isMS

        address {
          country
          city
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryAimCardPatients = (page, limit, anonymize) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_AIMCARD_PATIENTS),
    variables: {
      page,
      limit,
      anonymize,
    },
  });
};