import { createBlock } from './utils_create';
import { getBlockAtIndex, getBlockItems, getBlocksNames } from './utils_svg';
const getTranformMouse = (
  e,
  canvas,
  panOffset,
  zoomLevel,
  devicePixelRatio
) => {
  const rect = canvas.getBoundingClientRect();
  const scaleX = canvas.width / devicePixelRatio / rect.width;
  const scaleY = canvas.height / devicePixelRatio / rect.height;
  const x = Math.round((e.clientX - rect.left) * scaleX);
  const y = Math.round((e.clientY - rect.top) * scaleY);
  const canvasX = Math.round((x - panOffset.x) / zoomLevel);
  const canvasY = Math.round((y - panOffset.y) / zoomLevel);
  return { canvasX, canvasY };
};
export const drawBackgroundWithDots = (canvas, ctx, self) => {
  const { devicePixelRatio } = self.state;
  const { panOffset } = self.state.diagram;
  // Clear the canvas
  ctx.clearRect(
    0,
    0,
    canvas.width / devicePixelRatio,
    canvas.height / devicePixelRatio
  );

  // Save the current context state
  ctx.save();
  ctx.fillStyle = 'white';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  const offsetX = panOffset.x;
  const offsetY = panOffset.y;
  // Draw the larger dots
  ctx.fillStyle = '#333';
  for (let x = offsetX % 40; x < canvas.width; x += 40) {
    for (let y = offsetY % 40; y < canvas.height; y += 40) {
      ctx.beginPath();
      ctx.arc(x, y, 1, 0, 2 * Math.PI); // Radius set to 1
      ctx.fill();
    }
  }

  // Draw additional small dots between the larger dots
  ctx.fillStyle = '#999'; // Darker gray for better visibility
  for (let x = (offsetX + 20) % 40; x < canvas.width; x += 40) {
    for (let y = offsetY % 40; y < canvas.height; y += 40) {
      ctx.beginPath();
      ctx.arc(x, y, 0.6, 0, 2 * Math.PI); // Radius changed to 0.6
      ctx.fill();
    }
  }

  // Draw additional small dots in between
  for (let x = offsetX % 20; x < canvas.width; x += 20) {
    for (let y = (offsetY + 20) % 40; y < canvas.height; y += 40) {
      ctx.beginPath();
      ctx.arc(x, y, 0.6, 0, 2 * Math.PI); // Radius changed to 0.6
      ctx.fill();
    }
  }
};
export const isMouseOverBlock = (x, y, self) => {
  const { blocks } = self.state;
  for (let i = 0; i < blocks.length; i++) {
    if (blocks[i].isPointInside(x, y)) return true;
  }
  return false;
};

export const initCanvasListener = (
  canvas,
  searchBox,
  searchInput,
  suggestions,
  self
) => {
  let { devicePixelRatio } = self.state;
  devicePixelRatio = 1;
  function centerContent() {
    const { blocks } = self.state;
    const { zoomLevel } = self.state.diagram;
    if (!blocks.length) return;
    // Calculate the bounding box of all elements
    let boundingBox = {
      left: blocks[0].x,
      top: blocks[0].y,
      right: blocks[0].x + blocks[0].width,
      bottom: blocks[0].y + blocks[0].height,
    };
    for (let i = 0; i < blocks.length; i++) {
      boundingBox = {
        left: Math.min(boundingBox.left, blocks[i].x),
        top: Math.min(boundingBox.top, blocks[i].y),
        right: Math.max(boundingBox.right, blocks[i].x + blocks[i].width),
        bottom: Math.max(boundingBox.bottom, blocks[i].y + blocks[i].height),
      };
    }

    const contentWidth = boundingBox.right - boundingBox.left;
    const contentHeight = boundingBox.bottom - boundingBox.top;

    // Calculate the scale to fit the content
    const scaleX = canvas.width / devicePixelRatio / (contentWidth * 1.1); // Add 10% padding
    const scaleY = canvas.height / devicePixelRatio / (contentHeight * 1.1);
    self.state.diagram.zoomLevel = Math.min(scaleX, scaleY, 1); // Ensure we don't zoom in past 100%

    // Calculate the pan offset to center the content
    self.state.diagram.panOffset.x =
      (canvas.width / devicePixelRatio - contentWidth * zoomLevel) / 2 -
      boundingBox.left * zoomLevel;
    self.state.diagram.panOffset.y =
      (canvas.height / devicePixelRatio - contentHeight * zoomLevel) / 2 -
      boundingBox.top * zoomLevel;
    console.log('centerContent');
  }
  self.holder.centerContent = () => centerContent();
  // Sample data for search
  const searchData = getBlocksNames();

  let currentIndex = -1;
  // Function to hide the search box and suggestions
  function hideSearch(full = false) {
    const { blocks } = self.state;
    if (full) {
      const { panOffset, zoomLevel } = self.state.diagram;
      const title = searchInput.value.trim();
      const { canvasX, canvasY } = getTranformMouse(
        {
          clientX: parseInt(searchBox.style.left.replace('px', '')),
          clientY: parseInt(searchBox.style.top.replace('px', '')),
        },
        canvas,
        panOffset,
        zoomLevel,
        devicePixelRatio
      );

      const newBlock = createBlock(canvasX, canvasY, title, self);
      if (newBlock) {
        blocks.push(newBlock);
        self.draw(true);
      }
      searchBox.style.display = 'none';
      searchInput.value = '';
    }
    suggestions.style.display = 'none';
  }
  // Filter search results as you type
  searchInput.addEventListener('input', function() {
    const inputText = searchInput.value.toLowerCase();
    suggestions.innerHTML = ''; // Clear previous suggestions
    currentIndex = -1; // Reset the index
    if (inputText) {
      const filteredResults = searchData.filter(item =>
        item.toLowerCase().includes(inputText)
      );
      if (filteredResults.length > 0) {
        suggestions.style.display = 'block';
        filteredResults.forEach(item => {
          const li = document.createElement('li');
          li.textContent = item;
          li.addEventListener('click', function() {
            searchInput.value = item;
            printSelection(item);
            hideSearch(); // Hide both search box and suggestions
          });
          suggestions.appendChild(li);
        });
      } else {
        suggestions.style.display = 'none';
      }
    } else {
      suggestions.style.display = 'none';
    }
  });

  // Handle arrow keys (up and down) and Enter key
  searchInput.addEventListener('keydown', function(e) {
    const items = suggestions.getElementsByTagName('li');
    if (e.key === 'ArrowDown') {
      if (searchInput.value === '' && !items.length) {
        suggestions.style.display = 'block';
        currentIndex = -1; // Reset the index
        searchData.forEach(item => {
          const li = document.createElement('li');
          li.textContent = item;
          li.addEventListener('click', function() {
            searchInput.value = item;
            printSelection(item);
            hideSearch(); // Hide both search box and suggestions
          });
          suggestions.appendChild(li);
        });
      }
      currentIndex++;
      if (currentIndex >= items.length) {
        currentIndex = 0; // Loop back to the first item
      }
      highlightItem(items);
    } else if (e.key === 'ArrowUp') {
      currentIndex--;
      if (currentIndex < 0) {
        currentIndex = items.length - 1; // Loop back to the last item
      }
      highlightItem(items);
    } else if (e.key === 'Enter') {
      // If Enter is pressed, select the highlighted item
      if (currentIndex > -1 && items[currentIndex]) {
        searchInput.value = items[currentIndex].textContent;
        currentIndex = -1;
        hideSearch(); // Hide both search box and suggestions
      } else {
        printSelection(searchInput.value);
        hideSearch(true); // Hide if no item is highlighted
      }
    }
  });

  // Function to highlight the current suggestion item
  function highlightItem(items) {
    // Remove 'active' class from all items
    for (let i = 0; i < items.length; i++) {
      items[i].classList.remove('active');
    }

    // Add 'active' class to the current item
    if (items[currentIndex]) {
      items[currentIndex].classList.add('active');
      // Ensure the current item is visible within the suggestion box
      items[currentIndex].scrollIntoView({ block: 'nearest' });
    }
  }

  // Function to print the selected item
  function printSelection(selectedItem) {
    console.log('Selected item: ' + selectedItem);
  }

  // Hide the search box when clicking outside
  document.addEventListener('click', function(event) {
    const canvas = document.getElementById('dashboard');
    if (!searchBox.contains(event.target) && event.target !== canvas) {
      hideSearch();
    }
  });

  hideSearch();

  canvas.addEventListener('dblclick', event => {
    const selectedBlock = self.selectedBlock;
    event.preventDefault();
    if (
      !isMouseOverBlock(
        event.clientX - canvas.offsetLeft,
        event.clientY - canvas.offsetTop,
        self
      )
    ) {
      // centerContent();
      self.draw();
    }
    console.log({ selectedBlock });
    if (selectedBlock) return;
    // const rect = self.canvas.getBoundingClientRect();
    searchBox.style.left = event.clientX + 'px';
    searchBox.style.top = event.clientY + 'px';
    searchBox.style.display = 'block';
    searchInput.focus();

    // Position suggestions directly below the input box
    const inputRect = searchInput.getBoundingClientRect();
    suggestions.style.left = inputRect.left + 'px';
    suggestions.style.top = inputRect.bottom + 2 + 'px'; // 2px gap between input and suggestions
    suggestions.style.width = inputRect.width + 'px'; // Match width to the input
  });
  // To ensure the canvas is focusable and receives keyboard events:
  canvas.setAttribute('tabindex', '0'); // Make the canvas focusable
  // canvas.focus(); // Set focus on the canvas

  canvas.addEventListener('keydown', event => {
    const selectedBlock = self.selectedBlock;
    const { blocks } = self.state;
    console.log(' key pressed', event.key);
    if (event.key === 'Backspace' || event.key === 'Delete') {
      // Add your logic here
      if (selectedBlock) {
        const index = blocks.findIndex(b => b === selectedBlock);
        const lines = selectedBlock.disconnectBlock();
        const hasLines = selectedBlock.isConnected();
        if (index !== -1 && (lines.length || !hasLines)) {
          for (let i = 0; i < lines.length; i++) {
            lines[i] = null;
          }
          blocks.splice(index, 1);
          self.selectedBlock = null;
          self.draw(true);
        }
      }
    }
  });

  canvas.addEventListener('mousemove', e => {
    const draggingBlock = self.draggingBlock;
    const selectedLine = self.selectedLine;
    const { lastPanPoint, isPanning } = self.state;
    const { panOffset, zoomLevel } = self.state.diagram;
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / devicePixelRatio / rect.width;
    const scaleY = canvas.height / devicePixelRatio / rect.height;
    const x = Math.round((e.clientX - rect.left) * scaleX);
    const y = Math.round((e.clientY - rect.top) * scaleY);
    const canvasX = Math.round((x - panOffset.x) / zoomLevel);
    const canvasY = Math.round((y - panOffset.y) / zoomLevel);
    if (isPanning) {
      const dx = x - lastPanPoint.x;
      const dy = y - lastPanPoint.y;
      panOffset.x += dx;
      panOffset.y += dy;
      self.state.lastPanPoint = { x, y };
      self.draw();
    } else if (draggingBlock) {
      draggingBlock.drag(canvasX, canvasY);
      canvas.style.cursor = 'grabbing';
      self.draw(true);
    } else {
      canvas.style.cursor = isMouseOverBlock(canvasX, canvasY, self)
        ? 'grab'
        : 'default';
      if (selectedLine) {
        canvas.style.cursor = 'grabbing';
        selectedLine.floatingPoint = {
          x: canvasX,
          y: canvasY,
        };
        self.draw(true);
      } else if (self.state.selection.isDrawing) {
        let { startX, startY } = self.state.selection;

        self.state.selection.lastWidth = canvasX - startX;
        self.state.selection.lastHeight = canvasY - startY;
        self.state.selection.lastStartX = startX;
        self.state.selection.lastStartY = startY;
        canvas.style.cursor = 'crosshair';
        self.draw();
      } else {
        const { blocks } = self.state;
        for (let i = 0; i < blocks.length; i++) {
          const block = blocks[i];
          const portOver = block.getPortOnMouseOver(canvasX, canvasY);
          if (portOver) {
            canvas.style.cursor = 'pointer';
            self.draw();
            break;
          }
        }
      }
    }
  });

  canvas.addEventListener('mousedown', e => {
    const { blocks } = self.state;
    const { panOffset, zoomLevel } = self.state.diagram;
    if (e && e.button === 0) {
      console.log('Left mouse button pressed');
      self.mouseLeftPressed = true;
      // Add your code here for handling the left button click
    }
    hideSearch(true);
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / devicePixelRatio / rect.width;
    const scaleY = canvas.height / devicePixelRatio / rect.height;
    const x = Math.round((e.clientX - rect.left) * scaleX);
    const y = Math.round((e.clientY - rect.top) * scaleY);

    if (e.shiftKey) {
      self.state.isPanning = true;
      self.state.lastPanPoint = { x, y };
      canvas.style.cursor = 'move';
    } else {
      const canvasX = Math.round((x - panOffset.x) / zoomLevel);
      const canvasY = Math.round((y - panOffset.y) / zoomLevel);
      // console.log({ canvasX, canvasY, scaleX, scaleY });
      let prevSelectedBlock = self.selectedBlock;
      self.selectedBlock = null;
      for (let i = 0; i < blocks.length; i++) {
        const block = blocks[i];
        const portOver = block.getPortOnMouseOver(canvasX, canvasY);
        if (portOver) {
          if (!self.selectedLine) {
            const isRight = portOver.isOutputPort();
            if (prevSelectedBlock) {
              prevSelectedBlock.selected = false;
              prevSelectedBlock = null;
            }
            if (isRight) {
              self.selectedLine = portOver.createStartLine();
            }
          }
          if (!self.selectedLine && disconnectBlock(canvasX, canvasY)) {
            canvas.style.cursor = 'grabbing';
            self.selectedLine.floatingPoint = {
              x: canvasX,
              y: canvasY,
            };
            self.draw();
            console.log('Line was disconnected');
          }
        } else if (block.isPointInside(canvasX, canvasY)) {
          self.draggingBlock = block;
          self.selectedBlock = block;
          self.draggingBlock.selected = true;
          self.selectedBlock.startDrag(canvasX, canvasY);
          canvas.style.cursor = 'grab';
          self.setState({ selected_name: block.title });
        } else {
          if (self.mouseLeftPressed && !self.selectedBlock) {
            self.state.selection.isDrawing = true;
            self.state.selection.startX = canvasX;
            self.state.selection.startY = canvasY;
            canvas.style.cursor = 'crosshair';
          } else if (self.selectedBlock) {
            canvas.style.cursor = 'grabbing';
          } else {
            canvas.style.cursor = 'default';
          }
          block.selected = false;
          self.setState({ selected_name: null });
        }
      }
      self.draw(); // Redraw to show selection
    }
  });

  function connectBlocks(canvasX, canvasY) {
    if (!self.selectedLine) return false;
    const { blocks } = self.state;
    const startPort = self.selectedLine.getStartPort();
    let text = 'mouse not close to a block';
    let isRight = null;
    let block = null;
    for (let i = 0; i < blocks.length; i++) {
      const portOver = blocks[i].getPortOnMouseOver(canvasX, canvasY);
      if (portOver) block = blocks[i];
      if (portOver === startPort) {
        text = 'mouse over same port: ';
      } else if (blocks[i] === self.selectedBlock) {
        text = 'mouse over same block: ';
      } else if (portOver) {
        isRight = portOver.isOutputPort();
        if (isRight) {
          text = 'mouse over output port: ';
        } else if (self.selectedLine.connectToEndPort(portOver)) {
          console.log('mouser connected: ', {
            id: blocks[i].id,
            isRight,
          });
          return true;
        } else {
          text = 'Failed to connect';
        }
      }
    }
    console.log(text, {
      id: block ? block.id : 'null',
      isRight,
    });
    return false;
  }
  function disconnectBlock(canvasX, canvasY) {
    if (self.selectedLine) return false;
    const { blocks } = self.state;
    let text = 'mouse not close to a block';
    let isRight = null;
    let block = null;
    for (let i = 0; i < blocks.length; i++) {
      const portOver = blocks[i].getPortOnMouseOver(canvasX, canvasY);
      if (portOver) {
        block = blocks[i];
        isRight = portOver.isOutputPort();
        self.selectedLine = portOver.getFirstLine();
        if (self.selectedLine) {
          if (portOver.disconnectLine(self.selectedLine)) {
            console.log('mouse disconnected: ', {
              id: blocks[i].id,
              isRight,
            });
            return true;
          } else {
            text = 'Failed to disconnect';
          }
        } else {
          text = 'No line found in port';
        }
      }
    }
    self.selectedLine = null;
    console.log(text, {
      id: block ? block.id : 'null',
      isRight,
    });
    return false;
  }

  canvas.addEventListener('mouseup', e => {
    const { isPanning, blocks } = self.state;
    const { panOffset, zoomLevel } = self.state.diagram;
    const draggingBlock = self.draggingBlock;
    const selectedLine = self.selectedLine;

    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / devicePixelRatio / rect.width;
    const scaleY = canvas.height / devicePixelRatio / rect.height;
    const x = Math.round((e.clientX - rect.left) * scaleX);
    const y = Math.round((e.clientY - rect.top) * scaleY);
    const canvasX = Math.round((x - panOffset.x) / zoomLevel);
    const canvasY = Math.round((y - panOffset.y) / zoomLevel);
    let render = self.state.selection.isDrawing;
    if (render) {
      const { startX, startY, lastWidth, lastHeight } = self.state.selection;
      // Normalize negative width/height
      const x = lastWidth < 0 ? startX + lastWidth : startX;
      const y = lastHeight < 0 ? startY + lastHeight : startY;
      const width = Math.abs(lastWidth);
      const height = Math.abs(lastHeight);
      for (let i = 0; i < blocks.length; i++) {
        const block = blocks[i];
        const selected = !(
          block.x + block.width < x || // block is left of selection
          block.x > x + width || // block is right of selection
          block.y + block.height < y || // block is above selection
          block.y > y + height
        ); // block is below selection
        if (selected) {
          blocks[i].selected = selected;
        } else {
          blocks[i].selected = false;
        }
      }
    }
    self.state.selection = {
      isDrawing: false,
      startX: 0,
      startY: 0,
      fadeOutOpacity: 1.0,
      fadeOutAnimation: 0,
      lastWidth: 0,
      lastHeight: 0,
      lastStartX: 0,
      lastStartY: 0,
    };

    if (isPanning) {
      self.state.isPanning = false;
    }
    if (draggingBlock) {
      self.draggingBlock = null;
    }
    if (selectedLine) {
      if (selectedLine.isConnected()) {
        console.log('Is Connected');
        const connection = connectBlocks(canvasX, canvasY);
        if (!connection) {
          selectedLine.disconnectLine();
          console.log('Line Connected');
        }
      } else {
        console.log('Line is not connected');
      }
      self.selectedLine = null;
      render = true;
    }
    canvas.style.cursor = 'default';
    if (render) {
      self.draw();
    }
    self.mouseLeftPressed = false;
  });

  canvas.addEventListener('mouseleave', () => {
    self.state.isPanning = false;
    self.draggingBlock = null;
    canvas.style.cursor = 'default';
  });

  function zoomTowardPoint(point, factor) {
    const { panOffset } = self.state.diagram;
    console.log({ panOffset, point, factor });
    const oldZoom = self.state.diagram.zoomLevel;
    self.state.diagram.zoomLevel *= factor;
    self.state.diagram.zoomLevel = Math.max(
      0.5,
      Math.min(3, self.state.diagram.zoomLevel)
    ); // Limit zoom level

    const zoomFactor = self.state.diagram.zoomLevel / oldZoom;

    // Adjust pan offset to zoom towards the mouse position
    panOffset.x = point.x - (point.x - panOffset.x) * zoomFactor;
    panOffset.y = point.y - (point.y - panOffset.y) * zoomFactor;
  }
  self.holder.zoomTowardPoint = (point, factor) => {
    zoomTowardPoint(point, factor);
    self.draw();
  };
  canvas.addEventListener('wheel', e => {
    e.preventDefault();
    // const rect = canvas.getBoundingClientRect();
    // const scaleX = canvas.width / devicePixelRatio / rect.width;
    // const scaleY = canvas.height / devicePixelRatio / rect.height;
    // const mouseX = (e.clientX - rect.left) * scaleX;
    // const mouseY = (e.clientY - rect.top) * scaleY;

    // const zoomFactor = e.deltaY < 0 ? 1.1 : 0.9;
    // // zoomTowardPoint({ x: mouseX, y: mouseY }, zoomFactor);

    // self.draw();
  });
  canvas.addEventListener('dragover', e => {
    e.preventDefault();
    // console.log('canvas.addEventListener - dragover');
    canvas.classList.add('dragover');
    // Change cursor to indicate drop is possible
    e.dataTransfer.dropEffect = 'copy';
  });

  canvas.addEventListener('drop', e => {
    e.preventDefault();
    console.log('canvas.addEventListener - drop', self.state.blocks.length);

    canvas.classList.remove('dragover');
    const { panOffset, zoomLevel } = self.state.diagram;
    const { canvasX, canvasY } = getTranformMouse(
      e,
      canvas,
      panOffset,
      zoomLevel,
      devicePixelRatio
    );

    const draggedElementId = e.dataTransfer.getData('text');
    let index = getBlockItems().findIndex(item => item.id === draggedElementId);
    if (index !== -1) {
      const title = getBlockAtIndex(index).label;
      const to_search = `${canvasX}x${canvasY}-${title}`;
      index = self.state.blocks.findIndex(b => {
        return `${b.x}x${b.y}-${b.title}` === to_search;
      });
      console.log({ to_search, index });
      let newBlock = null;
      if (index === -1) newBlock = createBlock(canvasX, canvasY, title, self);
      if (newBlock) {
        self.state.blocks.push(newBlock);
        self.draw(true);
      } else {
        console.log('Block not found or not implemente yet: ', title);
      }
    }
  });
  canvas.addEventListener('dragleave', () => {
    canvas.classList.remove('dragover');
  });
};
