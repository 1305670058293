import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { withRouter } from 'react-router-dom';
import { TabPanel } from 'components';
import {
  ClinicMS,
  ClinicStroke,
  ClinicEpilepsy,
  ClinicSleep,
  PatientClinics,
  AimCard
} from './components';
import {
  IsAdministrator,
  IsValid,
  getParams,
  GetData,
  IsInvalid,
} from 'helpers';
import { QueryUsersClinicals } from 'graphql/Users/utils_user_clinical';
import { QueryAccountClinicalData } from 'graphql/DataClinics/utils_clinical_accounts';
class DataClinics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      tabs: [],
    };
  }
  componentDidMount() {
    const { user } = this.props;
    let userId = null;
    let accountTypeId = null;
    if (IsInvalid(user)) return;
    if (IsAdministrator(user)) {
      this.initAllTabs();
    } else {
      const { id, type } = user;
      if (type === 1) {
        this.initAllTabs();
      } else {
        userId = id;
        accountTypeId = type;

        this.initUserTabs(userId, accountTypeId);
      }
    }
  }

  initAllTabs = () => {
    const tabs = [
      { TabComponent: PatientClinics, name: 'Clinical Patients' },
      { TabComponent: ClinicMS, name: 'MS' },
      { TabComponent: ClinicStroke, name: 'Stroke' },
      { TabComponent: ClinicEpilepsy, name: 'Epilepsy' },
      { TabComponent: ClinicSleep, name: 'Sleep' },
      { TabComponent: AimCard, name: 'Aim_Card' },
    ];
    this.setState({ tabs });
  };

  async initUserTabs(userId, accountTypeId) {
    let tabs = [];
    const { clinical_account, clinical_user } = await this.queryClinics(
      userId,
      accountTypeId
    );
    // concat and remove duplicated
    let authorized_tabs = clinical_user.concat(clinical_account);

    authorized_tabs = authorized_tabs.filter(
      (obj, index, self) => index === self.findIndex(o => o.id === obj.id)
    );
    // loop on results and push Tabs
    for (let i = 0; i < authorized_tabs.length; i++) {
      const element = authorized_tabs[i];
      let tab = null;
      let tab_name = element.description.toLowerCase().replace(' ', '_');
      
      if (tab_name === 'clinical_patients') {
        tab = { TabComponent: PatientClinics, name: 'Clinical Patients' };
      } else if (tab_name === 'ms') {
        tab = { TabComponent: ClinicMS, name: 'MS' };
      } else if (tab_name === 'stroke') {
        tab = { TabComponent: ClinicStroke, name: 'Stroke' };
      } else if (tab_name === 'epilepsy') {
        tab = { TabComponent: ClinicEpilepsy, name: 'Epilepsy' };
      } else if (tab_name === 'sleep') {
        tab = { TabComponent: ClinicSleep, name: 'Sleep' };
      } else if (tab_name === 'aim_card') {
        tab = { TabComponent: AimCard, name: 'Aim_Card' };
      }
      tabs.push(tab);
    }
    this.setState({ tabs });
  }

  async queryClinics(userId, accountTypeId) {
    try {
      let clinical_user = [];
      let clinical_account = [];
      const userData = await QueryUsersClinicals(userId);
      const userDataResponse = GetData(userData);
      const {
        clinical_type: userClinicalType,
        clinical_types,
      } = userDataResponse.userClinicalDataType;

      const clinical_type = clinical_types.map(userStudies => {
        return userClinicalType.find(
          item => item.id === userStudies.idClinicalDataType
        );
      });
      if (IsValid(clinical_type)) {
        clinical_user = clinical_type;
      }
      const accountData = await QueryAccountClinicalData(accountTypeId);
      const accountDataResponse = GetData(accountData);
      const {
        clinical_type: accountClinicalType,
      } = accountDataResponse.accountClinicalData;
      if (IsValid(accountClinicalType)) {
        clinical_account = accountClinicalType;
      }
      return { clinical_account, clinical_user };
    } catch (error) {
      console.log({ error });
      return null;
    }
  }
  handleTabValue = (event, newValue) => {
    event.preventDefault();
    const { tabs } = this.state;
    if (newValue >= 0 && newValue < tabs.length) {
      let text = tabs[newValue].name.toLowerCase();
      if (newValue === 0) {
        text = text.replace(/ /g, '_');
      }
      this.props.history.push(`/clinical_data/${text}`);
    } else {
      this.props.history.push('/clinical_data/clinical_patients');
    }
  };
  render() {
    const { level_1 } = getParams(this.props);
    const index = this.state.tabs
      .map(x => x.name.toLowerCase())
      .indexOf(level_1);
    let tabValue = 0;
    if (index !== -1) {
      tabValue = index;
    }
    return (
      <React.Fragment>
        <Box
          sx={{
            flexGrow: 1,
            maxWidth: { xs: 320, sm: '100%' },
            bgcolor: 'background.paper',
          }}>
          <Tabs
            aria-label="visible arrows tabs example"
            onChange={this.handleTabValue}
            scrollButtons
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
            }}
            value={tabValue}
            variant="scrollable">
            {this.state.tabs.map((tab, index) => {
              const { name } = tab;
              return <Tab key={`tab-head-clinic-${index}`} label={name} />;
            })}
          </Tabs>
        </Box>
        {this.state.tabs.map((tab, index) => {
          const { TabComponent } = tab;
          return (
            <TabPanel
              index={index}
              key={`tab-panel-clinic-${index}`}
              value={tabValue}>
              <TabComponent
                handleAddError={this.props.handleAddError}
                handleAddSuccess={this.props.handleAddSuccess}
                height={this.props.height}
                history={this.props.history}
                isActive={index === tabValue}
                isDesktop={this.props.isDesktop}
                match={this.props.match}
                user={this.props.user}
              />
            </TabPanel>
          );
        })}
      </React.Fragment>
    );
  }
}

DataClinics.propTypes = {
  clases: PropTypes.object,
};

export default withRouter(DataClinics);
