import React from 'react';
import PropTypes from 'prop-types';
import { Buffer } from 'buffer';
import { withStyles } from 'tss-react/mui';
import { PDFDocument } from 'pdf-lib';
import { useStyles } from './Styles';
import { BoxWaiting } from 'components/Waiting';
import { IsInvalid } from 'helpers';

class PDFViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parsedPDF: null,
      loading: false,
      ready: false,
      filename: null,
    };
  }
  componentDidMount() {
    const { pdfData } = this.props;
    this.parsePDFData(pdfData);
  }
  componentDidUpdate() {
    const { pdfData } = this.props;
    this.parsePDFData(pdfData);
  }
  parsePDFData = pdfString => {
    if (IsInvalid(pdfString) || pdfString === '') return;
    const { loading } = this.state;
    if (loading) return;
    const { filename } = this.props;
    if (this.state.filename === filename) return;
    // Initialize a PDF parser library (e.g., pdf-lib or PDF.js)
    this.setState({ loading: true });
    (async () => {
      // Convert the base64-encoded PDF to an ArrayBuffer
      try {
        const pdfBuffer = Buffer.from(pdfString, 'base64');
        const formPdfBytes = pdfBuffer.buffer;
        const pdfDoc = await PDFDocument.load(formPdfBytes);
        // Create a new PDF document
        // const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

        // const page = pdfDoc.addPage();
        // const { width, height } = page.getSize();
        // const fontSize = 30;
        // page.drawText('Creating PDFs in JavaScript is awesome!', {
        //   x: 50,
        //   y: height - 4 * fontSize,
        //   size: fontSize,
        //   font: timesRomanFont,
        //   color: rgb(0, 0.53, 0.71),
        // });

        const parsedPDF = await pdfDoc.save();
        this.setState({ parsedPDF, loading: false, ready: true, filename });
      } catch (error) {
        console.log({ pdfString, error });
      }
    })();
  };
  render() {
    const { classes } = this.props;
    const { parsedPDF, loading } = this.state;
    if (loading) {
      return <BoxWaiting />;
    }
    return (
      <div className={classes.root}>
        {parsedPDF && (
          <iframe
            height="100%"
            src={URL.createObjectURL(
              new Blob([parsedPDF], { type: 'application/pdf' })
            )}
            title="PDF Viewer"
            width="100%"
          />
        )}
      </div>
    );
  }
}

PDFViewer.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(PDFViewer, useStyles);
