import {
  MutationAddSleepCases,
  MutationAddSleepTestTypes,
  MutationAddSleepPolysomnographies,
  MutationAddSleepVigilances,
  MutationRemoveSleepCases,
  MutationRemoveSleepTestTypes,
  MutationRemoveSleepPolysomnographies,
  MutationRemoveSleepVigilances,
  MutationUploadSleepAll,
  QuerySleepCases,
  QuerySleepTestTypes,
  QuerySleepPolysomnographies,
  QuerySleepVigilances,
  QuerySleepPatients,
  MutationAddClinicalPatients,
} from 'graphql/DataClinics';
import * as searches from 'graphql/DataClinics/sleep/utils_search';

export const QuerySleep = (table, name, page, limit, search_query, anonymize) => {
  if (table === 'sleep_cases') {
    return QuerySleepCases(name, search_query, page, limit);
  } else if (table === 'sleep_polysomnographies') {
    return QuerySleepPolysomnographies(name, search_query, page, limit);
  } else if (table === 'sleep_mslt') {
    return QuerySleepTestTypes(name, search_query, 'mslt', page, limit);
  } else if (table === 'sleep_mwt') {
    return QuerySleepTestTypes(name, search_query, 'mwt', page, limit);
  } else if (table === 'sleep_vigilances') {
    return QuerySleepVigilances(name, search_query, page, limit);
  } else if (table === 'sleep_patient') {
    return QuerySleepPatients(page, limit, anonymize);
  } else {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      resolve({
        data: { data: 1 },
      });
    });
  }
};
export const GetResponse = (table, data) => {
  if (table === 'sleep_cases') {
    const { ok, errors, cases, total } = data.sleepCases;
    return { ok, errors, total, rows: cases };
  } else if (table === 'sleep_polysomnographies') {
    const {
      ok,
      errors,
      polysomnographies,
      total,
    } = data.sleepPolysomnographies;
    return { ok, errors, total, rows: polysomnographies };
  } else if (table === 'sleep_mslt') {
    let { ok, errors, test_types, total } = data.sleepTestTypes;
    test_types = formatRepetitions(test_types);
    return { ok, errors, total, rows: test_types };
  } else if (table === 'sleep_mwt') {
    let { ok, errors, test_types, total } = data.sleepTestTypes;
    test_types = formatRepetitions(test_types);
    return { ok, errors, total, rows: test_types };
  } else if (table === 'sleep_vigilances') {
    const { ok, errors, vigilances, total } = data.sleepVigilances;
    return { ok, errors, total, rows: vigilances };
  } else if (table === 'sleep_patient') {
    const { ok, errors, patients, total } = data.sleepPatients;
    return { ok, errors, total, rows: patients };
  } else {
    return { ok: true, errors: null, total: 0, rows: [] };
  }
};
export const MutationRemoveSleep = (table, ids) => {
  if (table === 'sleep_cases') {
    return MutationRemoveSleepCases(ids);
  } else if (table === 'sleep_polysomnographies') {
    return MutationRemoveSleepPolysomnographies(ids);
  } else if (table === 'sleep_mslt' || table === 'sleep_mwt') {
    return MutationRemoveSleepTestTypes(ids);
  } else if (table === 'sleep_vigilances') {
    return MutationRemoveSleepVigilances(ids);
  } else {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      resolve({
        data: { data: 1 },
      });
    });
  }
};
export const GetRemoveResponse = (table, data) => {
  let reply = {
    ok: false,
    errors: [{ path: 'Invalid', message: 'Invalid table' }],
  };
  if (table === 'sleep_cases') {
    reply = data.deleteSleepCases;
  } else if (table === 'sleep_polysomnographies') {
    reply = data.deleteSleepPolysomnographies;
  } else if (table === 'sleep_mslt' || table === 'sleep_mwt') {
    reply = data.deleteSleepTestTypes;
  } else if (table === 'sleep_vigilances') {
    reply = data.deleteSleepVigilances;
  }
  return reply;
};
export const MutationAddSleep = (table, inputs) => {
  if (table === 'sleep_cases') {
    return MutationAddSleepCases(inputs);
  } else if (table === 'sleep_polysomnographies') {
    return MutationAddSleepPolysomnographies(inputs);
  } else if (table === 'sleep_mwt' || table === 'sleep_mslt') {
    return MutationAddSleepTestTypes(inputs);
  } else if (table === 'sleep_vigilances') {
    return MutationAddSleepVigilances(inputs);
  } else if (table === 'sleep_patient') {
    return MutationAddClinicalPatients(inputs, 'sleep');
  } else {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      resolve({
        data: { data: 1 },
      });
    });
  }
};

export const GetAddResponse = (table, data) => {
  if (table === 'sleep_cases') {
    const { ok, errors, cases, total } = data.addSleepCases;
    return { ok, errors, total, rew_rows: cases };
  } else if (table === 'sleep_polysomnographies') {
    const {
      ok,
      errors,
      polysomnographies,
      total,
    } = data.addSleepPolysomnographies;
    return { ok, errors, total, rew_rows: polysomnographies };
  } else if (table === 'sleep_mwt' || table === 'sleep_mslt') {
    let { ok, errors, test_types, total } = data.addSleepTestTypes;
    test_types = formatRepetitions(test_types);
    return { ok, errors, total, rew_rows: test_types };
  } else if (table === 'sleep_vigilances') {
    const { ok, errors, vigilances, total } = data.addSleepVigilances;
    return { ok, errors, total, rew_rows: vigilances };
  } else if (table === 'sleep_patient') {
    const { ok, errors, patients, total } = data.addPatientsClinics;
    return { ok, errors, total, rew_rows: patients };
  } else {
    return { ok: true, errors: null, total: 0, rew_rows: [] };
  }
};

export const MutationUploadSleep = (table, file, handleProgress) => {
  return MutationUploadSleepAll(file, handleProgress);
};
export const GetUploadResponse = (table, data) => {
  const { ok, errors, total, cases } = data.uploadSleep;
  return { ok, errors, total, new_rows: cases };
};
export const searchSleepTables = (text, table, page, limit) => {
  const functionName = `Search${table.charAt(0).toUpperCase() +
    table.slice(1)}`;
  const searchSleepFunction = searches[functionName];

  // const functionsArray = Object.values(searches);
  // const searchSleepFunction = functionsArray.find(
  //   func => func.name === functionName
  // );
  return searchSleepFunction(text, page, limit, table);
};
function formatRepetitions(test_types) {
  for (let j = 0; j < test_types.length; j++) {
    for (let i = 0; i < 5; i++) {
      const index = i + 1;
      const rep_str = 'rep' + index;
      if (i < test_types[j].repetitions.length) {
        const element = test_types[j].repetitions[i];
        test_types[j][rep_str + '_id'] = element['id'];
        test_types[j][rep_str + '_kss_post'] = element['kss_post'];
        test_types[j][rep_str + '_kss_pre'] = element['kss_pre'];
        test_types[j][rep_str + '_rem_latency'] = element['rem_latency'];
        test_types[j][rep_str + '_sleep_latency'] = element['sleep_latency'];
        test_types[j][rep_str + '_start_datetime'] = element['start_datetime'];
      } else {
        test_types[j][rep_str + '_id'] = '';
        test_types[j][rep_str + '_kss_post'] = '';
        test_types[j][rep_str + '_kss_pre'] = '';
        test_types[j][rep_str + '_rem_latency'] = '';
        test_types[j][rep_str + '_sleep_latency'] = '';
        test_types[j][rep_str + '_start_datetime'] = '';
      }
    }
  }
  return test_types;
}
