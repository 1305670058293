import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { TableVariableTypes, DialogCreateVariableType } from 'components';
import { QueryVariableTypes } from 'graphql/Mapping/utils_mapping';
import { GetData, ServerErrorsString, SelectedArray } from 'helpers';
import { ToolBarVariableTypes, EditVariableTypes } from './components';

class VariableTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      types: [],
      loading: false,
      page: 0,
      limit: 10,
      total: 0,
      open_edit: false,
      open_create: false,
      init: false,
    };
  }
  componentDidMount() {
    if (this.props.isActive) this.runVariableTypes();
  }
  componentDidUpdate() {
    const { init } = this.state;
    if (this.props.isActive && !init) this.runVariableTypes();
  }
  runVariableTypes = (newPage = null, newLimit = null) => {
    let { loading, page, limit } = this.state;
    if (loading) {
      return;
    }
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    this.setState({ loading: true });
    (async () => {
      QueryVariableTypes(null, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, types, total, errors } = data.variableTypes;
          if (ok) {
            this.setState({
              types,
              total,
              loading: false,
              page,
              limit,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log('catch-UsersProjects', error);
          this.setState({
            types: [],
            loading: false,
            page,
            limit,
            init: true,
          });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState({ selected: this.state.types });
    } else {
      this.setState({ selected: [] });
    }
  };
  handleSelectedClick = (event, index, row) => {
    event.preventDefault();
    const { selected } = this.state;
    const selectedIndex = selected
      ? selected.map(x => x.id).indexOf(row.id)
      : 0;
    const newSelected = SelectedArray(selected, row, selectedIndex);
    this.setState({ selected: newSelected });
    return newSelected.length;
  };
  handleOpenEdit = () => {
    const { selected, open_edit } = this.state;
    if (selected && selected.length > 0) {
      this.setState({ open_edit: !open_edit });
    }
  };
  handleCreatedVariableType = type => {
    let { types } = this.state;
    const index = types.map(x => x.id).indexOf(type.id);
    if (index === -1) {
      types.unshift(type);
      this.setState({ types, open_create: false });
    }
  };
  handleUpdatedVarType = to_edit => {
    if (!to_edit) return;
    let { selected, types } = this.state;
    let index = selected.map(x => x.id).indexOf(to_edit.id);
    if (index !== -1) selected[index] = { ...to_edit };
    index = types.map(x => x.id).indexOf(to_edit.id);
    if (index !== -1) types[index] = { ...to_edit };
    this.setState({ selected, types });
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.runVariableTypes(newPage, this.state.limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.runVariableTypes(0, +event.target.value);
  };
  handelAddVariableTypes = newTypes => {
    let { types, total } = this.state;
    for (let i = 0; i < newTypes.length; i++) {
      const { id } = newTypes[i];
      const index = types.map(x => x.id).indexOf(id);
      if (index === -1) {
        types.unshift(newTypes[i]);
        total++;
      }
    }
    this.setState({ types, total });
  };
  render() {
    if (!this.props.isActive) return null;
    let { height } = this.props;
    const { open_edit } = this.state;
    if (open_edit) {
      return (
        <EditVariableTypes
          handleAddError={this.props.handleAddError}
          handleCloseEdit={this.handleOpenEdit}
          handleUpdatedVarType={this.handleUpdatedVarType}
          types={this.state.selected}
        />
      );
    }
    if (height) {
      height = height - 90;
    }
    return (
      <div style={{ padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ToolBarVariableTypes
              enable_upload
              handelAddVariableTypes={this.handelAddVariableTypes}
              handleAddError={this.props.handleAddError}
              handleClearSelected={() => this.setState({ selected: [] })}
              handleOpenCreate={() => this.setState({ open_create: true })}
              handleOpenEdit={this.handleOpenEdit}
              handleReload={this.runVariableTypes}
              loading={this.state.loading}
              selected={this.state.selected}
            />
          </Grid>
          <Grid item xs={12}>
            <TableVariableTypes
              disable_splice
              handleChangePage={this.handleChangePage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleSelectAllClick={this.handleSelectAllClick}
              handleSelectedClick={this.handleSelectedClick}
              height={height}
              hide_toolbar
              limit={this.state.limit}
              loading={this.state.loading}
              page={this.state.page}
              selected={this.state.selected}
              total={this.state.total}
              types={this.state.types}
            />
          </Grid>
        </Grid>
        <DialogCreateVariableType
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_create: false })}
          handleCreated={this.handleCreatedVariableType}
          open={this.state.open_create}
        />
      </div>
    );
  }
}

VariableTypes.propTypes = {
  classes: PropTypes.object,
};

export default VariableTypes;
