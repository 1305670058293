import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Badge, IconButton, Tooltip } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';

import { cleanCopyCut } from 'redux-store/actions/file_folder_manager';
import { DialogCleanObjects } from './components';

const useStyles = theme => ({
  root: {
    marginLeft: theme.spacing(2),
  },
});

class ButtonCleanCutCopy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  handleCleanCopyCut = () => {
    this.props.cleanCopyCut();
  };

  render() {
    const { cut_copy_objects, classes } = this.props;
    const defaultProps = {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      color: 'error',
      children: <DeleteOutlineIcon />,
    };
    let ComponentDialogCleanObjects = null;
    if (this.state.open) {
      ComponentDialogCleanObjects = (
        <DialogCleanObjects
          handleClose={() => this.setState({ open: false })}
          open={this.state.open}
        />
      );
    }
    return (
      <React.Fragment>
        <Tooltip title="Clean Copy and Cut objects">
          <span style={{ margin: 0, padding: 0 }}>
            <IconButton
              aria-controls="delete-folders"
              className={classes.root}
              onClick={() => this.setState({ open: true })} ///{this.handleCleanCopyCut}
              size="large">
              <Badge badgeContent={cut_copy_objects.length} {...defaultProps} />
            </IconButton>
          </span>
        </Tooltip>
        {ComponentDialogCleanObjects}
      </React.Fragment>
    );
  }
}

ButtonCleanCutCopy.propTypes = {
  classes: PropTypes.object,
  cut_copy_objects: PropTypes.array,
};

export default connect(null, { cleanCopyCut })(
  withStyles(ButtonCleanCutCopy, useStyles)
);
