import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  TextField,
  DialogContentText,
} from '@mui/material';
import { ButtonLoad } from 'components/Buttons';

class DialogConvertEDFToH5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subjectID: '',
      utilityFreq: 0,
    };
  }
  handleChangeSubjectId = event => {
    this.setState({ subjectID: event.target.value });
  };
  handleChangeUtilityFreq = event => {
    this.setState({ utilityFreq: parseInt(event.target.value) });
  };
  render() {
    const { open, handleClose, loading } = this.props;
    const { subjectID, utilityFreq } = this.state;
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Input parameters for Convert EDF to H5</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Type the values needed to be passed to the algorithm to convert EDF
            to H5 files. Empty fields will use the default values:{' '}
            <code>SubjectID={'""'}</code>, <code>utilityFreq=0</code>.
          </DialogContentText>
          <TextField
            autoFocus
            fullWidth
            id="subjectID"
            InputLabelProps={{
              shrink: true,
            }}
            label="Subject ID"
            margin="dense"
            onChange={this.handleChangeSubjectId}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                this.handleChangeSubjectId(event);
              }
            }}
            value={subjectID}
            variant="standard"
          />
          <TextField
            autoFocus
            fullWidth
            id="utilityFreq"
            InputLabelProps={{
              shrink: true,
            }}
            label="Utility Frequency"
            margin="dense"
            onChange={this.handleChangeUtilityFreq}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                this.handleChangeUtilityFreq(event);
              }
            }}
            type="number"
            value={utilityFreq}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ButtonLoad
            handleClick={() => this.props.handleUpdateInputParams(subjectID, utilityFreq)}
            loading={loading}
            name="Save"
            transform
            variant="text"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

DialogConvertEDFToH5.propTypes = {
  classes: PropTypes.object,
  handleUpdateInputParams: PropTypes.func,
};
DialogConvertEDFToH5.defaultProps = {
  handleUpdateInputParams: () => '',
};
export default DialogConvertEDFToH5;
