import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Paper, Tab, Tabs } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { TabExecutedUpload, TabListFolders } from './components';
import { SnackMessage, TabPanel } from 'components';

const useStyles = () => ({
  root: {},
});

class TabProphet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      error: '',
      tabs: [
        {
          name: 'UPLOADS',
          TabComponent: TabExecutedUpload,
        },
        {
          name: 'FOLDERS',
          TabComponent: TabListFolders,
        },
      ],
    };
  }

  handleTabValue = (event, newValue) => {
    event.preventDefault();
    newValue = parseInt(newValue, 10);
    if (newValue < 0 || newValue > 3) {
      newValue = 0;
    }
    this.setState({ tabValue: newValue });
  };
  handleErrorClose = (error = '') => {
    this.setState({ error });
  };

  render() {
    if (!this.props.isActive) return null;
    const { classes, user, height } = this.props;
    const internal_height = height - 210;
    const { tabValue, error, tabs } = this.state;
    return (
      <div style={{ margin: 10 }}>
        <Paper square>
          <Tabs
            indicatorColor="primary"
            onChange={this.handleTabValue}
            textColor="primary"
            value={tabValue}
            variant="scrollable">
            {tabs.map((tab, index) => {
              return (
                <Tab
                  key={`tab-center-${index}`}
                  label={tab.name}
                  style={{ textTransform: 'none' }}
                />
              );
            })}
          </Tabs>
        </Paper>
        <Divider />
        <div style={{ width: '100%', height: internal_height }}>
          <div className={classes.tab_content}>
            {tabs.map((tab, index) => {
              const { TabComponent } = tab;
              return (
                <TabPanel
                  className={classes.tab_panel}
                  index={index}
                  key={`tab-center-panel-${index}`}
                  value={tabValue}>
                  <TabComponent
                    handleAddSuccess={this.props.handleAddSuccess}
                    handleError={this.handleErrorClose}
                    height={internal_height}
                    isActive={this.props.isActive}
                    user={user}
                  />
                </TabPanel>
              );
            })}
          </div>
        </div>

        <SnackMessage
          handleClose={() => this.handleErrorClose()}
          message_text={error}
          open={error !== '' ? true : false}
          type="error"
        />
      </div>
    );
  }
}

TabProphet.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(TabProphet, useStyles);
