import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemButton, ListItemIcon, Tooltip } from '@mui/material';
import { CustomRouterLink } from 'common';
import clsx from 'clsx';
import SidebarNavItemSmallMenu from './SidebarNavItemSmallMenu';

class SidebarNavItemSmall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
    };
  }
  handlePopoverOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };
  render() {
    const { classes, page, isSelected } = this.props;
    return (
      <React.Fragment>
        <Tooltip title={page.title}>
          <span>
            <ListItem
              className={clsx({
                [classes.item]: true,
                [classes.selected]: isSelected,
              })}
              component={CustomRouterLink}
              disablePadding
              key={`menu-base-${page.title}`}
              onClick={() => this.props.handleSelected()}
              onMouseEnter={this.handlePopoverOpen}
              onMouseLeave={this.handlePopoverClose}
              sx={{ display: 'block' }}
              to={page.href}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: 'center',
                  px: 2.5,
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 'auto',
                    justifyContent: 'center',
                  }}>
                  {page.icon}
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </span>
        </Tooltip>
        <SidebarNavItemSmallMenu
          anchorEl={this.state.anchorEl}
          key={`menu-collapse-${page.title}`}
          open={this.state.open}
          page={page}
        />
      </React.Fragment>
    );
  }
}

SidebarNavItemSmall.propTypes = {
  classes: PropTypes.object,
};

export default SidebarNavItemSmall;
