import { createBoxTitle, getConfig } from '../../utils_blocks';
import { getBlockConfig, iconPeople_22, iconPeople_2 } from '../../utils_svg';
import { Block } from '../base';
import { drawCircle } from '../functions';

class AnnotateBlock extends Block {
  constructor(
    x,
    y,
    self,
    width = 170,
    height = 90,
    config = getBlockConfig('Annotate')
  ) {
    const contentHeight = 40; // 40px for top, 60px for bottom
    const bottomHeight = 40;
    super(
      x,
      y,
      width,
      height,
      config,
      contentHeight,
      bottomHeight,
      200,
      120,
      '#eceff1'
    );
    this.addPort('Input files', false, 20, '#455a64'); // Add port on the left side
    this.addPort('In progress', true, 60, '#ff5722'); // Add port on the left side
    this.addPort('Evaluated', true, 100, '#ffd600'); // Add port on the left side
  }

  drawBase(ctx, config = {}) {
    super.drawBase(ctx, config);
    // drawCircle(ctx, this.x + this.width - 15, this.y + 15, 0, 10);
  }

  drawContent(ctx) {
    super.drawContent(ctx);
    const { scaleX, scaleY } = this.getScalingFactor();
    // const padding = 20 * scaleX;
    const topSeparatorY = this.y + this.topHeight; // Y-coordinate of the top separator

    const config = getConfig(this.x, topSeparatorY, '#000');
    config.font = `${14 * scaleY}px Arial`;
    config.x = this.x;
    config.scaleX = scaleX;
    config.scaleY = scaleY;
    const icon = {
      move: 6,
      delta: 2,
      path: iconPeople_22,
      fill: '#e57373',
      stroke: '#e57373',
      size: { width: 450, height: 450 },
    };
    createBoxTitle(ctx, 'In Progress', config, icon);
    drawCircle(
      ctx,
      this.x + this.width - 40 * scaleX,
      topSeparatorY + 20 * scaleY,
      0,
      10
    );
  }
  drawBottom(ctx) {
    const postSeparatorY = super.drawBottom(ctx);
    const { scaleX, scaleY } = this.getScalingFactor();

    const config = getConfig(this.x, postSeparatorY, '#000');
    config.font = `${14 * scaleY}px Arial`;
    config.x = this.x;
    config.scaleX = scaleX;
    config.scaleY = scaleY;
    const icon = {
      move: 6,
      delta: 2,
      path: iconPeople_2,
      fill: '#ffd600',
      stroke: '#ffd600',
      size: { width: 450, height: 450 },
    };
    createBoxTitle(ctx, 'Evaluated', config, icon);
    drawCircle(
      ctx,
      this.x + this.width - 40 * scaleX,
      postSeparatorY + 20 * scaleY,
      0,
      10
    );
  }
}

export default AnnotateBlock;
