import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Typography } from '@mui/material';
// SwiperJS
import SwiperCore, { Autoplay, Navigation } from 'swiper';
// SwiperJS react components
import { Swiper, SwiperSlide } from 'swiper/react';
// SwiperJS styles
import 'swiper/swiper.min.css';
import 'common/navigation.min.css';
import Icon from '@mui/material/Icon';
import { navigationStyles, SlideCard } from './components';

class SlideSoftware extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.navigationPrevRef = React.createRef(); // create a ref object
    this.navigationNextRef = React.createRef(); // create a ref object
    this.swiperRef = React.createRef(); // create a ref object
  }
  componentDidMount() {}
  handleMouseEnter = () => {
    if (this.swiperRef.current) this.swiperRef.current.autoplay.stop();
    // console.log('handleMouseEnter');
  };
  handleMouseLeave = () => {
    if (this.swiperRef.current) this.swiperRef.current.autoplay.start();
    // console.log('handleMouseLeave');
  };
  render() {
    const { pages } = this.props;
    console.log({ pages });
    SwiperCore.use([Autoplay, Navigation]);
    return (
      <Box
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        pb={1}
        position="relative">
        <Swiper
          autoplay={{ delay: 6000 }}
          loop
          onInit={swiper => {
            this.swiperRef.current = swiper;
            const { params, navigation } = swiper;
            if (!navigation) return;
            const { navigation: nav } = params;
            nav.prevEl = this.navigationPrevRef.current;
            nav.nextEl = this.navigationNextRef.current;
            navigation.init();
            navigation.update();
          }}
          slidesPerView={1}
          spaceBetween={0}
          speed={1000}>
          {pages.map((item, index) => {
            return (
              <SwiperSlide key={`swiper-slider-${index}`}>
                <Container>
                  <SlideCard {...item} />
                </Container>
              </SwiperSlide>
            );
          })}
          <Typography
            color="dark"
            ref={this.navigationNextRef}
            sx={{
              ...navigationStyles,
              left: -5,
            }}
            variant="h2">
            <Icon>chevron_left</Icon>
          </Typography>
          <Typography
            color="dark"
            ref={this.navigationPrevRef}
            sx={{
              ...navigationStyles,
              right: -5,
            }}
            variant="h2">
            <Icon fontSize="large">chevron_right</Icon>
          </Typography>
        </Swiper>
      </Box>
    );
  }
}

SlideSoftware.propTypes = {
  classes: PropTypes.object,
};
SlideSoftware.defaultProps = {
  pages: [],
};
export default SlideSoftware;
