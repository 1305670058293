import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
const QUERY_LOAD_DIAGRAM = gql`
  query($idDiagram: LongLong!) {
    loadDiagram(idDiagram: $idDiagram) {
      ok
      errors {
        path
        message
      }
      diagram {
        id
        name
        zoomLevel
        panOffset {
          x
          y
        }
        blocks {
          id
          x
          y
          width
          height
          title
          color
          icon
          type
          inPorts {
            id
            x
            y
            idBlock
            color
            type
            name
            visible
            in_lines {
              id
              idStartBlock
              idStartPort
              idEndBlock
              idEndPort
            }
            out_lines {
              id
              idStartBlock
              idStartPort
              idEndBlock
              idEndPort
            }
          }
          outPorts {
            id
            x
            y
            idBlock
            color
            type
            name
            visible
            in_lines {
              id
              idStartBlock
              idStartPort
              idEndBlock
              idEndPort
            }
            out_lines {
              id
              idStartBlock
              idStartPort
              idEndBlock
              idEndPort
            }
          }
        }
      }
    }
  }
`;
export const QueryLoadDiagram = idDiagram => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_LOAD_DIAGRAM),
    variables: {
      idDiagram,
    },
  });
};
const QUERY_LOAD_DIAGRAMS = gql`
  query($name: String, $page: Int, $limit: Int) {
    loadDiagrams(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      diagrams {
        id
        name
        total
        zoomLevel
        panOffset {
          x
          y
        }
      }
    }
  }
`;
export const QueryLoadDiagrams = (name, page = null, limit = null) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_LOAD_DIAGRAMS),
    variables: {
      name,
      page,
      limit,
    },
  });
};
