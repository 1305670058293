import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_SSR_TREATMENT = gql`
  query($name: String, $query_input: String, $page: Int, $limit: Int) {
    ssrTreatments(name: $name, query_input: $query_input, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      treatments {
        id
        idPatient
        idCase
        aspirin_pre
        clopidogrel_pre
        prasugrel_pre
        ticagrelor_pre
        dipyridamole_pre
        vka_pre
        vka_inr
        rivaroxaban_pre
        dabigatran_pre
        apixaban_pre
        edoxaban_pre
        parenteralanticg_pre
        antihypertensive_pre
        antilipid_pre
        hormone_pre
        firstimage_type
        firstimage_time
        door_image_time
        firstimage_result
        perfusion_type
        perfusion_result
        firstangio_type
        firstangio_result
        treat_antiplatelet
        treat_anticoagulant
        treat_ivt
        ivt_start
        rtpa_dose
        onset_treat_time
        door_treat_time
        treat_iat
        iat_start
        onset_iat_time
        door_iat_time
        anesthesia
        iat_rtpa
        iat_rtpa_dose
        iat_uk
        iat_uk_dose
        iat_mech
        iat_stentretriever
        iat_aspiration
        iat_distalretriever
        iat_balloon
        iat_stentintracran
        iat_stentextracran
        iat_mech_other
        iat_end
        treat_study
        hospitalisation
        other_unitcenter
        other_unitcenter_rsn
        other_unit_datetime
        other_unit_dido
        not_monitored_rsn
        admisstreat_comment
        sys_fu_plan_date
        sys_noac_isp
        createdAt
        updatedAt

        patient {
          id
          PatientID
        }
      }
    }
  }
`;

export const QueryStrokeTreatment = (name, query_input, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SSR_TREATMENT),
    variables: {
      name,
      query_input,
      page,
      limit,
    },
  });
};

const MUTATION_ADD_SSR_TREATMENT = gql`
  mutation($inputs: [InputSsrTreatment!]!) {
    addSsrTreatment(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      total
      treatments {
        id
        idPatient
        idCase
        aspirin_pre
        clopidogrel_pre
        prasugrel_pre
        ticagrelor_pre
        dipyridamole_pre
        vka_pre
        vka_inr
        rivaroxaban_pre
        dabigatran_pre
        apixaban_pre
        edoxaban_pre
        parenteralanticg_pre
        antihypertensive_pre
        antilipid_pre
        hormone_pre
        firstimage_type
        firstimage_time
        door_image_time
        firstimage_result
        perfusion_type
        perfusion_result
        firstangio_type
        firstangio_result
        treat_antiplatelet
        treat_anticoagulant
        treat_ivt
        ivt_start
        rtpa_dose
        onset_treat_time
        door_treat_time
        treat_iat
        iat_start
        onset_iat_time
        door_iat_time
        anesthesia
        iat_rtpa
        iat_rtpa_dose
        iat_uk
        iat_uk_dose
        iat_mech
        iat_stentretriever
        iat_aspiration
        iat_distalretriever
        iat_balloon
        iat_stentintracran
        iat_stentextracran
        iat_mech_other
        iat_end
        treat_study
        hospitalisation
        other_unitcenter
        other_unitcenter_rsn
        other_unit_datetime
        other_unit_dido
        not_monitored_rsn
        admisstreat_comment
        sys_fu_plan_date
        sys_noac_isp
        createdAt
        updatedAt

        patient {
          id
          PatientID
        }
      }
    }
  }
`;

export const MutationAddSsrTreatement = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_SSR_TREATMENT),
    variables: {
      inputs,
    },
  });
};

const SEARCH_SSR_TREATMENT = gql`
  query($phrase: String, $page: Int, $limit: Int) {
    searchSsrTreatments(phrase: $phrase, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      treatments {
        id
        idPatient
        idCase
        aspirin_pre
        clopidogrel_pre
        prasugrel_pre
        ticagrelor_pre
        dipyridamole_pre
        vka_pre
        vka_inr
        rivaroxaban_pre
        dabigatran_pre
        apixaban_pre
        edoxaban_pre
        parenteralanticg_pre
        antihypertensive_pre
        antilipid_pre
        hormone_pre
        firstimage_type
        firstimage_time
        door_image_time
        firstimage_result
        perfusion_type
        perfusion_result
        firstangio_type
        firstangio_result
        treat_antiplatelet
        treat_anticoagulant
        treat_ivt
        ivt_start
        rtpa_dose
        onset_treat_time
        door_treat_time
        treat_iat
        iat_start
        onset_iat_time
        door_iat_time
        anesthesia
        iat_rtpa
        iat_rtpa_dose
        iat_uk
        iat_uk_dose
        iat_mech
        iat_stentretriever
        iat_aspiration
        iat_distalretriever
        iat_balloon
        iat_stentintracran
        iat_stentextracran
        iat_mech_other
        iat_end
        treat_study
        hospitalisation
        other_unitcenter
        other_unitcenter_rsn
        other_unit_datetime
        other_unit_dido
        not_monitored_rsn
        admisstreat_comment
        sys_fu_plan_date
        sys_noac_isp
        createdAt
        updatedAt
        patient {
          id
          PatientID
        }
      }
    }
  }
`;
export const QuerySearchStrokeTreatment = (phrase, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(SEARCH_SSR_TREATMENT),
    variables: {
      phrase,
      page,
      limit,
    },
  });
};
