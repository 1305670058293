import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { Card } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const useStyles = () => ({
  chart_area: {
    position: 'absolute',
    width: '92%',
    height: '100%',
    '& .grid': {
      position: 'absolute',
      zIndex: 0,
      top: 0,
      left: 0,
      height: '80%',
      width: '100%',
      backgroundImage: `repeating-linear-gradient(
      to right,
      transparent 0% 3%,
      #f9f9f9 3% 3.5%,
      transparent 3% 18.65%)`,
      '&::after': {
        content: '""',
        position: 'inherit',
        zIndex: -1,
        top: 'inherit',
        bottom: 'inherit',
        width: 'inherit',
        height: '100%',
        background: `linear-gradient(
          180deg,
          rgba(27, 27, 27, 1),
          rgba(27, 27, 27, 0.9),
          rgba(27, 27, 27, 1)
        )`,
      },
    },
  },

  chart: {
    '& .apexcharts-tooltip.apexcharts-theme-light': {
      color: '#e2dede',
      background: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(10px)',
      boxShadow: 'none',
      padding: '8px 6px 4px',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: ' 0.5px',
      border: 0,
    },
    '& .apexcharts-tooltip-title': {
      display: 'none', // Hides the tooltip title
    },
    '& .apexcharts-zaxistooltip-bottom.apexcharts-theme-light': {
      display: 'none', // Hides the x-axis tooltip
    },
  },
});
const primaryColor = '#44d4fd';
const labelColor = '#a7a7a7';
// // --color-text: #e2dede;
const fontFamily = 'Poppins';
class ProductStore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [
        {
          name: 'Stocks',
          data: [15, 50, 18, 90, 30, 65],
        },
      ],
      options: {
        chart: {
          type: 'area',
          toolbar: {
            show: false, // Hide the toolbar (menu)
          },
          zoom: {
            enabled: false,
          },
          width: '100%',
          height: 180,
          offsetY: 18,
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: [primaryColor],
        fill: {
          type: 'gradient',
          gradient: {
            type: 'vertical',
            opacityFrom: 1,
            opacityTo: 0,
            stops: [0, 100],
            colorStops: [
              {
                offset: 0,
                opacity: 0.2,
                color: '#ffffff',
              },
              {
                offset: 100,
                opacity: 0,
                color: '#ffffff',
              },
            ],
          },
        },
        stroke: {
          colors: [primaryColor],
          lineCap: 'round',
        },
        markers: {
          strokeColors: primaryColor,
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          labels: {
            show: true,
            floating: true,
            style: {
              colors: labelColor,
              fontFamily: fontFamily,
            },
          },
          axisBorder: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
          categories: ['Jan', 'Mar', 'May', 'July', 'Sept', 'Nov'],
          tooltip: {
            enabled: false,
          },
        },
        grid: {
          borderColor: 'rgba(0, 0, 0, 0)',
          padding: {
            top: -30,
            right: 0,
            bottom: -8,
            left: 12,
          },
        },
      },
    };
  }

  render() {
    const {
      fullScreen,
      // width,
      classes,
    } = this.props;
    // let style = {};
    if (!fullScreen) {
      // style = {
      //   height: 280,
      //   overflowX: 'auto',
      // };
    }
    return (
      <Card
        sx={{
          marginLeft: 1,
          position: 'relative',
          width: '290px',
          background: '#1b1b1b',
          padding: '10px',
          borderRadius: '24px',
          boxShadow: '0 50px 100px rgba(0, 0, 0, 0.08)',
        }}>
        <div className={classes.chart_area}>
          <div className="grid" />
        </div>
        <Chart
          className={classes.chart}
          // height={fullScreen ? 180 : 220}
          options={this.state.options}
          series={this.state.series}
          type="area"
          // width={fullScreen ? width / 2.5 : 260}
        />
      </Card>
    );
  }
}

ProductStore.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(ProductStore, useStyles);
