import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Tab, Tabs, Divider } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import {
  VariableTypes,
  SeriesVariableMaps,
  SeriesSequenceMapping,
  FilesVariableTypes,
  ClinicalVariableMaps,
  SignalVariableMaps,
  SeriesMappingDetails,
} from './components';
import useStyles from '../Logs/components/Styles';
import { TabPanel } from 'components';
import { getParams, getTabLabel } from 'helpers';

class Mapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        { name: 'Variable Types', TabComponent: VariableTypes },
        {
          name: 'Clinical Type Mapping',
          TabComponent: ClinicalVariableMaps,
        },
        {
          name: 'Signal Type Mapping',
          TabComponent: SignalVariableMaps,
        },
        {
          name: 'Series Type Mapping',
          TabComponent: SeriesVariableMaps,
        },
        {
          name: 'Series Details',
          TabComponent: SeriesMappingDetails,
        },
        {
          name: 'File Type Mapping',
          TabComponent: FilesVariableTypes,
        },
        {
          name: 'Sequence Series Mapping',
          TabComponent: SeriesSequenceMapping,
        },
      ],
    };
  }
  handleTabValue = (event, newValue) => {
    event.preventDefault();
    let url = '/management/mapping';
    newValue = parseInt(newValue, 10);
    if (newValue >= 0 && newValue < this.state.tabs.length) {
      const name = getTabLabel(this.state.tabs[newValue].name);
      url = `/management/mapping/${name}`;
    }
    this.props.history.push(url);
  };
  getTabValue = () => {
    const { level_1 } = getParams(this.props);
    let valueTab = 0;
    const index = this.state.tabs
      .map(x => getTabLabel(x.name))
      .indexOf(level_1);
    if (index !== -1) {
      valueTab = index;
    }
    return valueTab;
  };
  render() {
    if (!this.props.isActive) return null;
    const { classes, height } = this.props;
    let height_content = height - 165;
    let height_table = height - 300;
    const tabValue = this.getTabValue();
    return (
      <React.Fragment>
        <Paper>
          <Tabs
            indicatorColor="primary"
            onChange={this.handleTabValue}
            textColor="primary"
            value={tabValue}
            variant="scrollable">
            {this.state.tabs.map((x, index) => {
              return (
                <Tab
                  key={`tab-mapping-${index}`}
                  label={x.name}
                  style={{ textTransform: 'none' }}
                />
              );
            })}
          </Tabs>
          <Divider />
        </Paper>
        <div
          style={{ width: '100%', height: height_content, overflow: 'auto' }}>
          <div className={classes.content_overview}>
            {this.state.tabs.map((x, index) => {
              const { TabComponent } = x;
              return (
                <TabPanel
                  className={classes.files}
                  index={index}
                  key={`tab-mapping-${index}`}
                  value={tabValue}>
                  <TabComponent
                    handleAddError={this.props.handleAddError}
                    handleAddSuccess={this.props.handleAddSuccess}
                    height={height_table}
                    height_content={height_content}
                    isActive={index === tabValue}
                  />
                </TabPanel>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Mapping.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(Mapping, useStyles);
