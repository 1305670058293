import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import MemoryIcon from '@mui/icons-material/Memory';
import { SearchAIModels } from 'components';
import ProductStore from './ProductStore';
class ConfigAIModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ai_model: null,
    };
  }

  render() {
    if (this.props.view !== 'Configuration') return null;
    const { ai_model } = this.state;
    return (
      <>
        <Card sx={{ height: '100%', margin: 1, marginTop: 0 }}>
          <CardContent>
            <Grid
              container
              direction="row"
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <Grid item>
                <MemoryIcon />
              </Grid>
              <Grid item>
                <Typography
                  component="h2"
                  gutterBottom
                  sx={{ fontWeight: '600' }}
                  variant="subtitle2">
                  Select AI model that you want to run
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid container pt={2}>
              <Grid item xs={12}>
                <SearchAIModels
                  external_selected={ai_model}
                  handleAddSelected={this.handleSelect}
                  handleSetSelected={this.handleSelect}
                  no_icon
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <ProductStore {...this.props} />
      </>
    );
  }
}

ConfigAIModel.propTypes = {
  classes: PropTypes.object,
};

export default ConfigAIModel;
