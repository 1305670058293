import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Grid } from '@mui/material';
import { StatCard } from '../components';
import SalesMetricsCard from './SalesMetricsCard';
const data_1 = {
  title: 'Agreement',
  value: '14k',
  interval: 'Last 30 days',
  trend: 'up',
  // prettier-ignore
  data: [
    200, 24, 220, 260, 240, 380, 100, 240, 280, 240, 300, 340, 320, 360, 340, 380,
    360, 400, 380, 420, 400, 640, 340, 460, 440, 480, 460, 600, 880, 920,
  ]
};
const data_2 = {
  title: 'Disagrement',
  value: '325',
  interval: 'Last 30 days',
  trend: 'down',
  // prettier-ignore
  data: [
    1640, 1250, 970, 1130, 1050, 900, 720, 1080, 900, 450, 920, 820, 840, 600, 820,
    780, 800, 760, 380, 740, 660, 620, 840, 500, 520, 480, 400, 360, 300, 220,
  ]
};
class ConfigConsensus extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: 'test', label: 'another' };
  }
  handleChange = event => {
    console.log({ t: event.target.value });
    this.setState({ value: event.target.value });
  };
  render() {
    if (this.props.view !== 'Configuration') return null;
    const { fullScreen } = this.props;
    return (
      <Card sx={{ height: '100%', margin: 1, marginTop: 0 }}>
        <CardContent>
          <Grid
            container
            direction="row"
            spacing={2}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Grid item xs={6}>
              <StatCard
                {...data_1}
                sxt={{ fontSize: 13 }}
                sxv={{ fontSize: 26 }}
              />
            </Grid>
            <Grid item xs={6}>
              <StatCard
                {...data_2}
                sxt={{ fontSize: 13 }}
                sxv={{ fontSize: 26 }}
              />
            </Grid>
            <Grid item xs={12}>
              <SalesMetricsCard fullScreen={fullScreen} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

ConfigConsensus.propTypes = {
  classes: PropTypes.object,
};

export default ConfigConsensus;
