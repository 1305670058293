import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { QueryUserProjects } from 'graphql/Projects/utils_project';

import { useMakeStyles } from '../Styles';
import { GetData } from 'helpers';
import SearchAutocomplete from '../SearchAutocomplete';

const SearchUserProjects = props => {
  let { style, external_options, external_selected, idUser } = props;
  if (external_options === undefined || !external_options) {
    external_options = [];
  }
  const { classes } = useMakeStyles();

  const [name, setProjectName] = React.useState('');
  const [project, setProject] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState(external_options);
  let loading = open && options.length === 0;
  if (external_options.length) {
    loading = false;
    if (options.length !== external_options.length) {
      setOptions(external_options);
    }
  }
  if (external_selected && project && project.id !== external_selected.id) {
    setProject(external_selected);
  }
  React.useEffect(() => {
    if (!loading || external_options.length) {
      return undefined;
    }
    (async () => {
      QueryUserProjects(idUser, name, 0, 5)
        .then(res => {
          const data = GetData(res);
          const { ok, projects, errors } = data.userProjects;
          if (ok) {
            if (projects.length === 0) {
              projects.push({ name: 'Empty' });
            }
          } else {
            throw errors;
          }
          setOptions(projects);
        })
        .catch(error => {
          console.log({ error });
          setOptions([]);
        });
    })();
  }, [loading, name]);
  const handelProjectNameChanged = event => {
    const name = event.target.value;
    //
    if (options.length) {
      const here = options.filter(x =>
        x.name.toUpperCase().startsWith(name.toUpperCase())
      );
      //
      if (!here.length || !name.length) {
        setOptions([]);
      }
    }
    setProjectName(name);
  };
  const handleAddProject = project => {
    setProject(project);
    props.handleAddProject(project);
  };
  const handleSetProject = project => {
    setProject(project);
    if (props.handleSetProject) {
      props.handleSetProject(project);
    }
  };
  if (props.no_icon) {
    return (
      <SearchAutocomplete
        clean_output={props.clean_output}
        disabled={props.disabled}
        handelSearchNameChanged={handelProjectNameChanged}
        handleAddResultObject={handleAddProject}
        label={props.label}
        loading={loading}
        name={name}
        no_icon
        open={open}
        options={options}
        placeholder={props.placeholder}
        selected={project}
        setObject={handleSetProject}
        setObjectName={setProjectName}
        setOpen={setOpen}
        setOptions={setOptions}
      />
    );
  }
  return (
    <Paper className={classes.root} style={style}>
      <SearchIcon className={classes.icon} />
      <SearchAutocomplete
        clean_output={props.clean_output}
        disabled={props.disabled}
        handelSearchNameChanged={handelProjectNameChanged}
        handleAddResultObject={handleAddProject}
        label={props.label}
        loading={loading}
        name={name}
        open={open}
        options={options}
        placeholder={props.placeholder}
        selected={project}
        setObject={handleSetProject}
        setObjectName={setProjectName}
        setOpen={setOpen}
        setOptions={setOptions}
      />
    </Paper>
  );
};

SearchUserProjects.propTypes = {
  className: PropTypes.string,
  clean_output: PropTypes.bool.isRequired,
  external_options: PropTypes.array.isRequired,
  external_selected: PropTypes.object,
  handleSetProject: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  style: PropTypes.object,
};

SearchUserProjects.defaultProps = {
  placeholder: 'Search User Projects...',
  handleSetProject: null,
  no_icon: false,
  external_options: [],
  clean_output: true,
  external_selected: null,
  label: 'Projects',
  disabled: false,
};

export default SearchUserProjects;
