import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';

import ContactMailIcon from '@mui/icons-material/ContactMail';
import InsertChartIcon from '@mui/icons-material/InsertChartOutlined';
import AirlineSeatFlatAngledIcon from '@mui/icons-material/AirlineSeatFlatAngled';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import {
  AnatomicalRegions,
  ImageFocus,
  BodyParts,
  LatestActivity,
  LatestProjectActivity,
  LatestSeriesSequences,
  LatestSeriesTypes,
  NervePathologies,
  UsersByDevice,
} from './components';

import { CardSummary } from 'components';

import {
  TotalPatientQuery,
  TotalSeriesQuery,
  TotalStudiesQuery,
} from '../../../../graphql';
import TotalUserQuery from '../../../../graphql/Users/TotalUserQuery';

import { initialState } from 'redux-store/reducers/page_settings';

const useStyles = makeStyles()(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56,
  },
  avatar_red: {
    backgroundColor: 'red',
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56,
  },
  avatar_blue: {
    backgroundColor: 'blue',
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56,
  },
}));

const DashboardGeneral = props => {
  const { classes } = useStyles();
  const { dashboard_view } = props;
  let latest_series_sequences = null;
  if (dashboard_view.latest_series_sequences) {
    latest_series_sequences = (
      <Grid item lg={8} md={12} xl={6} xs={12}>
        <LatestSeriesSequences handleAddError={props.handleAddError} />
      </Grid>
    );
  }

  let anatomical_regions = null;
  if (dashboard_view.anatomical_regions) {
    anatomical_regions = (
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <AnatomicalRegions handleAddError={props.handleAddError} />
      </Grid>
    );
  }
  let latest_activity = null;
  if (dashboard_view.latest_activity) {
    latest_activity = (
      <Grid item lg={8} md={12} xl={9} xs={12}>
        <LatestActivity handleAddError={props.handleAddError} />
      </Grid>
    );
  }
  let latest_project_activity = null;
  if (dashboard_view.latest_project_activity) {
    latest_project_activity = (
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <LatestProjectActivity
          handleAddError={props.handleAddError}
          user={props.user}
        />
      </Grid>
    );
  }
  let pathologies = null;
  if (dashboard_view.pathologies) {
    pathologies = (
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <NervePathologies handleAddError={props.handleAddError} />
      </Grid>
    );
  }
  let investigative_side = null;
  if (dashboard_view.investigative_side) {
    investigative_side = (
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <BodyParts handleAddError={props.handleAddError} />
      </Grid>
    );
  }
  let user_by_device = null;
  if (dashboard_view.user_by_device) {
    user_by_device = (
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <UsersByDevice handleAddError={props.handleAddError} />
      </Grid>
    );
  }
  let latest_series_modalities = null;
  if (dashboard_view.latest_series_modalities) {
    latest_series_modalities = (
      <Grid item lg={8} md={12} xl={3} xs={12}>
        <LatestSeriesTypes handleAddError={props.handleAddError} />
      </Grid>
    );
  }
  let image_focus = null;
  if (dashboard_view.image_focus) {
    image_focus = (
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <ImageFocus handleAddError={props.handleAddError} />
      </Grid>
    );
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalPatientQuery handleAddError={props.handleAddError}>
            <CardSummary icon={ContactMailIcon} title="Total Cases" />
          </TotalPatientQuery>
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalStudiesQuery handleAddError={props.handleAddError}>
            <CardSummary
              avatar={classes.avatar}
              icon={InsertChartIcon}
              title="Total Studies"
            />
          </TotalStudiesQuery>
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalSeriesQuery handleAddError={props.handleAddError}>
            <CardSummary
              avatar={classes.avatar_red}
              icon={AirlineSeatFlatAngledIcon}
              title="Total Series"
            />
          </TotalSeriesQuery>
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalUserQuery handleAddError={props.handleAddError}>
            <CardSummary
              avatar={classes.avatar_blue}
              icon={SupervisorAccountIcon}
              title="Total Users"
            />
          </TotalUserQuery>
        </Grid>
        {/* ------------------------------------- */}
        {latest_series_sequences}
        {anatomical_regions}
        {/* ------------------------------------- */}
        {latest_project_activity}
        {latest_activity}
        {/* ------------------------------------- */}
        {pathologies}
        {image_focus}
        {investigative_side}
        {user_by_device}
        {latest_series_modalities}
      </Grid>
    </div>
  );
};
const mapStateToProps = state => {
  let { dashboard_view } = state.page_settings;
  if (dashboard_view === undefined) {
    dashboard_view = {
      ...initialState.dashboard_view,
    };
  }
  return { dashboard_view };
};

export default connect(mapStateToProps, null)(DashboardGeneral);
