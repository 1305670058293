import { createLine, createText, getConfig } from '../../utils_blocks';
import { getBlockConfig } from '../../utils_svg';
import { Block } from '../base';
import { drawCircle } from '../functions';

class SamplingBlock extends Block {
  constructor(
    x,
    y,
    self,
    width = 180,
    height = 140,
    config = getBlockConfig('Sampling')
  ) {
    const contentHeight = 80; // 40px for top, 60px for bottom
    const bottomHeight = 40;
    super(
      x,
      y,
      width,
      height,
      config,
      contentHeight,
      bottomHeight,
      200,
      160,
      '#eceff1'
    );
    this.addPort('Input data', false, 20, '#fb8c00'); // Add port on the left side
    this.addPort('Training', true, 60, '#ff5722'); // Add port on the left side
    this.addPort('Testing', true, 100, 'blue'); // Add port on the left side
    this.addPort('External', true, 140, 'green'); // Add port on the left side
  }

  drawBase(ctx, config = {}) {
    const { scaleX, scaleY } = this.getScalingFactor();
    super.drawBase(ctx, config);
    drawCircle(
      ctx,
      this.x + this.width - 20 * scaleX,
      this.y + 20 * scaleY,
      0,
      10
    );
  }
  drawContent(ctx) {
    super.drawContent(ctx);
    const { scaleX, scaleY } = this.getScalingFactor();
    const topSeparatorY = this.y + this.topHeight; // Y-coordinate of the top separator
    const padding = 20 * scaleX;
    let config = getConfig(
      this.x + padding,
      topSeparatorY + 25 * scaleY,
      '#000'
    );
    config.font = `${14 * scaleY}px Arial`;
    createText(ctx, 'Training', config);

    config = getConfig(this.x, topSeparatorY + 40 * scaleY, '#e0e0e0');
    config.width = this.width;
    config.lineWidth = 1;
    createLine(ctx, config);

    config.x = this.x + padding;
    config.y = topSeparatorY + 65 * scaleY;
    config.color = '#000';
    config.font = `${14 * scaleY}px Arial`;
    createText(ctx, 'Testing', config);
  }
  drawBottom(ctx) {
    const postSeparatorY = super.drawBottom(ctx);
    const { scaleX, scaleY } = this.getScalingFactor();
    // const yTopBottom = super.drawBottom(ctx);
    const padding = 20 * scaleX;
    const config = getConfig(
      this.x + padding,
      postSeparatorY + 25 * scaleY,
      '#000'
    );
    config.font = `${14 * scaleY}px Arial`;
    createText(ctx, 'External', config);
  }
}

export default SamplingBlock;
