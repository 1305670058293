import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Switch,
} from '@mui/material';
import { getPortIcon, Widget } from './utils_setting';
import { IsInvalid } from 'helpers';
class SettingPorts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      ports: [],
    };
  }
  componentDidMount() {
    const { block } = this.props;
    if (IsInvalid(block)) return;
    const ports = block.getPorts();
    this.setState({
      ports: ports.map(port => ({
        id: port.id,
        Icon: getPortIcon(port),
        name: port.name,
        color: port.color,
      })),
      checked: ports.filter(port => port.visible).map(p => p.id),
    });
  }
  handleToggle = value => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    const { block } = this.props;
    if (IsInvalid(block)) return;
    const ports = block.getPorts();

    const ports_off = ports.filter(
      port => !newChecked.some(id => port.id === id)
    );
    const ports_on = ports.filter(port =>
      newChecked.some(id => port.id === id)
    );
    if (ports_off.length) {
      block.setPortVisible(ports_off, false);
    }
    if (ports_on.length) {
      block.setPortVisible(ports_on, true);
    }
    this.props.handleSettingChanged();
    // console.log({
    //   ports_off,
    //   ports_on,
    //   checked: [...this.state.checked],
    //   newChecked,
    // });
    this.setState({ checked: newChecked });
  };
  render() {
    let { sx, view, ...res } = this.props;
    if (view !== 'Setting') return null;
    if (IsInvalid(sx)) sx = { marginTop: 1, paddingTop: 0 };
    else if (IsInvalid(sx.paddingTop)) sx.paddingTop = 0;
    const { checked, ports } = this.state;
    return (
      <Widget sx={sx} {...res}>
        <Box sx={{ width: 270 }}>
          <List
            subheader={
              <React.Fragment>
                <ListSubheader sx={{ backgroundColor: 'transparent' }}>
                  Settings
                </ListSubheader>
                <Divider />
              </React.Fragment>
            }
            sx={{ width: '100%', maxWidth: 360 }}>
            {ports.map((port, index) => {
              const { Icon, name } = port;
              const id = `switch-list-label-${port.id}`;
              return (
                <ListItem key={`port-config-${index}`}>
                  <ListItemIcon sx={{ minWidth: 35 }}>
                    <Icon sx={{ color: port.color }} />
                  </ListItemIcon>
                  <ListItemText id={id} primary={name} />
                  <Switch
                    checked={checked.includes(port.id)}
                    edge="end"
                    inputProps={{
                      'aria-labelledby': id,
                    }}
                    onChange={() => this.handleToggle(port.id)}
                  />
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Widget>
    );
  }
}

SettingPorts.propTypes = {
  classes: PropTypes.object,
};
SettingPorts.defaultProps = {
  ports: [],
  handleRender: () => '',
};
export default SettingPorts;
