import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import { styleToolbar } from 'common';
import {
  ButtonReloadDelete,
  BadgeIcon,
  ToggleButtonUpload,
  SearchTransparent,
  DialogDicomCreateRule,
} from 'components';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { GetData, IsAdministrator, ServerErrorsString } from 'helpers';
import {
  MutationUploadRulesCSV,
  MutationAddAnonymizationTags,
} from 'graphql/Anonymization';
class ToolBarAnonyRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_create: false,
      search: '',
      uploading: false,
      name: null,
    };
  }
  handleSelectFile = event => {
    const { uploading } = this.state;
    if (uploading) return;
    const { files } = event.target;
    if (!files.length) {
      return;
    }

    this.setState({ uploading: true, progress_all: 0 });
    (async () => {
      MutationUploadRulesCSV(files[0], this.handleProgress)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, rules } = data.uploadAnonymizationRules;
          if (ok) {
            this.setState({
              uploading: false,
            });
            this.props.handelAddRules(rules);
            if (errors && errors.length) {
              throw errors;
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAction = (event, action) => {
    console.log({ action });
    if (action === 'save') {
      this.mutationSaveChanges();
    } else if (action === 'create') {
      this.setState({ open_create: true });
    } else if (action === 'edit') {
      this.props.history.push('/administrator/general/anonymization/rules');
    }
  };
  mutationSaveChanges = () => {
    const { rows_edited } = this.props;
    if (rows_edited === undefined || !rows_edited || !rows_edited.length) {
      this.props.handleAddError('Nothing to save');
      return;
    }
    const { saving } = this.state;
    if (saving) return;
    const inputs = rows_edited.map(x => {
      return {
        tag: x.tag,
        tag_rule: x.tag_rule ? x.tag_rule : null,
        tag_value: x.tag_value ? x.tag_value : null,
      };
    });
    this.setState({ saving: true });
    (async () => {
      MutationAddAnonymizationTags(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.addAnonymizationTags;
          if (ok) {
            this.setState({ saving: false });
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { classes, rows_edited, disabled, user } = this.props;
    const { search } = this.state;
    const count_save = rows_edited.length;
    // console.log({ rows_edited });
    const isAdmin = IsAdministrator(user);
    let ComponentAdmin = null;
    if (isAdmin) {
      ComponentAdmin = [
        <ToggleButton aria-label="create" key="create" value="create">
          <AddIcon sx={{ color: 'blue' }} />
        </ToggleButton>,
        <ToggleButton aria-label="edit" key="edit" value="edit">
          <EditIcon sx={{ color: 'green' }} />
        </ToggleButton>,
      ];
    }
    return (
      <>
        <Paper className={classes.paper}>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="space-between"
            sx={{ minHeight: 45, marginTop: 0 }}>
            <Grid item>
              <ToggleButtonGroup
                aria-label="text alignment"
                disabled={disabled}
                exclusive
                onChange={this.handleAction}
                size="small"
                value={this.props.view}>
                <ToggleButton
                  aria-label="left aligned"
                  sx={{ paddingTop: count_save ? 0 : '7px' }}
                  value="save">
                  <BadgeIcon
                    color="blue"
                    count={count_save}
                    disabled={!count_save}
                    Icon={SaveIcon}
                    title="Save"
                  />
                </ToggleButton>
                {ComponentAdmin}
                <ToggleButtonUpload
                  color="#00b0ff"
                  handleSelectFile={this.handleSelectFile}
                  uploading={this.state.uploading}
                />
              </ToggleButtonGroup>
            </Grid>
            <Grid item>
              <SearchTransparent
                onChange={event => {
                  this.setState({ search: event.target.value });
                }}
                onKeyPress={event => {
                  if (!event) return;
                  if (event.key === 'Enter') {
                    this.props.handleReloadTable(search);
                  }
                }}
                value={search}
              />
            </Grid>
            <Grid item>
              <ButtonReloadDelete
                handleClearSelected={this.props.handleClearSelected}
                handleDeleteSelected={this.props.handleDeleteSelected}
                handleReloadTable={() => this.props.handleReloadTable(search)}
                loading={this.props.loading}
                loading_table={this.props.loading_table}
                show_delete
                tooltip="Reload Jobs"
              />
            </Grid>
          </Grid>
        </Paper>
        <DialogDicomCreateRule
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_create: false })}
          handleCreatedTagRule={rules => {
            this.setState({ open_create: false });
            this.props.handelAddRules(rules);
          }}
          open={this.state.open_create && isAdmin}
        />
      </>
    );
  }
}

ToolBarAnonyRules.propTypes = {
  classes: PropTypes.object,
  height: PropTypes.number,
};
ToolBarAnonyRules.defaultProps = {
  handelAddRules: () => '',
  handleAddError: () => '',
};
export default withRouter(withStyles(ToolBarAnonyRules, styleToolbar));
