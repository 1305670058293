import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Grid from '@mui/material/Grid';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { FormatTime, GetData, getInitials, IsInvalid } from 'helpers';
import MenuProjectActivities from './MenuProjectActivities';
import { MutationDeleteActivities } from 'graphql/Projects/utils_activities';
import { DialogDeleteWarning } from 'components';

const useStyles = theme => ({
  root: {
    height: '60.5px',
  },
  content: {
    padding: 0,
  },
  image: {
    height: 48,
    width: 48,
    margin: 0,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  expand: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(2),
    marginRight: 0,
  },
});

class ListProjectActivities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      activity: null,
      open_delete: false,
      deleting: false,
    };
  }
  handleAction = action => {
    let { activity } = this.state;
    let idProject = null;
    let open_delete = false;
    let folder_content = null;
    let idFolder = null;
    if (activity) {
      idProject = activity.Project.id;
      folder_content = activity.folder_content;
      idFolder = activity.idFolder;
    }
    if (action === 'show') {
      activity = null;
      if (folder_content) {
        this.props.history.push(`/explorer/folder/${folder_content.idFolder}`);
      } else if (idFolder) {
        this.props.history.push(`/explorer/folder/${idFolder}`);
      } else {
        this.props.history.push(`/project_management/${idProject}/activity`);
      }
    } else if (action === 'delete') {
      open_delete = true;
    }
    this.setState({ anchorEl: null, activity, open_delete });
  };
  handleDeleteSelected = () => {
    const { deleting, activity } = this.state;
    if (deleting || IsInvalid(activity)) return;
    this.setState({ deleting: true });
    (async () => {
      MutationDeleteActivities([activity.id])
        .then(res => {
          const data = GetData(res);
          if (data.DeleteActivities) {
            const { ok, errors } = data.DeleteActivities;
            if (ok) {
              this.setState({
                deleting: false,
                activity: null,
                open_delete: false,
              });
              this.props.handleDeleteSelected(activity);
            } else {
              throw errors;
            }
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({
            deleting: false,
            activity: null,
            open_delete: false,
          });
        });
    })();
  };
  render() {
    const { data, classes } = this.props;
    let emptyRows = 6 - data.length;
    if (emptyRows < 0) {
      emptyRows = 0;
    }
    return (
      <React.Fragment>
        <List>
          {data.map((activity, i) => {
            let { icon, Project, User } = activity;
            //
            let FullName = 'Unknown';
            let project_name = 'Unknown';
            if (!icon) {
              icon = '/images/activity/unknown.png';
            }
            if (User) {
              FullName = User.FullName;
            }
            if (Project) {
              project_name = Project.name;
            }
            const date = `Updated ${FormatTime(activity.updatedAt, 'FromNow')}`;
            return (
              <ListItem
                className={classes.root}
                divider={i < data.length - 1}
                key={activity.id}>
                <Tooltip title={FullName}>
                  <ListItemAvatar>
                    <img
                      alt={getInitials(FullName)}
                      className={classes.image}
                      src={`${icon}`}
                    />
                  </ListItemAvatar>
                </Tooltip>
                <ListItemText
                  primary={
                    <Grid
                      alignItems="flex-start"
                      container
                      direction="row"
                      justifyContent="space-between">
                      <div
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}>
                        {`Name: ${project_name}`}
                      </div>
                      <div
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}>
                        {activity.activity}
                      </div>
                    </Grid>
                  }
                  secondary={date}
                  style={{ padding: 0, margin: 0 }}
                />
                <IconButton
                  className={classes.button}
                  edge="end"
                  onClick={e =>
                    this.setState({ anchorEl: e.currentTarget, activity })
                  }
                  size="small">
                  <MoreVertIcon />
                </IconButton>
              </ListItem>
            );
          })}
          {emptyRows > 0 && (
            <React.Fragment>
              <Divider />
              <ListItem
                style={{
                  height: 60.5 * emptyRows,
                  border: 0,
                  width: '100%',
                  margin: 0,
                  padding: 0,
                }}
              />
            </React.Fragment>
          )}
        </List>
        <MenuProjectActivities
          activity={this.state.activity}
          anchorEl={this.state.anchorEl}
          handleAction={this.handleAction}
          handleClose={() => this.setState({ anchorEl: null })}
          user={this.props.user}
        />
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_delete: false })}
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text="You are about to delete the activity, this action cannot be undone"
          title_text="Delete Project Activity"
        />
      </React.Fragment>
    );
  }
}

ListProjectActivities.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.array,
  handleDeleteSelected: () => '',
};

export default withRouter(withStyles(ListProjectActivities, useStyles));
