import { createText, getConfig } from '../../utils_blocks';
import { getBlockConfig } from '../../utils_svg';
import { Block } from '../base';

class ArchiveBlock extends Block {
  constructor(
    x,
    y,
    self,
    width = 200,
    height = 60,
    config = getBlockConfig('Archive')
  ) {
    const contentHeight = height - 100; // 40px for top, 60px for bottom
    const bottomHeight = 60;
    super(x, y, width, height, config, contentHeight, bottomHeight, 250, 80);
    this.addPort('Archive', false); // Add port on the left side
  }
  drawContent(ctx) {
    super.drawContent(ctx);
    const { scaleX, scaleY } = this.getScalingFactor();
    const padding = 20 * scaleX;
    const topSeparatorY = this.y + this.topHeight; // Y-coordinate of the top separator
    // const bottomSeparatorY = this.y + this.height - this.bottomHeight * scaleY; // Y-coordinate of the bottom separator

    // Calculate position for title
    const titleSeparatorY = topSeparatorY + this.topHeight;
    const titleY = titleSeparatorY - 17 * scaleY; // Moved down 5 pixels from previous position

    // Draw  title
    const config = getConfig(this.x + padding, titleY, '#000');
    config.font = `${14 * scaleY}px Arial`;
    createText(ctx, 'Location: Folder', config);
  }
}
export default ArchiveBlock;
