import {
  AIModelBlock,
  DatasetBlock,
  ArchiveBlock,
  ImageAlgorithmBlock,
  LogicBlock,
  ReviewBlock,
  CompleteBlock,
  ConsensusBlock,
  Line,
  AnnotateBlock,
  SamplingBlock,
  TrainAIModelBlock,
} from './blocks';
import { getBlockItems, getIndexAtBlock } from './utils_svg';
const classes = [
  { id: 'ai_model', BlockClass: AIModelBlock },
  { id: 'dataset', BlockClass: DatasetBlock },
  { id: 'archive', BlockClass: ArchiveBlock },
  { id: 'image_algorith', BlockClass: ImageAlgorithmBlock },
  { id: 'logic', BlockClass: LogicBlock },
  { id: 'review', BlockClass: ReviewBlock },
  { id: 'completed', BlockClass: CompleteBlock },
  { id: 'consensus', BlockClass: ConsensusBlock },
  { id: 'annotate', BlockClass: AnnotateBlock },
  { id: 'sampling', BlockClass: SamplingBlock },
  { id: 'train_ai_model', BlockClass: TrainAIModelBlock },
];
export const getBlockClass = block_name => {
  const ListBlocks = getBlockItems();
  let index = getIndexAtBlock(block_name);
  if (index === -1) return null;
  index = classes.map(x => x.id).findIndex(id => id === ListBlocks[index].id);
  if (index === -1) return null;
  return classes[index].BlockClass;
};
export const createBlock = (x, y, block_name, self) => {
  if (block_name === '') return;
  console.log('createBlock', block_name);
  const BlockClass = getBlockClass(block_name);
  if (BlockClass) {
    return new BlockClass(x, y, self);
  }
  return null;
};
export const createBlockFromSQL = (inBlock, self) => {
  const { x, y, title, width, height, color, inPorts, outPorts } = inBlock;
  const BlockClass = getBlockClass(title);
  if (BlockClass) {
    const block = new BlockClass(x, y, self);
    block.width = width;
    block.height = height;
    block.color = color;
    block.title = title;
    block.id = inBlock.id;
    let list_out_lines = [];
    for (let i = 0; i < inPorts.length; i++) {
      const port = inPorts[i];
      const id = `${port.x}-${port.y}`;
      // console.log({ port });
      const j = block.inPorts.findIndex(p => `${p.x}-${p.y}` === id);
      if (j !== -1) {
        block.inPorts[j].id = port.id ? port.id : `${port.x}-${port.y}`;
        block.inPorts[j].name = port.name;
        block.inPorts[j].color = port.color;
        block.inPorts[j].visible = port.visible;
      } else {
        console.error(`port not found: ${id}`);
      }
    }
    for (let i = 0; i < outPorts.length; i++) {
      const port = outPorts[i];
      const id = `${port.x}-${port.y}`;
      // console.log({ port });
      const j = block.outPorts.findIndex(p => `${p.x}-${p.y}` === id);
      if (j !== -1) {
        block.outPorts[j].id = port.id ? port.id : `${port.x}-${port.y}`;
        block.outPorts[j].name = port.name;
        block.outPorts[j].color = port.color;
        block.outPorts[j].visible = port.visible;
        if (port.out_lines.length) {
          list_out_lines = list_out_lines.concat(port.out_lines);
        }
      } else {
        console.error(`port not found: ${id}`);
      }
    }
    // console.log({ id: block.id, title: block.title, index });
    return { block, list_out_lines };
  }
  return { block: null, list_out_lines: [] };
};

export const getIcon = async svgIcon => {
  return new Promise((resolve, reject) => {
    try {
      //  const svgIcon = new XMLSerializer().serializeToString(
      //    Icon({ fontSize: 'large' }).props.children
      //  );
      const svgBlob = new Blob([svgIcon], { type: 'image/svg+xml' });
      const url = URL.createObjectURL(svgBlob);

      const img = new Image();
      img.onload = () => {
        URL.revokeObjectURL(url);
        resolve(img);
      };
      img.onerror = () => {
        reject('Failed to load the SVG image.');
        URL.revokeObjectURL(url);
      };
      img.src = url;
    } catch (error) {
      reject(error);
    }
  });
};

export const createBlockLines = (blocks, ListOutLines) => {
  // console.log({ blocks, ListOutLines });
  ListOutLines.forEach(line => {
    const iSB = blocks.findIndex(b => b.id === line.idStartBlock);
    let iSP = -1;
    if (iSB !== -1) {
      iSP = blocks[iSB].outPorts.findIndex(p => p.id === line.idStartPort);
    }
    const iEB = blocks.findIndex(b => b.id === line.idEndBlock);
    let iEP = -1;
    if (iEB !== -1) {
      iEP = blocks[iEB].inPorts.findIndex(p => p.id === line.idEndPort);
    }
    if (iSP !== -1 && iEP !== -1) {
      const line = new Line(blocks[iSB], blocks[iEB], iSP, iEP);
      line.color = blocks[iSB].outPorts[iSP].color;
    } else {
      console.log('error creating line', { iSB, iEB, iSP, iEP });
    }
  });
};
