import React from 'react';
import { Paper, TablePagination } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import { columns } from './Styles';
import useStyles from '../components/Styles';

import { MainTable, MainTableToolbar } from '../components';
import { MutationDeleteActivities } from 'graphql/Projects/utils_activities';
import { SelectedArray, GetData, rowsPerPageBig } from 'helpers';

class TableProjectActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 10,
      selected: [],
      deleting: false,
    };
  }

  handleDeleteSelected = event => {
    event.preventDefault();
    const { selected, deleting } = this.state;
    if (deleting) return;
    this.setState({ deleting: true });
    (async () => {
      MutationDeleteActivities(selected.map(x => x.id))
        .then(res => {
          const data = GetData(res);
          if (data.DeleteActivities) {
            const { ok, errors } = data.DeleteActivities;
            if (ok) {
              this.setState({ deleting: false, selected: [] });
              if (this.props.handleDeleteSelected) {
                this.props.handleDeleteSelected(selected);
              }
            } else {
              throw errors;
            }
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ deleting: false });
        });
    })();
  };
  handleSelectedClick = (event, index, row) => {
    event.preventDefault();
    //
    const { selected } = this.state;
    const selectedIndex = selected
      ? selected.map(x => x.id).indexOf(row.id)
      : 0;
    const newSelected = SelectedArray(selected, row, selectedIndex);
    this.setState({ selected: newSelected });
    return newSelected.length;
  };
  isSelected = row => {
    const { selected } = this.state;
    const i = selected.map(x => x.id).indexOf(row.id);
    return i === -1 ? false : true;
  };
  handleSelectedRow = () => {};
  handleAcceptReject = () => {};
  handleButtonView = () => {};
  handleSelectAllClick = event => {
    if (event.target.checked) {
      const { rows } = this.props;
      this.setState({ selected: rows });
    } else {
      this.setState({ selected: [] });
    }
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setState({ page: newPage });
    this.props.handleChangeRowsPerPage(newPage, this.state.limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.setState({ page: 0, limit: +event.target.value });
    this.props.handleChangeRowsPerPage(0, +event.target.value);
  };
  handleClearSelected = () => {
    this.setState({ selected: [] });
  };

  render() {
    const { classes, rows, title, loading, height } = this.props;
    const { selected, page, limit, deleting } = this.state;
    let style = null;
    if (height) {
      style = { height, maxHeight: height };
    }
    return (
      <Paper className={classes.root}>
        <MainTableToolbar
          handleClearSelected={this.handleClearSelected}
          handleDeleteSelected={this.handleDeleteSelected}
          handleReloadTable={this.props.handleReloadTable}
          loading={deleting}
          loading_table={loading}
          numSelected={selected.length}
          title={title}
        />
        <div className={classes.tableWrapper} style={style}>
          <MainTable
            columns={columns}
            disable_checkbox={this.props.disable_checkbox}
            disable_splice
            handleAcceptReject={this.handleAcceptReject}
            handleButtonView={this.handleButtonView}
            handleSelectAllClick={this.handleSelectAllClick}
            handleSelectedClick={this.handleSelectedClick}
            handleSelectedRow={this.handleSelectedRow}
            isSelected={this.isSelected}
            limit={limit}
            loading={loading}
            page={page}
            rows={rows}
            selected={selected}
          />
        </div>
        <TablePagination
          component="div"
          count={this.props.total}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </Paper>
    );
  }
}

TableProjectActivity.defaultProps = {
  loading: false,
  rows: [],
  disable_checkbox: false,
  handleChangePageLimit: () => console.log('handleChangePageLimit'),
};
TableProjectActivity.propTypes = {
  rows: PropTypes.array,
};
export default withStyles(TableProjectActivity, useStyles);
