import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';

class GridTwoLevelTypo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { title, subtitle, propsTitle, propsSubTitle, xs = 8 } = this.props;
    return (
      <Grid
        container
        direction="column"
        item
        sx={{
          paddingLeft: 0.5,
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
        xs={xs}>
        <Grid item xs={12}>
          <Typography
            component="p"
            sx={{
              fontWeight: 600,
              margin: 0,
              fontFamily: '"__Inter_5475bd","__Inter_Fallback_5475bd"',
              fontSize: '0.9375rem',
              lineHeight: 1.46667,
              color: 'var(--mui-palette-text-primary)',
              '--mui-palette-text-primary':
                'rgb(var(--mui-mainColorChannels-light) / 0.9)',
              '--mui-mainColorChannels-light': '46 38 61',
              ...propsTitle,
            }}
            variant="body1">
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            component="p"
            sx={{
              // position: 'absolute',
              fontWeight: 400,
              margin: 0,
              fontFamily: '"__Inter_5475bd","__Inter_Fallback_5475bd"',
              fontSize: '0.8125rem',
              lineHeight: 1.53846154,
              color: 'var(--mui-palette-text-secondary)',
              '--mui-palette-text-secondary':
                'rgb(var(--mui-mainColorChannels-light) / 0.7)',
              '--mui-mainColorChannels-light': '46 38 61',
              ...propsSubTitle,
            }}
            variant="body2">
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

GridTwoLevelTypo.propTypes = {
  propsSubTitle: PropTypes.object,
  propsTitle: PropTypes.object,
};

export default GridTwoLevelTypo;
