import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
// import FormatBoldIcon from '@mui/icons-material/FormatBold';
// import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Button, ButtonGroup } from '@mui/material';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]: {
    marginLeft: -1,
    borderLeft: '1px solid transparent',
  },
}));
const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  border: '0px solid white',
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]: {
    marginLeft: -1,
    borderLeft: '1px solid transparent',
  },
}));
export default function CustomizedDividers({ handleAlignment }) {
  const [zoom, setZoom] = React.useState('');
  const [alignment, setAlignment] = React.useState('');
  const [formats, setFormats] = React.useState([]);

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };

  const handleInteralAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
    handleAlignment('aligment', newAlignment);
  };
  const handleZoom = newZoom => {
    setZoom(newZoom);
    handleAlignment('zoom', newZoom);
  };
  const height = 32;
  return (
    <div style={{ paddingLeft: 10, marginTop: '-2px', display: 'flex' }}>
      <StyledButtonGroup
        exclusive="true"
        size="small"
        sx={{ paddingRight: 1 }}
        value={zoom}>
        <Button
          aria-label="zoom_in"
          onClick={() => handleZoom('zoom_in')}
          value="zoom_in">
          <ZoomInIcon />
        </Button>
        <Button
          aria-label="zoom_out"
          onClick={() => handleZoom('zoom_out')}
          value="zoom_out">
          <ZoomOutIcon />
        </Button>
      </StyledButtonGroup>
      <Paper
        elevation={0}
        sx={theme => ({
          display: 'flex',
          border: `1px solid ${theme.palette.divider}`,
          flexWrap: 'wrap',
          height,
        })}>
        <StyledToggleButtonGroup
          aria-label="text alignment"
          exclusive
          onChange={handleInteralAlignment}
          size="small"
          sx={{ height }}
          value={alignment}>
          <ToggleButton aria-label="left aligned" value="left">
            <FormatAlignLeftIcon size="small" />
          </ToggleButton>
          <ToggleButton aria-label="centered" value="center">
            <FormatAlignCenterIcon />
          </ToggleButton>
          <ToggleButton aria-label="right aligned" value="right">
            <FormatAlignRightIcon />
          </ToggleButton>
          <ToggleButton aria-label="justified" value="justify">
            <FormatAlignJustifyIcon />
          </ToggleButton>
        </StyledToggleButtonGroup>
        <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
        <StyledToggleButtonGroup
          aria-label="text formatting"
          onChange={handleFormat}
          size="small"
          sx={{ height }}
          value={formats}>
          <ToggleButton aria-label="underlined" value="underlined">
            <FormatUnderlinedIcon />
          </ToggleButton>
          <ToggleButton aria-label="color" disabled value="color">
            <FormatColorFillIcon />
            <ArrowDropDownIcon />
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Paper>
    </div>
  );
}
