import moment from 'moment';
import { IsInvalid } from 'helpers';
import {
  cases,
} from './utils_columns';
import { header_patients } from '../../PatientClinics/components';

const base = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'idPatient',
    headerName: 'PatientID',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue(params.id, 'patient');
      if (IsInvalid(value)) return '';
      return value.PatientID;
    },
    editerGetter: (row, value) => {
      row = {
        ...row,
        patient: {
          PatientID: value,
        },
      };
      return row;
    },
  },
];

export const getHeader = (index = 0) => {
  let headers = [];
  if (index === 1) headers = base.concat(cases);
  else headers = header_patients;
  return headers.map((x, index) => {
    if (index === 0) return x;
    return {
      ...x,
    };
  });
};
export const getEmptyRow = index => {
  let headers = [...base];
  if (index === 1) headers = headers.concat(cases);
  else headers = header_patients;
  let row = {};
  for (let i = 0; i < headers.length; i++) {
    const key = headers[i].field;
    let value = '';
    if (
      headers[i].type === 'date' ||
      key === 'createdAt' ||
      key === 'updatedAt'
    ) {
      value = `${moment().valueOf()}`;
    }
    if (headers[i].type === 'boolean') {
      value = 0;
    }
    row = {
      ...row,
      [key]: value,
      edit: true,
    };
    if (headers[i].editerGetter) {
      row = headers[i].editerGetter(row, '');
    }
  }
  return row;
};
