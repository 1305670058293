import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@mui/material';
import { SliderSelection } from '../components';
import AssignmentProgress from './AssignmentProgress';

class ConfigSampling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      position: 90,
    };
  }

  render() {
    if (this.props.view !== 'Configuration') return null;
    const { position } = this.state;
    // const duration = 200; // seconds
    return (
      <>
        <Card sx={{ height: '20%', margin: 1, marginTop: 0 }}>
          <CardContent>
            <SliderSelection
              position={position}
              setState={state => this.setState(state)}
              title="Training/Testing distribution:"
            />
          </CardContent>
        </Card>
        <AssignmentProgress {...this.props} />
      </>
    );
  }
}

ConfigSampling.propTypes = {
  classes: PropTypes.object,
};

export default ConfigSampling;
