import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { DialogQuestion, TableGrid } from 'components';
import {
  QueryFileObjectTypeMap,
  MutationDeleteFileMapTypes,
} from 'graphql/Mapping';
import EditFileVariableMap from '../EditFileVariableMap';
import { GetData, ServerErrorsString } from 'helpers';
import { rowsPerPageBig } from 'helpers';
import { columns } from './Style';

class TabFileVariableMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_delete: false,
      open_create: false,
      open_edit: false,
      loading: false,
      page: 0,
      limit: 50,
      selected: [],
      mappings: [],
      name: '',
      init: false,
    };
    const { holder } = props;
    if (holder) {
      holder.setCleanMap = () => this.setState({ selected: [] });
      holder.deleteMaps = () => this.setState({ open_delete: true });
    }
  }
  componentDidMount() {
    if (this.props.isActive) this.handleUpdatedMapping();
  }
  componentDidUpdate() {
    const { init } = this.state;
    if (this.props.isActive && !init) this.handleUpdatedMapping();
  }
  handleUpdatedMapping = (newPage = null, newLimit = null) => {
    let { loading, page, limit, name } = this.state;
    if (loading) {
      return;
    }
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    this.setState({ loading: true });
    (async () => {
      QueryFileObjectTypeMap(name, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, mappings, total, errors } = data.fileObjectTypeMaps;
          if (ok) {
            this.setState({
              mappings,
              total,
              loading: false,
              page,
              limit,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log('catch-UsersProjects', error);
          this.setState({ types: [], loading: false, page, limit, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  runDeleteSelected = () => {
    const { deleting, selected, mappings } = this.state;
    if (deleting || !selected.length) return;
    this.setState({ deleting: true, open_delete: false });
    const ids = selected.map(x => x.id);
    (async () => {
      MutationDeleteFileMapTypes(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteFileObjectTypes;
          if (ok) {
            this.setState({
              deleting: false,
              selected: [],
              mappings: mappings.filter(x => ids.indexOf(x.id) === -1),
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log('catch-MutationDeleteFileMapTypes', error);
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.handleUpdatedMapping(newPage, this.state.limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.handleUpdatedMapping(0, +event.target.value);
  };
  handleSelect = selected => {
    this.setState({ selected });
    this.props.setState({ selected });
  };
  render() {
    if (!this.props.isActive) return null;
    let { height } = this.props;
    if (this.state.open_edit) {
      return (
        <EditFileVariableMap
          handleAddError={this.props.handleAddError}
          handleCloseEdit={() => this.setState({ open_edit: false })}
          handleUpdatedMapping={this.handleUpdatedMapping}
          mappings={this.state.selected}
        />
      );
    }
    return (
      <div style={{ padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableGrid
              checkboxSelection
              dense
              disableSelectionOnClick
              external_selected={this.state.selected}
              headers={columns}
              height={height - 40}
              loading={this.state.loading}
              onPageChange={page =>
                this.handleUpdatedMapping(page, this.state.limit)
              }
              onRowsPerPageChange={limit => this.handleUpdatedMapping(0, limit)}
              onSelectedChanged={selected => this.handleSelect(selected)}
              page={this.state.page}
              paginationMode="server"
              rows={this.state.mappings}
              rowsPerPage={this.state.limit}
              rowsPerPageOptions={rowsPerPageBig}
            />
          </Grid>
        </Grid>
        <DialogQuestion
          button_name="Delete"
          handleAction={this.runDeleteSelected}
          handleClose={() => this.setState({ open_delete: false })}
          message="This action cannot be undone. Do you want to continue?"
          open={this.state.open_delete}
          title="Delete Selected Mappings"
        />
      </div>
    );
  }
}

TabFileVariableMap.propTypes = {
  classes: PropTypes.object,
};
TabFileVariableMap.defaultProps = {
  handleAddError: () => '',
};

export default TabFileVariableMap;
