import { short_patients } from '../../PatientClinics/components';

const parseIntegerValue = (row, value, field) => {
  let parsedValue = parseInt(value);
  if (!isNaN(parsedValue)) {
    row[field] = parsedValue;
  } else {
    row[field] = 0;
  }
  return row;
};

export const cases = [
  { field: 'case_id', headerName: 'Idcase', width: 100, align: 'left' },
  {
    field: 'case_start_date',
    headerName: 'Start Date',
    width: 157,
    align: 'left',
    type: 'date',
    edit: true,
  },
  {
    field: 'case_types',
    headerName: 'Event Type',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'case_service_area',
    headerName: 'Service Area',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'case_start_type',
    headerName: 'Onset Time',
    width: 222,
    align: 'left',
    edit: true,
  },
  {
    field: 'case_creation_reason',
    headerName: 'Creation Reason',
    width: 75,
    align: 'left',
  },
  {
    field: 'case_illness_grade',
    headerName: 'Illness Grade',
    width: 75,
    align: 'left',
    editerGetter: (row, value) => {
      return parseIntegerValue(row, value, 'case_illness_grade');
    },
  },
  {
    field: 'is_emergency_case',
    headerName: 'Emergency Case',
    width: 75,
    align: 'left',
    edit: true,
    type: 'boolean',
  },
  

];

export const getColumnList = () => {
  let columns = null;

  const new_patients = short_patients.map(x => {
    return { key: 'pat_' + x.field, value: x.headerName, parent: 'Patient' };
  });
  const new_cases = cases.map(x => {
    return { key: 'case_' + x.field, value: x.headerName, parent: 'Case' };
  });
 

  columns = new_patients.concat(
    new_cases,
  );
  columns = columns.filter(item => item.value !== 'Idcase');
  return columns;
};
