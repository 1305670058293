import { FormatTime, IsInvalid, IsInvalidParam } from 'helpers';

export const columns_logs_error = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'user',
    headerName: 'User',
    align: 'left',
    width: 200,
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'user');
      if (IsInvalid(value)) return '';
      return value.FullName;
    },
  },
  { field: 'count', headerName: '#Counts', width: 75, align: 'left' },
  { field: 'error', headerName: 'Errors', align: 'left' },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    align: 'left',
    width: 200,
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'updatedAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 200,
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
