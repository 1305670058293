import React from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import {
  CircularProgress,
  Paper,
  TextField,
  Autocomplete,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { QueryUnits } from 'graphql/Units';

import { useMakeStyles } from '../Styles';
import { GetData } from 'helpers';
import { inputProps } from 'common';

const SearchSitesInputs = props => {
  const { style } = props;

  const { classes } = useMakeStyles();

  const [name, setSiteName] = React.useState('');
  const [site, setSite] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    if (!loading) {
      return undefined;
    }

    (async () => {
      QueryUnits(name)
        .then(res => {
          const data = GetData(res);
          const {
            units: { ok, errors, units },
          } = data;

          if (ok) {
            if (units.length === 0) {
              units.push({ name: 'Empty' });
            }
          } else {
            throw errors;
          }
          setOptions(units);
        })
        .catch(error => {
          console.log({ error });
          setOptions([]);
        });
    })();
  }, [loading, name]);
  const handelSiteNameChanged = event => {
    setSiteName(event.target.value);
  };
  const height = '25px';
  return (
    <Paper className={classes.root} style={style}>
      <SearchIcon className={classes.icon} />
      <Autocomplete
        getOptionLabel={option => {
          if (option) return option.name;
          return '';
        }}
        id="search-sites-input"
        inputValue={name}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        loading={loading}
        onChange={(event, newValue) => {
          setSite(newValue);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onInputChange={(event, newInputValue) => {
          setSiteName(newInputValue);
        }}
        onOpen={() => {
          setOptions([]);
          setOpen(true);
        }}
        open={open}
        options={options}
        renderInput={params => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink: true,
              style: {
                borderWidth: 0,
                border: 0,
              },
            }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: {
                height,
                margin: 0,
                padding: 0,
                borderWidth: 0,
                border: 0,
              },
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </React.Fragment>
              ),
              ...inputProps,
            }}
            name="site"
            onChange={handelSiteNameChanged}
            onKeyDown={event => {
              const { key } = event;
              if (key === 'Enter') {
                props.handleAddUnit(site);
                setSiteName('');
              }
            }}
            placeholder={props.placeholder}
            style={{
              marginLeft: '6px',
              margin: 0,
              height,
              borderWidth: 0,
              border: 0,
            }}
            variant="standard"
            // value={name}
          />
        )}
        style={{ width: '100%', height, borderWidth: 0, border: 0 }}
      />
    </Paper>
  );
};

SearchSitesInputs.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  style: PropTypes.object,
};

SearchSitesInputs.defaultProps = {
  placeholder: 'Search',
};

export default SearchSitesInputs;
