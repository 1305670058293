import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { SearchImageAlgorithms } from 'components';
import ReviewStatistic from './ReviewStatistic';
class ConfigImageAlgoth extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: 'test', label: 'another', algorithm: null };
  }
  handleChange = event => {
    console.log({ t: event.target.value });
    this.setState({ value: event.target.value });
  };
  handleSelect = selected => {
    console.log({ selected });
    this.setState({ selected });
  };
  render() {
    if (this.props.view !== 'Configuration') return null;
    const { algorithm } = this.state;
    return (
      <>
        <Card sx={{ height: '100%', margin: 1, marginTop: 0 }}>
          <CardContent>
            <Grid
              container
              direction="row"
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <Grid item>
                <ImageIcon />
              </Grid>
              <Grid item sx={{ paddingLeft: 1 }}>
                <Typography
                  component="h2"
                  gutterBottom
                  sx={{ fontWeight: '600' }}
                  variant="subtitle2">
                  Select the algorithm
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid container pt={2}>
              <Grid item xs={12}>
                <SearchImageAlgorithms
                  external_selected={algorithm}
                  handleAddSelected={this.handleSelect}
                  handleSetSelected={this.handleSelect}
                  no_icon
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <ReviewStatistic {...this.props} />
      </>
    );
  }
}

ConfigImageAlgoth.propTypes = {
  classes: PropTypes.object,
};

export default ConfigImageAlgoth;
