/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/no-static-element-interactions */
const AddBlockItemContainer = ({
  selected = false,
  item,
  index,
  last,
  children,
  onDragEnd = () => '',
  onDragStart = () => '',
  left = null,
  top = null,
  ...props
}) => {
  const style = {};
  if (left && top) {
    style.position = 'absolute';
    style.left = left;
    style.top = top;
    style.width = 268;
    style.zIndex = 1000;
    style.marginTop = 0;
  }
  return (
    <div
      {...props}
      className={`bg-gray-200 rounded-lg p-4 text-left border-2 flex items-center ${
        selected ? 'border-gray-400 opacity-50' : 'border-transparent'
      }`}
      draggable
      id={item.id}
      key={`other-${item.id}`}
      onDragEnd={onDragEnd}
      onDragStart={e => onDragStart(e, item)}
      style={{
        borderColor: selected ? '#ccc' : 'transparent',
        backgroundColor: selected ? 'transparent' : '#f2f2f2',
        borderRadius: '8px',
        padding: '8px',
        paddingLeft: '15px',
        marginTop: index !== 0 ? 10 : 0,
        minHeight: 38,
        paddingBottom: last ? 0 : '8px',
        ...style,
      }}>
      {children ? children : <div style={{ height: 24 }} />}
    </div>
  );
};
export default AddBlockItemContainer;
