import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { ButtonLoad, Transition } from 'components';
import { IsValid } from 'helpers';
import { IsInvalid } from 'helpers';

const useStyles = makeStyles()(theme => ({
  title: {
    margin: 0,
    padding: 0,
    // marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(1.5),
    textTransform: 'none',
    fontWeight: 450,
  },
  middle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  bottom: {
    padding: 0,
    paddingTop: -2,
    margin: 0,
    marginBottom: theme.spacing(-3),
  },
  top_content: {
    minHeight: 180,
    '&.MuiDialogContent-root': {
      paddingBottom: 0,
    },
  },
  dialog_content: {
    padding: 0,
    margin: 0,
    '&.MuiCardContent-root': {
      padding: 0,
    },
  },
}));
const DialogDeleteWarning = ({ text_check, ...props }) => {
  const { classes } = useStyles();
  const {
    shared,
    open = false,
    loading = false,
    selected = [],
    title_text = null,
    question_text = null,
    button_text = 'Delete',
    button_cancel = 'Cancel',
    text_question = 'Do you want to continue?',
    handleButtonClicked = () => '',
    hide_bold_message = false,
    hide_cancel_button = false,
  } = props;
  if (IsInvalid(open)) return null;
  const [delete_series, setDeleteSeries] = React.useState(false);
  const count_folder = selected.filter(
    x => IsValid(x.idFolder) && IsInvalid(x.File)
  ).length;
  const list_objects = selected.filter(x => IsValid(x.idFolderContent));
  const count_object = list_objects.length;
  const count_files = list_objects.filter(x => IsValid(x.File)).length;
  const count_series = list_objects.filter(x => IsValid(x.Series)).length;
  // console.log({ selected, count_files, count_series, count_folder });
  const handleRemoveOrDelete = event => {
    if (!event) {
      return;
    }
    const { shared } = props;
    const { textContent } = event.target;
    if (textContent === 'Delete' || textContent === 'Remove') {
      props.handleDeleteFolders(shared, true, delete_series);
    } else if (textContent !== '') {
      handleButtonClicked();
    } else {
      props.handleClose();
    }
  };
  let type = 'folder and series';
  let content = 'SubFolder or/and Files';
  if (count_folder && !count_object) {
    type = `folder${count_folder > 1 ? 's' : ''}`;
    content = 'SubFolder and Files';
  } else if (!count_folder && count_object) {
    content = 'Files';
    if (count_series && count_files) {
      type = 'series and files';
      content = 'Series/Files';
    } else if (count_series && !count_files) {
      type = 'series';
      content = 'Series';
    } else {
      type = `file${count_object > 1 ? 's' : ''}`;
    }
  } else if (count_folder && count_object) {
    if (count_series && count_files) {
      type = 'folder, series and files';
    } else if (!count_series && count_files) {
      type = `file${count_files > 1 ? 's' : ''} and `;
      type += `folder${count_folder > 1 ? 's' : ''}`;
    }
  }
  const text = shared ? 'remove' : 'deleted';
  let question = `Are you sure you want to ${text} this ${type}?`;
  if (selected.length > 1) {
    question = `Are you sure you want to ${text} these ${type}?`;
  }
  if (title_text) {
    question = props.title_text;
  }
  let message = `This action will ${text} the selected ${type}, and its
  content (${content}). This action can not be undone.`;
  if (question_text) {
    message = props.question_text;
  }
  const handleChangeCheck = event => {
    setDeleteSeries(event.target.checked);
  };
  let MessageBold = null;
  if (!hide_bold_message) {
    MessageBold = (
      <Typography className={classes.bottom} component="h2" variant="h6">
        {text_question}
      </Typography>
    );
  }
  let ComponentCancelButton = null;
  if (!hide_cancel_button) {
    ComponentCancelButton = (
      <Button autoFocus onClick={props.handleClose}>
        {button_cancel}
      </Button>
    );
  }
  // console.log({ count_series });
  let ComponentCheckBox = null;
  if (count_series || text_check) {
    if (IsInvalid(text_check))
      text_check = `Delete ${count_series} series permanently`;
    ComponentCheckBox = (
      <Grid item mt={1} xs={12}>
        <FormControlLabel
          control={
            <Checkbox checked={delete_series} onChange={handleChangeCheck} />
          }
          label={text_check}
        />
      </Grid>
    );
  }
  return (
    <div>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={props.handleClose}
        open={open}
        TransitionComponent={Transition}>
        <DialogContent className={classes.top_content}>
          <CardContent className={classes.dialog_content}>
            <Typography className={classes.title} variant="h5">
              {question}
            </Typography>
            <Divider style={{ marginLeft: -3 }} variant="middle" />
            <Typography className={classes.middle} color="textSecondary">
              {message}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {MessageBold}
              </Grid>
              {ComponentCheckBox}
            </Grid>
          </CardContent>
        </DialogContent>
        <DialogActions>
          {ComponentCancelButton}
          <ButtonLoad
            color="primary"
            handleClick={handleRemoveOrDelete}
            loading={loading}
            name={shared ? 'Remove' : button_text}
            transform
            variant="text"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};
DialogDeleteWarning.propTypes = {
  button_text: PropTypes.string,
  hide_bold_message: PropTypes.bool,
  hide_cancel_button: PropTypes.bool,
  loading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  question_text: PropTypes.string,
  selected: PropTypes.array,
  text_check: PropTypes.string,
  title_text: PropTypes.string,
};
// DialogDeleteWarning.defaultProps = {
//   open: false,
//   loading: false,
//   selected: [],
//   title_text: null,
//   text_check: undefined,
//   question_text: null,
//   button_text: 'Delete',
//   button_cancel: 'Cancel',
//   text_question: 'Do you want to continue?',
//   handleButtonClicked: () => '',
//   hide_bold_message: false,
//   hide_cancel_button: false,
// };
export default DialogDeleteWarning;
