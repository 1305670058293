/* eslint-disable indent */
import * as React from 'react';
import { styled } from '@mui/system';
const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Textarea = styled('textarea')(
  ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === 'dark' ? grey[900] : grey[50]
    };

    // &:hover {
    //   border-color: ${blue[400]};
    // }

    &:focus {
      outline: 0;
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === 'dark' ? blue[600] : blue[200]
      };
          & + legend {
      border-color: ${blue[400]};
    }
    }
  
  &:disabled {
    opacity: 0.5;
    resize: none !important;
    // cursor: not-allowed;
    // background: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
  }
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);
const TextareaWrapper = styled('fieldset')(
  () => `
  position: relative;
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
`
);

const Label = styled('legend')(
  ({ theme }) => `
  position: absolute;
  top: -10px;
  left: 10px;
  padding: 0 5px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 32px);
  transform: translate(14px, 2px) scale(0.75);
  z-index: 1;
  pointer-events: auto;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  &:disabled {
    opacity: 0.5;
  }
`
);
const TextArea = ({ label, disabled, ...props }) => {
  if (disabled === undefined) disabled = false;
  return (
    <TextareaWrapper>
      <Textarea disabled={disabled} {...props} />
      {label && <Label disabled={disabled}>{label}</Label>}
    </TextareaWrapper>
  );
};
export default TextArea;
