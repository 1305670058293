let deltaMove = 5;
let minDistance = Infinity;
let distanceBetweenPorts = Infinity;
let print_on = false;
function console_log(...arg) {
  if (print_on) console.log(...arg);
}
// Function to calculate the parallel distance between two points
function parallelDistance(x1, y1, x2) {
  return Math.abs(x2 - x1);
}
// Function to create a path for two points
function pathForTwoPoints(portA, portB, full = true) {
  const halfWidth = Math.abs((portA.x - portB.x) / 2);
  let halfHeight = Math.abs(portA.y - portB.y);
  if (portA.y >= portB.y) {
    halfHeight = -halfHeight;
  }
  if (full) {
    return [
      [portA.x, portA.y],
      [portB.x + halfWidth, portA.y],
      [portB.x + halfWidth, portA.y + halfHeight],
      [portB.x, portB.y],
    ];
  } else {
    return [
      [{ x: portA.x, y: portA.y }],
      [{ x: portB.x + halfWidth, y: portA.y }],
      [{ x: portB.x + halfWidth, y: portA.y + halfHeight }],
      [{ x: portB.x, y: portB.y }],
    ];
  }
}
// Function to create a port segment
function createPortSegment(block, startPort, endPort) {
  const {
    x: blockX,
    y: blockY,
    width: blockWidth,
    height: blockHeight,
  } = block;

  distanceBetweenPorts = parallelDistance(
    startPort.x,
    startPort.y,
    endPort.x,
    endPort.y
  );
  let radius = 7;
  // Calculate the dynamic constant
  let dynamicConstant = Math.min(blockWidth, blockHeight) * 0.2; // 20% of the smaller dimension
  if (dynamicConstant < radius) dynamicConstant = radius;
  console_log('distanceBetweenPorts', distanceBetweenPorts);
  let cappedConstant;
  if (distanceBetweenPorts < 82) {
    cappedConstant = Math.max(7, distanceBetweenPorts * 0.1); // Minimum of 3, or 10% of the distance
  } else {
    cappedConstant = Math.min(dynamicConstant, 40); // Cap at 40 pixels for distances >= 82
  }
  console_log('before - cappedConstant', cappedConstant);
  cappedConstant = Math.ceil(cappedConstant);
  console_log('cappedConstant', cappedConstant);
  console_log('dynamicConstant', dynamicConstant);

  const { x: portX, y: portY } = startPort;

  // Determine which side of the block the port is on
  let side;
  if (portX === blockX) side = 'left';
  else if (portX === blockX + blockWidth) side = 'right';
  else if (portY === blockY) side = 'top';
  else if (portY === blockY + blockHeight) side = 'bottom';
  else throw new Error('Port must be on the edge of the block');

  // Calculate the endpoint of the segment
  let endX, endY;
  switch (side) {
    case 'left':
      endX = blockX - cappedConstant;
      endY = portY;
      break;
    case 'right':
      endX = blockX + blockWidth + cappedConstant;
      endY = portY;
      break;
    case 'top':
      endX = portX;
      endY = blockY - cappedConstant;
      break;
    case 'bottom':
      endX = portX;
      endY = blockY + blockHeight + cappedConstant;
      break;
  }

  // Return the segment coordinates
  return {
    start: { x: portX, y: portY },
    end: { x: endX, y: endY },
  };
}
// Function to check if two blocks are touching or crossing
function areBlocksTouchingOrCrossing(blockA, blockB) {
  return !(
    blockA.x + blockA.width < blockB.x ||
    blockA.x > blockB.x + blockB.width ||
    blockA.y + blockA.height < blockB.y ||
    blockA.y > blockB.y + blockB.height
  );
}
// Function to find the intersection point between two lines (infinite)
function getIntersectionPoint(p1, p2, p3, p4) {
  const a1 = p2.y - p1.y;
  const b1 = p1.x - p2.x;
  const c1 = a1 * p1.x + b1 * p1.y;

  const a2 = p4.y - p3.y;
  const b2 = p3.x - p4.x;
  const c2 = a2 * p3.x + b2 * p3.y;

  const det = a1 * b2 - a2 * b1;

  if (det === 0) {
    return null; // Lines are parallel, no intersection
  } else {
    const x = (b2 * c1 - b1 * c2) / det;
    const y = (a1 * c2 - a2 * c1) / det;
    return { x, y };
  }
}
// Function to check if two segments intersect
function doSegmentsIntersect(p1, p2, p3, p4) {
  const intersection = getIntersectionPoint(p1, p2, p3, p4);
  if (!intersection) return false;

  // Check if the intersection point lies on both segments
  return (
    isPointOnSegment(p1, intersection, p2) &&
    isPointOnSegment(p3, intersection, p4)
  );
}
// Function to return the intersected segments of a block
function getIntersectedSegments(p1, p2, block) {
  const { x, y, width, height } = block;

  // Define the four corners of the block
  const topLeft = { x: x, y: y };
  const topRight = { x: x + width, y: y };
  const bottomLeft = { x: x, y: y + height };
  const bottomRight = { x: x + width, y: y + height };

  const intersectedSegments = [];

  // Check each segment of the block for intersection
  if (doSegmentsIntersect(p1, p2, topLeft, topRight)) {
    intersectedSegments.push([topLeft, topRight]); // Top side
  }
  if (doSegmentsIntersect(p1, p2, topLeft, bottomLeft)) {
    intersectedSegments.push([topLeft, bottomLeft]); // Left side
  }
  if (doSegmentsIntersect(p1, p2, bottomLeft, bottomRight)) {
    intersectedSegments.push([bottomLeft, bottomRight]); // Bottom side
  }
  if (doSegmentsIntersect(p1, p2, topRight, bottomRight)) {
    intersectedSegments.push([topRight, bottomRight]); // Right side
  }

  return intersectedSegments;
}
// Function to get all intersected blocks in a line
function getIntersectedBlocksInLine(point, destiny, blocks) {
  console_log(
    'getIntersectedBlocksInLine',
    point,
    destiny,
    'total_blocks',
    blocks.length
  );
  const intersectedBlocks = [];
  blocks.forEach(block => {
    if (isBlockTouchingOrInsideRectangle(point, destiny, block)) {
      intersectedBlocks.push(block);
    }
  });
  return intersectedBlocks;
}
// Function to get all intersected blocks and their intersected segments
function getIntersectedBlocksWithSegments(point, destiny, blocks) {
  const result = [];
  const distance = lengthVector(subtractVectors(point, destiny));
  if (distance === 0) {
    console_log(
      'getIntersectedBlocksWithSegments - Length is 0',
      point,
      destiny
    );

    return result;
  }
  console_log('getIntersectedBlocksWithSegments', point, destiny);
  blocks.forEach(block => {
    if (isBlockTouchingOrInsideRectangle(point, destiny, block)) {
      const intersectedSegments = getIntersectedSegments(point, destiny, block);
      if (intersectedSegments.length > 0) {
        result.push({
          id: block.id,
          block: block,
          intersectedSegments,
        });
      }
    }
  });

  return result;
}
// Function to compute the distance between a point and a segment
function pointToSegmentDistance(p, p1, p2) {
  // Calculate the squared length of the segment
  const segmentLengthSquared = (p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2;

  if (segmentLengthSquared === 0) {
    // p1 and p2 are the same point
    return Math.sqrt((p.x - p1.x) ** 2 + (p.y - p1.y) ** 2);
  }

  // Projection of point p onto the line defined by p1 -> p2
  let t =
    ((p.x - p1.x) * (p2.x - p1.x) + (p.y - p1.y) * (p2.y - p1.y)) /
    segmentLengthSquared;

  // Clamp t to the range [0, 1] to ensure we stay within the segment
  t = Math.max(0, Math.min(1, t));

  // Find the projection point
  const projection = {
    x: p1.x + t * (p2.x - p1.x),
    y: p1.y + t * (p2.y - p1.y),
  };

  // Return the distance between the original point and the projection point
  return Math.sqrt((p.x - projection.x) ** 2 + (p.y - projection.y) ** 2);
}
// Function to find the closest block to the point based on the intersected segments
function findClosestBlock(point, intersectedBlocksWithSegments) {
  let closestBlock = null;
  let minDistance = Infinity;

  intersectedBlocksWithSegments.forEach(block => {
    block.intersectedSegments.forEach(([p1, p2]) => {
      const distance = pointToSegmentDistance(point, p1, p2);
      if (distance < minDistance) {
        minDistance = distance;
        closestBlock = block;
      }
    });
  });

  return closestBlock;
}
// Function to find the distance between two points
function distanceBetweenVectors(point1, point2) {
  return Math.sqrt((point2.x - point1.x) ** 2 + (point2.y - point1.y) ** 2);
}
function distanceToPoint(px1, py1, px2, py2) {
  return Math.sqrt((px2 - px1) ** 2 + (py2 - py1) ** 2);
}
// Function to find the segment closest to a point from a list of segments
function findFarSegment(point, segments) {
  let farthestSegment = null;
  let maxDistance = -Infinity;

  segments.forEach(([p1, p2]) => {
    const distance = pointToSegmentDistance(point, p1, p2);
    if (distance > maxDistance) {
      maxDistance = distance;
      farthestSegment = [p1, p2];
    }
  });

  return farthestSegment;
}
// Function to find the closest segment to a point from a list of segments
function findClosestSegment(point, segments) {
  let closestSegment = null;
  let minDistance = Infinity;

  segments.forEach(([p1, p2]) => {
    const distance = pointToSegmentDistance(point, p1, p2);
    if (distance < minDistance) {
      minDistance = distance;
      closestSegment = [p1, p2];
    }
  });

  return closestSegment;
}

// Function to project a point on a line
function projectPointOnLine(point, direction, line) {
  console_log('projectPointOnLine', point, direction, line);
  if (line === undefined || !line) return point;
  const [p1, p2] = line;

  if (direction === null) {
    // If direction is null, find the closest point on the line
    const lineVector = subtractVectors(p2, p1);
    const pointVector = subtractVectors(point, p1);

    // Calculate the projection of pointVector onto lineVector
    const lineVectorLength = Math.sqrt(lineVector.x ** 2 + lineVector.y ** 2);
    const dotProduct =
      pointVector.x * lineVector.x + pointVector.y * lineVector.y;
    const t = dotProduct / lineVectorLength ** 2;

    // Calculate the projected point
    return {
      x: p1.x + t * lineVector.x,
      y: p1.y + t * lineVector.y,
    };
  } else {
    // If direction is provided, use the existing intersection logic
    const intersection = getIntersectionPoint(
      point,
      { x: point.x + direction.x, y: point.y + direction.y },
      p1,
      p2
    );
    return intersection;
  }
}
// Function to find the farthest point from a destination on a segment
function farthestToDestiny(destiny, farthestSegment) {
  const [p1, p2] = farthestSegment;
  const distanceToP1 = distanceToPoint(destiny.x, destiny.y, p1.x, p1.y);
  const distanceToP2 = distanceToPoint(destiny.x, destiny.y, p2.x, p2.y);
  return distanceToP1 > distanceToP2 ? p1 : p2;
}
// Function to find the closest point to a destination on a segment
function closestToDestiny(destiny, closestSegment) {
  const [p1, p2] = closestSegment;
  const distanceToP1 = distanceToPoint(destiny.x, destiny.y, p1.x, p1.y);
  const distanceToP2 = distanceToPoint(destiny.x, destiny.y, p2.x, p2.y);
  return distanceToP1 < distanceToP2 ? p1 : p2;
}
// Function to add a vector to a point
function addVectors(point, scalar, vector, print = true) {
  if (print) {
    console_log('addVectors', point, scalar, vector);
  }
  return {
    x: point.x + scalar * vector.x,
    y: point.y + scalar * vector.y,
  };
}
// Function to normalize a vector
function normalize(vector) {
  const length = Math.sqrt(vector.x ** 2 + vector.y ** 2);
  return {
    x: vector.x / length,
    y: vector.y / length,
  };
}
// Function to check if two segments are parallel
function areSegmentsParallelToPoint(segments) {
  if (segments.length <= 1) return null;
  const [segment1, segment2] = segments;
  const [p1, p2] = segment1;
  const [p3, p4] = segment2;

  // Check if destiny.x is between the segment's x-coordinates
  // const isDestinyBetween =
  //   (destiny.x >= Math.min(p1.x, p2.x) && destiny.x <= Math.max(p1.x, p2.x)) ||
  //   (destiny.x >= Math.min(p3.x, p4.x) && destiny.x <= Math.max(p3.x, p4.x));

  // Calculate the direction vectors of both segments
  const vector1 = { x: p2.x - p1.x, y: p2.y - p1.y };
  const vector2 = { x: p4.x - p3.x, y: p4.y - p3.y };

  // Calculate the cross product of the direction vectors
  const crossProduct = vector1.x * vector2.y - vector1.y * vector2.x;

  // If the cross product is close to zero (accounting for floating-point precision),
  // the segments are parallel
  const epsilon = 1e-10; // Small value to account for floating-point precision
  return Math.abs(crossProduct) < epsilon;
}
// Function to subtract two vectors
function subtractVectors(v1, v2) {
  return {
    x: v1.x - v2.x,
    y: v1.y - v2.y,
  };
}
// Function to calculate the length of a vector
function lengthVector(vector) {
  return Math.sqrt(vector.x ** 2 + vector.y ** 2);
}
// Function to get the borders of a block
function getBlockBorders(block) {
  const block_borders = [];
  // Define the four corners of the block
  const topLeft = { x: block.x, y: block.y };
  const topRight = { x: block.x + block.width, y: block.y };
  const bottomLeft = { x: block.x, y: block.y + block.height };
  const bottomRight = {
    x: block.x + block.width,
    y: block.y + block.height,
  };
  block_borders.push([bottomLeft, bottomRight]);
  block_borders.push([topLeft, topRight]);
  block_borders.push([bottomLeft, topLeft]);
  block_borders.push([bottomRight, topRight]);
  return block_borders;
}

// Function to project a point on a line with a delta
function projectPointOnLineWithDelta(
  point,
  direction,
  segment,
  delta = -deltaMove
) {
  console_log('projectPointOnLineWithDelta', point, direction, segment);
  const point_v_2 = projectPointOnLine(point, direction, segment);
  console_log('projected-point', point_v_2);
  if (!point_v_2) return null;
  let direction_to_segment = subtractVectors(point_v_2, point);
  console_log('direction_to_segment', direction_to_segment);
  const length = lengthVector(direction_to_segment);
  if (length === 0) {
    direction_to_segment = normalize(direction);
  }
  console_log('length', length);
  const projected_point = addVectors(
    point,
    length + delta,
    normalize(direction_to_segment)
  );
  console_log('projected-point-delta', projected_point);
  return projected_point;
}

// Function to check if two segments are perpendicular
function areSegmentsPerpendicular(segment1, segment2) {
  const [p1, p2] = segment1;
  const [p3, p4] = segment2;

  // Calculate the direction vectors of both segments
  const vector1 = { x: p2.x - p1.x, y: p2.y - p1.y };
  const vector2 = { x: p4.x - p3.x, y: p4.y - p3.y };

  // Calculate the dot product of the direction vectors
  const dotProduct = vector1.x * vector2.x + vector1.y * vector2.y;
  return dotProduct === 0;
}

// Function to check if a direction intersects a segment
function isDirectionIntersectingSegment(point, direction, segment) {
  const intersection = getIntersectionPoint(
    point,
    { x: point.x + direction.x, y: point.y + direction.y },
    segment[0],
    segment[1]
  );
  return intersection;
}

// Function to get the intersected parallel segment to a direction
function getIntersectedParallelLineToDirection(
  point,
  direction,
  closest_segment,
  block
) {
  console_log(
    'getIntersectedParallelLineToDirection',
    point,
    direction,
    closest_segment,
    block
  );
  const segments = getBlockBorders(block);
  let parallel_segments = [];
  for (const segment of segments) {
    console_log('segment', segment);
    const isParallel = areSegmentsPerpendicular(segment, closest_segment);
    if (isParallel) {
      parallel_segments.push(segment);
    }
  }
  console_log('parallel_segments', parallel_segments);
  for (const segment of parallel_segments) {
    const isDirectionInsectingSegment = isDirectionIntersectingSegment(
      point,
      direction,
      segment
    );
    if (isDirectionInsectingSegment) {
      return segment;
    }
  }
  return null;
}
// Function to get the intersected parallel segment to a direction
function getIntersectedParallelSegmentToDirection(
  point,
  direction,
  closest_segment,
  block,
  show_segments = false
) {
  console_log(
    'getIntersectedParallelSegmentToDirection',
    point,
    direction,
    closest_segment,
    block
  );
  const segments = getBlockBorders(block);
  let parallel_segments = [];
  for (const segment of segments) {
    if (show_segments) {
      console_log('segment', segment);
    }
    const isParallel = areSegmentsPerpendicular(segment, closest_segment);
    if (isParallel) {
      parallel_segments.push(segment);
    }
  }
  console_log('parallel_segments', parallel_segments);
  for (const segment of parallel_segments) {
    const isDirectionInsectingSegment = isDirectionIntersectingSegment(
      point,
      direction,
      segment
    );
    const point_2 = addVectors(point, 1, direction, false);
    if (
      isDirectionInsectingSegment &&
      isPointOnSegment(segment[0], point_2, segment[1])
    ) {
      console_log('parallel_segment', segment);
      return segment;
    }
  }
  return null;
}
// Function to create a rectangle from two points
function createRect(p1, p2) {
  return {
    x: Math.min(p1.x, p2.x),
    y: Math.min(p1.y, p2.y),
    width: Math.abs(p1.x - p2.x),
    height: Math.abs(p1.y - p2.y),
  };
}
// Function to check if a point is on a line segment
function isPointOnSegment(startSegment, point, endSegment) {
  return (
    point.x <= Math.max(startSegment.x, endSegment.x) &&
    point.x >= Math.min(startSegment.x, endSegment.x) &&
    point.y <= Math.max(startSegment.y, endSegment.y) &&
    point.y >= Math.min(startSegment.y, endSegment.y)
  );
}
// Function to check if two rectangles intersect or touch
function rectanglesIntersectOrTouch(rect1, rect2) {
  const r1 = {
    topLeft: { x: rect1.x, y: rect1.y },
    topRight: { x: rect1.x + rect1.width, y: rect1.y },
    bottomLeft: { x: rect1.x, y: rect1.y + rect1.height },
    bottomRight: { x: rect1.x + rect1.width, y: rect1.y + rect1.height },
  };

  const r2 = {
    topLeft: { x: rect2.x, y: rect2.y },
    topRight: { x: rect2.x + rect2.width, y: rect2.y },
    bottomLeft: { x: rect2.x, y: rect2.y + rect2.height },
    bottomRight: { x: rect2.x + rect2.width, y: rect2.y + rect2.height },
  };

  // Check if any corner of one rectangle is inside the other
  const corners1 = [r1.topLeft, r1.topRight, r1.bottomLeft, r1.bottomRight];
  const corners2 = [r2.topLeft, r2.topRight, r2.bottomLeft, r2.bottomRight];

  for (let corner of corners1) {
    if (isPointOnSegment(r2.topLeft, corner, r2.bottomRight)) return true;
  }

  for (let corner of corners2) {
    if (isPointOnSegment(r1.topLeft, corner, r1.bottomRight)) return true;
  }

  // Check if any edges intersect
  const edges1 = [
    [r1.topLeft, r1.topRight],
    [r1.topRight, r1.bottomRight],
    [r1.bottomRight, r1.bottomLeft],
    [r1.bottomLeft, r1.topLeft],
  ];

  const edges2 = [
    [r2.topLeft, r2.topRight],
    [r2.topRight, r2.bottomRight],
    [r2.bottomRight, r2.bottomLeft],
    [r2.bottomLeft, r2.topLeft],
  ];

  for (let [p1, p2] of edges1) {
    for (let [p3, p4] of edges2) {
      if (doSegmentsIntersect(p1, p2, p3, p4)) return true;
    }
  }

  return false;
}
// Function to check if a point is touching or inside a rectangle
function isBlockTouchingOrInsideRectangle(pointStart, pointEnd, block) {
  // Calculate the bounds of the rectangle formed by point1 and point2
  const boundingRect = createRect(pointStart, pointEnd);
  return rectanglesIntersectOrTouch(boundingRect, block);
}
// Function to check if two vectors are equal
function areVectorEqual(v1, v2) {
  return v1.x === v2.x && v1.y === v2.y;
}
// Function to check if a point can go round one block
function canGoRoundOneBlock(currentPoint, destiny, blocks, globalBlocks) {
  console_log('-------------- blocks.length === 1 --------------');
  console_log(
    'canGoRoundOneBlock',
    currentPoint,
    destiny,
    blocks,
    'total - globalblocks',
    globalBlocks.length
  );
  console_log('-------------------------------------------------');
  const blocks_segments = getIntersectedBlocksWithSegments(
    currentPoint,
    destiny,
    blocks
  );
  const closestBlock = findClosestBlock(currentPoint, blocks_segments);
  const closestSegment = findClosestSegment(
    currentPoint,
    closestBlock.intersectedSegments
  );
  console_log('closestSegment', closestSegment);
  const destiny_segment = projectPointOnLineWithDelta(
    destiny,
    null,
    closestSegment,
    0
  );
  console_log('destiny_segment', destiny_segment);
  const direction_segment_1 = subtractVectors(
    closestSegment[0],
    destiny_segment
  );
  const length_segment_1 = lengthVector(direction_segment_1);
  console_log(
    'direction_segment_1',
    direction_segment_1,
    'length_segment_1',
    length_segment_1,
    'segment_1',
    closestSegment[0]
  );
  const direction_segment_2 = subtractVectors(
    closestSegment[1],
    destiny_segment
  );
  const length_segment_2 = lengthVector(direction_segment_2);
  console_log(
    'direction_segment_2',
    direction_segment_2,
    'length_segment_2',
    length_segment_2,
    'segment_2',
    closestSegment[1]
  );
  let final_point = null;
  if (length_segment_1 < length_segment_2) {
    console_log('-----------------------------------');
    console_log('project to perpendicular to closestSegment');
    console_log('-----------------------------------');
    const intersectedParallelSegment = getIntersectedParallelSegmentToDirection(
      destiny_segment,
      direction_segment_1,
      closestSegment,
      closestBlock.block
    );
    console_log('-----------------------------------');
    console_log('intersectedParallelSegment', intersectedParallelSegment);
    console_log('closestSegment', closestSegment);
    console_log('direction_segment_1', direction_segment_1);
    console_log('+++++++++++++++++++++++++++++++++++++');
    final_point = projectPointOnLineWithDelta(
      currentPoint,
      direction_segment_1,
      intersectedParallelSegment,
      +deltaMove
    );
    console_log('final_point', final_point);
    console_log('-----------------------------------');
  } else {
    console_log('-----------------------------------');
    console_log('project to parallel to closestSegment');
    console_log('-----------------------------------');
    final_point = addVectors(
      currentPoint,
      length_segment_2 + deltaMove,
      normalize(direction_segment_2)
    );
  }
  console_log('final_point', final_point);
  const intersectedBlocks = getIntersectedBlocksWithSegments(
    final_point,
    destiny,
    globalBlocks
  );
  if (intersectedBlocks.length === blocks.length) {
    return final_point;
  } else {
    console_log('-----------------------------------');
    console_log('total - intersectedBlocks', intersectedBlocks.length);
    console_log(
      'final_point is not valid, need to move it to the closest border of the block'
    );
    console_log('-----------------------------------');
    final_point = projectPointOnLineWithDelta(
      currentPoint,
      null,
      closestSegment
    );
    if (areVectorEqual(final_point, currentPoint)) {
      console_log('-----------------------------------');
      console_log(
        'final_point is equal to currentPoint, and move to opposite direction over closestSegment'
      );
      const intersectedParallelSegment = getIntersectedParallelSegmentToDirection(
        destiny_segment,
        direction_segment_2,
        closestSegment,
        closestBlock.block
      );
      // if (deltaMove < 5.5) deltaMove = 5;
      console_log(
        'currentPoint',
        currentPoint,
        'length_segment_2',
        length_segment_2
      );
      final_point = projectPointOnLineWithDelta(
        currentPoint,
        direction_segment_2,
        intersectedParallelSegment,
        +deltaMove
      );
      console_log('-----------------------------------');
    }
  }

  console_log('final_point', final_point);
  return final_point;
}
// Function to check if a segment is vertical
function isSegmentVertical(segment) {
  return segment[0].x === segment[1].x;
}
// Function to check if a point can go round a block
function canGoRoundTheBlock(currentPoint, destiny, blocks, globalBlocks) {
  console_log(
    'canGoRoundTheBlock',
    currentPoint,
    ' destiny',
    destiny,
    'Total blocks',
    blocks.length
  );
  console_log('blocks', blocks);
  const direction = {
    x: destiny.x - currentPoint.x,
    y: destiny.y - currentPoint.y,
  };
  let blocks_segments = getIntersectedBlocksWithSegments(
    currentPoint,
    destiny,
    blocks
  );
  let vertical = { x: 0, y: direction.y };
  let horizontal = { x: direction.x, y: 0 };
  // const length_vertical = lengthVector(vertical);
  // const length_horizontal = lengthVector(horizontal);
  if (areVectorEqual(vertical, horizontal)) {
    const next_point = addVectors(currentPoint, 1, vertical);
    const blocks_next_point = getIntersectedBlocksWithSegments(
      currentPoint,
      next_point,
      blocks
    );
    if (blocks_next_point.length === 0) {
      return next_point;
    } else {
      const closestBlock = findClosestBlock(currentPoint, blocks_next_point);
      if (closestBlock) {
        const closestSegment = findClosestSegment(
          currentPoint,
          closestBlock.intersectedSegments
        );
        if (closestSegment) {
          const point_segment = projectPointOnLineWithDelta(
            currentPoint,
            vertical,
            closestSegment
          );
          console_log('point_segment', point_segment);
          if (!areVectorEqual(point_segment, currentPoint)) {
            return point_segment;
          } else {
            // point was not able to move, so we need to move it to the closest border of the block
            console_log('-----------------------------------');
            console_log(
              'point was not able to move, so we need to move it to the closest border of the block'
            );
            console_log('-----------------------------------');
            const direction_segment = subtractVectors(destiny, currentPoint);
            const destiny_segment = projectPointOnLine(
              currentPoint,
              direction_segment,
              closestSegment
            );
            console_log('destiny_segment', destiny_segment);
            const direction_segment_1 = subtractVectors(
              closestSegment[0],
              destiny_segment
            );
            const length_segment_1 = lengthVector(direction_segment_1);
            console_log(
              'direction_segment_1',
              direction_segment_1,
              'length_segment_1',
              length_segment_1,
              'segment_1',
              closestSegment[0]
            );
            const direction_segment_2 = subtractVectors(
              closestSegment[1],
              destiny_segment
            );
            const length_segment_2 = lengthVector(direction_segment_2);
            console_log(
              'direction_segment_2',
              direction_segment_2,
              'length_segment_2',
              length_segment_2,
              'segment_2',
              closestSegment[1]
            );
            if (length_segment_1 < length_segment_2) {
              return addVectors(
                currentPoint,
                length_segment_1 + deltaMove,
                normalize(direction_segment_1)
              );
            } else {
              return addVectors(
                currentPoint,
                length_segment_2 + deltaMove,
                normalize(direction_segment_2)
              );
            }
          }
        }
      }
    }
  }
  console_log('vertical', vertical);
  console_log('horizontal', horizontal);
  const point_v = addVectors(currentPoint, 1, vertical);
  console_log('point_v', point_v);
  const point_h = addVectors(currentPoint, 1, horizontal);
  console_log('point_h', point_h);
  let count_vertical = 0;
  let count_horizontal = 0;
  for (const block of blocks) {
    console_log('---- checking block ------');
    console_log('block', block);
    let intersectedBlocks = getIntersectedBlocksWithSegments(
      currentPoint,
      point_v,
      [block]
    );
    if (intersectedBlocks.length > 0) {
      count_vertical++;
    }
    intersectedBlocks = getIntersectedBlocksWithSegments(
      currentPoint,
      point_h,
      [block]
    );
    if (intersectedBlocks.length > 0) {
      count_horizontal++;
    }
  }
  console_log('---- end checking block ------');
  console_log('count_vertical', count_vertical);
  console_log('count_horizontal', count_horizontal);

  let finalPoint = null;
  if (count_vertical > 0 && count_horizontal > 0) finalPoint = null;
  else {
    if (count_vertical === 0 && count_horizontal === 0) {
      const point_v = addVectors(currentPoint, 1, vertical, false);
      const point_h = addVectors(currentPoint, 1, horizontal, false);
      const intersectedBlocks_point_v = getIntersectedBlocksWithSegments(
        currentPoint,
        point_v,
        globalBlocks
      );
      const intersectedBlocks_point_h = getIntersectedBlocksWithSegments(
        currentPoint,
        point_h,
        globalBlocks
      );
      console_log('intersectedBlocks_point_v', intersectedBlocks_point_v);
      console_log('intersectedBlocks_point_h', intersectedBlocks_point_h);
      const direction_v = subtractVectors(point_v, destiny);
      const direction_h = subtractVectors(point_h, destiny);
      const length_v = lengthVector(direction_v);
      const length_h = lengthVector(direction_h);
      if (
        intersectedBlocks_point_v.length === 0 &&
        intersectedBlocks_point_h.length === 0
      ) {
        if (length_v < length_h) {
          finalPoint = point_v;
        } else {
          finalPoint = point_h;
        }
      } else if (intersectedBlocks_point_v.length === 0) {
        finalPoint = point_h;
      } else if (intersectedBlocks_point_h.length === 0) {
        finalPoint = point_v;
      } else {
        if (length_v < length_h) {
          finalPoint = addVectors(
            currentPoint,
            length_v - deltaMove,
            normalize(vertical)
          );
        } else {
          finalPoint = addVectors(
            currentPoint,
            length_h - deltaMove,
            normalize(horizontal)
          );
        }
      }
    } else if (blocks.length === 1) {
      finalPoint = canGoRoundOneBlock(
        currentPoint,
        destiny,
        blocks,
        globalBlocks
      );
    } else {
      finalPoint = null;
      const closestBlock = findClosestBlock(currentPoint, blocks_segments);
      console_log('more than 2 blocks - closestBlock', closestBlock);
      if (closestBlock) {
        const closestSegment = findClosestSegment(
          currentPoint,
          closestBlock.intersectedSegments
        );
        console_log('more than 2 blocks - closestSegment', closestSegment);
        if (closestSegment) {
          let direction = horizontal;
          let to_segment = vertical;
          if (isSegmentVertical(closestSegment)) {
            direction = vertical;
            to_segment = horizontal;
          }
          console_log('direction', direction);
          console_log('to_segment', to_segment);
          const intersectedParallelSegment = getIntersectedParallelLineToDirection(
            currentPoint,
            direction,
            closestSegment,
            closestBlock.block
          );
          console_log('intersectedParallelSegment', intersectedParallelSegment);
          const point_segment = projectPointOnLineWithDelta(
            currentPoint,
            direction,
            intersectedParallelSegment,
            +deltaMove
          );
          console_log('point_segment', point_segment);
          let next_point = addVectors(point_segment, 1, to_segment);
          console_log('next_point', next_point);
          const nextIntersectedBlocks = getIntersectedBlocksWithSegments(
            point_segment,
            next_point,
            globalBlocks
          );
          console_log('nextIntersectedBlocks', nextIntersectedBlocks);
          let hitClosestBlock = nextIntersectedBlocks.filter(
            block => block.id === closestBlock.id
          );
          console_log('hitClosestBlock', hitClosestBlock);
          if (hitClosestBlock.length === 0) {
            finalPoint = point_segment;
          } else {
            console_log('move with to_segment', to_segment);
            const point_to_segment = addVectors(currentPoint, 1, to_segment);
            console_log('point_to_segment', point_to_segment);
            const intersectedBlocks_to_segment = getIntersectedBlocksWithSegments(
              currentPoint,
              point_to_segment,
              globalBlocks
            );
            console_log(
              'intersectedBlocks_to_segment',
              intersectedBlocks_to_segment
            );
            console_log('closestBlock', closestBlock);
            hitClosestBlock = intersectedBlocks_to_segment.filter(
              block => block.id === closestBlock.id
            );
            console_log('hitClosestBlock', hitClosestBlock);
            if (hitClosestBlock.length === 0) {
              console_log('hitClosestBlock.length === 0');
              const closestBlock_to_segment = findClosestBlock(
                currentPoint,
                intersectedBlocks_to_segment
              );
              if (closestBlock_to_segment) {
                const closestSegment_to_segment = findClosestSegment(
                  currentPoint,
                  closestBlock_to_segment.intersectedSegments
                );
                finalPoint = projectPointOnLineWithDelta(
                  currentPoint,
                  to_segment,
                  closestSegment_to_segment
                );
                console_log('hitClosestBlock - finalPoint', finalPoint);
              } else {
                throw new Error('not implemented');
              }
            } else {
              throw new Error('not implemented');
            }
          }
        }
      } else {
        finalPoint = null;
      }
    }
    if (!finalPoint && count_vertical === 0) {
      finalPoint = addVectors(point_v, deltaMove, normalize(vertical));
    } else if (!finalPoint && count_horizontal === 0) {
      finalPoint = addVectors(point_h, deltaMove, normalize(horizontal));
    }
  }
  console_log('finalPoint', finalPoint);
  if (count_vertical === 0 && count_horizontal === 0) {
    return finalPoint;
  }
  console_log('-----------------------------------');
  return finalPoint;
}

// distance between 2 segments
function segmentDistance(seg1, seg2) {
  const p1 = seg1.start;
  const p2 = seg1.end;
  const p3 = seg2.start;
  const p4 = seg2.end;

  // Check if segments intersect
  if (doSegmentsIntersect(p1, p2, p3, p4)) {
    return 0;
  }

  // Calculate distances from endpoints to the other segment
  const d1 = pointToSegmentDistance(p1, p3, p4);
  const d2 = pointToSegmentDistance(p2, p3, p4);
  const d3 = pointToSegmentDistance(p3, p1, p2);
  const d4 = pointToSegmentDistance(p4, p1, p2);

  // Return the minimum distance
  return Math.min(d1, d2, d3, d4);
}
// check if a segment is between 2 segments
function isSegmentBetweenSegments(segment, segments) {
  if (segments.length !== 2) return false;
  const x = segment.start.x;
  const y = segment.start.y;
  const vertical = segment.start.x === segment.end.x;
  const isVertical = segment => segment.start.x === segment.end.x;

  for (const seg of segments) {
    if (isVertical(seg) !== vertical) {
      return false;
    }
  }
  if (vertical) {
    if (
      Math.min(segments[0].start.x, segments[1].start.x) <= x &&
      x <= Math.max(segments[0].start.x, segments[1].start.x)
    ) {
      return true;
    }
  } else {
    if (
      Math.min(segments[0].start.y, segments[1].start.y) <= y &&
      y <= Math.max(segments[0].start.y, segments[1].start.y)
    ) {
      return true;
    }
  }

  return false;
}
// find closest segments and distance between 2 rectangles
function findClosestSegmentsAndDistance(rectA, rectB) {
  const segmentsA = {
    horizontal: [
      {
        start: { x: rectA.x, y: rectA.y },
        end: { x: rectA.x + rectA.width, y: rectA.y },
      },
      {
        start: { x: rectA.x, y: rectA.y + rectA.height },
        end: { x: rectA.x + rectA.width, y: rectA.y + rectA.height },
      },
    ],
    vertical: [
      {
        start: { x: rectA.x, y: rectA.y },
        end: { x: rectA.x, y: rectA.y + rectA.height },
      },
      {
        start: { x: rectA.x + rectA.width, y: rectA.y },
        end: { x: rectA.x + rectA.width, y: rectA.y + rectA.height },
      },
    ],
  };

  const segmentsB = {
    horizontal: [
      {
        start: { x: rectB.x, y: rectB.y },
        end: { x: rectB.x + rectB.width, y: rectB.y },
      },
      {
        start: { x: rectB.x, y: rectB.y + rectB.height },
        end: { x: rectB.x + rectB.width, y: rectB.y + rectB.height },
      },
    ],
    vertical: [
      {
        start: { x: rectB.x, y: rectB.y },
        end: { x: rectB.x, y: rectB.y + rectB.height },
      },
      {
        start: { x: rectB.x + rectB.width, y: rectB.y },
        end: { x: rectB.x + rectB.width, y: rectB.y + rectB.height },
      },
    ],
  };

  let minDistance = Infinity;
  let closestHorizontal = [];
  let closestVertical = [];

  // Find the overall minimum distance and collect closest segments
  for (const segA of [...segmentsA.horizontal, ...segmentsA.vertical]) {
    for (const segB of [...segmentsB.horizontal, ...segmentsB.vertical]) {
      const distance = segmentDistance(segA, segB);
      if (Math.abs(distance - minDistance) < 1e-10) {
        console_log('A', { distance, segA, segB });
        if ((segA.start.y === segA.end.y) === (segB.start.y === segB.end.y)) {
          if (segA.start.y === segA.end.y) {
            closestHorizontal.push({ segmentA: segA, segmentB: segB });
          } else {
            closestVertical.push({ segmentA: segA, segmentB: segB });
          }
        }
      } else if (distance < minDistance) {
        console_log('AA', { distance, segA, segB });
        minDistance = distance;
        closestHorizontal = [];
        closestVertical = [];
        if ((segA.start.y === segA.end.y) === (segB.start.y === segB.end.y)) {
          if (segA.start.y === segA.end.y) {
            closestHorizontal.push({ segmentA: segA, segmentB: segB });
          } else {
            closestVertical.push({ segmentA: segA, segmentB: segB });
          }
        }
      }
    }
  }

  const areTouching = rectanglesIntersectOrTouch(rectA, rectB);

  // Detect alignment
  const isAlignedHorizontally =
    isSegmentBetweenSegments(segmentsA.horizontal[0], segmentsB.horizontal) ||
    isSegmentBetweenSegments(segmentsA.horizontal[1], segmentsB.horizontal) ||
    isSegmentBetweenSegments(segmentsB.horizontal[0], segmentsA.horizontal) ||
    isSegmentBetweenSegments(segmentsB.horizontal[1], segmentsA.horizontal);

  const isAlignedVertically =
    isSegmentBetweenSegments(segmentsA.vertical[0], segmentsB.vertical) ||
    isSegmentBetweenSegments(segmentsA.vertical[1], segmentsB.vertical) ||
    isSegmentBetweenSegments(segmentsB.vertical[0], segmentsA.vertical) ||
    isSegmentBetweenSegments(segmentsB.vertical[1], segmentsA.vertical);

  // Determine relative positioning
  let horizontalPosition, verticalPosition;

  if (rectA.x + rectA.width / 2 < rectB.x + rectB.width / 2) {
    horizontalPosition = { left: 'A', right: 'B' };
  } else if (rectA.x + rectA.width / 2 > rectB.x + rectB.width / 2) {
    horizontalPosition = { left: 'B', right: 'A' };
  } else {
    horizontalPosition = { left: 'same', right: 'same' };
  }

  if (rectA.y + rectA.height / 2 < rectB.y + rectB.height / 2) {
    verticalPosition = { top: 'A', bottom: 'B' };
  } else if (rectA.y + rectA.height / 2 > rectB.y + rectB.height / 2) {
    verticalPosition = { top: 'B', bottom: 'A' };
  } else {
    verticalPosition = { top: 'same', bottom: 'same' };
  }

  return {
    horizontalSegments: closestHorizontal,
    verticalSegments: closestVertical,
    minDistance: minDistance,
    areTouching: areTouching,
    isAlignedHorizontally: isAlignedHorizontally,
    isAlignedVertically: isAlignedVertically,
    horizontalPosition: horizontalPosition,
    verticalPosition: verticalPosition,
  };
}
// find path with block in diagonal
function findPathDiagonal(
  path,
  currentPoint,
  destiny,
  vertical,
  horizontal,
  closestSegment,
  blocks
) {
  let delta = 10;
  const pointSegment = closestToDestiny(destiny, closestSegment);
  console_log('closestSegment', closestSegment);
  console_log('pointSegment - to - destiny', pointSegment);
  console_log('\nproject to vertical segment');
  if (distanceBetweenPorts < 82) {
    delta = deltaMove;
  }
  const verticalIntersection = projectPointOnLine(
    currentPoint,
    vertical,
    closestSegment
  );
  console_log('project - vertical', verticalIntersection, '\n');
  console_log('project to horizontal segment');
  const horizontalIntersection = projectPointOnLine(
    currentPoint,
    horizontal,
    closestSegment
  );
  console_log('project - horizontal', horizontalIntersection);
  if (verticalIntersection) {
    console_log('\nvertical intersection:', verticalIntersection);
    // compute the distance that the currentPoint needs to move in horizontal direction
    const distanceToverticalIntersection = distanceToPoint(
      pointSegment.x,
      pointSegment.y,
      verticalIntersection.x,
      verticalIntersection.y
    );
    console_log(
      'distanceToverticalIntersection',
      distanceToverticalIntersection
    );
    const tempCurrentPoint = addVectors(
      currentPoint,
      distanceToverticalIntersection + delta,
      normalize(horizontal)
    );
    const intersectedBlocksWithSegments = getIntersectedBlocksWithSegments(
      currentPoint,
      tempCurrentPoint,
      blocks
    );
    console_log('intersectedBlocksWithSegments', intersectedBlocksWithSegments);
    if (intersectedBlocksWithSegments.length > 0) {
      // not valid path, move the point in vertical direction
      const delta_temp = distanceBetweenVectors(
        verticalIntersection,
        currentPoint
      );
      const newCurrentPoint = addVectors(
        currentPoint,
        delta_temp - delta,
        normalize(vertical)
      );
      const distance = distanceBetweenVectors(currentPoint, newCurrentPoint);
      console_log('delta_temp', delta_temp, 'delta', delta);
      console_log('newCurrentPoint', newCurrentPoint);
      console_log('distance', distance, '\n');
      if (distance > 0) {
        currentPoint = newCurrentPoint;
      } else {
        const closestBlock = findClosestBlock(
          currentPoint,
          intersectedBlocksWithSegments
        );
        if (closestBlock) {
          const closestSegment = findClosestSegment(
            currentPoint,
            closestBlock.intersectedSegments
          );
          console_log('closestSegment', closestSegment);
          const destiny_segment = projectPointOnLineWithDelta(
            currentPoint,
            null,
            closestSegment
          );
          console_log('destiny_segment', destiny_segment);
          if (destiny_segment) {
            currentPoint = destiny_segment;
          } else {
            console_log('No valid intersection with distance === 0');
            // Handle the case where there's no valid intersection
            return null;
          }
        } else {
          console_log('No valid closestBlock with distance === 0');
          // Handle the case where there's no valid intersection
          return null;
        }
      }
      console_log('currentPoint', currentPoint);
    } else {
      currentPoint = tempCurrentPoint;
    }
  } else if (horizontalIntersection) {
    console_log('\nhorizontal intersection:', horizontalIntersection);
    const distanceTohorizontalIntersection = distanceToPoint(
      pointSegment.x,
      pointSegment.y,
      horizontalIntersection.x,
      horizontalIntersection.y
    );
    console_log(
      'distanceTohorizontalIntersection',
      distanceTohorizontalIntersection
    );
    currentPoint = addVectors(
      currentPoint,
      distanceTohorizontalIntersection + delta,
      normalize(vertical)
    );
  } else {
    console_log('No valid intersection found');
    // Handle the case where there's no valid intersection
    return null;
  }
  console_log('/////////////////////////////');
  console_log({ currentPoint });
  console_log('/////////////////////////////');
  path.push(currentPoint);
  return { currentPoint, path };
}
// find path with parallel
function findPathParallel(
  path,
  currentPoint,
  destiny,
  intersectedBlocksInLine,
  closestBlock,
  blocks,
  closestSegment,
  cblocks
) {
  let pointRound = null;
  try {
    pointRound = canGoRoundTheBlock(
      currentPoint,
      destiny,
      intersectedBlocksInLine,
      blocks
    );
    console_log('pointRound - 1', pointRound);
    if (!pointRound) {
      pointRound = canGoRoundTheBlock(
        currentPoint,
        destiny,
        [closestBlock.block],
        blocks
      );
      console_log('pointRound - 2', pointRound);
    }
  } catch (e) {
    console.error(e);
    intersectedBlocksInLine = getIntersectedBlocksInLine(
      currentPoint,
      destiny,
      cblocks
    );
    try {
      pointRound = canGoRoundTheBlock(
        currentPoint,
        destiny,
        intersectedBlocksInLine,
        cblocks
      );
    } catch (error) {
      pointRound = null;
    }
    console_log('pointRound - 1 - cblocks', pointRound);
    /*      if (!pointRound) {
            const intersectedBlocks = getIntersectedBlocksWithSegments(
              currentPoint,
              destiny,
              cblocks
            );
            closestBlock = findClosestBlock(currentPoint, intersectedBlocks);
            pointRound = canGoRoundTheBlock(
              currentPoint,
              destiny,
              [closestBlock.block],
              cblocks
            );
            console_log('pointRound - 2 - cblocks', pointRound);
          }*/
  }
  if (pointRound) {
    if (areVectorEqual(currentPoint, pointRound)) {
      console_log('currentPoint === pointRound', pointRound);
    } else {
      console_log('pointRound - 3', pointRound);
      currentPoint = pointRound;
      path.push(currentPoint);
    }
  } else {
    const farthestSegment = findFarSegment(
      currentPoint,
      closestBlock.intersectedSegments
    );
    const pointClosest = closestToDestiny(destiny, farthestSegment);
    const pointFarthest = farthestToDestiny(destiny, farthestSegment);
    const dirSegment = {
      x: pointClosest.x - pointFarthest.x,
      y: pointClosest.y - pointFarthest.y,
    };
    const dirSegmentNormalized = normalize(dirSegment);
    console_log('dirSegmentNormalized', dirSegmentNormalized);
    const newDestiny = addVectors(pointClosest, 10, dirSegmentNormalized);
    console_log('newDestiny', newDestiny);

    // Calculate the projection of currentPoint onto closestSegment
    const projectedPoint = projectPointOnLine(
      currentPoint,
      null,
      closestSegment
    );
    console_log('Projected point on closest segment:', projectedPoint);
    const vectorToCurrentPoint = subtractVectors(currentPoint, projectedPoint);
    const normalizedVector = normalize(vectorToCurrentPoint);
    const adjustedProjectedPoint = addVectors(
      projectedPoint,
      2,
      normalizedVector
    );
    console_log('Adjusted projected point:', adjustedProjectedPoint);
    path.push(adjustedProjectedPoint);
    const newPath = findPath(adjustedProjectedPoint, newDestiny, blocks);
    console_log('newPath', newPath);
    newPath.shift();
    newPath.pop();
    if (newPath.length > 0) {
      currentPoint = newPath[newPath.length - 1];
      console_log('path', path);
      console_log('newPath', newPath);
      console_log('currentPoint', currentPoint);
      path = [...path, ...newPath];
      console_log('pointClosest', pointClosest);
      console_log('pointFarthest', pointFarthest);
    } else {
      console_log('newPath is empty');
      return null;
    }
    console_log('+++++++++++++++++++++++++++++++++++++++');
  }
  return { currentPoint, path };
}
// find path between two points
function findPath(point, destiny, blocks, cblocks) {
  console_log('point', point);
  console_log('destiny', destiny);
  let dist = 10;
  let currentPoint = point;
  let path = [currentPoint];
  let recursive = 0;
  do {
    console_log('\n--- START - findPath -----\n');
    console_log('currentPoint', currentPoint);
    console_log('destiny', destiny, '\n');
    const intersectedBlocks = getIntersectedBlocksWithSegments(
      currentPoint,
      destiny,
      blocks
    );
    const intersectedBlocksInLine = getIntersectedBlocksInLine(
      currentPoint,
      destiny,
      blocks
    );
    console_log(
      'intersectedBlocksInLine',
      intersectedBlocksInLine.map(block => block.id)
    );
    const direction = {
      x: destiny.x - currentPoint.x,
      y: destiny.y - currentPoint.y,
    };
    let vertical = { x: 0, y: direction.y };
    let horizontal = { x: direction.x, y: 0 };
    console_log('vertical', vertical);
    console_log('horizontal', horizontal);
    const closestBlock = findClosestBlock(currentPoint, intersectedBlocks);
    console_log({ closestBlock });
    if (closestBlock) {
      console_log('intersectedSegments', closestBlock.intersectedSegments);
      const closestSegment = findClosestSegment(
        currentPoint,
        closestBlock.intersectedSegments
      );
      const areParallel = areSegmentsParallelToPoint(
        closestBlock.intersectedSegments,
        destiny
      );
      console_log('-----------------------------------');
      console_log(`Closest Segment (${closestBlock.id}):`, closestSegment);
      if (closestSegment && !areParallel) {
        console_log('-----------------------------------');
        const result = findPathDiagonal(
          path,
          currentPoint,
          destiny,
          vertical,
          horizontal,
          closestSegment,
          blocks,
          cblocks
        );
        if (result) {
          path = result.path;
          currentPoint = result.currentPoint;
        } else {
          console_log('currentPoint is null');
          break;
        }
      } else {
        console_log('-----------------------------------');
        console_log('areParallel', areParallel);
        console_log('-----------------------------------');
        const result = findPathParallel(
          path,
          currentPoint,
          destiny,
          intersectedBlocksInLine,
          closestBlock,
          blocks,
          closestSegment,
          cblocks
        );
        if (result) {
          path = result.path;
          currentPoint = result.currentPoint;
        } else {
          console_log('result is null');
          break;
        }
      }
    } else {
      // no intersecting blocks
      const newCurrentPoint = { x: destiny.x, y: currentPoint.y };
      if (areVectorEqual(newCurrentPoint, path[path.length - 1])) {
        break;
      }
      path.push(newCurrentPoint);
      break;
    }
    dist = distanceToPoint(
      currentPoint.x,
      currentPoint.y,
      destiny.x,
      destiny.y
    );
    console_log({ path });
    console_log({ dist, total: path.length }, '\n');
    if (path.length >= 10) break; // Safeguard against infinite loops
    if (path.length != recursive) {
      recursive = path.length;
    } else {
      console.error('recursive');
      break;
    }
  } while (dist > 1);
  console_log('\n');
  path.push(destiny);
  return path;
}
// compute path between two blocks
export const computePath = (blockA, portA, blockB, portB, blocks = []) => {
  const blocks_information = findClosestSegmentsAndDistance(blockA, blockB);
  minDistance = blocks_information.minDistance;
  if (minDistance >= 1 && minDistance < 5) {
    deltaMove = Math.round(minDistance / 2);
    if (deltaMove === 0) deltaMove = 1;
  } else {
    deltaMove = 5;
  }
  console_log('blocks_information', blocks_information);
  // Check if blocks are touching or crossing
  if (areBlocksTouchingOrCrossing(blockA, blockB)) {
    // If blocks are touching or crossing, return direct path between ports
    const path_thouching = pathForTwoPoints(portA, portB);
    console_log('minDistance', minDistance);
    return path_thouching;
  }

  const segmentA = createPortSegment(blockA, portA, portB);
  const segmentB = createPortSegment(blockB, portB, portA);
  const cblocks = [blockA, blockB];
  if (!blocks.length) {
    blocks = [blockA, blockB];
  }
  let path = findPath(segmentA.end, segmentB.end, blocks, cblocks);
  if (path.length === 11) {
    console.log('path.length === 11');
    path = findPath(segmentA.end, segmentB.end, cblocks, cblocks);
  }
  const { x: startX, y: startY } = segmentB.start;
  path = path.map(x => [x.x, x.y]);
  path.unshift([portA.x, portA.y]);
  path.push([startX, startY]);
  if (path.length > 4) {
    console_log('path.length');
    if (
      path[0][0] === path[1][0] &&
      path[1][0] === path[2][0] &&
      path[2][0] !== path[3][0]
    ) {
      path[2][0] = path[3][0];
      path[2][1] = path[1][1];
      console_log({ x1: path });
    }
    console_log({
      y0: path[0][0],
      y1: path[1][0],
      y2: path[2][0],
      y3: path[3][0],
    });
    if (
      path[0][1] === path[1][1] &&
      path[1][1] === path[2][1] &&
      path[2][1] !== path[3][1]
    ) {
      path[2][0] = path[1][0];
      path[2][1] = path[3][1];
      console_log(path[1]);
      console_log(path[2]);
      console_log(path[3]);
    }
  }
  console_log({ blockA });
  console_log({ blockB });
  console_log({ start: portA, end: portB });
  console_log({ start_out: segmentA.end, end_out: segmentB.end });
  // console.log({ path });
  console_log('blocks_information', blocks_information);
  console_log('minDistance', minDistance);
  console_log('deltaMove', deltaMove);
  return path;
};
