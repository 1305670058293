import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  Paper,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { AudioLines, File, Microchip } from 'lucide-react';
import { styled } from '@mui/material/styles';
import { GridTwoLevelTypo } from '../components';
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
    ...theme.applyStyles('dark', {
      backgroundColor: '#308fe8',
    }),
  },
}));
class TotalEarning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          name: 'Zipcar',
          subname: 'Vuejs, React & HTML',
          Icon: AudioLines,
          color: { primary: '#8C57FF', secondary: 'rgb(140 87 255 / 0.24)' },
          progress: 80,
          value: '$24,895.65',
        },
        {
          name: 'Bitbank',
          subname: 'Sketch, Figma & XD',
          Icon: File,
          color: { primary: '#16B1FF', secondary: 'rgb(22 177 255 / 0.24)' },
          progress: 40,
          value: '$8,650.20',
        },
        {
          name: 'Aviato',
          subname: 'HTML & Angular',
          Icon: Microchip,
          color: { primary: '#8A8D93', secondary: 'rgb(138 141 147 / 0.24)' },
          progress: 12,
          value: '$1,245.80',
        },
      ],
    };
  }

  render() {
    const { fullScreen, height, width } = this.props;
    const { data } = this.state;
    const basic = {
      margin: 0,
      fontFamily: '"__Inter_5475bd","__Inter_Fallback_5475bd"',
      fontWeight: 500,
      fontSize: '1.125rem',
      lineHeight: 1.5556,
    };
    return (
      <Paper sx={{ margin: 1 }}>
        <CardHeader
          action={
            <IconButton aria-label="settings" size="large">
              <MoreVertIcon />
            </IconButton>
          }
          sx={{ paddingTop: 1, paddingBottom: 1 }}
          title={
            <Typography
              component="span"
              sx={{
                flex: '1 1 auto',
                ...basic,
                color: 'var(--mui-palette-text-primary)',
                display: 'block',
              }}
              variant="h5">
              Total Earning
            </Typography>
          }
        />
        <CardContent
          sx={{
            padding: 1,
            paddingTop: 0,
            '&.MuiCardContent-root:last-child': {
              paddingBottom: '14px',
            },
          }}>
          <Grid
            container
            direction="row"
            sx={{
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}>
            <Typography
              sx={{ ...basic, fontWeight: 600, fontSize: '1.75rem' }}
              variant="h3">
              $24,895
            </Typography>
            <KeyboardArrowUpIcon sx={{ color: '#56CA00' }} />
            <Typography
              sx={{
                color: '#56CA00',
                ...basic,
                fontWeight: 400,
                fontSize: '0.9375rem',
              }}
              variant="body1">
              10%
            </Typography>
          </Grid>
          <Typography
            component="p"
            sx={{
              ...basic,
              fontWeight: 400,
              fontSize: '0.9375rem',
              marginBottom: 1,
              color: 'rgb(46 38 71 / 0.71)',
            }}
            variant="body1">
            Compared to $84,325 last year
          </Typography>
          <Grid
            item
            sx={{
              height: fullScreen ? height - 480 : 135,
              overflowX: 'auto',
            }}
            xs={12}>
            {data.map((item, index) => {
              const { name, subname, progress, value, Icon, color } = item; //  icon, progress, value
              return (
                <Grid
                  container
                  direction="row"
                  item
                  key={index}
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 0.5,
                  }}
                  xs={12}>
                  <Grid
                    container
                    item
                    md={6}
                    style={{ maxWidth: fullScreen ? width / 3 : undefined }}
                    sx={{
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                    xs={8}>
                    <Grid item style={{ maxWidth: 45 }} xs={2.6}>
                      <Avatar
                        sx={{
                          bgcolor: color['secondary'],
                          color: color['primary'],
                        }}
                        variant="rounded">
                        <Icon />
                      </Avatar>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        position: 'relative',
                      }}
                      xs>
                      <GridTwoLevelTypo
                        subtitle={subname}
                        title={name}
                        xs={12}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    item
                    style={{ maxWidth: fullScreen ? width / 3 : undefined }}
                    sx={{
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                    }}
                    xs>
                    <Grid item xs={12}>
                      <Typography
                        component="p"
                        sx={{
                          ...basic,
                          fontWeight: 600,
                          fontSize: fullScreen ? '1.2rem' : '0.9375rem',
                          lineHeight: 1.46667,
                          color: 'var(--mui-palette-text-primary)',
                          '--mui-palette-text-primary':
                            'rgb(var(--mui-mainColorChannels-light) / 0.9)',
                          '--mui-mainColorChannels-light': '46 38 61',
                        }}
                        variant="body1">
                        {value}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <BorderLinearProgress
                        sx={{
                          minWidth: fullScreen ? width / 3 : 70,
                          height: 5,
                          [`& .${linearProgressClasses.bar}`]: {
                            backgroundColor: color['primary'],
                          },
                          [`&.${linearProgressClasses.colorPrimary}`]: {
                            backgroundColor: color['secondary'],
                          },
                        }}
                        value={progress}
                        variant="determinate"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Paper>
    );
  }
}

TotalEarning.propTypes = {
  classes: PropTypes.object,
};

export default TotalEarning;
