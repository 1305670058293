import {
  MutationAddClinicalPatients,
  MutationAddMsComorbidities,
  MutationAddMsEdss,
  MutationAddMsImmunotherapies,
  MutationAddMsMedication,
  MutationAddMsMsfcs,
  MutationRemoveMsComorbidities,
  MutationRemoveMsEdss,
  MutationRemoveMsImmunotherapies,
  MutationRemoveMsMedication,
  MutationRemoveMsMsfcs,
  MutationUploadClinicalPatients,
  MutationUploadMsComorbidities,
  MutationUploadMsEdss,
  MutationUploadMsImmunotherapies,
  MutationUploadMsMedication,
  MutationUploadMsMsfcs,
  MutationMSLabResults,
  QueryMsComorbidities,
  QueryMsEdss,
  QueryMsImmunotherapies,
  QueryMsMedication,
  QueryMsMsfcs,
  QueryMsPatients,
  QueryMsResults,
  MutationRemoveMsLabResults
} from 'graphql/DataClinics';
import * as searches from 'graphql/DataClinics/ms/utils_search';

export const QueryMS = (table, name, page, limit, search_query, anonymize) => {
  if (table === 'comorbidities') {
    return QueryMsComorbidities(name, search_query, page, limit);
  } else if (table === 'medication') {
    return QueryMsMedication(name, search_query, page, limit);
  } else if (table === 'edss') {
    return QueryMsEdss(name, search_query, page, limit);
  } else if (table === 'immunotherapy') {
    return QueryMsImmunotherapies(name, search_query, page, limit);
  } else if (table === 'msfc') {
    return QueryMsMsfcs(name, search_query, page, limit);
  } else if (table === 'results') {
    return QueryMsResults( name, search_query, page, limit);
  } else if (table === 'patient') {
    return QueryMsPatients(page, search_query, limit, anonymize);
  } else {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      resolve({
        data: { data: 1 },
      });
    });
  }
};
export const GetResponse = (table, data) => {
  if (table === 'comorbidities') {
    const { ok, errors, comorbidities, total } = data.msComorbidities;
    return { ok, errors, total, rows: comorbidities };
  } else if (table === 'medication') {
    const { ok, errors, medications, total } = data.msConMedications;
    return { ok, errors, total, rows: medications };
  } else if (table === 'edss') {
    const { ok, errors, edss, total } = data.msEdss;
    return { ok, errors, total, rows: edss };
  } else if (table === 'immunotherapy') {
    const { ok, errors, immunotherapies, total } = data.msImmunotherapies;
    return { ok, errors, total, rows: immunotherapies };
  } else if (table === 'msfc') {
    const { ok, errors, msfcs, total } = data.msMsfcs;
    return { ok, errors, total, rows: msfcs };
  }  else if (table === 'results') {
    const { ok, errors, results, total } = data.msLabResults;
    return { ok, errors, total, rows: results };
  } else if (table === 'patient') {
    const { ok, errors, patients, total } = data.msPatients;
    return { ok, errors, total, rows: patients };
  } else {
    return { ok: true, errors: null, total: 0, rows: [] };
  }
};
export const MutationRemoveMS = (table, ids) => {
  if (table === 'comorbidities') {
    return MutationRemoveMsComorbidities(ids);
  } else if (table === 'medication') {
    return MutationRemoveMsMedication(ids);
  } else if (table === 'edss') {
    return MutationRemoveMsEdss(ids);
  } else if (table === 'immunotherapy') {
    return MutationRemoveMsImmunotherapies(ids);
  } else if (table === 'msfc') {
    return MutationRemoveMsMsfcs(ids);
  } else if (table === 'results') {
    return MutationRemoveMsLabResults(ids);
  } else {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      resolve({
        data: { data: 1 },
      });
    });
  }
};
export const GetRemoveResponse = (table, data) => {
  let reply = {
    ok: false,
    errors: [{ path: 'Invalid', message: 'Invalid table' }],
  };
  if (table === 'comorbidities') {
    reply = data.deleteMsComorbidities;
  } else if (table === 'medication') {
    reply = data.deleteMsConMedications;
  } else if (table === 'edss') {
    reply = data.deleteMsEdss;
  } else if (table === 'immunotherapy') {
    reply = data.deleteMsImmunotherapies;
  } else if (table === 'msfc') {
    reply = data.deleteMsMsfcs;
  }  else if (table === 'results') {
    reply = data.deleteMSLabResults;
  }
  return reply;
};
export const MutationAddMS = (table, inputs) => {
  if (table === 'comorbidities') {
    return MutationAddMsComorbidities(inputs);
  } else if (table === 'medication') {
    return MutationAddMsMedication(inputs);
  } else if (table === 'edss') {
    return MutationAddMsEdss(inputs);
  } else if (table === 'immunotherapy') {
    return MutationAddMsImmunotherapies(inputs);
  } else if (table === 'msfc') {
    return MutationAddMsMsfcs(inputs);
  } else if (table === 'patient') {
    return MutationAddClinicalPatients(inputs, 'ms');
  } else {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      resolve({
        data: { data: 1 },
      });
    });
  }
};

export const GetAddResponse = (table, data) => {
  if (table === 'comorbidities') {
    const { ok, errors, comorbidities, total } = data.addMsComorbidities;
    return { ok, errors, total, rew_rows: comorbidities };
  } else if (table === 'medication') {
    const { ok, errors, medications, total } = data.addMsConMedications;
    return { ok, errors, total, rew_rows: medications };
  } else if (table === 'edss') {
    const { ok, errors, edss, total } = data.addMsEdss;
    return { ok, errors, total, rew_rows: edss };
  } else if (table === 'immunotherapy') {
    const { ok, errors, immunotherapies, total } = data.addMsImmunotherapies;
    return { ok, errors, total, rew_rows: immunotherapies };
  } else if (table === 'msfc') {
    const { ok, errors, msfcs, total } = data.addMsMsfcs;
    return { ok, errors, total, rew_rows: msfcs };
  } else if (table === 'patient') {
    const { ok, errors, patients, total } = data.addPatientsClinics;
    return { ok, errors, total, rew_rows: patients };
  } else {
    return { ok: true, errors: null, total: 0, rew_rows: [] };
  }
};

export const MutationUploadMS = (table, file, handleProgress) => {
  if (table === 'comorbidities') {
    return MutationUploadMsComorbidities(file, handleProgress);
  } else if (table === 'medication') {
    return MutationUploadMsMedication(file, handleProgress);
  } else if (table === 'edss') {
    return MutationUploadMsEdss(file, handleProgress);
  } else if (table === 'immunotherapy') {
    return MutationUploadMsImmunotherapies(file, handleProgress);
  } else if (table === 'msfc') {
    return MutationUploadMsMsfcs(file, handleProgress);
  } else if (table === 'patient') {
    return MutationUploadClinicalPatients(file, 'ms', handleProgress);
  } else {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      resolve({
        data: { data: 1 },
      });
    });
  }
};
export const GetUploadResponse = (table, data) => {
  if (table === 'comorbidities') {
    const { ok, errors, comorbidities, total } = data.uploadMsComorbidities;
    return { ok, errors, total, new_rows: comorbidities };
  } else if (table === 'medication') {
    const { ok, errors, medications, total } = data.uploadMsConMedications;
    return { ok, errors, total, new_rows: medications };
  } else if (table === 'edss') {
    const { ok, errors, edss, total } = data.uploadMsEdss;
    return { ok, errors, total, new_rows: edss };
  } else if (table === 'immunotherapy') {
    const { ok, errors, immunotherapies, total } = data.uploadMsImmunotherapies;
    return { ok, errors, total, new_rows: immunotherapies };
  } else if (table === 'msfc') {
    const { ok, errors, msfcs, total } = data.uploadMsMsfcs;
    return { ok, errors, total, new_rows: msfcs };
  } else if (table === 'patient') {
    const { ok, errors, patients, total } = data.uploadPatientsClinics;
    return { ok, errors, total, new_rows: patients };
  } else if (table === 'all') {
    const { ok, errors, total, cases } = data.uploadMs;
    return { ok, errors, total, new_rows: cases };
  } else {
    return {
      ok: true,
      errors: [{ path: 'Invalid', message: 'Invalid table' }],
      total: 0,
      new_rows: [],
    };
  }
};

export const searchMSTables = (text, table, page, limit) => {
  const functionName = `Search${table.charAt(0).toUpperCase() +
    table.slice(1)}`;
  const searchMSFunction = searches[functionName];
  // const functionsArray = Object.values(searches);
  // const searchMSFunction = functionsArray.find(
  //   func => func.name === functionName
  // );
  return searchMSFunction(text, page, limit, table);
};

export const MutationUpdateMSLabResults = (page, limit) => {
  return MutationMSLabResults(page, limit);
}