/* eslint-disable quotes */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { SearchTransparent, TextArea } from 'components';

class ConfigReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      checked: [],
      reviewers: [
        {
          value: 0,
          name: 'John Doe',
          src: '/images/avatars/avatar_0.png',
          review: 'Ok',
        },
        {
          value: 1,
          name: 'Jane Doe',
          src: '/images/avatars/avatar_1.png',
          review: 'better',
        },
        {
          value: 2,
          name: 'Gaston Doe',
          src: '/images/avatars/avatar_2.png',
          review: 'No',
        },
        {
          value: 3,
          name: 'Waldo Doe',
          src: '/images/avatars/avatar_3.png',
          review: 'Ok',
        },
        {
          value: 4,
          name: 'Waldo Doe',
          src: '/images/avatars/avatar_4.png',
          review: "I don't have an opinion",
        },
        {
          value: 5,
          name: 'Claudia Doe',
          src: '/images/avatars/avatar_5.png',
          review: 'Bad',
        },
        {
          value: 6,
          name: 'Flor Doe',
          src: '/images/avatars/avatar_6.png',
          review: 'In progress',
        },
        {
          value: 7,
          name: 'Rodrigo Doe',
          src: '/images/avatars/avatar_7.png',
          review: 'No complete yet',
        },
      ],
      filter: '',
    };
  }
  handleChange = event => {
    console.log({ t: event.target.value });
    this.setState({ value: event.target.value });
  };
  handleToggle = value => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ checked: newChecked });
  };
  render() {
    if (this.props.view !== 'Configuration') return null;
    const { reviewers, checked, filter } = this.state;
    return (
      <Card
        sx={{
          height: '80%',
          margin: 1,
          padding: 0,
          marginTop: 0,
          marginBottom: 0,
          paddingBottom: 1,
          '&.MuiCardContent-root': { padding: 1 },
          '&.MuiCardContent-root:last-child': { paddingBottom: 1 },
        }}>
        <CardContent
          sx={{
            padding: 1,
            '&.MuiCardContent-root:last-child': { paddingBottom: 1 },
          }}>
          <SearchTransparent
            onChange={event => {
              this.setState({
                filter: event.target.value,
              });
            }}
            onKeyPress={event => {
              if (!event) return;
              if (event.key === 'Enter') {
                this.props.setState({ search: null });
              }
            }}
            sxb={{
              marginBottom: 1,
              border: '1px solid #e0e0e0',
              '@media (min-width:600px)': {
                marginLeft: 0,
                width: '100%',
              },
            }}
            sxi={{
              paddingLeft: 1.3,
            }}
            value={filter}
          />
          <List
            dense
            // sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            sx={{
              width: '100%',
              maxWidth: 360,
              bgcolor: 'background.paper',
              borderRadius: 2,
              boxShadow: 3, // Adds a subtle shadow around the list container
              padding: 1,
              maxHeight: 260, // Set the max height to allow scrolling after 5 items
              overflowY: 'auto', // Enables vertical scrolling
              paddingTop: 0, // Adds padding at the top for internal margin
              paddingBottom: 0, // Adds padding at the bottom for internal margin
              '&::-webkit-scrollbar': {
                // Customizes the scrollbar if needed
                width: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                borderRadius: 1,
              },
              marginBottom: 2,
            }}>
            <Box
              sx={{
                height: 9,
                bgcolor: 'background.paper',
                zIndex: 1200,
                position: 'sticky',
                top: 0,
              }}
            />
            {reviewers
              .filter(i => i.name.toLowerCase().includes(filter.toLowerCase()))
              .map(item => {
                const { value, src, name } = item;
                const labelId = `checkbox-list-secondary-label-${value}`;
                return (
                  <ListItem
                    disablePadding
                    key={value}
                    onClick={() => this.setState({ value: item.review })}
                    secondaryAction={
                      <Checkbox
                        checked={checked.includes(value)}
                        edge="end"
                        inputProps={{ 'aria-labelledby': labelId }}
                        onChange={() => this.handleToggle(value)}
                        sx={{
                          color: 'primary.main', // Checkbox color when unchecked
                          '&.Mui-checked': {
                            color: 'primary.dark', // Checkbox color when checked
                          },
                        }}
                      />
                    }
                    sx={{
                      border: '1px solid',
                      borderColor: 'divider',
                      borderRadius: 1,
                      marginBottom: '2px', // Adds space between list items
                      '&:last-child': {
                        marginBottom: 0, // Removes bottom margin for the last item
                      },
                    }}>
                    <ListItemButton>
                      <ListItemAvatar>
                        <Avatar
                          alt={`Avatar n°${value + 1}`}
                          src={src}
                          sx={{
                            width: 36,
                            height: 36,
                            border: '2px solid',
                            borderColor: 'primary.light',
                          }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        id={labelId}
                        primary={name}
                        primaryTypographyProps={{
                          fontSize: '0.9rem',
                          fontWeight: '500',
                          color: 'text.primary',
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            <Box
              sx={{
                height: 8,
                position: 'sticky',
                bottom: 0,
                bgcolor: 'background.paper',
              }}
            />
          </List>
          <TextArea
            // disabled
            label="Review"
            onChange={this.handleChange}
            value={this.state.value}
          />
        </CardContent>
      </Card>
    );
  }
}

ConfigReview.propTypes = {
  classes: PropTypes.object,
};

export default ConfigReview;
