import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import MemoryIcon from '@mui/icons-material/Memory';
import { GroupBox } from 'components';
import ButtonAddFolder from '../../../ToolBarBlockProcess/components/ButtonAddFolder';
class ConfigArchive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      folder: null,
    };
  }
  handleSelectFolder = folder => {
    this.setState({ folder });
  };
  render() {
    if (this.props.view !== 'Configuration') return null;
    const { folder } = this.state;
    return (
      <Card sx={{ height: '100%', margin: 1, marginTop: 0 }}>
        <CardContent>
          <Grid
            container
            direction="row"
            sx={{
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}>
            <Grid item>
              <MemoryIcon />
            </Grid>
            <Grid item>
              <Typography
                component="h2"
                gutterBottom
                sx={{ fontWeight: '600' }}
                variant="subtitle2">
                Select Folder
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container pt={2}>
            <Grid item sx={{ marginTop: 1, marginBottom: 1 }} xs={12}>
              <GroupBox title_box="Archive Folder">
                <ButtonAddFolder
                  folder={folder}
                  handleAddError={this.props.handleAddError}
                  handleSelectFolder={this.handleSelectFolder}
                  hide_plus
                  minWidth="100%"
                />
              </GroupBox>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

ConfigArchive.propTypes = {
  classes: PropTypes.object,
};

export default ConfigArchive;
