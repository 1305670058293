import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadMsCSV } from './utils';

const QUERY_MS_EDSS = gql`
  query($name: String, $search_query: String, $page: Int, $limit: Int) {
    msEdss(name: $name, search_query: $search_query, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      edss {
        id
        idPatient
        sequence_number
        date
        score
        fs1_visual
        fs2_brainstem
        fs3_pyramidal
        fs4_cerebellar
        fs5_sensory
        fs6_bowel_bladder
        fs7_cerebral
        tot_files
        tot_series
        tot_edfs
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryMsEdss = (name, search_query, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_MS_EDSS),
    variables: {
      name,
      search_query,
      page,
      limit,
    },
  });
};

const MUTATION_REMOVE_MS_EDSS = gql`
  mutation($ids: [LongLong!]!) {
    deleteMsEdss(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveMsEdss = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_MS_EDSS),
    variables: {
      ids,
    },
  });
};
const MUTATION_ADD_MS_EDSS = gql`
  mutation($inputs: [InputMsEdss!]!) {
    addMsEdss(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      total
      edss {
        id
        idPatient
        sequence_number
        date
        score
        fs1_visual
        fs2_brainstem
        fs3_pyramidal
        fs4_cerebellar
        fs5_sensory
        fs6_bowel_bladder
        fs7_cerebral
        tot_files
        tot_series
        tot_edfs
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationAddMsEdss = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_MS_EDSS),
    variables: {
      inputs,
    },
  });
};

const MUTATION_UPLOAD_MS_EDSS = gql`
  mutation($file: Upload!) {
    uploadMsEdss(file: $file) {
      ok
      errors {
        path
        message
      }
      total
      edss {
        id
        idPatient
        sequence_number
        date
        score
        fs1_visual
        fs2_brainstem
        fs3_pyramidal
        fs4_cerebellar
        fs5_sensory
        fs6_bowel_bladder
        fs7_cerebral
        tot_files
        tot_series
        tot_edfs
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationUploadMsEdss = (file, handleProgress = null) => {
  return MutationUploadMsCSV(file, MUTATION_UPLOAD_MS_EDSS, handleProgress);
};

const QUERY_SEARCH_MS_EDSS = gql`
  query($phrase: String, $page: Int, $limit: Int) {
    searchMsEdss(phrase: $phrase, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      edss {
        id
        idPatient
        sequence_number
        date
        score
        fs1_visual
        fs2_brainstem
        fs3_pyramidal
        fs4_cerebellar
        fs5_sensory
        fs6_bowel_bladder
        fs7_cerebral
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QuerySearchMsEdss = (phrase, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SEARCH_MS_EDSS),
    variables: {
      phrase,
      page,
      limit,
    },
  });
};
