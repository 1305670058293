import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { QueryFilesProjects } from 'graphql/Folders';

import { useMakeStyles } from '../Styles';
import { GetData, ServerErrorsString } from 'helpers';
import SearchAutocomplete from '../SearchAutocomplete';

const SearchFilesProjects = props => {
  const { style, selected, holder, sytle_autocomplete } = props;
  const { classes } = useMakeStyles();

  const [name, setCenterName] = React.useState(selected ? selected.name : '');
  const [center, setCenter] = React.useState(selected ? selected : {});
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState(selected ? [selected] : []);
  const loading = open && options.length === 0;
  if (holder) {
    holder.resetProject = () => {
      setCenterName('');
      setCenter({});
    };
  }
  React.useEffect(() => {
    if (!loading) {
      return undefined;
    }
    let final_name = name;
    if (name.startsWith('Without ') || name.startsWith('With ')) {
      final_name = '';
    }
    (async () => {
      QueryFilesProjects(final_name, 0, 5)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, projects } = data.fileProjects;
          console.log({ projects });
          let centers = [];
          if (ok) {
            if (projects.length === 0) {
              centers.push({ id: -1, name: 'Without Projects' });
            } else {
              centers = projects;
              if (props.add_empty) {
                centers.unshift({ id: -2, name: 'With Projects' });
                centers.unshift({ id: -1, name: 'Without Projects' });
              }
            }
          } else {
            throw errors;
          }
          setOptions(centers);
        })
        .catch(error => {
          setOptions([]);
          props.handleAddError(ServerErrorsString(error));
        });
    })();
  }, [loading, name]);
  const handelCenterNameChanged = event => {
    const name = event.target.value;
    //
    if (options.length) {
      const here = options.filter(x =>
        x.name.toUpperCase().startsWith(name.toUpperCase())
      );
      //
      if (!here.length || !name.length) {
        setOptions([]);
      }
    }
    setCenterName(name);
  };
  const handleAddSeletedItem = center => {
    setCenter(center);
    props.handleAddSeletedItem(center);
  };
  if (props.no_icon) {
    return (
      <SearchAutocomplete
        handelSearchNameChanged={handelCenterNameChanged}
        handleAddResultObject={handleAddSeletedItem}
        label="File project"
        loading={loading}
        name={name}
        no_icon
        open={open}
        options={options}
        placeholder={props.placeholder}
        selected={center}
        setObject={handleAddSeletedItem}
        setObjectName={setCenterName}
        setOpen={setOpen}
        setOptions={setOptions}
        small={props.small}
        sytle_autocomplete={sytle_autocomplete}
      />
    );
  }
  return (
    <Paper className={classes.root} style={style}>
      <SearchIcon className={classes.icon} />
      <SearchAutocomplete
        handelSearchNameChanged={handelCenterNameChanged}
        handleAddResultObject={handleAddSeletedItem}
        label="File project"
        loading={loading}
        name={name}
        open={open}
        options={options}
        placeholder={props.placeholder}
        selected={center}
        setObject={handleAddSeletedItem}
        setObjectName={setCenterName}
        setOpen={setOpen}
        setOptions={setOptions}
        small={props.small}
        variant="standard"
      />
    </Paper>
  );
};

SearchFilesProjects.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  style: PropTypes.object,
};

SearchFilesProjects.defaultProps = {
  placeholder: 'Search file projects...',
  no_icon: false,
  handleAddSeletedItem: () => '',
  selected: null,
};

export default SearchFilesProjects;
