import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { amber, green, red } from '@mui/material/colors';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};
const useStyles = theme => ({
  text: {
    padding: 0,
    maring: 0,
    paddingLeft: theme.spacing(1),
    fontWeight: 500,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

class SnackDroppedFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getColor = () => {
    const { snackbarVariant } = this.props;
    if (snackbarVariant === 'success') return green[600];
    if (snackbarVariant === 'error') return red[900];
    if (snackbarVariant === 'info') return amber[600];
    if (snackbarVariant === 'warning') return amber[600];
    return '';
  };
  render() {
    const {
      classes,
      snackbarMessage,
      showAlerts,
      snackbarVariant,
    } = this.props;
    //
    if (!showAlerts) return null;
    // return null;
    const Icon = variantIcon[snackbarVariant];
    return (
      <Snackbar
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            key="close"
            onClick={this.props.onCloseSnackbar}
            size="large">
            <CloseIcon />
          </IconButton>
        }
        anchorOrigin={this.props.position_snack}
        autoHideDuration={6000}
        ContentProps={{
          sx: {
            background: this.getColor(),
          },
        }}
        message={
          <span className={classes.message} id="client-snackbar">
            <Icon classes={clsx(classes.icon, classes.iconVariant)} />
            <div className={classes.text}>{snackbarMessage}</div>
          </span>
        }
        onClose={this.props.onCloseSnackbar}
        open={this.props.openSnackBar}
        style={{
          height: 'auto',
          lineHeight: '28px',
          padding: 24,
          whiteSpace: 'pre-line',
        }}
      />
    );
  }
}
{
  /* <SnackbarContentWrapper
          message={snackbarMessage}
          onClose={this.props.onCloseSnackbar}
          variant={snackbarVariant}
        />
      </Snackbar>  */
}

SnackDroppedFiles.propTypes = {
  classes: PropTypes.object.isRequired,
};
SnackDroppedFiles.defaultProps = {
  position_snack: {
    vertical: 'bottom',
    horizontal: 'left',
  },
};
export default withStyles(SnackDroppedFiles, useStyles);
