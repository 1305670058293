import React from 'react';
import PropTypes from 'prop-types';
import { GetData, ServerErrorsString, IsInvalid } from 'helpers';
import {
  Dialog,
  Typography,
  DialogContent,
  Divider,
  Grid,
  Stack,
  ButtonGroup,
  Button,
} from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import { CardEditHeader } from 'components/Cards';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { QueryMsCase } from 'graphql/DataClinics';
import {
  comorbidities,
  medication,
  edss,
  immunotherapy,
  msfc,
} from 'views/DataClinics/components/ClinicMS/components/utils_columns';
import {
  ClinicalFileCatalogueViewer,
  ClinicalPatientViewer,
  ClinicalChildObjectViewer,
} from 'components/DataClinic';
import { ButtonDownloadPDF } from 'components';
import { header_patients } from '../../../views/DataClinics/components/PatientClinics/components/Styles';

class DialogViewMsCase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      case_obj: null,
      pdfRequested: false,
    };
  }
  componentDidMount() {
    this.queryMsCase();
  }
  componentDidUpdate() {
    this.queryMsCase();
  }

  queryMsCase = () => {
    const { loading, case_obj } = this.state;
    const { open, ms_case } = this.props;
    if (!open || case_obj) return;
    if (loading) return;
    if (IsInvalid(ms_case)) return;
    if (IsInvalid(ms_case.idPatient)) return;
    this.setState({ loading: true });
    (async () => {
      QueryMsCase(ms_case.idPatient)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, cases } = data.msCase;
          if (ok) {
            this.setState({
              loading: false,
              case_obj: cases[0],
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };

  setPdfRequested = () => {
    this.setState({ pdfRequested: !this.state.pdfRequested });
  };

  handleClose = () => {
    console.log('handleClose');
    this.setState({ case_obj: null });
    this.props.handleClose();
  };
  render() {
    const { open } = this.props;
    const { case_obj } = this.state;
    if (IsInvalid(case_obj)) return null;
    if (IsInvalid(case_obj.comorbidities)) return null;
    if (IsInvalid(case_obj.medications)) return null;
    if (IsInvalid(case_obj.edsses)) return null;
    if (IsInvalid(case_obj.immunotherapies)) return null;
    if (IsInvalid(case_obj.msfcs)) return null;
    const pageId = 'clinical_ms';
    // let ids;
    // if (case_obj) {
    //   ids = [case_obj.id];
    // }
    let ComponentHeaderView = null;
    ComponentHeaderView = (
      <React.Fragment>
        <CardEditHeader
          description={'MS patient ID: ' + case_obj.patient.PatientID}
          handleClose={this.handleClose}
          icon={<VisibilityIcon style={{ color: 'green' }} />}
          title={
            <Typography
              display="inline"
              gutterBottom
              style={{
                marginRight: '15px',
                textTransform: 'none',
                fontWeight: 600,
              }}
              variant="h5">
              MS Case Viewer
            </Typography>
          }
        />
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          pb={0.5}
          pt={0.5}>
          <Grid item ml={7} xs={12}>
            <ButtonGroup aria-label="Basic button group" variant="outlined">
              <Button style={{ width: '26px', height: '26px' }}>
                <ButtonDownloadPDF
                  downloadFileName={pageId}
                  rootElementId="download_button"
                  setPdfRequested={this.setPdfRequested}
                />
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </React.Fragment>
    );
    return (
      <div>
        <Dialog fullWidth maxWidth="lg" onClose={this.handleClose} open={open}>
          {ComponentHeaderView}
          <Divider />
          <DialogContent dividers={scroll === 'paper'}>
            <Grid
              columns={{ xs: 4, sm: 8, md: 12 }}
              container
              direction="row-reverse"
              id="download_button"
              spacing={{ xs: 2, md: 3 }}>
              <Grid item md={12} sm={12} xs={12}>
                <CardHeader
                  title={
                    <Typography
                      display="inline"
                      gutterBottom
                      style={{
                        marginRight: '15px',
                        textTransform: 'none',
                        fontWeight: 600,
                      }}
                      variant="h4">
                      MS Case Viewer
                    </Typography>
                  }
                />
                <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
              </Grid>
              <Grid
                id="clinical_patient"
                item
                xs={this.state.pdfRequested ? 12 : 4}>
                <Stack direction="column">
                  <ClinicalPatientViewer
                    columns={header_patients}
                    currentCase={case_obj}
                  />
                  <Divider
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  />
                  {case_obj.files && (
                    <ClinicalFileCatalogueViewer
                      files={case_obj.files}
                      history={this.props.history}
                    />
                  )}
                </Stack>
              </Grid>
              <Grid item xs={this.state.pdfRequested ? 12 : 8}>
                <Stack direction="column">
                  <ClinicalChildObjectViewer
                    columns={comorbidities}
                    object={case_obj.comorbidities}
                    title="Ms Comorbidities"
                  />
                  <Divider
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  />
                  <ClinicalChildObjectViewer
                    columns={medication}
                    object={case_obj.medications}
                    title="Ms Medications"
                  />
                  <Divider
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  />
                  <ClinicalChildObjectViewer
                    columns={edss}
                    object={case_obj.edsses}
                    title="Ms Edsses"
                  />
                  <Divider
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  />
                  <ClinicalChildObjectViewer
                    columns={immunotherapy}
                    object={case_obj.immunotherapies}
                    title="Ms Immunotherapies"
                  />
                  <Divider
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  />
                  <ClinicalChildObjectViewer
                    columns={msfc}
                    object={case_obj.msfcs}
                    title="Ms Msfcs"
                  />
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

DialogViewMsCase.propTypes = {
  epilepsy_case: PropTypes.object,
  handleAddError: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};
DialogViewMsCase.defaultProps = {
  handleClose: () => '',
  handleAddError: () => '',
  open: false,
  epilepsy_case: null,
};

export default DialogViewMsCase;
