import {
  LOGOUT_PAGE_PIPELINE_BLOCKS,
  PAGE_PIPELINE_BLOCKS,
} from '../actions/types';

const initialState = {
  diagram: {
    id: null,
    name: '',
    zoomLevel: 1,
    panOffset: { x: 0, y: 0 },
  },
};

export default function reducer_page_project_view(
  state = initialState,
  action
) {
  const { type } = action;
  switch (type) {
    case LOGOUT_PAGE_PIPELINE_BLOCKS:
      return initialState;
    case PAGE_PIPELINE_BLOCKS:
      return {
        ...state,
        diagram: action.diagram,
      };
    default:
      return state;
  }
}
