import {
  createBoxTitle,
  createLine,
  createRectangle,
  createText,
  getConfig,
} from '../../utils_blocks';
import { getBlockConfig } from '../../utils_svg';
import { Block } from '../base';

class ConsensusBlock extends Block {
  constructor(
    x,
    y,
    self,
    width = 200,
    height = 340,
    config = getBlockConfig('Consensus')
  ) {
    const contentHeight = 210; // 40px for top, 60px for bottom
    const bottomHeight = 155;
    super(
      x,
      y,
      width,
      height,
      config,
      contentHeight,
      bottomHeight,
      250,
      390,
      '#eceff1'
    );
    this.addPort('Input data', false, 20, '#cddc39'); // Add port on the left side
    this.addPort('Agreement', true, 60, '#4caf50'); // Add port on the left side
    this.addPort('Disagrement', true, 100, '#f4511e'); // Add port on the left side
  }

  drawContent(ctx) {
    super.drawContent(ctx);
    const { scaleX, scaleY } = this.getScalingFactor();
    const padding = 20 * scaleX;
    const topSeparatorY = this.y + this.topHeight; // Y-coordinate of the top separator
    // const bottomSeparatorY = this.y + this.height - this.bottomHeight * scaleY; // Y-coordinate of the bottom separator

    // Calculate position for "MRI Segmentation" title
    const titleSeparatorY = topSeparatorY + this.topHeight;
    const titleY = titleSeparatorY - 15 * scaleY; // Moved down 5 pixels from previous position

    // Draw title
    const config = getConfig(this.x + padding, titleY, '#4caf50');
    config.width = this.width;
    config.lineWidth = 1;
    createText(ctx, 'Agreement', config);

    let position = titleY + 15 * scaleY;
    config.x = this.x;
    config.y = position;
    config.color = '#e0e0e0';
    createLine(ctx, config);

    config.color = '#f4511e';
    config.x = this.x + padding;
    config.y = position + 25 * scaleY;
    createText(ctx, 'Disagrement', config);

    config.x = this.x;
    config.y = position + this.topHeight;
    config.color = '#e0e0e0';
    createLine(ctx, config);

    const w = config.y - topSeparatorY;

    config.x = this.x + padding;
    config.y = config.y + (padding / 2) * scaleY;
    config.color = '#e0e0e0';
    config.width = this.width - 2 * padding * scaleY;
    config.height = this.contentHeight * scaleY - w - (padding / 2) * scaleY;
    config.lineWidth = 1;
    config.radius = 10;
    createRectangle(ctx, config);

    config.color = '#b388ff';
    config.font = `${13 * scaleX}px Arial`; // Scale font size based on scaleX
    config.scaleX = scaleX;
    config.scaleY = scaleY;
    createBoxTitle(ctx, 'Anotate', config);
    config.y = config.y + this.topHeight;
    config.color = '#e0e0e0';
    createLine(ctx, config);
    config.y = config.y + this.topHeight;
    createLine(ctx, config);
  }

  drawBottom(ctx) {
    const postSeparatorY = super.drawBottom(ctx, false);
    const { scaleX, scaleY } = this.getScalingFactor();
    // const yTopBottom = super.drawBottom(ctx);
    const padding = 20 * scaleX;
    const config = getConfig(
      this.x + padding,
      postSeparatorY + (padding / 2) * scaleY,
      this.color_logo
    );
    config.x = this.x + padding;
    config.y = postSeparatorY + (padding / 2) * scaleY;
    config.color = '#e0e0e0';
    config.width = this.width - 2 * padding * scaleY;
    config.height =
      this.height -
      this.contentHeight * scaleY -
      this.topHeight -
      padding * scaleY;
    config.lineWidth = 1;
    config.radius = 10;
    createRectangle(ctx, config);

    config.font = `${13 * scaleX}px Arial`; // Scale font size based on scaleX
    config.scaleX = scaleX;
    config.scaleY = scaleY;
    config.color = '#64ffda';
    createBoxTitle(ctx, 'Model', config);
    config.y = config.y + this.topHeight;
    config.color = '#e0e0e0';
    createLine(ctx, config);
    config.y = config.y + this.topHeight;
    createLine(ctx, config);
  }
}

export default ConsensusBlock;
