import React from 'react';
import PropTypes from 'prop-types';
import {
  // Box,
  Chip,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import Chart from 'react-apexcharts';
import { withStyles } from 'tss-react/mui';
const useStyles = () => ({
  chart: {
    '& .apexcharts-tooltip.apexcharts-theme-light': {
      color: '#e2dede',
      background: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(10px)',
      boxShadow: 'none',
      padding: '8px 6px 4px',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: ' 0.5px',
      border: 0,
    },
    '& .apexcharts-tooltip-title': {
      display: 'none', // Hides the tooltip title
    },
    '& .apexcharts-zaxistooltip-bottom.apexcharts-theme-light': {
      display: 'none', // Hides the x-axis tooltip
    },
  },
});
class ReviewStatistic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartOptions: {
        chart: {
          type: 'bar',
          height: '100%', // Scales chart height to container
          toolbar: {
            show: false, // Hides the toolbar
          },
          zoom: {
            enabled: false, // Globally disable zooming
            mouseWheel: {
              enabled: false, // Specifically disable mouse wheel zoom
            },
          },
          pan: {
            enabled: false, // Disable panning as well
          },
        },
        series: [
          {
            name: 'Week',
            data: [10, 20, 30, 40, 50, 40, 30],
          },
        ],
        xaxis: {
          show: false,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function(val) {
              const week = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
              return week[val - 1];
            },
          },
        },
        yaxis: {
          labels: {
            show: false, // Hides the Y-axis values
          },
          axisBorder: {
            show: false, // Hides the Y-axis border line
          },
          axisTicks: {
            show: false, // Hides the ticks on Y-axis
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false, // Hides the grid lines for Y-axis
            },
          },
          padding: {
            top: -25,
            left: 0,
            right: 0,
            bottom: -5,
          },
        },
        dataLabels: {
          enabled: false, // Removes values inside the bars
        },
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            distributed: true, // this line is mandatory
            horizontal: false,
            borderRadius: 5,
            highlightOnHover: false,
            borderRadiusApplication: 'around',
            borderRadiusWhenStacked: 'last',
            barHeight: '90%',
            columnWidth: '45%',
            dataLabels: {
              style: {
                colors: ['#000000'], // Default label color (set to black initially)
              },
              background: {
                enabled: false, // Disable background
              },
            },
          },
        },
        colors: [
          '#d6f5c6',
          '#6aeb2a',
          '#d6f5c6',
          '#d6f5c6',
          '#d6f5c6',
          '#d6f5c6',
          '#d6f5c6',
        ],
      },
    };
  }

  render() {
    const { fullScreen, width, classes } = this.props;
    const { chartOptions } = this.state;
    let style = {};
    if (!fullScreen) {
      style = {
        height: 280,
        overflowX: 'auto',
      };
    }
    return (
      <Grid
        component={Paper}
        container
        spacing={2}
        sx={{
          marginTop: 1,
          marginLeft: 1,
          width: 'calc(100% - 16px)',
          ...style,
        }}>
        <Grid item pb={2} pr={2} sm={fullScreen ? 6 : undefined} xs={12}>
          <Grid
            item
            pb={0}
            sm={fullScreen ? 6 : undefined}
            sx={{ backgroundColor: '#b0bec5' }}
            xs={12}>
            <Chart
              className={classes.chart}
              height={fullScreen ? 180 : '100%'}
              options={chartOptions}
              series={chartOptions.series}
              type="bar"
              width={fullScreen ? width / 2.5 : '100%'}
            />
          </Grid>
          <Grid
            container
            direction="column"
            item
            m={0}
            p={0}
            sx={{ gap: 6 }}
            xs={12}>
            <Grid container item sx={{ gap: 2 }}>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '1.1rem',
                  color: 'rgb(46 38 61 / 0.7)',
                }}
                variant="h5">
                Reviews statistics
              </Typography>
              <Grid container direction="row" item sx={{ gap: 2 }}>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '0.93rem',
                    color: 'rgb(46 38 61 / 0.7)',
                  }}
                  variant="body1">
                  12 New reviews
                </Typography>
                <Grid item>
                  <Chip
                    label="+8.4 %"
                    sx={{
                      height: 24,
                      minWidth: '100%',
                      color: '#56CA00',
                      fontWeight: 500,
                      fontSize: '0.81rem',
                      backgroundColor: 'rgb(86 202 0 / 0.16)',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                component="p"
                sx={{
                  fontWeight: 400,
                  fontSize: '0.93rem',
                  color: 'rgb(46 38 61 / 0.7)',
                }}
                variant="body1">
                <span style={{ color: '#64dd17', marginRight: 3 }}>87%</span>
                Positive reviews
              </Typography>
              <Typography
                component="p"
                sx={{
                  fontWeight: 400,
                  fontSize: '0.81rem',
                  color: 'rgb(46 38 61 / 0.6)',
                }}
                variant="body2">
                Weekly Report
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ReviewStatistic.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(ReviewStatistic, useStyles);
