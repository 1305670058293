import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import BackupIcon from '@mui/icons-material/Backup';
import { green, blue } from '@mui/material/colors';
import { withStyles } from 'tss-react/mui';
import { IconButtonLoad } from 'components';
import {
  DownloadItemsAtKey,
  GenerateDownload,
  MutationDownloadFinished,
} from 'graphql/Download';
import {
  ServerErrorsString,
  GetData,
  GetNameFromHeader,
  GetResponseError,
  IsValid,
} from 'helpers';
import DialogSelectDownloadColumns from 'components/Dialogs/DialogSelectDownloadColumns';

const useStyles = () => ({
  fabProgress: {
    color: green[800],
    position: 'absolute',
    top: 3,
    left: 8,
    zIndex: 1,
  },
});
class ButtonDownloadType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
      open_column_selection: false,
    };
  }
  handleContentDownload = () => {
    const { downloading } = this.state;
    const { type, idProject, inputs, save } = this.props;    
    if (downloading || type === undefined || !type) return;
    this.setState({ downloading: true });
    this.props.handleDownloadTriggered(true);
    const input = { type, save };
    if (idProject) {
      input.idProject = idProject;
    }
    if (inputs) {
      input.inputs = JSON.stringify(inputs);
    }
    (async () => {
      let download_key = null;
      try {
        const res = await GenerateDownload([input]);
        
        const data = GetData(res);
        
        const {
          downloadGenerate: { ok, key, errors: server_error },
        } = data;
        if (ok) {
          download_key = key;
        } else if (server_error) {
          this.setState({
            downloading: false,
            error: ServerErrorsString(server_error),
          });
          this.props.handleDownloadTriggered(false);
          return;
        }
      } catch (error) {
        this.setState({
          downloading: false,
          error: ServerErrorsString(error),
        });
        this.props.handleDownloadTriggered(false);
        return;
      }   
      DownloadItemsAtKey(download_key)
        .then(async response => {
          const name = GetNameFromHeader(response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          link.style.display = 'none';
          link.target = '_blank';
          link.hidden = true;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.setState({ downloading: false });
          await MutationDownloadFinished(download_key);
          this.props.handleDownloadTriggered(false);
        })
        .catch(async error => {
          error = await GetResponseError(error);
          console.log({ error });
          this.setState({ downloading: false });
          this.props.handleDownloadTriggered(false);
        });
    })();
  };
  handleDownloadColumns = selected_columns => {
    const { inputs } = this.props;
    this.setState({ open_column_selection: false });
    let inp = inputs;
    inp.selected_columns = selected_columns;
    this.props.setState({ inputs: inp });
    this.handleContentDownload();
  };
  handleOnClickDownload = () => {
    const { columns } = this.props;
    if (IsValid(columns) && columns.length > 0) {
      this.setState({ open_column_selection: true });
    } else {
      this.handleContentDownload();
    }
  };
  render() {
    const { classes, upload, disabled, columns, circular } = this.props;
    
    let { downloading } = this.state;
    let icon = (
      <CloudDownloadIcon
        style={{ color: !downloading && !disabled ? blue[500] : 'gray' }}
      />
    );
    if (upload) {
      icon = (
        <BackupIcon
          style={{ color: !downloading && !disabled ? blue[500] : 'gray' }}
        />
      );
    }
    if (circular) {
      return (
        <IconButtonLoad
          disabled={downloading || disabled}
          handleClick={this.handleContentDownload}
          icon={icon}
          loading={downloading}
          tooltip={this.props.tooltip}
        />
      );
    }
    return (
      <>
        <Button
          disabled={this.props.disabled}
          onClick={this.handleOnClickDownload}
          style={{ margin: 0, padding: 0 }}>
          {icon}
          {downloading && (
            <CircularProgress className={classes.fabProgress} size={25} />
          )}
        </Button>

        {this.state.open_column_selection ? (
          <DialogSelectDownloadColumns
            columns={columns}
            downloading={downloading}
            handleAddError={this.props.handleAddError}
            handleClose={() => {
              this.setState({ open_column_selection: false });
            }}
            handleDownload={selected_columns =>
              this.handleDownloadColumns(selected_columns)
            }
            open={this.state.open_column_selection}
            studyName={this.props.studyName}
            table={this.props.table}
          />
        ) : null}
      </>
    );
  }
}

ButtonDownloadType.propTypes = {
  circular: PropTypes.bool,
  classes: PropTypes.object,
  type: PropTypes.string,
  upload: PropTypes.bool,
};
ButtonDownloadType.defaultProps = {
  circular: false,
  upload: false,
  disabled: false,
  save: false,
  handleDownloadTriggered: () => '',
};

export default withStyles(ButtonDownloadType, useStyles);
