import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, ImageList, ImageListItem, Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { getStudySeriesThumbnail } from 'helpers';
import { connect } from 'react-redux';

const useStyles = (theme, props) => ({
  item: {
    backgroundColor: 'white',
    margin: 1,
    width: 'auto',
    // height: 'auto',
    minHeight: 100,
  },
  image: {
    width: props.width,
    maxHeight: 100,
  },
});

class StudySeriesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
    };
  }
  getGridListCols = () => {
    let { isDesktop } = this.props;
    let { width } = this.state;
    if (!width) {
      width = window.innerWidth;
    }
    width = width - 100;
    if (isDesktop) {
      width = width - 300;
    }
    const cols = parseInt(width / 90, 10);
    // const { ListSeries } = this.props;
    // if (ListSeries && ListSeries.length < cols) {
    //   return ListSeries.length;
    // }
    if (cols) {
      return cols;
    }
    return 1;
  };
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  render() {
    const { classes, ListSeries, width } = this.props;
    // console.log({ w: this.state.width, h: this.state.height })
    return (
      <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <ImageList
          cols={this.getGridListCols()}
          style={{ width: '100%', padding: 2, paddingBottom: 4 }}
          sx={{
            width: 50,
          }}>
          {ListSeries.map(series => {
            //
            let thumbnail = getStudySeriesThumbnail(series);
            // if (thumbnail && !thumbnail.startsWith('/images/')) {
            //   thumbnail = `data:image/jpeg;base64,${thumbnail}`;
            // }
            return (
              <ImageListItem
                key={`item-series-${series.idSeries}`}
                style={{
                  margin: 2,
                  paddingBottom: 1,
                  maxWidth: 100,
                  height: '100%',
                  boxShadow: '-1px 1px 5px 0px rgba(0,0,0,0.20)',
                  '-webkit-box-shadow': '-1px 1px 5px 0px rgba(0,0,0,0.20)',
                  // '-moz-box-shadow': '-1px 1px 5px 0px rgba(0,0,0,0.20)',
                  MozBoxShadow: '-1px 1px 5px 0px rgba(0,0,0,0.20)',
                }}>
                <Box className={classes.item}>
                  <Grid
                    alignItems="center"
                    container
                    justifyContent="center"
                    style={{ minHeight: 100 }}>
                    <img
                      alt={series.SeriesDescription}
                      className={classes.image}
                      src={thumbnail}
                    />
                  </Grid>
                  <Box pr={2} style={{ margin: 2 }} width={width - 4}>
                    <Typography
                      align="left"
                      color="textSecondary"
                      display="block"
                      noWrap
                      variant="caption">
                      {`${series.SeriesDescription}`}
                    </Typography>
                    <Typography
                      align="left"
                      color="textSecondary"
                      display="block"
                      variant="caption">
                      {`Files: ${series.NumberOfSeriesRelatedInstances}`}
                    </Typography>
                  </Box>
                </Box>
              </ImageListItem>
            );
          })}
        </ImageList>
      </div>
    );
  }
}

StudySeriesList.propTypes = {
  classes: PropTypes.object,
};
StudySeriesList.defaultProps = {
  width: 90,
};
const mapStateToProps = state => ({
  user: state.info_user.user,
  isDesktop: state.layout.isDesktop,
});
export default connect(mapStateToProps)(withStyles(StudySeriesList, useStyles));
