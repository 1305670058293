import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_AI_TRAINER_MODELS = gql`
  query($name: String, $page: Int, $limit: Int) {
    dockerAITrainerModels(name: $name, page: $page, limit: $limit) {
      ok
      total
      ai_trainers {
        id
        name
        type
      }
      errors {
        path
        message
      }
    }
  }
`;

export const QueryAITrainerModel = (name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_AI_TRAINER_MODELS),
    variables: {
      name,
      page,
      limit,
    },
  });
};
