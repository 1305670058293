import Line from './Line';
const radius = 7;
class Port {
  constructor(x, y, color, parent_block) {
    this.id = `${x}-${y}`;
    this.name = '';
    this.x = x;
    this.y = y;
    this.radius = radius;
    this.color = color;
    this.block = parent_block;
    this.visible = true;
    this.startLines = [];
    this.arrivedLines = [];
  }
  isSavedPort() {
    if (this.id) return true;
    return false;
  }
  getFirstLine() {
    if (this.arrivedLines.length) return this.arrivedLines[0];
    if (this.startLines.length) return this.startLines[0];
    return null;
  }
  getLines() {
    if (this.arrivedLines.length) return this.arrivedLines;
    if (this.startLines.length) return this.startLines;
    return [];
  }
  createStartLine() {
    const index = this.block.outPorts.findIndex(p => p === this);
    const newLine = new Line(this.block, null, index >= 0 ? index : 0);
    newLine.color = this.color;
    return newLine;
  }

  isOutputPort() {
    if (this.block.isOutputPort(this)) return true;
    return false;
  }
  isLineHere(line) {
    for (let i = 0; i < this.startLines.length; i++) {
      if (line === this.startLines[i]) return true;
    }
    for (let i = 0; i < this.arrivedLines.length; i++) {
      if (line === this.arrivedLines[i]) return true;
    }
    return false;
  }
  connectStartLine(line) {
    // console.log('connectStartLine');
    if (!this.isLineHere(line)) this.startLines.push(line);
  }
  connectArrivedLine(line) {
    if (!this.isLineHere(line)) this.arrivedLines.push(line);
  }
  disconnectLine(line) {
    let index = this.startLines.findIndex(obj => obj === line);
    if (index !== -1) {
      console.log(`total lines: ${this.startLines.length}`);
      this.startLines.splice(index, 1); // Removes the object from the array
      console.log(
        `Start line at position ${index} removed, total: ${this.startLines.length}`
      );
    } else {
      index = this.arrivedLines.findIndex(obj => obj === line);
      if (index !== -1) {
        console.log(`total arriving lines: ${this.arrivedLines.length}`);
        this.arrivedLines.splice(index, 1); // Removes the object from the array
        console.log(
          `Arrived line at position ${index} removed, total: ${this.arrivedLines.length}`
        );
      } else return false;
    }
    line.removeBlock(this.block);
    return true;
  }
  // New function added to detect if the mouse is on top of the port
  isMouseOver(x, y) {
    const dx = x - this.x;
    const dy = y - this.y;
    return Math.sqrt(dx * dx + dy * dy) < this.radius; // Assuming a radius of 5 for the port
  }

  draw(ctx) {
    // Draw outer circle
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
    ctx.fillStyle = '#fff';
    ctx.fill();
    ctx.strokeStyle = this.color;
    ctx.lineWidth = 2;
    ctx.stroke();

    // Draw inner circle
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.radius - 3, 0, Math.PI * 2);
    ctx.fillStyle = this.color;
    ctx.fill();
  }
  drawLines(ctx, blocks = []) {
    let lines = this.startLines;
    if (this.arrivedLines.length) {
      lines = this.startLines.concat(this.arrivedLines);
    }
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].isConnected(true)) {
        const startBlock = lines[i].getStartBlock();
        const endBlock = lines[i].getEndBlock();
        if (
          endBlock &&
          startBlock &&
          (endBlock.selected || startBlock.selected)
        ) {
          const B = startBlock.selected ? startBlock : endBlock;
          const P = startBlock.selected
            ? lines[i].getEndPort()
            : lines[i].getStartPort();
          if (B.isPortInside(P)) continue;
        }
        lines[i].draw(ctx, blocks);
      }
    }
  }
}
export default Port;
