import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Divider } from '@mui/material';

import { useStyles } from './components/styles';
import { withStyles } from 'tss-react/mui';
import { StackMiddle, TableCustom, TabsCustom } from './components';

class MaterialUiTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modified: [],
      all: 0,
      active: 0,
      pending: 0,
      blocked: 0,
      value: 0,
      status: 'All',
      selected: [],
      search: null,
      textsearchs: {},
      anchorEl: null,
      menu_selected: 'Newest',
    };
  }
  handleChange = (event, value) => {
    const status = ['All', 'Active', 'Pending', 'Blocked'];
    this.setState({ value, status: status[value] });
  };
  handleDelete = () => {
    const { selected, data } = this.state;
    this.setState({
      selected: [],
      data: data.filter(
        r => selected.findIndex(p => p.user.name === r.user.name) === -1
      ),
    });
  };
  handleSearchClick = search => {
    this.setState({ search });
  };
  handleClose = menu_selected => {
    const state = { anchorEl: null };
    if (menu_selected) state.menu_selected = menu_selected;
    this.setState(state);
  };
  render() {
    const { classes, height, minWidth } = this.props;
    let height_table = height - 535;
    console.log({ height, height_table });
    if (height_table < 200) height_table = 200;
    const {
      value,
      active,
      blocked,
      pending,
      selected,
      search,
      textsearchs,
    } = this.state;
    const count_searchs = Object.keys(textsearchs).length;
    const width = minWidth < 310 ? minWidth - 5 : minWidth - 15;
    return (
      <Paper
        sx={{
          boxShadow: 'var(--Paper-shadow)',
          backgroundImage: 'none',
          overflow: 'hidden',
          borderRadius: '20px',
          marginRight: 1,
          marginLeft: 1,
          maxWidth: width,
          minWidth: width,
        }}>
        <TabsCustom
          active={active}
          all={this.state.all}
          blocked={blocked}
          classes={classes}
          handleChange={this.handleChange}
          pending={pending}
          textsearchs={textsearchs}
          value={value}
        />
        <Divider />
        <StackMiddle
          classes={classes}
          count_searchs={count_searchs}
          handleDelete={this.handleDelete}
          handleSearchClick={this.handleSearchClick}
          search={search}
          selected={selected}
          setState={state => this.setState(state)}
          textsearchs={textsearchs}
        />
        <Divider
          sx={{
            margin: ' 0px',
            flexShrink: 0,
            borderWidth: '0px 0px thin',
            borderStyle: 'solid',
            borderColor: 'var(--mui-palette-divider)',
            '--mui-palette-divider': '#dcdfe4',
          }}
        />
        <TableCustom
          height_table={height_table}
          setState={state => this.setState(state)}
          status={this.state.status}
        />
      </Paper>
    );
  }
}
MaterialUiTabs.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(MaterialUiTabs, useStyles);
