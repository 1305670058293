import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_DATABASE_TABLE = gql`
  query($inputs: [InputDatabaseTable!]!) {
    queryDatabaseTables(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      tables {
        table_name
        columns {
          fieldName
          type
          allowNull
          primaryKey
          defaultValue
          unique
          references
          autoIncrement
          comment
          validate
        }
      }
      total
    }
  }
`;
export const QueryDatabaseTables = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_DATABASE_TABLE),
    variables: {
      inputs,
    },
  });
};
