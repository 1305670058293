import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
const getInputBlocks = (b, save_as) => {
  const { x, y, title, width, height, color } = b;
  let idBlock = null;
  if (!b.isSavedBlock() && !save_as) {
    idBlock = b.id;
  }
  return {
    idBlock,
    x,
    y,
    width,
    height,
    title,
    color,
    type: title,
    in_ports: b.inPorts.map(p => ({
      id: p.id ? (`${p.x}-${p.y}` === p.id ? null : p.id) : null,
      idBlock: idBlock ? `${idBlock}` : `${title}-${p.x}x${p.y}`,
      x: p.x,
      y: p.y,
      color: p.color,
      name: p.name,
      visible: p.visible,
    })),
    out_ports: b.outPorts.map(p => ({
      id: p.id ? (`${p.x}-${p.y}` === p.id ? null : p.id) : null,
      idBlock: idBlock ? `${idBlock}` : `${title}-${p.x}x${p.y}`,
      x: p.x,
      y: p.y,
      color: p.color,
      name: p.name,
      visible: p.visible,
      out_lines: p.startLines.map(l => {
        let idText = `${l.blockA.title}-(${l.blockA.x}x${l.blockA.y})`;
        const idStartBlock = isOnlyNumber(l.blockA.id)
          ? `${l.blockA.id}`
          : idText;
        const startPort = l.getStartPort();
        let idStartPort = `${idText}-p-(${startPort.x}x${startPort.y})`;
        if (startPort.isSavedPort()) idStartPort = null;

        idText = `${l.blockB.title}-(${l.blockB.x}x${l.blockB.y})`;
        const idEndBlock = isOnlyNumber(l.blockB.id)
          ? `${l.blockB.id}`
          : idText;
        const endPort = l.getEndPort();
        let idEndPort = `${idText}-p-(${endPort.x}x${endPort.y})`;
        if (endPort.isSavedPort()) idEndPort = null;
        return {
          id: l.id,
          idStartBlock,
          idStartPort: idStartPort ? idStartPort : `${startPort.id}`,
          idEndBlock,
          idEndPort: idEndPort ? idEndPort : `${endPort.id}`,
        };
      }),
    })),
  };
};
const isOnlyNumber = value => {
  if (typeof value === 'string') {
    // Check if the string contains only numeric characters
    if (/^\d+$/.test(value)) {
      console.log(`Numeric string (${value})`);
      return true;
    } else {
      console.log(`String with non-numeric characters (${value})`);
      return false;
    }
  } else if (typeof value === 'number') {
    console.log(`Number (${value})`);
    return true;
  } else {
    console.log(`Not a number or string (${value})`);
  }
  return false;
};
const MUTATION_SAVE_DIAGRAM = gql`
  mutation($diagram_name: String!, $diagram: InputDiagram!) {
    saveDiagram(diagram_name: $diagram_name, diagram: $diagram) {
      ok
      errors {
        path
        message
      }
      diagram {
        id
        name
        zoomLevel
        panOffset {
          x
          y
        }
        total
        blocks {
          id
          title
          x
          y
          inPorts {
            id
            x
            y
            in_lines {
              id
              idStartBlock
              idStartPort
              idEndBlock
              idEndPort
            }
            out_lines {
              id
              idStartBlock
              idStartPort
              idEndBlock
              idEndPort
            }
          }
          outPorts {
            id
            x
            y
            in_lines {
              id
              idStartBlock
              idStartPort
              idEndBlock
              idEndPort
            }
            out_lines {
              id
              idStartBlock
              idStartPort
              idEndBlock
              idEndPort
            }
          }
        }
      }
    }
  }
`;

export const MutationSaveDiagram = (
  diagram,
  diagram_name,
  blocks,
  save_as = false
) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_SAVE_DIAGRAM),
    variables: {
      diagram_name,
      diagram: {
        idDiagram: save_as ? null : diagram.id,
        zoomLevel: diagram.zoomLevel,
        panOffsetX: diagram.panOffset.x,
        panOffsetY: diagram.panOffset.y,
        blocks: blocks.map(b => getInputBlocks(b, save_as)),
        save_as,
      },
    },
  });
};
