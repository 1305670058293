import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { TableGrid } from 'components';
import TabRulesToolbar from './TabRulesToolbar';
import {
  MutationAddAnonymizationRules,
  QueryAnonymizationRules,
} from 'graphql/Anonymization';
import { ServerErrorsString, GetData, rowsPerPageBig } from 'helpers';
import { columns_ano_rules } from '../Styles';

class TabRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rules: [],
      selected: [],
      rows_edited: [],
      page: 0,
      limit: 100,
      init: false,
    };
  }
  componentDidMount() {
    this.handleLoadRules();
  }
  componentDidUpdate() {
    if (!this.state.init) this.handleLoadRules();
  }
  handleLoadRules = (name = null) => {
    if (!this.props.isActive) return;
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryAnonymizationRules(name)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, rules } = data.anonymizationRules;
          if (ok) {
            this.setState({ loading: false, rules, init: true });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAction = (event, action) => {
    console.log({ action });
    if (action === 'save') {
      this.mutationSaveChanges();
    }
  };
  mutationSaveChanges = () => {
    const { rows_edited } = this.props;
    if (rows_edited === undefined || !rows_edited || !rows_edited.length) {
      this.props.handleAddError('Nothing to save');
      return;
    }
    const { saving } = this.state;
    if (saving) return;
    const inputs = rows_edited.map(x => {
      return {
        tag: x.tag,
        tag_rule: x.tag_rule ? x.tag_rule : null,
        tag_value: x.tag_value ? x.tag_value : null,
      };
    });
    this.setState({ saving: true });
    (async () => {
      MutationAddAnonymizationRules(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.addAnonymizationRules;
          if (ok) {
            this.setState({ saving: false });
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handelAddRules = new_rules => {
    const { rules } = this.state;
    for (let i = 0; i < new_rules.length; i++) {
      const { id } = new_rules[i];
      const index = rules.map(x => x.id).indexOf(id);
      if (index === -1) {
        rules.push(new_rules[i]);
      } else {
        rules[index] = { ...rules[index], ...new_rules[i] };
      }
    }
    this.setState({ rules, rows_edited: [] });
  };
  render() {
    return (
      <Grid container>
        <Grid item xs={12}>
          <TabRulesToolbar
            classes={this.props.classes}
            handelAddRules={this.handelAddRules}
            handleAddError={this.props.handleAddError}
            handleClearSelected={() => this.setState({ selected: [] })}
            handleOpenHelp={this.props.handleOpenHelp}
            handleReload={this.handleLoadRules}
            loading={this.state.loading}
            rows_edited={this.state.rows_edited}
            rules={this.state.rules}
            selected={this.state.selected}
            setState={state => this.setState(state)}
          />
        </Grid>
        <Grid item xs={12}>
          <TableGrid
            checkboxSelection
            dense
            disableSelectionOnClick
            external_edited={this.state.rows_edited}
            external_selected={this.state.selected}
            grid
            headers={columns_ano_rules}
            height={this.props.height}
            loading={this.state.loading}
            onPageChange={page => this.setState({ page })}
            onRowClicked={row_highlight => this.setState({ row_highlight })}
            onRowEditedChanged={rows_edited => this.setState({ rows_edited })}
            onRowsPerPageChange={limit => this.setState({ limit })}
            onSelectedChanged={selected => this.setState({ selected })}
            page={this.state.page}
            rows={this.state.rules}
            rowsPerPage={this.state.limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </Grid>
      </Grid>
    );
  }
}

TabRules.propTypes = {
  classes: PropTypes.object,
};

export default TabRules;
