import { MutationBigFiles } from './utils_chunk_upload';
import { MutationUploadInputs } from './utils_single_upload';
import { max_file_size, MUTATION_FILE_UPLOAD } from './utls_gql_upload';

const getFileInfoForEmail = (files) => {
  let data = null;
  if (files) {
    data = files.map(file => {
      return {
        filename: file.name,
        filesize: 0
      }
    });
  }
  return data;
}

export const MutationUploadFile = (
  file,
  handleProgress,
  idFolder,
  settings,
  file_info
) => {
  let files_information = null
  if (settings && settings.send_project_email) {
    files_information = getFileInfoForEmail(file_info);
  }
  const { size } = file;
  const query = { file };
  console.log({ file });
  if (idFolder) {
    query.settings = { folderIds: [idFolder], files_information: files_information };
  }
  if (settings) {
    query.settings = settings;
  }
  if (size < max_file_size) {
    return MutationUploadInputs(query, MUTATION_FILE_UPLOAD, handleProgress);
  } else {
    return MutationBigFiles(file, query, handleProgress);
  }
};
