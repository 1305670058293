import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import {
  Badge,
  Button,
  CardActions,
  CardHeader,
  Divider,
  Grid,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';

import { StyledMenu, StyledMenuItem } from 'components/StyledMenu';
import { withRouter } from 'react-router-dom';
import { useStyles } from './Style';
import { ReadNotifications } from 'graphql/Communications';
import { setNotifications } from 'redux-store/actions';
import { FormatTime, getInitials } from 'helpers';
import { ButtonReload } from 'components';
import MenuProjectActivities from 'views/Dashboard/components/DashboardGeneral/components/LatestProjectActivity/components/ListProjectActivities/MenuProjectActivities';

class LatestNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorElShow: null,
      anchorEl: null,
      selected: -1,
    };
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
    let { notifications } = this.props;
    const read = notifications.filter(x => x.read === false).length;
    if (notifications.length === 0 || read === 0) return;
    (async () => {
      ReadNotifications()
        .then(res => {
          const { data, error } = res;
          if (data) {
            notifications = notifications.map(x => ({ ...x, read: true }));
            this.props.setNotifications(notifications);
          } else {
            throw error;
          }
        })
        .catch(error => {
          console.log({ error });
        });
    })();
  };

  handleClose = (select, notification) => {
    this.setState({ anchorEl: null, selected: select });
    if (!notification) return;
    const { name } = notification;
    if (name === 'User Request') {
      this.props.history.push('/management/registration_requests');
    } else if (name === 'Shared Folder') {
      this.props.history.push('/explorer/shared');
    }
  };
  handleViewAll = () => {
    const { direct_to } = this.props;
    if (direct_to === '') {
      this.handleClose();
    } else {
      this.props.history.push(direct_to);
      this.setState({ anchorEl: null });
    }
  };
  handleReload = () => {
    const { holder } = this.props;
    if (holder.handleReload) {
      holder.handleReload();
    }
  };
  handleOpenShowMenu = (e, selected, notification) => {
    const { Folder, FolderContent } = notification;
    if (Folder || FolderContent) {
      this.setState({
        anchorElShow: e.currentTarget,
        notification,
      });
    } else {
      this.handleClose(selected, notification);
      return;
    }
  };

  handleMenuNotification = action => {
    let { selected, notification } = this.state;
    // console.log({ action, notification });
    let idProject = null;
    let open_delete = false;
    let folder_content = null;
    let idFolder = null;
    if (notification) {
      const { FolderContent, Folder } = notification;
      if (FolderContent) folder_content = FolderContent;
      else if (Folder) idFolder = Folder.idFolder;
    }
    if (action === 'show') {
      notification = null;
      if (folder_content) {
        this.props.history.push(`/explorer/folder/${folder_content.idFolder}`);
      } else if (idFolder) {
        this.props.history.push(`/explorer/folder/${idFolder}`);
      } else if (idProject) {
        this.props.history.push(`/project_management/${idProject}/activity`);
      }
    } else if (action === 'delete') {
      open_delete = true;
    }
    this.setState({ anchorElShow: null, notification, open_delete }, () => {
      this.handleClose(selected, notification);
    });
  };
  render() {
    const { classes, icon, title } = this.props;
    let { notifications } = this.props;

    const { anchorEl } = this.state;
    const total = notifications.filter(x => x.read === false).length;
    if (total > 5 || notifications.length > 5) {
      notifications = notifications.slice(0, 5);
    }
    return (
      <React.Fragment>
        <div style={{ maxWidth: 100 }}>
          <IconButton
            aria-controls="customized-menu"
            aria-haspopup="true"
            color="inherit"
            onClick={this.handleClick}
            size="large">
            <Badge badgeContent={total} color="error">
              {icon}
            </Badge>
          </IconButton>
          <StyledMenu
            anchorEl={anchorEl}
            id="customized-menu"
            keepMounted
            onClose={this.handleClose}
            open={Boolean(anchorEl)}>
            <div className={classes.menu}>
              <CardHeader
                action={
                  <ButtonReload
                    handleReload={this.handleReload}
                    loading={this.props.loading}
                    small
                    tooltip="Reload Notifications"
                  />
                }
                style={{ padding: 0, paddingLeft: 16, paddingRight: 16 }}
                subtitle={`${total} in total`}
                title={title}
              />
              <Divider />
              {notifications.map((notification, i) => {
                let avatar = null;
                let FullName = null;
                if (notification.From) {
                  avatar = notification.From.avatar;
                  FullName = notification.From.FullName;
                }
                if (!avatar) avatar = '/images/avatars/avatar_0.png';
                let text = '';
                if (notification.Folder) {
                  text = notification.Folder.name;
                } else if (notification.name === 'User Request') {
                  text = FullName;
                }
                let title_message = notification.name;
                if (title_message && title_message.length > 30) {
                  title_message = title_message.substring(0, 30) + '...';
                }

                return (
                  <StyledMenuItem
                    divider={i < total - 1}
                    key={`notification-${i}`}>
                    <ListItemAvatar
                      onClick={() => this.handleClose(i, notification)}>
                      <img
                        alt={getInitials(FullName)}
                        className={classes.image}
                        src={avatar}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      onClick={() => this.handleClose(i, notification)}
                      primary={
                        <Grid alignItems="center" container>
                          <Grid item xs>
                            <Typography
                              className={classes.inline}
                              color="textPrimary"
                              component="span"
                              variant="h6">
                              {`${title_message}`}
                            </Typography>
                          </Grid>
                          <Grid item style={{ width: '5px' }} />
                          {' — '}
                          <Grid item style={{ width: '5px' }} />
                          <Grid item>
                            <Typography variant="body2">{` ${text}`}</Typography>
                          </Grid>
                        </Grid>
                      }
                      secondary={`Updated ${FormatTime(
                        notification.updatedAt,
                        'FromNow'
                      )}`}
                    />
                    <IconButton
                      edge="end"
                      onClick={e => this.handleOpenShowMenu(e, i, notification)}
                      size="small">
                      <MoreVertIcon />
                    </IconButton>
                  </StyledMenuItem>
                );
              })}
              <Divider />
              <CardActions className={classes.actions}>
                <Button
                  color="primary"
                  onClick={this.handleViewAll}
                  size="small"
                  variant="text">
                  View all <ArrowRightIcon />
                </Button>
              </CardActions>
            </div>
          </StyledMenu>
        </div>
        <MenuProjectActivities
          activity={this.state.notification}
          anchorEl={this.state.anchorElShow}
          handleAction={this.handleMenuNotification}
          handleClose={() => this.setState({ anchorElShow: null })}
          user={this.props.user}
        />
      </React.Fragment>
    );
  }
}

LatestNotifications.defaultProps = {
  open: false,
  icon: <MailIcon />,
  title: 'Latest Messages',
  direct_to: '',
  menu_arrow: '50.0%',
};
LatestNotifications.propTypes = {
  classes: PropTypes.object.isRequired,
  direct_to: PropTypes.string,
  history: PropTypes.object.isRequired,
  icon: PropTypes.object,
  menu_arrow: PropTypes.string,
  notifications: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

const mapStateToProps = state => {
  let { notifications } = state.page_activity;
  let size = 0;
  if (notifications === undefined) {
    size = 0;
    notifications = [];
  } else {
    size = notifications.map(x => x.read).length;
  }
  return {
    notifications,
    size,
  };
};
export default withRouter(
  connect(mapStateToProps, { setNotifications })(
    withStyles(LatestNotifications, useStyles)
  )
);
