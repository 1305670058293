/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { CardContent } from '@mui/material';
import { getBlockItems } from '../utils/utils_svg';
import AddBlockItem from './AddBlockItem';
import AddBlockItemContainer from './AddBlockItemContainer';
import AddBlockItemFake from './AddBlockItemFake';
import CardBlocks from '../CardBlocks';

class AddBlocks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stageItems: getBlockItems(),
      disable_dragging: false,
      isDragging: false,
      draggedItem: null,
      mouseX: 0,
      mouseY: 0,
      position: {
        x: 0,
        y: 0,
      },
    };
    this.cardRef = React.createRef();
  }
  handleDragEnd = e => {
    e.preventDefault();
    console.log('handleDragEnd');
    this.setState({
      isDragging: false,
      draggedItem: null,
      top: null,
      left: null,
    });
  };
  handleDragStart = (e, item) => {
    console.log('handleDragStart');
    const rect = e.currentTarget.getBoundingClientRect();
    const deltaX = e.clientX - rect.left;
    const deltaY = e.clientY - rect.top;
    this.setState({
      isDragging: true,
      draggedItem: item,
      deltaX,
      deltaY,
      top: rect.top,
      left: rect.left,
    });
    e.dataTransfer.setData('text/plain', item.id);
  };
  handleDrag = (e, data) => {
    let y = data.y;
    let stop = this.state.disable_dragging;
    const rect = this.cardRef.current.getBoundingClientRect();
    if (rect.top < 70) {
      y = this.state.position.y;
      stop = false;
    }
    this.setState({
      position: { x: data.x, y },
      disable_dragging: stop,
    });
  };
  render() {
    const { stageItems, isDragging, draggedItem } = this.state;
    let { open, height } = this.props;
    if (!open) return null;
    let ComponentReplace = null;
    if (isDragging && draggedItem) {
      ComponentReplace = (
        <AddBlockItemContainer
          id={draggedItem.id}
          index={draggedItem.index}
          item={draggedItem}
          key={draggedItem.id}
          last={draggedItem.index === stageItems.length - 1}
          left={this.state.left}
          top={this.state.top}
        />
      );
    }
    return (
      <>
        {/* Render the dragged item separately, if any */}
        {isDragging && draggedItem && (
          <AddBlockItemFake
            draggedItem={draggedItem}
            isDragging={isDragging}
            props={this.props}
            state={this.state}
          />
        )}
        {ComponentReplace}
        <CardBlocks
          cardRef={this.cardRef}
          height={height}
          onActionClick={() => this.props.setState({ open_add_blocks: false })}
          onDrag={this.handleDrag}
          onDragEnd={() => this.setState({ disable_dragging: true })}
          position={this.state.position}
          title="Add Blocks"
          width={this.props.width}>
          <CardContent
            sx={{
              maxHeight: height - 280,
              overflowY: 'auto',
              paddingBottom: 0,
            }}>
            {stageItems.map((item, index) => {
              return (
                <AddBlockItemContainer
                  id={item.id}
                  index={index}
                  item={item}
                  key={item.id}
                  last={index === stageItems.length - 1}
                  onDragEnd={this.handleDragEnd}
                  onDragStart={e => this.handleDragStart(e, { ...item, index })}
                  selected={isDragging && draggedItem.index === index}>
                  <AddBlockItem
                    draggedItem={draggedItem}
                    index={index}
                    isDragging={isDragging}
                    item={item}
                  />
                </AddBlockItemContainer>
              );
            })}
          </CardContent>
        </CardBlocks>
      </>
    );
  }
}

AddBlocks.propTypes = {
  classes: PropTypes.object,
};

export default AddBlocks;
