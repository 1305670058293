import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { Grid, Card, CardContent, Divider } from '@mui/material';

import { CardEditHeader } from 'components';
import { useStyles } from './Styles';
import { FormatTime, ServerErrorsString, GetData } from 'helpers';
import TableProjectMemberSetting from '../TableProjectMemberSetting';
import { MemberSettingEditor } from './components';
import { MutationUpdateProjectMemberSettings } from 'graphql/Projects/utils_project';
class ProjectMemberSettingEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_avatar: false,
      saved: true,
      members: [],
      selected: null,
      updating: false,
    };
    this.holder = {
      reloadTable: null,
    };
  }
  handleClickedRow = selected => {
    // console.log({ selected });
    this.setState({ selected });
  };
  handleMemberSettingChanged = member => {
    if (!member || member === undefined) return;
    let { members } = this.state;
    if (members.length === 0) {
      for (let i = 0; i < this.props.members.length; i++) {
        members.push({ ...this.props.members[i] });
      }
    }
    const index = members.map(x => x.id).indexOf(member.id);
    if (index !== -1) {
      members[index] = { ...member };
      this.setState({ members });
    }
  };
  AreDifferentSettings = (A, B) => {
    if (A.access_level !== B.access_level) return true;
    if (A.email_notification !== B.email_notification) return true;
    if (A.upload_notification !== B.upload_notification) return true;
    return false;
  };
  GetModifiedMembers = () => {
    let modified = [];
    const { members } = this.state;
    for (let i = 0; i < this.props.members.length; i++) {
      const tocheck = this.props.members[i];
      const index = members.map(x => x.id).indexOf(tocheck.id);
      if (index === -1) continue;
      if (this.AreDifferentSettings(members[index], tocheck)) {
        modified.push({ ...members[index] });
      }
    }
    return modified;
  };
  IsChanged = () => {
    const { members } = this.state;
    if (!members.length) {
      return false;
    } else {
      const modified = this.GetModifiedMembers();
      if (modified.length) return true;
      return false;
    }
  };
  handleUpdateSettings = () => {
    const { updating } = this.state;
    if (updating) return;
    const modified = this.GetModifiedMembers();
    
    if (modified.length === 0) return;
    this.setState({ updating: true });
    (async () => {
      MutationUpdateProjectMemberSettings(modified)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateProjectMemberSetting;
          if (ok) {
            this.props.handleEditedMembers(modified);
            this.setState({ updating: false, members: [] });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ updating: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { classes } = this.props;
    const { selected } = this.state;
    let members = [];
    if (this.state.members.length) {
      members = this.state.members;
    } else {
      members = this.props.members;
    }
    let createdAt = 'Not defined';
    let fullname = 'None';
    if (selected) {
      createdAt = FormatTime(selected.createdAt, 'MMM Do, YYYY');
      fullname = selected.user.FullName;
    }
    return (
      <React.Fragment>
        <Card className={classes.card}>
          <CardEditHeader
            description={`Selected: ${fullname}, Created: ${createdAt}`}
            handleClose={() => this.props.handleOpenEditView([])}
            title="Edit Seleted Member Settings"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item style={{ width: '100%' }}>
                To edit a member setting, just please click a row in the Table
              </Grid>
              <Grid item style={{ width: '100%' }}>
                <MemberSettingEditor
                  handleMemberSettingChanged={this.handleMemberSettingChanged}
                  handleUpdateSettings={this.handleUpdateSettings}
                  IsChanged={this.IsChanged}
                  member={selected}
                  updating={this.state.updating}
                />
              </Grid>
              <Grid item style={{ width: '100%' }}>
                <TableProjectMemberSetting
                  disable_checkbox
                  handleAddError={this.props.handleAddError}
                  handleClickedRow={this.handleClickedRow}
                  member_settings={members}
                  setState={state => this.setState(state)}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}

ProjectMemberSettingEditor.propTypes = {
  handleAddError: PropTypes.func,
  handleOpenEditView: PropTypes.func,
  history: PropTypes.object,
  members: PropTypes.array,
};
ProjectMemberSettingEditor.defaultProps = {
  enable_edit: false,
  handleAddError: () => '',
  handleOpenEditView: () => '',
  handleEditedMembers: () => '',
  members: [],
};
export default withStyles(withRouter(ProjectMemberSettingEditor), useStyles);
