import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Box, Tabs, Tab, Divider } from '@mui/material';
import { TabPanel } from 'components';
import {
  MappedSeries,
  SystemSequences,
  TabSummary,
  UnMappedSeries,
} from './components';
import { getParams } from 'helpers';
import { getTabLabel } from 'helpers';
class SeriesSequenceMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      tabs: [
        { label: 'Summary', TabComponent: TabSummary },
        { label: 'Series Mapped', TabComponent: MappedSeries },
        { label: 'UnMapped', TabComponent: UnMappedSeries },
        { label: 'Sequences', TabComponent: SystemSequences },
      ],
    };
  }
  componentDidMount() {
    const { level_2 } = getParams(this.props);
    const index = this.state.tabs
      .map(x => getTabLabel(x.label))
      .indexOf(level_2);
    console.log({ level_2, index });
    if (index !== -1) {
      this.setState({ value: index });
    }
  }
  handleChange = (event, value) => {
    this.setState({ value });
    let label = 'summary';
    if (value >= 0 && value < this.state.tabs.length) {
      label = this.state.tabs[value].label;
      label = getTabLabel(label);
    }
    this.props.history.push(
      `/management/mapping/sequence_series mapping/${label}`
    );
  };
  render() {
    if (!this.props.isActive) return null;
    const { value, tabs } = this.state;
    const { height, ...res } = this.props;
    const table_height = height - 42;
    return (
      <React.Fragment>
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <Tabs centered onChange={this.handleChange} value={value}>
            {tabs.map((tab, index) => {
              return (
                <Tab
                  key={`tab-mapping-series-${index}`}
                  label={tab.label}
                  sx={{ textTransform: 'none' }}
                />
              );
            })}
          </Tabs>
        </Box>
        <Divider />
        {tabs.map((tab, index) => {
          const { TabComponent } = tab;
          return (
            <TabPanel
              index={index}
              key={`tab-component-mapping-series-${index}`}
              value={value}>
              <TabComponent
                {...res}
                height={table_height}
                isActive={index === value}
              />
            </TabPanel>
          );
        })}
      </React.Fragment>
    );
  }
}

SeriesSequenceMapping.propTypes = {
  classes: PropTypes.object,
};

export default withRouter(SeriesSequenceMapping);
