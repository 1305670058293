import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@mui/material';
import { TableGrid } from 'components';
import { rowsPerPageBig, GetData, ServerErrorsString } from 'helpers';
import { useStyles, columns } from './components';
import { withStyles } from 'tss-react/mui';
import { QueryFileObjectLinks } from 'graphql/Files';
class TabFileLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      open_delete: false,
      selected: [],
      file_links: [],
      page: 0,
      limit: 50,
      name: null,
      init: false,
    };
    const { holder } = props;
    if (holder) {
      holder.setCleanLink = () => this.setState({ selected: [] });
      holder.deleteLinks = () => this.setState({ open_delete: true });
    }
  }
  componentDidMount() {
    if (this.props.isActive) this.queryFileObjectLinks();
  }
  componentDidUpdate() {
    const { init } = this.state;
    if (this.props.isActive && !init) this.queryFileObjectLinks();
  }
  queryFileObjectLinks = (newPage, newLimit) => {
    let { loading, page, limit, name } = this.state;
    if (loading) return;
    if (newLimit) limit = newLimit;
    if (newPage !== undefined) page = newPage;
    this.setState({ loading: true });
    (async () => {
      QueryFileObjectLinks(name, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, file_links, errors } = data.fileObjectLinks;
          if (ok) {
            this.setState({ loading: false, file_links, init: true });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSelected = selected => {
    this.setState({ selected });
    this.props.setState({ selected });
  };
  render() {
    if (!this.props.isActive) return null;
    const { height } = this.props;
    return (
      <Paper sx={{ margin: 1 }}>
        <Grid container>
          <Grid item xs>
            <TableGrid
              checkboxSelection
              dense
              disableSelectionOnClick
              external_selected={this.state.selected}
              headers={columns}
              height={height - 40}
              onPageChange={page => this.setState({ page })}
              onRowsPerPageChange={limit => this.setState({ limit })}
              onSelectedChanged={this.handleSelected}
              page={this.state.page}
              rows={this.state.file_links}
              rowsPerPage={this.state.limit}
              rowsPerPageOptions={rowsPerPageBig}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

TabFileLinks.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(TabFileLinks, useStyles);
