import React from 'react';
import PropTypes from 'prop-types';
import { TableGrid } from 'components/Tables';
import { getColumns } from 'common';
import {
  detectDelimiter,
  parseCSVLine,
  rowsPerPageBig,
  splitCSVRows,
} from 'helpers';

class CSVViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 100,
      selected: [],
      headers: getColumns(),
      rows: [],
      loading: false,
    };
  }
  componentDidMount() {
    this.handleLoad();
  }
  handleLoad = () => {
    const { loading, rows } = this.state;
    if (loading || rows.length) return;
    this.setState({ loading: true });
    (async () => {
      const { content } = this.props;
      const rows = [];
      const rows_text = splitCSVRows(content);
      let headers = this.state.headers;
      const delimiter = detectDelimiter(content);
      // console.log({ delimiter });
      for (let i = 0; i < rows_text.length; i++) {
        let columns = { id: i };
        const columns_text = parseCSVLine(rows_text[i], delimiter);
        // console.log({ columns_text });
        if (i === 0) {
          headers = getColumns(null, columns_text.length);
          // console.log({
          //   colm: generateColumnLabels(columns_text.length),
          // });
        }

        for (let j = 0; j < columns_text.length; j++) {
          // const element = columns[j];
          if (j >= headers.length) continue;
          columns = {
            ...columns,
            [headers[j].field]: columns_text[j].replace(/"/g, ''),
          };
        }
        rows.push(columns);
      }
      this.setState({ rows, loading: false, headers });
    })();
  };
  render() {
    const { rows, headers } = this.state;
    return (
      <div>
        <TableGrid
          cell_selection
          checkboxSelection
          dense
          disableSelectionOnClick
          external_selected={this.state.selected}
          grid
          headers={headers}
          loading={this.state.loading}
          onPageChange={page => this.setState({ page })}
          onRowsPerPageChange={limit => this.setState({ limit })}
          onSelectedChanged={selected => this.setState({ selected })}
          page={this.state.page}
          row_height={33}
          rows={rows}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageBig}
          // style_table={{ backgroundColor: '#eeeeee' }}
        />
      </div>
    );
  }
}

CSVViewer.propTypes = {
  classes: PropTypes.object,
};

export default CSVViewer;
