import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';
const QUERY_ALL_PROJECT_OBJECTS_BY_CATEGORY = gql`
  query(
    $idCategory: LongLong!
    $idProject: LongLong
    $page: Int
    $limit: Int
    $keywords: [LongLong!]
    $filters: [LongLong!]
  ) {
    allProjectObjectsByCategory(
      idCategory: $idCategory
      idProject: $idProject
      page: $page
      limit: $limit
      keywords: $keywords
      filters: $filters
    ) {
      ok
      errors {
        path
        message
      }
      total
      project_objects {
        id
        total_maps
        folderStructure
        team {
          id
          name
        }
        center {
          id
          name
        }
        folder_content {
          idFolderContent
          Series {
            idSeries
            idStudy
            SeriesDescription
            createdAt
          }
          File {
            idFileObject
            original_name
            mimetype
            metadata {
              name
            }
            Thumbnail
            createdAt
          }
        }
        multi_object {
          idObject
          Series {
            idSeries
            idStudy
            SeriesDescription
            createdAt
          }
          File {
            idFileObject
            original_name
            mimetype
            Thumbnail
            createdAt
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryAllProjectObjectByCategory = (
  idCategory,
  idProject,
  page,
  limit,
  keywords,
  filters
) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ALL_PROJECT_OBJECTS_BY_CATEGORY),
    variables: {
      idCategory,
      idProject,
      page,
      limit,
      keywords,
      filters,
    },
  });
};

const QUERY_ALL_PROJECT_OBJECTS = gql`
  query(
    $idProject: LongLong!
    $page: Int
    $limit: Int
    $inputs: InputQueryObjects
  ) {
    allProjectObjects(
      idProject: $idProject
      page: $page
      limit: $limit
      inputs: $inputs
    ) {
      ok
      errors {
        path
        message
      }
      total
      project_objects {
        id
        total_maps
        folderStructure
        team {
          id
          name
        }
        center {
          id
          name
        }
        folder {
          idFolder
          name
        }
        folder_content {
          idFolderContent
          idFolder
          Series {
            idSeries
            idStudy
            SeriesDescription
            sequence {
              idSequence
              name
            }
          }
          File {
            idFileObject
            original_name
            mimetype
            Thumbnail
          }
        }
        multi_object {
          idObject
          Series {
            idSeries
            idStudy
            SeriesDescription
          }
          File {
            idFileObject
            original_name
            mimetype
            Thumbnail
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryAllProjectObject = (idProject, page, limit, inputs) => {
  // console.log({
  //   type,
  // });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ALL_PROJECT_OBJECTS),
    variables: {
      idProject,
      page,
      limit,
      inputs,
    },
  });
};
const QUERY_ALL_PROJECT_OBJECTS_MAPS = gql`
  query($idProject: LongLong!, $page: Int, $limit: Int) {
    allProjectObjectMappings(
      idProject: $idProject
      page: $page
      limit: $limit
    ) {
      ok
      total
      errors {
        path
        message
      }
      project_object_maps {
        id
        project_object {
          idFolder
          idFolderContent
          idObject
          folder {
            name
          }
          folder_content {
            Series {
              SeriesDescription
            }
            File {
              original_name
            }
          }
          multi_object {
            Series {
              SeriesDescription
            }
            File {
              original_name
            }
          }
        }
        maps {
          map_file {
            idType
            name
          }
          map_series {
            idMap
            idType
            idSeries
            type
            value
            updatedAt
            createdAt
          }
          map_sequence {
            idSequence
            Sequence {
              name
            }
          }
        }
      }
    }
  }
`;
export const QueryAllProjectObjectMapping = (idProject, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ALL_PROJECT_OBJECTS_MAPS),
    variables: {
      idProject,
      page,
      limit,
    },
  });
};
const QUERY_ALL_PROJECT_OBJECTS_MAPS_FLAT = gql`
  query($idProject: LongLong!, $types: [LongLong!]!, $page: Int, $limit: Int) {
    allProjectObjectMappingsFlat(
      idProject: $idProject
      types: $types
      page: $page
      limit: $limit
    ) {
      ok
      total
      errors {
        path
        message
      }
      project_object_maps {
        id
        type_name
        type_value
        object_type {
          idSeries
          idFileObject
        }
        secondary_maps {
          id
          type_name
          type_value
          object_type {
            idSeries
            series {
              SeriesDescription
              SeriesDate
            }
            idFileObject
            file_object {
              original_name
              createdAt
            }
          }
        }
      }
    }
  }
`;
export const QueryAllProjectObjectMappingFlat = (
  idProject,
  types,
  page,
  limit
) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ALL_PROJECT_OBJECTS_MAPS_FLAT),
    variables: {
      idProject,
      types,
      page,
      limit,
    },
  });
};
const MUTATION_ADD_PROJECT_OBJECTS = gql`
  mutation($objects: [ProjectObjectInput!]!) {
    addProjectObjects(objects: $objects) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationAddProjectObjects = objects => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_PROJECT_OBJECTS),
    variables: { objects },
  });
};

const QUERY_PROJECT_USER_FILES = gql`
  query($idProject: LongLong!, $page: Int, $limit: Int) {
    projectFiles(idProject: $idProject, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      files {
        idFileObject
        Owner {
          id
          FullName
        }
        derivated

        original_name
        mimetype
        Size
        filepath
        locations {
          idFolderContent
          Folder {
            idFolder
            name
            folders_paths {
              idFolder
              name
            }
          }
        }
        updatedAt
        createdAt
      }
    }
  }
`;

export const QueryProjectUsersFiles = (text, idProject, page, limit) => {
  console.log('QueryProjectUsersFiles', { idProject, limit });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PROJECT_USER_FILES),
    variables: { idProject, page, limit },
  });
};

const QUERY_PROJECT_USER_SERIES = gql`
  query($idProject: LongLong!, $page: Int, $limit: Int) {
    projectSeries(idProject: $idProject, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      Total
      Series {
        idSeries
        idStudy
        idUser
        Owner {
          id
          FullName
        }
        SeriesDate
        SeriesTime
        SeriesInstanceUID
        SeriesDescription
        NumberOfSeriesRelatedInstances
        Modality
        Size
        locations {
          idFolderContent
          Folder {
            idFolder
            name
            folders_paths {
              idFolder
              name
            }
          }
        }
        updatedAt
        createdAt
      }
    }
  }
`;

export const QueryProjectUsersSeries = (text, idProject, page, limit) => {
  console.log('QueryProjectUsersSeries', { idProject, limit });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PROJECT_USER_SERIES),
    variables: { idProject, page, limit },
  });
};
