import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadSingleFile } from 'common';

const QUERY_ENCRYPTION_MAPS = gql`
  query($query_inputs: EncryptionMapQuery, $page: Int, $limit: Int) {
    encryptionMaps(query_inputs: $query_inputs, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      mappings {
        id
        idPatient
        idUser
        idProject

        type
        tag_name
        tag_value
        encryption_key
        timepoint

        patient {
          PatientID
          PatientName
        }
        user {
          FullName
        }
        project {
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const QueryEncryptionMaps = (query_inputs, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ENCRYPTION_MAPS),
    variables: {
      query_inputs,
      page,
      limit,
    },
  });
};

const QUERY_ADVANCED_SEARCH_ENCRYPTION_MAPS = gql`
  query($query_inputs: [EncryptionMapAdvancedQuery!]!, $type: String, $page: Int, $limit: Int) {
    advancedSearchEncryptionMaps(query_inputs: $query_inputs, type: $type, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      mappings {
        id
        idPatient
        idUser
        idProject

        type
        tag_name
        tag_value
        encryption_key
        timepoint
        
        advanced_search_result

        patient {
          PatientID
          PatientName
        }
        user {
          FullName
        }
        project {
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const QueryAdvancedSearchEncryptionMaps = (query_inputs, type, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ADVANCED_SEARCH_ENCRYPTION_MAPS),
    variables: {
      query_inputs,
      type,
      page,
      limit,
    },
  });
};

const MUTATIOM_UPLOAD_ENCRYPTED_MAPS = gql`
  mutation($file: Upload!) {
    uploadEncryptionMaps(file: $file) {
      ok
      errors {
        path
        message
      }
      mappings {
        id
        idPatient
        idUser
        idProject

        type
        tag_name
        tag_value
        encryption_key
        timepoint

        patient {
          PatientID
          PatientName
        }
        user {
          FullName
        }
        project {
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationUploadEncryptionMapsCSV = (
  file,
  handleProgress = null
) => {
  return MutationUploadSingleFile(
    file,
    MUTATIOM_UPLOAD_ENCRYPTED_MAPS,
    handleProgress
  );
};
const MUTATION_ADD_ENCRYPTION_MAPS = gql`
  mutation($inputs: [InputEncryptionMap!]!) {
    addEncryptionMaps(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      mappings {
        id
        idPatient
        idUser
        idProject

        type
        tag_name
        tag_value
        encryption_key
        timepoint

        patient {
          PatientID
          PatientName
        }
        user {
          FullName
        }
        project {
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const MutationAddEncryptionMaps = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_ENCRYPTION_MAPS),
    variables: {
      inputs,
    },
  });
};

const MUTATION_REMOVE_ENCRYPTION_MAPS = gql`
  mutation($ids: [LongLong!]!) {
    removeEncryptionMaps(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationRemoveEncryptionMaps = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_ENCRYPTION_MAPS),
    variables: {
      ids,
    },
  });
};
