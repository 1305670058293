import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { loadGoogleScript } from 'common';

class CountriesGoogle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      searchText: '',
      countryName: '',
      googleLoaded: false,
      country: undefined,
    };
  }
  componentDidMount() {
    console.log({ window });
    if (typeof window.google === 'object') {
      this.handleScriptLoad();
    } else {
      loadGoogleScript(this);
    }
  }

  handleInputChange = (event, value) => {
    this.setState({ searchText: value }, () => {
      if (this.state.searchText) {
        const options = {
          input: this.state.searchText,
          types: ['(regions)'],
        };

        this.state.autoComplete.getPlacePredictions(
          options,
          (results, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              this.setState({ options: results });
            } else {
              this.setState({ options: [] });
            }
          }
        );
      } else {
        this.setState({ options: [] });
      }
    });
  };

  handlePlaceSelect = (event, value) => {
    if (value && value.place_id) {
      const placesService = new window.google.maps.places.PlacesService(
        document.createElement('div')
      );
      placesService.getDetails(
        { placeId: value.place_id },
        (placeResult, placesServiceStatus) => {
          if (
            placesServiceStatus ===
            window.google.maps.places.PlacesServiceStatus.OK
          ) {
            for (let i = 0; i < placeResult.address_components.length; i++) {
              const component = placeResult.address_components[i];
              if (component.types[0] === 'country') {
                this.setState({
                  countryName: component.long_name,
                  country: value,
                });
                this.props.handleCountryChanged({ name: component.long_name });
                break;
              }
            }
          } else {
            this.setState({
              countryName: 'No results found',
              country: undefined,
            });
            this.props.handleCountryChanged(null);
          }
        }
      );
    } else {
      this.setState({ countryName: 'No results found', country: undefined });
      this.props.handleCountryChanged(null);
    }
  };

  handleScriptLoad = () => {
    const options = {
      types: ['(regions)'],
    };
    try {
      const autoComplete = new window.google.maps.places.AutocompleteService(
        options
      );
      console.log({ autoComplete });
      this.setState({ autoComplete, googleLoaded: true });
    } catch (error) {
      console.log({ error });
    }
  };
  render() {
    const { country, googleLoaded, options } = this.state;
    const { error } = this.props;
    if (country) {
      const index = options.map(x => x.place_id).indexOf(country.place_id);
      if (index === -1) options.push(country);
    }
    let hasError = error && error !== '';
    return (
      <div>
        <Autocomplete
          filterOptions={x => x}
          getOptionLabel={option =>
            typeof option === 'string' ? option : option.description
          }
          inputValue={this.state.searchText}
          isOptionEqualToValue={(option, value) =>
            option.place_id === value.place_id
          }
          onChange={this.handlePlaceSelect}
          onInputChange={this.handleInputChange}
          options={options}
          renderInput={params => (
            <TextField
              {...params}
              error={hasError}
              helperText={hasError ? error : null}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {googleLoaded ? null : (
                      <CircularProgress color="inherit" size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              label="Countries"
              name="country"
              placeholder="Select your country"
              size="small"
              variant="outlined"
            />
          )}
          sx={{ width: 300 }}
          value={country}
        />
      </div>
    );
  }
}

CountriesGoogle.propTypes = {
  handleCountryChanged: PropTypes.func,
};
CountriesGoogle.defaultProps = {
  error: '',
  handleCountryChanged: () => '',
};

export default CountriesGoogle;
