import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { Divider, Paper } from '@mui/material';
import { TabPanel, TabSmall, TabsSmall } from 'components';
import {
  TabSeries,
  TabEncryptionKeys,
  TabAnonymizationRules,
  TabSkipRules,
  TabFolderStructure,
} from './components';
import { styleTabPanels } from 'common';
import { getLevels } from 'helpers';
class TabAnonymization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          name: 'Series',
          TabComponent: TabSeries,
          key: 'series',
        },
        {
          name: 'Encryption Keys',
          TabComponent: TabEncryptionKeys,
          key: 'encryptionkeys',
        },
        {
          name: 'Anonymization Rules',
          TabComponent: TabAnonymizationRules,
          key: 'anonymizationrules',
        },
        {
          name: 'Skip Rules',
          TabComponent: TabSkipRules,
          key: 'skiprules',
        },
        {
          name: 'Folder Structure',
          TabComponent: TabFolderStructure,
          key: 'folderstructure',
        },
      ],
      tabValue: 0,
    };
  }
  componentDidMount() {
    let { level_1, level_2 } = getLevels(this.props);
    if (level_1 === 'anonymization') {
      let tabValue = this.state.tabs.findIndex(x => x.key === level_2);
      if (tabValue >= 0 && tabValue < this.state.tabs.length) {
        this.setState({ tabValue });
      }
    }
  }
  handleTabValue = (event, tabValue) => {
    event.preventDefault();
    tabValue = parseInt(tabValue, 10);
    this.setState({ tabValue });
    const key = this.state.tabs[tabValue].key;
    this.props.history.push(`/pipelines/anonymization/${key}`);
  };
  render() {
    if (!this.props.isActive) return null;
    const { classes } = this.props;
    const tab_value = parseInt(this.state.tabValue, 10);
    return (
      <React.Fragment>
        <Paper square>
          <TabsSmall
            indicatorColor="primary"
            onChange={this.handleTabValue}
            scrollButtons="auto"
            textColor="primary"
            value={tab_value}
            variant="scrollable">
            {this.state.tabs.map((tab, index) => {
              return (
                <TabSmall key={`anonymization-tab-${index}`} label={tab.name} />
              );
            })}
          </TabsSmall>
          <Divider />
        </Paper>
        <div style={{ width: '100%' }}>
          <div className={classes.tab_content}>
            {this.state.tabs.map((tab, index) => {
              const { TabComponent } = tab;
              return (
                <TabPanel
                  className={classes.tab_panel}
                  index={index}
                  key={`anonymization-tab-panel-${index}`}
                  value={tab_value}>
                  <TabComponent
                    handleAddError={this.props.handleAddError}
                    handleAddSuccess={this.props.handleAddSuccess}
                    handleTabChanged={tabValue => this.setState({ tabValue })}
                    height={this.props.height - 40}
                    history={this.props.history}
                    isDesktop={this.props.isDesktop}
                    user={this.props.user}
                  />
                </TabPanel>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

TabAnonymization.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(TabAnonymization, styleTabPanels);
