import { styled } from '@mui/material/styles';
import { Slider, Typography } from '@mui/material';
import { IsInvalid } from 'helpers';
export const Widget = styled('div')(({ theme }) => ({
  padding: 16,
  borderRadius: 16,
  width: 290,
  maxWidth: '100%',
  margin: 'auto',
  position: 'relative',
  zIndex: 1,
  backgroundColor: 'rgba(255,255,255,0.4)',
  backdropFilter: 'blur(40px)',
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(0,0,0,0.6)',
  }),
}));
export function formatDuration(value) {
  const minute = Math.floor(value / 60);
  const secondLeft = value - minute * 60;
  return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
}
export const TinyText = styled(Typography)({
  fontSize: '0.75rem',
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
});
export const PrettoSlider = styled(Slider)({
  color: '#52af77',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});
// const WallPaper = styled('div')({
//   position: 'absolute',
//   width: '100%',
//   height: '100%',
//   top: 0,
//   left: 0,
//   overflow: 'hidden',
//   background: 'linear-gradient(rgb(255, 38, 142) 0%, rgb(255, 105, 79) 100%)',
//   transition: 'all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s',
//   '&::before': {
//     content: '""',
//     width: '140%',
//     height: '140%',
//     position: 'absolute',
//     top: '-40%',
//     right: '-50%',
//     background:
//       'radial-gradient(at center center, rgb(62, 79, 249) 0%, rgba(62, 79, 249, 0) 64%)',
//   },
//   '&::after': {
//     content: '""',
//     width: '140%',
//     height: '140%',
//     position: 'absolute',
//     bottom: '-50%',
//     left: '-30%',
//     background:
//       'radial-gradient(at center center, rgb(247, 237, 225) 0%, rgba(247, 237, 225, 0) 70%)',
//     transform: 'rotate(30deg)',
//   },
// });
import InputIcon from '@mui/icons-material/Input';
import OutputIcon from '@mui/icons-material/Output';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ArchiveIcon from '@mui/icons-material/Archive';
import TrainIcon from '@mui/icons-material/Train';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import MemoryIcon from '@mui/icons-material/Memory';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
const ports_config = [
  { id: 'training', name: 'Training', Icon: ContentPasteIcon },
  { id: 'testing', name: 'Testing', Icon: ContentPasteSearchIcon },
  { id: 'external', name: 'External', Icon: CopyAllIcon },
  { id: 'training', name: 'Train data', Icon: ContentPasteIcon },
  { id: 'testing', name: 'Test data', Icon: ContentPasteSearchIcon },
  { id: 'testing', name: 'Accepted', Icon: ThumbUpAltIcon },
  { id: 'testing', name: 'Rejected', Icon: ThumbDownAltIcon },
  { id: 'testing', name: 'Agreement', Icon: ThumbUpAltIcon },
  { id: 'testing', name: 'Disagrement', Icon: ThumbDownAltIcon },
  { id: 'testing', name: 'Archive', Icon: ArchiveIcon },
  { id: 'testing', name: 'Training data', Icon: TrainIcon },
  { id: 'testing', name: 'Testing data', Icon: FindInPageIcon },
  { id: 'testing', name: 'Output model', Icon: MemoryIcon },
  { id: 'testing', name: 'In progress', Icon: ManageSearchIcon },
  { id: 'testing', name: 'Evaluated', Icon: ChecklistRtlIcon },
  { id: 'testing', name: 'Input model', Icon: MemoryIcon },
  { id: 'testing', name: 'Output Model', Icon: MemoryIcon },
];
export const getPortIcon = port => {
  if (IsInvalid(port)) return null;
  const index = ports_config.findIndex(p => p.name === port.name);
  if (index !== -1) return ports_config[index].Icon;
  if (port.type === 'Input') {
    return InputIcon;
  } else {
    return OutputIcon;
  }
};

export const getSliderStyle = t => ({
  color: 'rgba(0,0,0,0.87)',
  height: 4,
  '& .MuiSlider-thumb': {
    width: 8,
    height: 8,
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
    '&::before': {
      boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
    },
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 8px ${'rgb(0 0 0 / 16%)'}`,
      ...t.applyStyles('dark', {
        boxShadow: `0px 0px 0px 8px ${'rgb(255 255 255 / 16%)'}`,
      }),
    },
    '&.Mui-active': {
      width: 20,
      height: 20,
    },
  },
  '& .MuiSlider-rail': {
    opacity: 0.28,
  },
  ...t.applyStyles('dark', {
    color: '#fff',
  }),
});
