import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Paper, ButtonGroup, Button, Tooltip } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { ButtonReloadDelete, DialogText, SnackMessage } from 'components';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SettingsIcon from '@mui/icons-material/Settings';
import SaveIcon from '@mui/icons-material/Save';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';

import { styleToolbar } from 'common';
import { GetData, ServerErrorsString } from 'helpers';
import { MutationSaveDiagram, QueryLoadDiagram } from 'graphql/Blocks';
import { createBlockFromSQL, createBlockLines } from '../utils';
import DialogLoadWorkflow from '../DialogLoadWorkflow/DialogLoadWorkflow';
import { ButtonAlignment } from './components';

class ToolBarBlockProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: 'input_jobs',
      open_save: false,
      scene_modified: false,
      save_as: false,
      open_file: false,
      create_empty: false,
      warning: null,
    };
    this.holder = {
      getSetting: null,
      addDiagram: null,
      setDiagram: null,
      updateDiagram: null,
    };
    const { holder } = props;
    if (holder) {
      holder.setTabClicked = clicked => this.handleChange(null, clicked);
      holder.setBlockMoved = scene_modified =>
        this.setState({ scene_modified });
    }
  }
  componentDidMount() {
    const { clicked, diagram } = this.props;
    if (clicked !== this.state.clicked) {
      this.setState({ clicked });
    }
    if (diagram && diagram.id > 0) {
      this.handleLoadDiagram();
    }
  }
  componentDidUpdate() {
    const { clicked } = this.props;
    if (clicked !== this.state.clicked) {
      this.setState({ clicked });
    }
  }
  handleSaveDiagram(name = null) {
    const { saving, save_as, create_empty } = this.state;
    let { diagram, blocks } = this.props.state;
    console.log('handleSaveDiagram', { diagram, name });
    if (saving) return;
    let diagram_name = '';
    if (diagram) diagram_name = diagram.name;
    if (name && name !== '') diagram_name = name;
    if (create_empty) {
      diagram = { id: null, name: '', zoomLevel: 1, panOffset: { x: 0, y: 0 } };
      blocks = [];
    }
    this.setState({ saving: true, create_empty: false });
    (async () => {
      MutationSaveDiagram(diagram, diagram_name, blocks, save_as)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, diagram: diagram_saved } = data.saveDiagram;
          if (ok) {
            const errors = [];
            diagram_saved.blocks.forEach(block => {
              let index = blocks.findIndex(b => b.id === block.id);
              if (index === -1) {
                index = blocks.findIndex(
                  b =>
                    b.getTitleID() === `${block.title} (${block.x}x${block.y})`
                );
              }
              if (index === -1) {
                errors.push(`${block.title} (${block.x}x${block.y})`);
              } else {
                blocks[index].id = block.id;
                for (let i = 0; i < block.inPorts.length; i++) {
                  const port = block.inPorts[i];
                  const id = `${port.x}-${port.y}`;
                  const j = blocks[index].inPorts.findIndex(
                    p => `${p.x}-${p.y}` === id
                  );
                  if (j !== -1) {
                    blocks[index].inPorts[j].id = port.id;
                  } else {
                    console.error(`port not found: ${id}`);
                  }
                }
                for (let i = 0; i < block.outPorts.length; i++) {
                  const port = block.outPorts[i];
                  const id = `${port.x}-${port.y}`;
                  const j = blocks[index].outPorts.findIndex(
                    p => `${p.x}-${p.y}` === id
                  );
                  if (j !== -1) {
                    blocks[index].outPorts[j].id = port.id;
                  } else {
                    console.error(`port not found: ${id}`);
                  }
                }
              }
            });
            this.setState({
              saving: false,
              open_save: false,
              scene_modified: false,
              save_as: false,
            });
            this.props.setState({
              diagram: diagram_saved,
              blocks,
              diagram_name: diagram_saved.name,
              setting_changed: false,
              block_moved: false,
            });
            if (this.holder.addDiagram) this.holder.addDiagram(diagram_saved);
            if (this.holder.updateDiagram)
              this.holder.updateDiagram(diagram_saved);
            if (errors.length)
              this.props.handleAddError(ServerErrorsString(errors));
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  }
  handleLoadDiagram(new_diagram = null) {
    const { loading } = this.state;
    let { diagram } = this.props.state;
    if (loading) return;
    this.setState({ loading: true });
    if (new_diagram) diagram = new_diagram;
    (async () => {
      QueryLoadDiagram(diagram.id)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, diagram } = data.loadDiagram;
          if (ok) {
            let blocks = [];
            let ListOutLines = [];
            diagram.blocks.forEach(b => {
              const { block, list_out_lines } = createBlockFromSQL(b, this);
              if (block) blocks.push(block);
              if (list_out_lines.length) {
                ListOutLines = ListOutLines.concat(list_out_lines);
              }
            });
            createBlockLines(blocks, ListOutLines);
            this.setState({
              loading: false,
              open_file: false,
              scene_modified: false,
            });
            // console.log({ blocks });
            this.props.handleLoadDiagram(diagram, blocks);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.setState({
            diagram: {
              id: null,
              name: '',
              zoomLevel: 1,
              panOffset: { x: 0, y: 0 },
            },
          });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  }
  handleCreateEmpty = () => {
    console.log('handleCreateEmpty');
    this.setState({ open_save: true, create_empty: true });
  };
  render() {
    const { classes, diagram } = this.props;
    const { scene_modified, save_as, warning } = this.state;
    // console.log({ scene_modified, diagram });
    return (
      <Paper
        className={classes.paper}
        style={{
          marginTop: 0,
          paddingTop: 2,
          paddingBottom: 0,
          marginBottom: 0,
        }}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          sx={{ minHeight: 45, marginTop: 0 }}>
          <Grid
            container
            direction="row"
            item
            sx={{
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
            xs>
            <Grid item>
              <ButtonGroup
                // className={classes.root}
                exclusive="false"
                size="small"
                value="center">
                <Button onClick={this.handleCreateEmpty}>
                  <Tooltip title="Create new diagram">
                    <PlaylistAddIcon style={{ color: '#2196f3' }} />
                  </Tooltip>
                </Button>
                <Button
                  onClick={() => {
                    this.holder.setDiagram(diagram);
                    this.setState({ open_file: true });
                  }}>
                  <Tooltip title="Load new workflow">
                    <FileOpenIcon style={{ color: 'green' }} />
                  </Tooltip>
                </Button>
                <Button
                  disabled={!scene_modified}
                  onClick={() => {
                    if (scene_modified && diagram) {
                      this.handleSaveDiagram();
                    } else {
                      this.setState({ open_save: true });
                    }
                  }}>
                  <Tooltip title="Save workflow">
                    <SaveIcon
                      style={{ color: scene_modified ? 'blue' : 'gray' }}
                    />
                  </Tooltip>
                </Button>
                <Button
                  onClick={() => {
                    this.setState({ open_save: true, save_as: true });
                  }}>
                  <Tooltip title="Save as new workflow">
                    <SaveAsIcon style={{ color: '#2196f3' }} />
                  </Tooltip>
                </Button>
                <Button>
                  <PlayArrowIcon style={{ color: 'gray' }} />
                </Button>
                <Button
                  onClick={() =>
                    this.props.setState({ open_add_blocks: true })
                  }>
                  <Tooltip title="Add Blocks">
                    <DashboardCustomizeIcon
                      style={{
                        color: this.props.state.open_add_blocks
                          ? '#ff6d00'
                          : '#ffd180',
                      }}
                    />
                  </Tooltip>
                </Button>
                <Button
                  disabled={!this.props.selectedBlock}
                  onClick={() =>
                    this.props.setState({ open_block_setting: true })
                  }>
                  <Tooltip title="Block Setting">
                    <SettingsIcon
                      style={{
                        color: this.props.selectedBlock
                          ? this.props.state.open_block_setting
                            ? '#ab47bc'
                            : '#e1bee7'
                          : 'gray',
                      }}
                    />
                  </Tooltip>
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item>
              <ButtonAlignment handleAlignment={this.props.handleAlignment} />
            </Grid>
          </Grid>
          <Grid item>
            <ButtonReloadDelete
              handleReloadTable={this.props.handleResetView}
              small
              tooltip_reload="Reset Viewer"
            />
          </Grid>
        </Grid>
        <DialogText
          description={
            'Please enter the name that you want to asign to the diagram'
          }
          handleClose={() =>
            this.setState({
              open_save: false,
              saving: false,
              create_empty: false,
            })
          }
          loading={this.state.saving}
          onClick={text => this.handleSaveDiagram(text)}
          open={this.state.open_save}
          title={`${save_as ? 'Save As' : 'Save'} New Diagram`}
          title_text="Name of the diagram"
        />
        <DialogLoadWorkflow
          diagram={diagram}
          handleAddError={this.props.handleAddError}
          handleClose={() =>
            this.setState({ open_file: false, loading: false })
          }
          holder={this.holder}
          loading={this.state.loading}
          onClick={diagram => this.handleLoadDiagram(diagram)}
          open={this.state.open_file}
        />
        <SnackMessage
          handleClose={() => this.setState({ warning: null })}
          message_text={warning !== '' ? warning : 'Unknown warning'}
          open={warning && warning !== '' ? true : false}
          type="warning"
        />
      </Paper>
    );
  }
}

ToolBarBlockProcess.propTypes = {
  classes: PropTypes.object,
};
ToolBarBlockProcess.defaultProps = {
  handleViewChanged: () => '',
  handleProcessFailed: () => '',
  handleResetView: () => '',
  handleProcessExecuted: () => '',
  selected: [],
};
export default withStyles(withRouter(ToolBarBlockProcess), styleToolbar);
