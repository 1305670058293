import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import { PaperComponent } from 'helpers';
import {
  ButtonLoad,
  SearchUsersProjects,
  TextFieldCustom,
} from 'components';
import { IsInvalid, GetData, ServerErrorsString } from 'helpers';
import { MutationAddEncryptionMaps } from 'graphql/Anonymization';
import SearchEncryptedPatients from 'components/Search/SearchEncryptedPatients';

class DialogCreateEncryptedPatientMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patient: null,
    };
  }
  handleTextChanged = event => {
    let { patient } = this.state;
    const name = event.target.name;
    if (IsInvalid(patient)) {
      patient = {
        [name]: event.target.value,
      };
    } else {
      patient = {
        ...patient,
        [name]: event.target.value,
      };
    }
    this.setState({ patient });
  };
  handleAddEncryptedPatient = new_patient => {
    console.log({ new_patient });
    let { patient } = this.state;
    if (new_patient) {
      patient = {
        idPatient: new_patient.id,
        PatientID: new_patient.PatientID,
        type: 'patient',
        tag_name: 'PatientID',
        tag_value: new_patient.PatientID,
      };
    } else {
      patient = null;
    }
    this.setState({ patient });
  };
  handleAddEncryptionMap = () => {
    const { creating, patient } = this.state;
    const { idProject, idUser } = this.props;
    if (creating || IsInvalid(patient)) return;
    this.setState({ creating: false });
    (async () => {
      MutationAddEncryptionMaps([{ idProject, idUser, ...patient }])
        .then(res => {
          const data = GetData(res);
          const { ok, errors, mappings } = data.addEncryptionMaps;
          if (ok) {
            this.setState({ creating: false });
            this.props.handleEncryptedMapCreated(mappings);
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ creating: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  IsNotChanged = () => {
    if (!this.state.patient) return true;
    const { encryption_key } = this.state.patient;
    if (encryption_key) return false;
    return true;
  };
  handleAddProject = selected => {
    console.log({ selected });
    const value = this.state;
    let { patient } = this.state;
    if (selected) {
      if (IsInvalid(patient)) patient = {};
      if (selected.type === 'User') patient.idUser = selected.id;
      else if (selected.type === 'Project') patient.idProject = selected.id;
      else {
        this.props.handleAddError('Invalid type, select User or Project');
        return;
      }
      value.patient = patient;
    }
    value.project = selected;
    this.setState(value);
  };
  render() {
    const { patient } = this.state;
    if (!this.props.open) return null;
    return (
      <div>
        <Dialog
          onClose={this.props.handleClose}
          open={this.props.open}
          PaperComponent={PaperComponent}>
          <DialogTitle style={{ cursor: 'move' }}>
            Create Encrypted Patient Map
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter all the necessary information of the rule.
            </DialogContentText>
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="space-between"
              pt={1}
              spacing={2}>
              <Grid item sx={{ width: '100%' }} xs={12}>
                <SearchEncryptedPatients
                  handleAddEncryptedPatient={this.handleAddEncryptedPatient}
                  text_field
                />
              </Grid>
              <Grid item pt={4} xs={6}>
                <TextFieldCustom
                  label="PatientID"
                  name="PatientID"
                  value={patient ? patient.PatientID : undefined}
                />
              </Grid>
              <Grid item pt={4} xs={6}>
                <SearchUsersProjects
                  handleAddUserProject={this.handleAddProject}
                  onSelected={this.handleAddProject}
                  text_field
                  types={['User', 'Project']}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldCustom
                  label="Encryption Key"
                  name="encryption_key"
                  onChange={this.handleTextChanged}
                  value={patient ? patient.encryption_key : undefined}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldCustom
                  label="Tag Value"
                  name="tag_value"
                  onChange={this.handleTextChanged}
                  value={patient ? patient.tag_value : undefined}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.handleClose}
              sx={{ textTransform: 'none' }}>
              Cancel
            </Button>
            <ButtonLoad
              color="primary"
              disable_button={this.IsNotChanged()}
              handleClick={this.handleAddEncryptionMap}
              loading={this.state.creating}
              name="Create Map"
              variant="text"
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DialogCreateEncryptedPatientMap.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default DialogCreateEncryptedPatientMap;
