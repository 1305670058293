import React from 'react';
import PropTypes from 'prop-types';
// import { Box } from '@mui/material';
import { SettingPorts } from '../components';
import ConfigTrainAI from './ConfigTrainAI';

class SettingTrainAI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    // const { height } = this.props;
    return (
      <>
        <ConfigTrainAI view={this.props.view} {...this.props} />
        <SettingPorts
          block={this.props.block}
          handleSettingChanged={this.props.handleSettingChanged}
          view={this.props.view}
        />
      </>
    );
  }
}

SettingTrainAI.propTypes = {
  classes: PropTypes.object,
};

export default SettingTrainAI;
