import { computePath } from '../functions';

class Line {
  constructor(blockA, blockB, index_A = 0, index_B = -1) {
    this.id = null;
    this.blockA = blockA;
    this.color = this.blockA.color;
    this.blockA.connectLine(this, index_A, true);
    this.blockB = blockB;
    this.path = [];
    if (index_B === -1) index_B = index_A;
    if (blockB) {
      this.blockB.connectLine(this, index_B, false);
    }
    this.radius = 5; // Radius for curved corners
    this.floatingPoint = null;
  }
  removeBlock(block) {
    if (this.blockA === block) {
      this.blockA = null;
    } else if (this.blockB === block) {
      this.blockB = null;
    } else return false;
    return true;
  }
  connectToEndPort(port) {
    if (!port) return false;
    const blockB = port.block;
    if (!blockB) return false;
    const startPort = this.getStartPort();
    const lines = port.getLines();
    for (let i = 0; i < lines.length; i++) {
      const portA = lines[i].getStartPort();
      const portB = lines[i].getEndPort();
      if (portA === startPort && portB === port) {
        console.log('these ports have a line connected');
        return false;
      }
    }
    if (blockB.connectLineToPort(this, port)) {
      this.blockB = blockB;
      this.floatingPoint = null;
      return true;
    }
    return false;
  }
  getEndPortIndex() {
    const port = this.getEndPort();
    if (port) return this.blockB.getIndexLeft(port);
    return -1;
  }
  getStartPortIndex() {
    const port = this.getEndPort();
    if (port) return this.blockA.getIndexRight(port);
    return -1;
  }
  getEndPort() {
    if (this.blockB) return this.blockB.getConnectPort(this);
    return null;
  }
  getStartPort() {
    if (this.blockA) return this.blockA.getConnectPort(this);
    return null;
  }
  getEndBlock() {
    return this.blockB;
  }
  getStartBlock() {
    return this.blockA;
  }
  disconnectLine() {
    if (this.blockA) {
      this.blockA.disconnectLine(this);
    }
    if (this.blockB) {
      this.blockB.disconnectLine(this);
    }
  }
  getBezierPoint(t, p1, cp1, cp2, p2) {
    const t1 = 1 - t;
    return {
      x:
        t1 * t1 * t1 * p1.x +
        3 * t1 * t1 * t * cp1.x +
        3 * t1 * t * t * cp2.x +
        t * t * t * p2.x,
      y:
        t1 * t1 * t1 * p1.y +
        3 * t1 * t1 * t * cp1.y +
        3 * t1 * t * t * cp2.y +
        t * t * t * p2.y,
    };
  }
  isConnectedToSelectedBlock() {
    if (this.blockA && this.blockA.selected) return true;
    if (this.blockB && this.blockB.selected) return true;
    return false;
  }
  isConnected(full = false) {
    if (this.blockA) {
      const portA = this.blockA.getConnectPort(this);
      if (portA) {
        if (this.blockB || !full) return true;
      }
    }
    if (this.blockB) {
      const portB = this.blockB.getConnectPort(this);
      if (portB) {
        if (this.blockA || !full) return true;
      }
    }
    return false;
  }
  getPath(blocks) {
    const portA = this.blockA.getConnectPort(this);
    let path = [];
    if (this.blockB) {
      const portB = this.blockB.getConnectPort(this);
      path = computePath(this.blockA, portA, this.blockB, portB, blocks);
    } else if (portA) {
      const startX = portA.x;
      const startY = portA.y;
      path.push([startX, startY]);
      if (this.floatingPoint) {
        path.push([this.floatingPoint.x, this.floatingPoint.y]);
      }
    }
    return path;
  }

  draw(ctx, blocks) {
    this.path = this.getPath(blocks); // Recalculate path before drawing
    if (this.path.length <= 2) {
      if (this.floatingPoint) {
        const p1 = {
          x: this.path[0][0],
          y: this.path[0][1],
        };
        const p2 = {
          x: this.path[1][0],
          y: this.path[1][1],
        };
        this.drawSegmentedSpline(ctx, p1, p2);
        return;
      } else if (this.path.length < 2) return;
    }
    this.drawPath(ctx);
  }

  drawPath(ctx) {
    ctx.beginPath();
    ctx.moveTo(this.path[0][0], this.path[0][1]);

    for (let i = 1; i < this.path.length - 1; i++) {
      const [x1, y1] = this.path[i];
      const [x2, y2] = this.path[i + 1];

      // Calculate the direction change
      const [prevX, prevY] = this.path[i - 1];
      const dx1 = x1 - prevX;
      const dy1 = y1 - prevY;
      const dx2 = x2 - x1;
      const dy2 = y2 - y1;

      // Calculate segment lengths
      const len1 = Math.floor(Math.sqrt(dx1 * dx1 + dy1 * dy1));
      const len2 = Math.floor(Math.sqrt(dx2 * dx2 + dy2 * dy2));

      // Determine the maximum radius based on segment lengths
      const maxRadius = Math.min(len1, len2) / 2;
      const cornerRadius = Math.min(this.radius, maxRadius);

      // If there's a direction change and segments are long enough, draw a curved corner
      if ((dx1 !== 0 && dy2 !== 0) || (dy1 !== 0 && dx2 !== 0)) {
        // Calculate the start and end points of the curve
        const startX = x1 - (dx1 !== 0 ? Math.sign(dx1) * cornerRadius : 0);
        const startY = y1 - (dy1 !== 0 ? Math.sign(dy1) * cornerRadius : 0);
        const endX = x1 + (dx2 !== 0 ? Math.sign(dx2) * cornerRadius : 0);
        const endY = y1 + (dy2 !== 0 ? Math.sign(dy2) * cornerRadius : 0);

        // Draw the line to the start of the curve
        ctx.lineTo(startX, startY);
        // Draw the curve
        ctx.arcTo(x1, y1, endX, endY, cornerRadius);
      } else {
        // If no direction change or segments are too short, just draw a straight line
        ctx.lineTo(x1, y1);
      }
    }

    // Draw the final segment
    ctx.lineTo(
      this.path[this.path.length - 1][0],
      this.path[this.path.length - 1][1]
    );

    ctx.strokeStyle = this.color;
    ctx.lineWidth = 2;
    ctx.stroke();
  }

  drawSegmentedSpline(ctx, p1, p2) {
    // Calculate control points
    const cp1 = {
      x: p1.x + (p2.x - p1.x) / 3,
      y: p1.y,
    };
    const cp2 = {
      x: p1.x + (2 * (p2.x - p1.x)) / 3,
      y: p2.y,
    };

    // Calculate points along the curve
    const points = [];
    const steps = 100;
    const segmentLength = 15;
    const gapLength = 10;

    for (let i = 0; i <= steps; i++) {
      const t = i / steps;
      points.push(this.getBezierPoint(t, p1, cp1, cp2, p2));
    }

    // Draw segmented line
    ctx.beginPath();
    ctx.strokeStyle = this.color;
    ctx.lineWidth = 2;

    let isDrawing = true;
    let currentLength = 0;
    let segmentStart = 0;

    for (let i = 1; i < points.length; i++) {
      const dx = points[i].x - points[i - 1].x;
      const dy = points[i].y - points[i - 1].y;
      const segmentDistance = Math.sqrt(dx * dx + dy * dy);

      currentLength += segmentDistance;

      if (isDrawing) {
        if (currentLength >= segmentLength) {
          ctx.moveTo(points[segmentStart].x, points[segmentStart].y);
          ctx.lineTo(points[i].x, points[i].y);
          ctx.stroke();
          isDrawing = false;
          currentLength = 0;
          segmentStart = i;
        }
      } else {
        if (currentLength >= gapLength) {
          isDrawing = true;
          currentLength = 0;
          segmentStart = i;
        }
      }
    }

    // Draw the arrow at the end
    // Get the direction from the last two points for the arrow
    const lastPoint = points[points.length - 1];
    let index_2 = points.length - 2;
    if (points.length > 10) index_2 = points.length - 10;
    if (Math.abs(lastPoint.x - points[index_2].x) < 4) index_2 = 0;
    const secondLastPoint = points[index_2];
    // console.log({
    //   dx: Math.abs(lastPoint.x - secondLastPoint.x),
    //   dy: Math.abs(lastPoint.y - secondLastPoint.y),
    // });
    this.drawArrow(
      ctx,
      secondLastPoint.x,
      secondLastPoint.y,
      lastPoint.x,
      lastPoint.y
    );

    // Draw the points
    ctx.fillStyle = '#FF5722';
    ctx.beginPath();
    ctx.arc(p1.x, p1.y, 4, 0, Math.PI * 2);
    ctx.arc(p2.x, p2.y, 4, 0, Math.PI * 2);
    ctx.fill();
  }

  drawArrow(ctx, fromX, fromY, toX, toY) {
    const arrowSize = 15;

    const angle = Math.atan2(toY - fromY, toX - fromX);

    ctx.beginPath();
    ctx.moveTo(toX, toY);
    ctx.lineTo(
      toX - arrowSize * Math.cos(angle - Math.PI / 6),
      toY - arrowSize * Math.sin(angle - Math.PI / 6)
    );
    ctx.lineTo(
      toX - arrowSize * Math.cos(angle + Math.PI / 6),
      toY - arrowSize * Math.sin(angle + Math.PI / 6)
    );
    ctx.closePath();
    ctx.fillStyle = this.color;
    ctx.fill();
  }
}

export default Line;
