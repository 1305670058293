import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadEpilepsyCSV } from './utils';

const QUERY_EPILEPSY_CASE = gql`
  query($id: LongLong) {
    epilepsyCase(id: $id) {
      ok
      errors {
        path
        message
      }
      cases {
        id
        idPatient

        case_identifier
        date
        first_seizure_date
        patient_age
        neurologist
        assistant
        comment
        laterality
        scalp
        subscalp
        strip
        grid
        depth
        date_pre_implantation_mr
        date_post_implantation_ct
        covered_region
        electrodes_located_mr
        electrodes_labelled
        ct_registered_to_mri
        normalized_to_mni
        pial_reconstruction
        tot_files
        tot_series
        tot_edfs

        patient {
          id
          FamilyName
          GivenName
          MiddleName
          PatientName
          PatientID
          PatientBirthDate
          PatientSex
        }
        research_study {
          id
          idEpilepsyCase
          brain_map
          input_output
          circadian
          pharmaco
          audio_sleep
          music_start_date
          music_end_date
          epi_os
          spec_ictal
          comment
        }
        resection {
          id
          idEpilepsyCase
          date
          patient_age
          op_side
          operation
          details
          complication
          histology
          histology_temporolpol
          histology_amygdala
          histology_hippocampus
        }
        outcome {
          id
          idEpilepsyCase
          engel_3m
          engel_1y
          engel_2y
          percentage_aed_1y
          percentage_aed_2y
        }
        files {
          id
          idEpilepsyCase
          idSleepCase
          idPatient
          idFileObject
          idSeries
          idSignalEDF
          idFolder
          epilepsy_case {
            id
          }
          sleep_case {
            id
          }
          file_object {
            idFileObject
            original_name
            mimetype
            filepath
            createdAt
          }
          series {
            idSeries
            Thumbnail
            DicomFiles {
              idFile
              OriginalName
              FilePath
            }
            createdAt
          }
          edf {
            id
            file_object {
              idFileObject
              original_name
              mimetype
              filepath
            }
            createdAt
          }
          folder {
            idFolder
            name
            createdAt
          }
          folder_content {
            idFolderContent
            idFolder
          }
          patient {
            id
            PatientID
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryEpilepsyCase = id => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_EPILEPSY_CASE),
    variables: {
      id,
    },
  });
};

const QUERY_EPILEPSY_CASES = gql`
  query($name: String, $search_query: String, $page: Int, $limit: Int) {
    epilepsyCases(name: $name, search_query: $search_query, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      cases {
        id
        idPatient

        case_identifier
        date
        first_seizure_date
        patient_age
        neurologist
        assistant
        comment
        laterality
        scalp
        subscalp
        strip
        grid
        depth
        date_pre_implantation_mr
        date_post_implantation_ct
        covered_region
        electrodes_located_mr
        electrodes_labelled
        ct_registered_to_mri
        normalized_to_mni
        pial_reconstruction
        tot_files
        tot_series
        tot_edfs

        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryEpilepsyCases = (name, search_query, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_EPILEPSY_CASES),
    variables: {
      name,
      search_query,
      page,
      limit,
    },
  });
};

const QUERY_EPILEPSY_PATIENTS = gql`
  query($page: Int, $limit: Int, $anonymize: Int) {
    epilepsyPatients(page: $page, limit: $limit, anonymize: $anonymize) {
      ok
      errors {
        path
        message
      }
      total
      patients {
        id
        idUser
        idAddress

        user {
          FullName
        }

        FamilyName
        GivenName
        MiddleName
        NamePrefix
        NameSuffix

        PatientName

        PatientID

        PatientBirthDate
        PatientSex

        PatientAge
        PatientSize
        PatientWeight
        PatientWaist
        PatientHip
        bmi
        PatientEthnicity
        patientSAPStatus
        patientLastActivity
        patientRegistrationDate
        patientVitalStatus
        patientDeathDate
        patientLastVitalityCheck

        UniqueKeyIdentifier
        tot_sleep_files
        tot_epilepsy_files
        tot_stroke_files
        tot_ms_files

        tot_sleep_series
        tot_epilepsy_series
        tot_stroke_series
        tot_ms_series

        tot_sleep_edfs
        tot_epilepsy_edfs
        tot_stroke_edfs
        tot_ms_edfs

        isStroke
        isEpilepsy
        isSleep
        isMS
        
        address {
          country
          city
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryEpilepsyPatients = (page, limit, anonymize) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_EPILEPSY_PATIENTS),
    variables: {
      page,
      limit,
      anonymize,
    },
  });
};

const MUTATION_REMOVE_EPILEPSY_CASES = gql`
  mutation($ids: [LongLong!]!) {
    deleteEpilepsyCases(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveEpilepsyCases = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_EPILEPSY_CASES),
    variables: {
      ids,
    },
  });
};
const MUTATION_ADD_EPILEPSY_CASES = gql`
  mutation($inputs: [InputEpilepsyCase!]!) {
    addEpilepsyCases(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      cases {
        id
        idPatient
        case_identifier
        date
        first_seizure_date
        patient_age
        neurologist
        assistant
        comment
        laterality
        scalp
        subscalp
        strip
        grid
        depth
        date_pre_implantation_mr
        date_post_implantation_ct
        covered_region
        electrodes_located_mr
        electrodes_labelled
        ct_registered_to_mri
        normalized_to_mni
        pial_reconstruction
        tot_files
        tot_series
        tot_edfs

        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationAddEpilepsyCases = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_EPILEPSY_CASES),
    variables: {
      inputs,
    },
  });
};
const MUTATION_UPLOAD_EPILEPSY_CASES = gql`
  mutation($file: Upload!) {
    uploadEpilepsyCases(file: $file) {
      ok
      errors {
        path
        message
      }
      total
      cases {
        id
        idPatient

        case_identifier
        date
        first_seizure_date
        patient_age
        neurologist
        assistant
        comment
        laterality
        scalp
        subscalp
        strip
        grid
        depth
        date_pre_implantation_mr
        date_post_implantation_ct
        covered_region
        electrodes_located_mr
        electrodes_labelled
        ct_registered_to_mri
        normalized_to_mni
        pial_reconstruction
        tot_files
        tot_series
        tot_edfs

        patient {
          id
          PatientID
        }

        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationUploadEpilepsyCases = (file, handleProgress = null) => {
  return MutationUploadEpilepsyCSV(
    file,
    MUTATION_UPLOAD_EPILEPSY_CASES,
    handleProgress
  );
};

const MUTATION_UPLOAD_EPILEPSY_ALL = gql`
  mutation($file: Upload!) {
    uploadEpilepsy(file: $file) {
      ok
      errors {
        path
        message
      }
      total
      cases {
        id
        idPatient

        case_identifier
        date
        first_seizure_date
        patient_age
        neurologist
        assistant
        comment
        laterality
        scalp
        subscalp
        strip
        grid
        depth
        date_pre_implantation_mr
        date_post_implantation_ct
        covered_region
        electrodes_located_mr
        electrodes_labelled
        ct_registered_to_mri
        normalized_to_mni
        pial_reconstruction
        tot_files
        tot_series
        tot_edfs
        patient {
          id
          # FamilyName
          # GivenName
          # MiddleName
          # PatientName
          PatientID
          # PatientBirthDate
          # PatientSex
        }

        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationUploadEpilepsyAll = (file, handleProgress = null) => {
  return MutationUploadEpilepsyCSV(
    file,
    MUTATION_UPLOAD_EPILEPSY_ALL,
    handleProgress
  );
};

// const QUERY_SEARCH_EPILEPSY_CASES = gql`
//   query($phrase: String, $page: Int, $limit: Int) {
//     searchEpilepsyCases(phrase: $phrase, page: $page, limit: $limit) {
//       ok
//       errors {
//         path
//         message
//       }
//       total
//       cases {
//         id
//         idPatient

//         case_identifier
//         date
//         first_seizure_date
//         patient_age
//         neurologist
//         assistant
//         comment
//         laterality
//         scalp
//         subscalp
//         strip
//         grid
//         depth
//         date_pre_implantation_mr
//         date_post_implantation_ct
//         covered_region
//         electrodes_located_mr
//         electrodes_labelled
//         ct_registered_to_mri
//         normalized_to_mni
//         pial_reconstruction

//         patient {
//           id
//           PatientID
//         }
//         createdAt
//         updatedAt
//       }
//     }
//   }
// `;
// export const QuerySearchEpilepsyCases = (phrase, page, limit) => {
//   return axios.post(HTTP_ADDRESS_GRAPHQL, {
//     query: print(QUERY_SEARCH_EPILEPSY_CASES),
//     variables: {
//       phrase,
//       page,
//       limit,
//     },
//   });
// };

const MUTATION_CREATE_EPILEPSY = gql`
  mutation($inputs: [InputEpilepsy!]!) {
    createEpilepsy(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      total
      cases {
        id
        idPatient

        case_identifier
        date
        first_seizure_date
        patient_age
        neurologist
        assistant
        comment
        laterality
        scalp
        subscalp
        strip
        grid
        depth
        date_pre_implantation_mr
        date_post_implantation_ct
        covered_region
        electrodes_located_mr
        electrodes_labelled
        ct_registered_to_mri
        normalized_to_mni
        pial_reconstruction
        tot_files
        tot_series
        tot_edfs
        patient {
          id
          PatientID
        }

        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationCreateEpilepsy = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_CREATE_EPILEPSY),
    variables: {
      inputs,
    },
  });
};
