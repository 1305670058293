import { createText, getConfig } from '../../utils_blocks';
import { getBlockConfig } from '../../utils_svg';
import { Block } from '../base';
// import { drawCircle } from '../functions';

class TrainAIModelBlock extends Block {
  constructor(
    x,
    y,
    self,
    width = 170,
    height = 90,
    config = getBlockConfig('Train AI Model')
  ) {
    const contentHeight = 40; // 40px for top, 60px for bottom
    const bottomHeight = 40;
    super(
      x,
      y,
      width,
      height,
      config,
      contentHeight,
      bottomHeight,
      200,
      120,
      '#eceff1'
    );
    this.addPort('Output model', true, 20, 'green'); // Add port on the left side
    this.addPort('Training data', false, 60, '#ff5722'); // Add port on the left side
    this.addPort('Testing data', false, 100, '#795548'); // Add port on the left side
  }
  drawContent(ctx) {
    super.drawContent(ctx);
    const { scaleX, scaleY } = this.getScalingFactor();
    const padding = 20 * scaleX;
    const topSeparatorY = this.y + this.topHeight; // Y-coordinate of the top separator
    const config = getConfig(this.x + padding, topSeparatorY + 20, '#000');
    config.font = `${15 * scaleY}px Arial`;
    createText(ctx, 'Training data', config);
  }
  drawBottom(ctx) {
    const postSeparatorY = super.drawBottom(ctx);
    const { scaleX, scaleY } = this.getScalingFactor();

    const padding = 20 * scaleX;
    const config = getConfig(this.x + padding, postSeparatorY + 20, '#000');
    config.font = `${15 * scaleY}px Arial`;
    createText(ctx, 'Testing data', config);
  }
}

export default TrainAIModelBlock;
