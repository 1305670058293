import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_CORRECTION_FILE = gql`
  query($n: Int!) {
    getCorrectionFile(n: $n) {
      ok
      content
      errors {
        path
        message
      }
    }
  }
`;
export const QueryCorrectionFile = () => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_CORRECTION_FILE),
    variables: { n: 1 },
  });
};

const MUTATION_SAVE_CORRECTION_FILE = gql`
  mutation($json_content: String!) {
    saveConfigurationFile(json_content: $json_content) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationSaveCorrectionFile = json_content => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_SAVE_CORRECTION_FILE),
    variables: { json_content: json_content },
  });
};

const MUTATION_RUN_SEQUENCE_PREDICTION = gql`
  mutation($inputs: [InputPredictionSequence!]!) {
    runSequencePrediction(inputs: $inputs) {
      ok
      sequence_errors {
        id
        idUser
        idSeries

        idSeqPrediction
        idSeqCategory

        user {
          id
          FullName
        }
        series {
          SeriesDescription
          locations {
            idFolderContent
            idFolder
          }
        }
        sequence_predic {
          idSequence
          name
        }
        sequence_categ {
          idSequence
          name
        }
        count
        prediction_class_category
        prediction_class

        prediction_probability_category
        prediction_probability
        confidence

        error

        AccessionNumber
        AcquisitionDate
        ContrastBolusAgent
        EchoTime
        EchoTrainLength
        FlipAngle
        ImageType
        InversionTime
        Modality
        MRAcquisitionType
        PatientID
        PhotometricInterpretation
        PixelBandwidth
        PixelSpacing
        ProtocolName
        RepetitionTime
        ScanOptions
        ScanningSequence
        SequenceName
        SequenceVariant
        SeriesDescription
        SliceThickness
        SeriesInstanceUID

        createdAt
        updatedAt
      }
      total
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRunSequencePrediction = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_RUN_SEQUENCE_PREDICTION),
    variables: { inputs },
  });
};

const MUTATION_ADD_SEQUENCE_PREDICTION = gql`
  mutation($inputs: [InputAddPrediction!]!) {
    addSequencePrediction(inputs: $inputs) {
      ok
      sequence_errors {
        id
        idUser
        idSeries

        idSeqPrediction
        idSeqCategory

        user {
          id
          FullName
        }
        series {
          SeriesDescription
          locations {
            idFolderContent
            idFolder
          }
        }
        sequence_predic {
          idSequence
          name
        }
        sequence_categ {
          idSequence
          name
        }
        count
        prediction_class_category
        prediction_class

        prediction_probability_category
        prediction_probability
        confidence

        error

        AccessionNumber
        AcquisitionDate
        ContrastBolusAgent
        EchoTime
        EchoTrainLength
        FlipAngle
        ImageType
        InversionTime
        Modality
        MRAcquisitionType
        PatientID
        PhotometricInterpretation
        PixelBandwidth
        PixelSpacing
        ProtocolName
        RepetitionTime
        ScanOptions
        ScanningSequence
        SequenceName
        SequenceVariant
        SeriesDescription
        SliceThickness
        SeriesInstanceUID

        createdAt
        updatedAt
      }
      total
      errors {
        path
        message
      }
    }
  }
`;
export const MutationAddSequencePrediction = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_SEQUENCE_PREDICTION),
    variables: { inputs },
  });
};
