import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  Tooltip,
  Fab,
  Avatar,
  InputAdornment,
} from '@mui/material';
import { connect } from 'react-redux';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { getUserFullName } from 'helpers';
import SearchAutocomplete from 'components/Search/SearchAutocomplete';
import { ServerErrorsString, GetData } from 'helpers';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import {
  DialogAddTeamMembers,
  DivGrow,
  DialogEditTeam,
  AutoComboBox,
} from 'components';
import { getInitials } from 'helpers';
import { IsInvalid } from 'helpers';
import { QueryTeamMembers } from 'graphql/Teams';
class TeamViewToolBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open_edit_team: false,
      open_add_member: false,
      open: false,
      loading: false,
      team: null,
      name: '',
      projects: [],
      idProject: null,
    };
    const { holder } = props;
    if (holder) {
      holder.reloadTable = () => this.queryMembers(this.state.team);
    }
  }

  handleAddTeam = team => {
    let projects = [];
    if (team && team.projects && team.projects.length) {
      projects = team.projects;
      projects.unshift({ id: -1, name: 'None' });
    }
    this.queryMembers(team, projects);
  };
  handelSiteNameChanged = event => {
    this.setState({ name: event.target.value });
  };
  queryMembers = (team = null, new_projects = null) => {
    let { loading, projects } = this.state;
    if (loading || !team || team.id < 1) return;
    if (new_projects) projects = new_projects;
    this.setState({ loading: true });
    (async () => {
      QueryTeamMembers(team.id)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, members } = data.allTeamMembers;
          if (ok) {
            team.members = members;
            this.props.handleTeamMembers(team, members);
            this.setState({ loading: false, team, projects });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleOpenAddMember = () => {
    this.setState({ open_add_member: !this.state.open_add_member });
  };
  handleAddMembers = members => {
    this.setState({ open_add_member: false });
    this.props.handleAddMembers(members);
  };
  handleOpenEditTeam = () => {
    this.setState({ open_edit_team: !this.state.open_edit_team });
  };
  handleUpdatedTeam = team => {
    this.setState({ open_edit_team: false, team });
    this.props.handleUpdatedTeam(team);
  };
  handleProjectChanged = project => {
    let { team } = this.state;
    if (IsInvalid(team)) return;
    const { projects } = team;
    let idProject = null;
    if (project) {
      const index = projects.map(x => x.id).indexOf(project.id);
      if (index !== -1) {
        idProject = projects[index].id;
      }
    }
    this.setState({ idProject });
  };
  render() {
    const { classes, selected, user } = this.props;
    let idProject = this.props.idProject;
    let { team, projects } = this.state;
    let admin = '';
    let name_team = 'Team Center';
    if (!team && this.props.team) {
      team = this.props.team;
    }
    let isAdmin = false;
    if (team) {
      admin = team.admin;
      name_team = `Team Center (${team.id})`;
      if (user && admin && admin.id === user.id) {
        isAdmin = true;
      }
    }
    const options = selected.map(x => {
      const { center_team } = x;
      if (center_team) {
        return center_team.team;
      }
      return x;
    });
    let EditTeamButton = null;
    if (this.props.enable_edit) {
      EditTeamButton = (
        <Tooltip title={'Edit Selected Team'}>
          <span style={{ margin: 0, padding: 0, width: 60 }}>
            <Fab
              aria-label="edit-team"
              className={classes.expand}
              color="disable"
              disabled={team ? false : true}
              onClick={this.handleOpenEditTeam}>
              {<EditIcon style={{ color: 'green' }} />}
            </Fab>
          </span>
        </Tooltip>
      );
    }
    // console.log({ team });
    let team_name = '';
    let avatar = '';
    if (team && team.avatar) {
      team_name = team.name;
      avatar = team.avatar;
    }
    let ComponentProjects = null;
    if (team && projects.length) {
      ComponentProjects = (
        <Grid item md={3} sm={3} xs={12}>
          <AutoComboBox
            defaultValue={projects[0]}
            label="Projects"
            onObjectChanged={this.handleProjectChanged}
            options={projects}
            small
            width={180}
          />
        </Grid>
      );
    }
    if (IsInvalid(idProject) && this.state.idProject) {
      idProject = this.state.idProject;
    }
    console.log({ idProject });
    return (
      <div className={classes.margin}>
        <Grid
          alignItems="flex-start"
          container
          direction="row"
          justifyContent="flex-start"
          spacing={2}>
          <Grid item style={{ paddingTop: 15 }} xs={1}>
            <Avatar
              aria-label="avatar-project"
              className={classes.avatar}
              onClick={() => {
                if (isAdmin) this.props.handleOpenCloseAvatar(true);
              }}
              src={avatar}>
              {getInitials(team_name)}
            </Avatar>
          </Grid>
          <Grid item xs={3}>
            <SearchAutocomplete
              disabled={this.state.loading}
              handelSearchNameChanged={this.handelSiteNameChanged}
              handleAddResultObject={this.handleAddTeam}
              label={name_team}
              loading={this.state.loading}
              name={this.state.name}
              no_icon
              open={this.state.open}
              options={options}
              placeholder="Display Team..."
              selected={team}
              setObject={this.handleAddTeam}
              setObjectName={name => this.setState({ name })}
              setOpen={open => this.setState({ open })}
              sytle_autocomplete={{ paddingTop: 0 }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="input-with-icon-grid"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              label="Administrator"
              placeholder="Select a team..."
              size="small"
              value={getUserFullName(admin)}
            />
          </Grid>
          {ComponentProjects}
          <DivGrow />
          <Grid
            alignItems="center"
            container
            direction="row"
            item
            justifyContent="flex-end"
            xs={2}>
            {EditTeamButton}
            <Tooltip title={'Add Team Member'}>
              <span style={{ margin: 0, padding: 0, width: 60 }}>
                <Fab
                  aria-label="add"
                  className={classes.expand}
                  color="primary"
                  disabled={team ? false : true}
                  onClick={this.handleOpenAddMember}>
                  <PersonAddIcon />
                </Fab>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
        <DialogAddTeamMembers
          handleAddMembers={this.handleAddMembers}
          handleClose={this.handleOpenAddMember}
          handleTeamModified={this.props.handleAddTeamMembers}
          idProject={idProject}
          open={this.state.open_add_member}
          team={team}
        />
        <DialogEditTeam
          handleCloseDialog={this.handleOpenEditTeam}
          handleUpdatedTeam={this.handleUpdatedTeam}
          open={this.state.open_edit_team}
          team={team}
        />
      </div>
    );
  }
}

TeamViewToolBar.propTypes = {
  classes: PropTypes.object,
};
TeamViewToolBar.defaultProps = {
  options: [],
  handleUpdatedTeam: () => '',
  handleOpenCloseAvatar: () => '',
};

const mapStateToProps = state => {
  return {
    user: state.info_user.user,
  };
};

export default connect(mapStateToProps, null)(TeamViewToolBar);
