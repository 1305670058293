import { combineReducers } from 'redux';
// import postReducer from './postReducer';
import loginReducer from './loginReducer';
import registerReducer from './registerReducer';
import projectReducer from './projectReducer';
import pageTeamsReducer from './pageTeamsReducer';
import pageProjectsReducer from './pageProjectsReducer';
import layout from './layoutReducer';
import notifications from './notifications';
import manager from './file_folder_manager';
import pageUploadDownload from './pageUploadDownload';
import page_database from './page_database';
import page_explorer from './page_explorer';
import page_shared from './page_shared';
import page_project_view from './page_project_view';
import page_settings from './page_settings';
import page_dicom_viewer from './page_dicom_viewer';
import page_globals from './page_globals';
import running_processes from './running_processes';
import page_pipeline_blocks from './page_pipeline_blocks';

export default combineReducers({
  info_user: loginReducer,
  register: registerReducer,
  projects: projectReducer,
  page_teams: pageTeamsReducer,
  page_projects: pageProjectsReducer,
  page_database: page_database,
  page_explorer: page_explorer,
  page_shared: page_shared,
  page_project_view: page_project_view,
  layout: layout,
  page_activity: notifications,
  manager: manager,
  items: pageUploadDownload,
  page_settings: page_settings,
  page_dicom_viewer: page_dicom_viewer,
  page_globals: page_globals,
  running_processes: running_processes,
  page_pipeline_blocks: page_pipeline_blocks,
});
