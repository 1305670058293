import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const DELETE_PROJECT_ACTIVITIES = gql`
  mutation($ids: [Int!]!) {
    DeleteActivities(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationDeleteActivities = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(DELETE_PROJECT_ACTIVITIES),
    variables: { ids },
  });
};
