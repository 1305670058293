// import { drawSVGPath } from '../../utils_blocks';
import { getBlockConfig } from '../../utils_svg';
import { Block } from '../base';

class ReviewBlock extends Block {
  constructor(
    x,
    y,
    self,
    width = 180,
    height = 170,
    config = getBlockConfig('Review')
  ) {
    const contentHeight = 80; // 40px for top, 60px for bottom
    const bottomHeight = 70;
    super(
      x,
      y,
      width,
      height,
      config,
      contentHeight,
      bottomHeight,
      200,
      190,
      '#eceff1'
    );
    this.addPort('Input data', false, 20, '#cddc39'); // Add port on the left side
    this.addPort('Accepted', true, 60, '#4caf50'); // Add port on the left side
    this.addPort('Rejected', true, 100, '#f4511e'); // Add port on the left side
  }

  drawContent(ctx) {
    super.drawContent(ctx);
    const { scaleX, scaleY } = this.getScalingFactor();
    const padding = 20 * scaleX;
    const topSeparatorY = this.y + this.topHeight; // Y-coordinate of the top separator
    // const bottomSeparatorY = this.y + this.height - this.bottomHeight * scaleY; // Y-coordinate of the bottom separator

    // Calculate position for "MRI Segmentation" title
    const titleSeparatorY = topSeparatorY + this.topHeight;
    const titleY = titleSeparatorY - 15 * scaleY; // Moved down 5 pixels from previous position

    // Draw "MRI Segmentation" title
    ctx.font = `${13 * scaleY}px Arial`;
    ctx.fillStyle = '#4caf50';
    ctx.fillText('Accepted', this.x + padding, titleY);

    let position = titleY + 15 * scaleY;
    ctx.strokeStyle = '#e0e0e0';
    ctx.lineWidth = 1; // Line width remains unchanged
    ctx.beginPath();
    ctx.moveTo(this.x, position); // Adjust height only
    ctx.lineTo(this.x + this.width, position); // Adjust height only
    ctx.stroke();

    ctx.fillStyle = '#f4511e';
    ctx.fillText('Reject', this.x + padding, position + 25 * scaleY);
  }

  drawBottom(ctx) {
    const postSeparatorY = super.drawBottom(ctx);
    const { scaleX, scaleY } = this.getScalingFactor();
    // const yTopBottom = super.drawBottom(ctx);
    const padding = 20 * scaleX;

    ctx.font = `${14 * scaleY}px Arial`;
    ctx.fillStyle = '#9e9e9e';
    ctx.fillText(
      'Count reviewers: 100',
      this.x + padding,
      postSeparatorY + 25 * scaleY
    );
    ctx.fillText(
      'Reviewers rejected: 60',
      this.x + padding,
      postSeparatorY + 50 * scaleY
    );
  }
}

export default ReviewBlock;
