import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

class StatusbarBlockPipeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { diagram, selectedElement, selectedBlock } = this.props;
    return (
      <div
        className="h-6 bg-gradient-to-b from-gray-100 to-gray-200 border border-t-0 border-gray-300 rounded-b-lg flex items-center justify-between text-sm text-gray-600 px-2 "
        style={{ marginTop: 0, paddingTop: 0 }}>
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: 0,
            padding: 0,
            paddingLeft: '15px',
            paddingRight: '5px',
            marginTop: '-2px',
            width: '100%',
          }}>
          <Grid item>
            <strong className="mr-1" style={{ paddingRight: 5 }}>
              Loaded diagram:
            </strong>
            <span>
              {diagram && diagram.name !== ''
                ? diagram.name
                : 'No diagram load'}
            </span>
          </Grid>
          {selectedElement && (
            <Grid item>
              <strong className="mr-1" style={{ paddingRight: 5 }}>
                Selected:
              </strong>
              <span className="text-gray-500">{selectedElement}</span>
              {selectedBlock && (
                <>
                  <strong
                    className="mr-1"
                    style={{ paddingLeft: 10, paddingRight: 5 }}>
                    Position:
                  </strong>
                  <span
                    className="text-gray-500"
                    style={{
                      paddingRight: 10,
                    }}>{`${selectedBlock.x}x${selectedBlock.y}`}</span>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

StatusbarBlockPipeline.propTypes = {
  classes: PropTypes.object,
};

export default StatusbarBlockPipeline;
