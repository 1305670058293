import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';


const QUERY_MS_LAB_RESULTS = gql`
  query( $name: String, $search_query: String, $page: Int, $limit: Int) {
    msLabResults(name: $name, search_query: $search_query, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      results {
        id
        idPatient
        caseId
        lioncId
        methodId
        method
        method_abreviation
        test_material
        result_unit
        result_value
        test_type
        orderId
        order_date
        createdAt
        updatedAt
      }
    }
  }
`;

export const QueryMsResults = (name, search_query, page, limit) => {
  
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_MS_LAB_RESULTS),
    variables: {
      name,
      search_query,
      page,
      limit,
    },
  });
};


const MUTATION_MS_LAB_RESULTS = gql`
  mutation($page: Int, $limit: Int) {
    addMSLabResults(page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      results {
        id
        idPatient
        caseId
        lioncId
        methodId
        method
        method_abreviation
        test_material
        result_unit
        result_value
        result_date
        test_type
        orderId
        order_date
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationMSLabResults = (page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_MS_LAB_RESULTS),
    variables: {
      page,
      limit,
    },
  });
};

const MUTATION_REMOVE_MS_LAB_RESULTS = gql`
  mutation($ids: [LongLong!]!) {
    deleteMSLabResults(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationRemoveMsLabResults = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_MS_LAB_RESULTS),
    variables: {
      ids,
    },
  });
};