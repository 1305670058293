import { HTTP_ADDRESS_WEB } from 'config';
import React from 'react';
const style = {
  textAlign: 'justify',
  fontSize: 20,
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
};

export const page_aaoca = {
  direction: 'image_bottom',
  image: '/images/aaoca.jpg',
  title:
    'Artificial Intelligence Based Detection and Classification of AAOCA in CCTA',
  description: (
    <>
      <p style={style}>
        &nbsp; &nbsp; &nbsp;AAOCA is a rare cardiac condition that can cause
        ischemia or sudden cardiac death and might be overlooked or
        misclassified in routine coronary computed tomography angiography (CCTA)
        analysis. This fully automated, Artificial intelligence-based tool has
        been developed and externally evaluated to accurately analyze CCTA
        images to detect AAOCA, classify the origin of AAOCA, and assess the
        anatomical risk of AAOCA.
        <br />
        To center the link text vertically and horizontally in the middle of the
        page and ensure that the bottom line matches the width of the text, you
        can use the following setup: Updated Code:
      </p>
      <div
        style={{
          ...style,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          paddingTop: 10,
        }}>
        <a
          href={`${HTTP_ADDRESS_WEB}/public_access/projects/aaoca`}
          rel="noreferrer"
          style={{
            fontWeight: 600,
            color: 'blue',
            borderBottom: '2px solid blue',
            textAlign: 'center',
            display: 'inline-block',
            textDecoration: 'none',
            padding: '0 2px' /* Ensures the underline aligns perfectly */,
          }}
          target="_blank">
          Click here to test AAOCA
        </a>
      </div>
    </>
  ),
  text_bottom: (
    <p style={{ ...style, padding: 10 }}>
      <span style={{ fontWeight: 600 }}>Reference:</span> Shiri, I., Baj, G.,
      Mohammadi Kazaj, P., Bigler, M. R., Stark, A. W., Valenzuela, W.,
      Kakizaki, R., Siepe, M., Windecker, S., Räber, L., Giannopoulos, A. A.,
      Siontis, G. C. M., Buechel, R. R., & Gräni, C. (2024).{' '}
      <span style={{ fontWeight: 600 }}>
        Artificial Intelligence Based Detection and Classification of Anomalous
        Aortic Origin of Coronary Arteries in Coronary CT Angiography: A
        Multi-Center Development, Testing and Clinical Evaluation Study.
      </span>{' '}
      In submission.
    </p>
  ),
};
