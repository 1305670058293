import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
// import PersonAdd from '@mui/icons-material/PersonAdd';
// import Settings from '@mui/icons-material/Settings';
// import FullscreenIcon from '@mui/icons-material/Fullscreen';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { Divider } from '@mui/material';
import { IsAdministrator } from 'helpers';
class MenuProjectActivities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { anchorEl, user } = this.props;
    const list = [
      <MenuItem key="0" onClick={() => this.props.handleAction('show')}>
        <ListItemIcon>
          <OpenWithIcon fontSize="small" />
        </ListItemIcon>
        Show Activity
      </MenuItem>,
    ];
    if (IsAdministrator(user)) {
      list.push(<Divider key="1" />);
      list.push(
        <MenuItem key="2" onClick={() => this.props.handleAction('delete')}>
          <ListItemIcon>
            <DeleteOutline fontSize="small" />
          </ListItemIcon>
          Delete
        </MenuItem>
      );
    }
    return (
      <React.Fragment>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          id="account-menu"
          onClick={this.props.handleClose}
          onClose={this.props.handleClose}
          open={anchorEl ? true : false}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}>
          {list}
        </Menu>
      </React.Fragment>
    );
  }
}

MenuProjectActivities.propTypes = {
  handleAction: () => '',
  handleClose: () => '',
  open: PropTypes.bool,
};

export default MenuProjectActivities;
