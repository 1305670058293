import { getBlockConfig } from '../../utils_svg';
import { Block } from '../base';

class AIModelBlock extends Block {
  constructor(
    x,
    y,
    self,
    width = 200,
    height = 200,
    config = getBlockConfig('AI Model')
  ) {
    const contentHeight = 150; // 40px for top, 60px for bottom
    const bottomHeight = 60;
    super(x, y, width, height, config, contentHeight, bottomHeight, 250, 250);
    this.addPort('Output Model', true); // Add port on the left side
    this.addPort('Input data', false, 60); // Add port on the left side
    this.addPort('Input model', false); // Add port on the left side
  }

  drawContent(ctx) {
    super.drawContent(ctx);
    const { scaleX, scaleY } = this.getScalingFactor();
    const padding = 20 * scaleX;
    const topSeparatorY = this.y + this.topHeight; // Y-coordinate of the top separator
    const bottomSeparatorY = this.y + this.height - this.bottomHeight * scaleY; // Y-coordinate of the bottom separator

    // Calculate position for "MRI Segmentation" title
    const titleSeparatorY = topSeparatorY + this.topHeight;
    const titleY = titleSeparatorY - 15 * scaleY; // Moved down 5 pixels from previous position

    // Draw "MRI Segmentation" title
    ctx.font = `bold ${14 * scaleY}px Arial`;
    ctx.fillStyle = '#000';
    ctx.fillText('MRI Segmentation', this.x + padding, titleY);

    // Draw separator line below "MRI Segmentation"
    ctx.strokeStyle = '#e0e0e0';
    ctx.lineWidth = 1; // Line width remains unchanged
    ctx.beginPath();
    ctx.moveTo(this.x, titleSeparatorY);
    ctx.lineTo(this.x + this.width, titleSeparatorY);
    ctx.stroke();

    // Calculate the available space for items
    const availableHeight = bottomSeparatorY - titleSeparatorY;
    const itemHeight = 30 * scaleY;
    const totalItemsHeight = itemHeight * 3; // 3 items
    const startY = titleSeparatorY + (availableHeight - totalItemsHeight) / 2;

    // Draw items
    const items = ['Brain Tumors', 'Cerebellum', 'Stroke'];
    items.forEach((item, index) => {
      const itemY = startY + index * itemHeight + 5; // Added 5 pixels here
      ctx.fillStyle = this.color;
      ctx.fillRect(this.x + padding, itemY, 20 * scaleX, 20 * scaleY);

      ctx.font = `${14 * scaleY}px Arial`;
      ctx.fillStyle = '#000';
      ctx.fillText(item, this.x + padding + 30 * scaleX, itemY + 15 * scaleY);
    });
  }

  drawBottom(ctx) {
    super.drawBottom(ctx);
    const { scaleX, scaleY } = this.getScalingFactor();
    // const yTopBottom = super.drawBottom(ctx);
    const padding = 20 * scaleX;
    const bottomHeight = this.bottomHeight * scaleY;

    // Draw bottom content
    ctx.fillStyle = '#f5f5f5';
    ctx.fillRect(
      this.x + padding,
      this.y + this.height - bottomHeight + 10 * scaleY,
      this.width - padding * 2,
      bottomHeight - 20 * scaleY
    );

    ctx.font = `${14 * scaleY}px Arial`;
    ctx.fillStyle = '#000';
    ctx.fillText(
      '23:12 Avg. Time 1.5k Passes',
      this.x + padding + 10 * scaleX,
      this.y + this.height - 25 * scaleY
    );
  }
}

export default AIModelBlock;
