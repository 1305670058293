import React from 'react';
import { withStyles } from 'tss-react/mui';

import PropTypes from 'prop-types';

import {
  GroupBoxQuery,
  TableQueryResults,
  TopTabQuery,
  useStyles,
} from './components';

import { StudyQueryRetrieve } from 'graphql/Study';
import { GetData, ServerErrorsString } from 'helpers';
class TabQueryImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      loading: false,
      rows: [],
      total: 0,
      current_query: {},
      setting: null,
      numSeries: 0,
    };
    this.holder = {
      getQuerySearch: null,
      getStudyTimes: null,
      getModalities: null,
      getDescription: null,
      getImageType: null,
      getTabImageType: null,
      getCleanSeries: null,
      getPageLimit: null,
      setPageLimit: null,
      getFilters: null,
    };
    this.holder.getCleanSeries = () => this.state.clean_series;
  }

  handleStudyDeleted = studies => {
    if (studies.length === 0) return;
    const { rows } = this.state;
    const newRows = [];
    for (let i = 0; i < rows.length; i++) {
      const { idStudy } = rows[i];
      const index = studies.map(x => x.idStudy).indexOf(idStudy);
      if (index === -1) {
        newRows.push(rows[i]);
      }
    }
    this.setState({ rows: newRows });
  };
  handleTabValue = (event, newValue) => {
    event.preventDefault();
    this.setState({ tabValue: parseInt(newValue, 10) });
  };
  getSettingSearch = () => {
    if (!this.holder.getPageLimit) {
      return null;
    }
    const { page, limit } = this.holder.getPageLimit();
    let setting = {
      page,
      limit,
    };
    const imagetype = this.holder.getImageType();
    if (imagetype.nifti) {
      let nifti = 0;
      if (imagetype.nifti === 'With NIfTI') nifti = 1;
      if (imagetype.nifti === 'Without NIfTI') nifti = 2;
      setting.nifti = nifti;
    }
    const filters = this.holder.getFilters();
    if (filters) {
      setting = {
        ...setting,
        ...filters,
      };
    }
    return setting;
  };
  menuRunSearch = () => {
    let query = undefined;
    let newPage = undefined;
    if (this.holder.getQuerySearch) {
      query = this.holder.getQuerySearch();
    }
    const { limit } = this.holder.getPageLimit();
    this.runSearch(query, newPage, limit);
  };
  runSearch = (query, newPage, newLimit) => {
    const { loading } = this.state;
    if (loading || query === undefined) {
      return;
    }
    let setting = this.getSettingSearch();
    if (newLimit) {
      setting.page = newPage;
      setting.limit = newLimit;
    }
    this.setState({ loading: true, current_query: query, setting });
    (async () => {
      StudyQueryRetrieve(query, setting)
        .then(res => {
          const data = GetData(res);
          const { ok, studies, errors, total } = data.Studies;
          if (ok) {
            let numSeries = 0;
            for (let i = 0; i < studies.length; i++) {
              const { NumberOfStudyRelatedSeries } = studies[i];
              numSeries += NumberOfStudyRelatedSeries;
            }
            this.setState({
              loading: false,
              total,
              rows: studies,
              clean_series: true,
              numSeries,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
          });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };

  render() {
    let { classes, height } = this.props;
    const {
      tabValue,
      loading,
      total,
      rows,
      current_query,
      numSeries,
    } = this.state;
    let style = null;
    let style_content = null;
    if (height) {
      height = height - 118;
      style = { height, maxHeight: height };
      style_content = {
        height: height - 105,
        maxHeight: height - 105,
        overflowY: 'auto',
        margin: 5,
        marginTop: 0,
        marginBottom: 0,
        paddingBottom: 0,
      };
    }
    let table_height = height - 15;
    if (table_height < 300) table_height = 300;
    return (
      <div className={classes.main_container} style={style}>
        <TopTabQuery
          classes={classes}
          handleAddError={this.props.handleAddError}
          handleAddSuccess={this.props.handleAddSuccess}
          handleAddWarning={this.props.handleAddWarning}
          handleTabValue={this.handleTabValue}
          holder={this.holder}
          loading={loading}
          runSearch={this.runSearch}
          tabValue={tabValue}
        />
        <div className={classes.sub_container} style={style_content}>
          <GroupBoxQuery
            classes={classes}
            holder={this.holder}
            menuRunSearch={this.menuRunSearch}
            runSearch={() => this.runSearch()}
            tabValue={tabValue}
          />
          <TableQueryResults
            current_query={current_query}
            handleAddError={this.props.handleAddError}
            handleAddSuccess={this.props.handleAddSuccess}
            handleAddWarning={this.props.handleAddWarning}
            handleStudyDeleted={this.handleStudyDeleted}
            height={table_height}
            holder={this.holder}
            loading={loading}
            numSeries={numSeries}
            rows={rows}
            runSearch={this.runSearch}
            setting={this.state.setting}
            total={total}
          />
        </div>
      </div>
    );
  }
}

TabQueryImages.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(TabQueryImages, useStyles);
