import SettingDataset from './SettingDataset';
import SettingAnnotate from './SettingAnnotate';
import SettingConsensus from './SettingConsensus';
import SettingAIModel from './SettingAIModel';
import SettingLogic from './SettingLogic';
import SettingReview from './SettingReview';
import SettingSampling from './SettingSampling';
import SettingImageAlgoth from './SettingImageAlgoth';
import SettingArchive from './SettingArchive';
import SettingCompleted from './SettingCompleted';
import SettingTrainAI from './SettingTrainAI';

export const list_components = [
  { id: 'Dataset', ComponentSetting: SettingDataset },
  { id: 'Annotate', ComponentSetting: SettingAnnotate },
  { id: 'Consensus', ComponentSetting: SettingConsensus },
  { id: 'AI Model', ComponentSetting: SettingAIModel },
  { id: 'Logic', ComponentSetting: SettingLogic },
  { id: 'Review', ComponentSetting: SettingReview },
  { id: 'Sampling', ComponentSetting: SettingSampling },
  { id: 'Image Algorithm', ComponentSetting: SettingImageAlgoth },
  { id: 'Archive', ComponentSetting: SettingArchive },
  { id: 'Completed', ComponentSetting: SettingCompleted },
  { id: 'Train AI Model', ComponentSetting: SettingTrainAI },
];

export const getComponent = title => {
  const index = list_components.findIndex(x => x.id === title);
  if (index === -1) return null;
  return list_components[index].ComponentSetting;
};
