import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadEpilepsyCSV } from './utils';

const QUERY_EPILEPSY_RESEARCH_STUDIES = gql`
  query($name: String, $search_query: String, $page: Int, $limit: Int) {
    epilepsyResearchStudies(name: $name, search_query: $search_query, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      research_studies {
        id
        idPatient
        idEpilepsyCase
        brain_map
        input_output
        circadian
        pharmaco
        audio_sleep
        music_start_date
        music_end_date
        epi_os
        spec_ictal
        comment

        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryEpilepsyResearchStudies = (name, search_query, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_EPILEPSY_RESEARCH_STUDIES),
    variables: {
      name,
      search_query,
      page,
      limit,
    },
  });
};
const MUTATION_REMOVE_EPILEPSY_RESEARCH_STUDIES = gql`
  mutation($ids: [LongLong!]!) {
    deleteEpilepsyResearchStudies(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveEpilepsyResearchStudies = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_EPILEPSY_RESEARCH_STUDIES),
    variables: {
      ids,
    },
  });
};
const MUTATION_ADD_EPILEPSY_RESEARCH_STUDIES = gql`
  mutation($inputs: [InputEpilepsyResearchStudy!]!) {
    addEpilepsyResearchStudies(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      research_studies {
        id
        idPatient
        idEpilepsyCase
        brain_map
        input_output
        circadian
        pharmaco
        audio_sleep
        music_start_date
        music_end_date
        epi_os
        spec_ictal
        comment

        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationAddEpilepsyResearchStudies = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_EPILEPSY_RESEARCH_STUDIES),
    variables: {
      inputs,
    },
  });
};
const MUTATION_UPLOAD_EPILEPSY_RESEARCH_STUDIES = gql`
  mutation($file: Upload!) {
    uploadEpilepsyResearchStudies(file: $file) {
      ok
      errors {
        path
        message
      }
      total
      research_studies {
        id
        idPatient
        idEpilepsyCase
        brain_map
        input_output
        circadian
        pharmaco
        audio_sleep
        music_start_date
        music_end_date
        epi_os
        spec_ictal
        comment

        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationUploadEpilepsyResearchStudies = (
  file,
  handleProgress = null
) => {
  return MutationUploadEpilepsyCSV(
    file,
    MUTATION_UPLOAD_EPILEPSY_RESEARCH_STUDIES,
    handleProgress
  );
};

const QUERY_SEARCH_EPILEPSY_RESEARCH_STUDIES = gql`
  query($phrase: String, $page: Int, $limit: Int) {
    searchEpilepsyResearchStudies(phrase: $phrase, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      research_studies {
        id
        idPatient
        idEpilepsyCase
        brain_map
        input_output
        circadian
        pharmaco
        audio_sleep
        music_start_date
        music_end_date
        epi_os
        spec_ictal
        comment
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QuerySearchEpilepsyResearchStudies = (phrase, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SEARCH_EPILEPSY_RESEARCH_STUDIES),
    variables: {
      phrase,
      page,
      limit,
    },
  });
};
