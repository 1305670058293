import React from 'react';
import PropTypes from 'prop-types';
import CardBlocks from '../CardBlocks';
import { Box, Typography } from '@mui/material';
import { getComponent } from './components';
import { SettingBottom } from './components/components';
const AreEqual = (a, b) => {
  return (
    a.length === b.length && // Ensure both arrays have the same length
    a.every(itemA => b.some(itemB => itemA === itemB)) && // Check each id in a exists in b
    b.every(itemB => a.some(itemA => itemA === itemB))
  ); // Check each id in b exists in a
};

class BlockSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      position: { x: 0, y: 0 },
      view: 'Setting',
      on_ports: [],
      off_ports: [],
      block: null,
      minWidth: 300,
      maxWidth: 345,
      normalview: true,
    };
    this.cardRef = React.createRef();
  }
  componentDidMount() {
    const { selectedBlock } = this.props;
    let on_ports = [];
    let off_ports = [];
    if (selectedBlock) {
      on_ports = selectedBlock.getPortsOn().map(x => x.id);
      off_ports = selectedBlock.getPortsOff().map(x => x.id);
    }
    this.setState({ on_ports, off_ports, block: selectedBlock });
  }
  componentDidUpdate() {
    const { selectedBlock } = this.props;
    const { block } = this.state;
    let changed = false;
    if (selectedBlock) {
      if (block && block.id !== selectedBlock.id) changed = true;
      else if (!block) changed = true;
    } else if (block) changed = true;
    if (changed) {
      let on_ports = [];
      let off_ports = [];
      if (selectedBlock) {
        on_ports = selectedBlock.getPortsOn().map(x => x.id);
        off_ports = selectedBlock.getPortsOff().map(x => x.id);
      }
      this.setState({
        block: selectedBlock,
        on_ports,
        off_ports,
      });
    }
  }
  handleDrag = (e, data) => {
    let y = data.y;
    let stop = this.state.disable_dragging;
    const rect = this.cardRef.current.getBoundingClientRect();
    if (rect.top < 70) {
      y = this.state.position.y;
      stop = false;
    }
    this.setState({
      position: { x: data.x, y },
      disable_dragging: stop,
    });
  };
  handleSettingChanged = () => {
    const { selectedBlock } = this.props;
    const new_on_ports = selectedBlock.getPortsOn().map(p => p.id);
    const new_off_ports = selectedBlock.getPortsOff().map(p => p.id);
    const { on_ports, off_ports } = this.state;
    const on_ok = AreEqual(new_on_ports, on_ports);
    const off_ok = AreEqual(new_off_ports, off_ports);
    this.props.handleSettingChanged(!(on_ok && off_ok));
  };
  render() {
    const { open, selectedBlock, width, height } = this.props;
    if (!open || !selectedBlock) return null;
    const title = selectedBlock.title;
    let style = { minHeight: 490 };
    if (height < 500) style = { ...style, height: height - 200 };
    const ComponentSetting = getComponent(selectedBlock.title);
    return (
      <CardBlocks
        cardRef={this.cardRef}
        height={height}
        onActionClick={() => this.props.setState({ open_block_setting: false })}
        onDrag={this.handleDrag}
        onDragEnd={() => this.setState({ disable_dragging: true })}
        position={this.state.position}
        setState={state => this.setState(state)}
        title={
          <div style={{ display: 'inline-block' }}>
            <Typography display="inline" sx={{ fontWeight: 1000 }}>
              Block:
            </Typography>
            <Typography
              display="inline"
              sx={{ paddingLeft: 1, fontWeight: 100 }}>
              {title}
            </Typography>
          </div>
        }
        width={width}>
        <Box
          sx={{
            backgroundColor: '#e0e0e0', //'#bcaaa4',
            padding: 0,
            paddingTop: 1,
            ...style,
          }}>
          {ComponentSetting && (
            <ComponentSetting
              block={selectedBlock}
              fullScreen={this.state.maxWidth > 350 ? true : false}
              handleSettingChanged={this.handleSettingChanged}
              height={height}
              maxWidth={this.state.maxWidth}
              minWidth={this.state.minWidth}
              normalview={this.state.normalview}
              ports={selectedBlock.getPorts()}
              view={this.state.view}
              width={width}
            />
          )}
          <SettingBottom
            handleView={view => this.setState({ view })}
            view={this.state.view}
          />
        </Box>
      </CardBlocks>
    );
  }
}

BlockSetting.propTypes = {
  classes: PropTypes.object,
};
BlockSetting.defaultProps = {
  handleRender: () => '',
};
export default BlockSetting;
