import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Paper, Tab, Tabs } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { TabPanel } from 'components';
import { TabActivityNotifications, TabActivityProjects } from './components';
import { useStylesAdministrator } from 'views/Administrator/components/AdminDefault/useStyles';

class TabActivities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
    };
  }

  handleTabValue = (event, newValue) => {
    event.preventDefault();
    newValue = parseInt(newValue, 10);
    if (newValue < 0 || newValue > 1) {
      newValue = 0;
    }
    this.setState({ tabValue: newValue });
  };

  render() {
    const { classes, height, user } = this.props;
    const { tabValue } = this.state;
    if (!this.props.isActive) return null;
    return (
      <div style={{ margin: 10 }}>
        <Paper square>
          <Tabs
            indicatorColor="primary"
            onChange={this.handleTabValue}
            textColor="primary"
            value={tabValue}
            variant="scrollable">
            <Tab label="Notifications" />
            <Tab label="Project Activities" />
          </Tabs>
          <Divider />
          <div style={{ width: '100%', height: height - 114 - 75 }}>
            <div className={classes.tab_content}>
              <TabPanel
                className={classes.tab_panel}
                index={0}
                value={tabValue}>
                <TabActivityNotifications
                  handleAddError={this.props.handleAddError}
                  handleAddSuccess={this.props.handleAddSuccess}
                  height={height}
                  isActive={this.props.isActive}
                  user={user}
                />
              </TabPanel>
              <TabPanel
                className={classes.tab_panel}
                index={1}
                value={tabValue}>
                <TabActivityProjects
                  handleAddError={this.props.handleAddError}
                  handleAddSuccess={this.props.handleAddSuccess}
                  height={height}
                  isActive={this.props.isActive}
                  user={user}
                />
              </TabPanel>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

TabActivities.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(TabActivities, useStylesAdministrator);
