import React from 'react';

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';

import { GetData, PaperComponent, IsValid, IsInvalid } from 'helpers';
import { ButtonLoad, TextFieldCustom } from 'components';
import { QueryDatabaseTables } from 'graphql/utils_database_tables';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { LocalizationProvider } from '@mui/x-date-pickers';
import { IsGreaterThan, IsWrongFormat } from 'components/DatePickers/utils_picker';

class DialogEncryptionAdvancedSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: null,
      tables: [],
      selected_table: null,
      errors: {},
    };
  }
  componentDidMount() {
    this.getTables();
  }

  handleClose = () => {
    this.props.handleClose();
  };
  getTables = () => {
    const inputs = [
      { table_name: 'encryption_patients' },
      { table_name: 'patient_dicoms' },
      { table_name: 'dicom_studies' },
      { table_name: 'dicom_series' },
      { table_name: 'projects' },
      { table_name: 'users' },
      { table_name: 'dicom_sequences' },
    ];
    (async () => {
      QueryDatabaseTables(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, tables } = data.queryDatabaseTables;
          if (ok) {
            this.setState({ tables });
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          console.log('QueryDatabaseTables-catch-error', errors);
        });
    })();
  };

  handleTableChanged = value => {
    console.log('handleTableChange', value);
    if (IsInvalid(value)) {
      this.setState({ query: null });
      return;
    }
    const { tables } = this.state;
    const table = tables.find(x => x.table_name === value);
    const new_query = {
      table: table.table_name,
      column: table.columns[0],
      text: '',
    };
    this.setState({ query: new_query, selected_table: table });
  };
  handleColumnChanged = value => {
    console.log('handleColumnChange', value);
    const { query } = this.state;
    const new_query = {
      table: query.table,
      column: value,
      text: '',
    };
    this.setState({ query: new_query });
  };
  handleTextChanged = event => {
    const { query } = this.state;
    const text = event.target.value;
    console.log('handleColumnChange', text);
    const new_query = {
      table: query.table,
      column: query.column,
      text: text,
    };
    this.setState({ query: new_query });
  };
  handleSendQuery = () => {
    const { query } = this.state;
    console.log('handleSendQuery in DialogEncryptionAdvancedSearch', { query });
    const formErrors = this.validateForm();
    if (Object.keys(formErrors).length === 0) {
      // Handle form submission
      console.log('Form submitted:', query);
      const query_submit = JSON.parse(JSON.stringify(query));
      query_submit.column = query.column.fieldName;
      this.props.handleAdvancedSearch(query_submit);
    } else {
      this.setState({ errors: formErrors });
    }
  };
  validateForm = () => {
    const { query } = this.state;
    const newErrors = {};
    if (!query || !query.table) newErrors.table = 'Table is required';
    if (!query || !query.column) newErrors.column = 'Column is required';
    if (!query || !query.text) newErrors.text = 'Value is required';
    return newErrors;
  };
  keyPress = event => {
    if (event.keyCode === 13) {
      this.handleSendQuery();
    }
  };
  onChangeDate = text => {
    // setTextDate(text);
    try {
      const parts = text.split('-');
      if (
        parts.length !== 2 ||
        IsWrongFormat(parts[0]) ||
        IsWrongFormat(parts[1]) ||
        IsGreaterThan(parts[0], parts[1], 'DD/MM/YYYY')
      ) {
        return;
      }
      const date_from = moment(parts[0].trim(), 'DD/MM/YYYY');
      const date_to = moment(parts[1].trim(), 'DD/MM/YYYY');
      console.log({ date_from, date_to });
      // updateDate([date_from.toDate(), date_to.toDate()]);
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    let { open, loading } = this.props;
    const { query, tables, selected_table, errors } = this.state;
    if (tables.length === 0) return;
    return (
      <Dialog
        aria-labelledby="draggable-dialog-title"
        onClose={this.handleClose}
        open={open}
        PaperComponent={PaperComponent}>
        <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }}>
          Encryption Advanced Search
        </DialogTitle>

        <DialogContent>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="space-between"
            pt={1}
            spacing={2}>
            <Grid item sx={{ width: '100%' }} xs={12}>
              <Grid item pt={2} xs={12}>
                <Autocomplete
                  id="table-select"
                  onChange={(event, values) => this.handleTableChanged(values)}
                  options={tables.map(x => x.table_name)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      error={!!errors.table}
                      helperText={errors.table}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Tables"
                      placeholder="Select table..."
                      required
                    />
                  )}
                  size="small"
                  value={IsValid(query) ? query.table : null}
                />
              </Grid>
              <Grid item pt={2} xs={12}>
                <Autocomplete
                  getOptionLabel={option => option.fieldName}
                  id="column-select"
                  isOptionEqualToValue={(option, value) => {
                    if (value) return option.fieldName === value.fieldName;
                    else return false;
                  }}
                  onChange={(event, values) => this.handleColumnChanged(values)}
                  options={
                    IsValid(selected_table) ? selected_table.columns : []
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      error={!!errors.column}
                      helperText={errors.column}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Columns"
                      placeholder="Select column..."
                      required
                    />
                  )}
                  size="small"
                  value={
                    IsValid(query) && IsValid(query.column)
                      ? query.column
                      : null
                  }
                />
              </Grid>
              <Grid item pt={2} xs={12}>
                <TextFieldCustom
                  error={!!errors.text}
                  helperText={errors.text}
                  label="Value"
                  name="value"
                  onChange={this.handleTextChanged}
                  onKeyDown={this.keyPress}
                  required
                  value={IsValid(query) ? query.text : ''}
                />
              </Grid>
              {/* <Grid item pt={2} xs={12}>
                {IsValid(query) && (query.column.type === 'DATE' ||
                query.column.type === 'DATETIME') ? (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Value to Filter"
                        onChange={(value) => this.onChangeDate(value)}
                        onKeyDown={this.keyPress}
                        required
                        slotProps={{
                          textField: {
                            error: !!errors.text,
                            helperText: errors.text,
                            fullWidth: true,
                            size: 'small',
                            variant: 'standard',
                          },
                        }}
                        sx={{
                          mt: 0,
                          width: 170,
                        }}
                        value={
                          IsValid(query)
                            ? moment(query.text)
                            : null
                        }
                      />
                    </LocalizationProvider>
                  ) : (
                    <TextField
                      error={!!errors.text}
                      fullWidth
                      helperText={errors.text}
                      id="input-with-icon-textfield"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Value to Filter"
                      onChange={this.handleTextChanged}
                      onKeyDown={this.keyPress}
                      required
                      style={{ paddingTop: 0, marginTop: 0 }}
                      sx={{
                        mt: 0,
                        width: 170,
                      }}
                      value={IsValid(query) ? query.text : ''}
                      variant="standard"
                    />
                  )}
              </Grid> */}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="primary" onClick={this.handleClose}>
            Cancel
          </Button>
          {/* loading */}
          <ButtonLoad
            autoFocus
            color="primary"
            handleClick={this.handleSendQuery}
            loading={loading}
            name="SEARCH"
            variant="text"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

DialogEncryptionAdvancedSearch.propTypes = {
  handleAddError: PropTypes.func,
  handleAdvancedSearch: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};
DialogEncryptionAdvancedSearch.defaultProps = {
  handleClose: () => '',
  handleAdvancedSearch: () => '',
  handleAddError: () => '',
  open: false,
};
export default DialogEncryptionAdvancedSearch;
