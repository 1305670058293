import { parseSVGPaths } from './utils_svg';

export const createLine = (
  ctx,
  config = { x: 0, y: 0, width: 10, lineWidth: 1, color: 'black' }
) => {
  const strokeStyle = ctx.strokeStyle;
  ctx.strokeStyle = config.color;
  ctx.lineWidth = config.lineWidth; // Line width remains unchanged
  ctx.beginPath();
  ctx.moveTo(config.x, config.y); // Adjust height only
  ctx.lineTo(config.x + config.width, config.y); // Adjust height only
  ctx.stroke();
  ctx.strokeStyle = strokeStyle;
};

export const createText = (
  ctx,
  text,
  config = { x: 0, y: 0, width: 10, lineWidth: 1, color: 'black', font: null }
) => {
  const font = ctx.font;
  if (config.font) ctx.font = config.font;
  ctx.fillStyle = config.color;
  ctx.fillText(text, config.x, config.y);
  ctx.font = font;
};

export const createRectangle = (
  ctx,
  config = {
    x: 0,
    y: 0,
    width: 10,
    height: 10,
    lineWidth: 2,
    color: 'black',
    font: null,
    radius: 10,
  }
) => {
  ctx.strokeStyle = config.color;
  ctx.lineWidth = config.lineWidth; // Line width remains unchanged
  ctx.beginPath();
  ctx.roundRect(
    config.x, // Keep x position unchanged
    config.y, // Keep y position unchanged
    config.width, // Already scaled
    config.height, // Already scaled
    config.radius // Adjust corner radius based on scaleX
  );
  ctx.stroke();
};

export const createBoxTitle = (ctx, title, config = {}, icon = null) => {
  const { x = 0, y = 0, scaleX = 1, scaleY = 1 } = config;
  ctx.fillStyle = config.color;
  let move_title_x = 50;
  if (icon) {
    const yIcon = y + icon.delta * scaleY;
    const { size } = icon;
    let iconWidth = size.width;
    let iconHeight = size.height;
    const h = 32 * scaleY;
    let scale_x = 1;
    if (iconHeight > h) {
      const scale = h / size.height;
      scale_x = h / size.width;
      iconHeight = size.height * scale;
      iconWidth = size.width * scale_x;
    }
    move_title_x = size.width * scale_x + 30 * scaleX;
    let paddingLeft = 14;
    if (icon.move) {
      paddingLeft = icon.move;
      move_title_x = size.width * scale_x + 20 * scaleX;
    }
    drawSVGPath(ctx, icon.path, x + paddingLeft * scaleX, yIcon, {
      fill: icon.fill,
      stroke: icon.stroke,
      strokeWidth: 2,
      width: iconWidth,
      height: iconHeight,
      originalHeight: size.width,
      originalWidth: size.height,
    });
  } else {
    ctx.fillRect(
      x + 20, // Keep x position unchanged
      y + 10 * scaleY, // Adjust y position only for scaling
      20 * scaleX, // Fixed width
      20 * scaleY // Adjust height for scaling
    );
  }

  ctx.fillStyle = '#000';
  const font_prev = ctx.font;
  if (ctx.font) ctx.font = config.font; // Scale font size based on scaleX
  ctx.fillText(title, x + move_title_x * scaleX, y + 25 * scaleY); // Adjust only y-offset
  ctx.font = font_prev;
};

export const getConfig = (x, y, color = 'black') => {
  const config = {
    x: x,
    y: y,
    width: 10,
    height: 10,
    lineWidth: 2,
    color,
    font: null,
    radius: 10,
    scaleX: 1,
    scaleY: 1,
  };
  return config;
};
export const drawSVGPath = (ctx, path, x, y, options = {}) => {
  const {
    fill = '#000',
    stroke = 'none',
    width,
    height,
    originalWidth,
    originalHeight,
  } = options;
  const paths = parseSVGPaths(path);
  if (!paths.length) paths.push({ d: path });
  const scaleX = width / originalWidth;
  const scaleY = height / originalHeight;
  // Draw path
  ctx.save();
  ctx.translate(x, y);
  ctx.scale(scaleX, scaleY);
  paths.forEach(path => {
    // console.log({ d: path.d });
    const p2d = new Path2D(path.d);

    ctx.save();
    //  ctx.scale(scale, scale);
    ctx.globalAlpha = path.opacity;
    ctx.fillStyle = options.fill;
    ctx.strokeStyle = options.stroke;
    ctx.lineWidth = path.strokeWidth;
    ctx.lineCap = path.strokeLinecap;
    if (fill !== 'none') ctx.fill(p2d);
    if (stroke !== 'none') ctx.stroke(p2d);
    ctx.restore();
  });
  ctx.restore();
};
