import React from 'react';
import PropTypes from 'prop-types';
import ImageIcon from '@mui/icons-material/Image';
import CreateIcon from '@mui/icons-material/Create';
import {
  TableRow,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  IconButton,
  TableCell,
} from '@mui/material';
import {
  cell_check,
  cell_check_icon,
  getIconStatus,
  CheckBoxSyan,
  StackProgress,
  TypoProgress,
  ChipButtom,
} from '../styles';

class TableCustomRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { row, idSelected } = this.props;
    const { user, phone, progress, created, status } = row;
    return (
      <TableRow>
        <TableCell sx={cell_check}>
          <CheckBoxSyan
            checked={idSelected}
            onClick={() => this.handleCheckbox(row)}
            sx={cell_check_icon}
          />
        </TableCell>
        <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <ListItem sx={{ padding: 0 }}>
            <ListItemAvatar>
              <Avatar>
                <ImageIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography
                  component="a"
                  sx={{
                    margin: '0px',
                    fontFamily:
                      '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                    fontWeight: 500,
                    fontSize: '0.875rem',
                    lineHeight: 1.57,
                    textDecoration: 'none',
                    color: 'inherit',
                    whiteSpace: 'nowrap',
                  }}
                  variant="subtitle2">
                  {user.name}
                </Typography>
              }
              secondary={
                <Typography
                  component="p"
                  sx={{
                    margin: 0,
                    fontFamily:
                      '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
                    fontWeight: 400,
                    fontSize: '0.875rem',
                    lineHeight: 1.57,
                    color: 'var(--mui-palette-text-secondary)',
                    '--mui-palette-text-secondary': '#667085',
                  }}
                  variant="body2">
                  {user.email}
                </Typography>
              }
            />
          </ListItem>
        </TableCell>
        <TableCell>
          <StackProgress>
            <LinearProgress
              sx={{
                position: 'relative',
                display: 'block',
                height: '4px',
                zIndex: 0,
                backgroundColor: 'var(--mui-palette-LinearProgress-primaryBg)',
                borderRadius: '8px',
                overflow: 'hidden',
                flex: '1 1 auto',
                '--mui-palette-LinearProgress-primaryBg': 'rgb(195, 192, 255)',
              }}
              value={progress}
              variant="determinate"
            />
            <TypoProgress component="p" variant="body2">
              {`${progress}%`}
            </TypoProgress>
          </StackProgress>
        </TableCell>
        <TableCell
          sx={{
            fontFamily:
              '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: 1.57,
            verticalAlign: 'inherit',
            textAlign: 'left',
            padding: '16px',
            color: 'var(--mui-palette-text-primary)',
            borderBottom:
              ' var(--TableCell-borderWidth, 1px) solid var(--mui-palette-TableCell-border)',
            '--mui-palette-text-primary': '#212636',
            '--mui-palette-TableCell-border': '#dcdfe4',
          }}>
          {phone}
        </TableCell>
        <TableCell
          sx={{
            fontFamily:
              '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: 1.57,
            verticalAlign: 'inherit',
            textAlign: 'left',
            padding: '16px',
            color: 'var(--mui-palette-text-primary)',
            borderBottom:
              ' var(--TableCell-borderWidth, 1px) solid var(--mui-palette-TableCell-border)',
            '--mui-palette-text-primary': '#212636',
            '--mui-palette-TableCell-border': '#dcdfe4',
          }}>
          {created}
        </TableCell>
        <TableCell>
          <ChipButtom
            color="secondary"
            icon={getIconStatus(status)}
            label={status}
            size="small"
            variant="outlined"
          />
        </TableCell>
        <TableCell>
          <IconButton
            color="secondary"
            onClick={() => this.props.handleEdit(row)}
            size="medium"
            sx={{
              borderRadius: '8px',
              height: 'var(--IconButton-size)',
              width: 'var(--IconButton-size)',
              color: 'var(--mui-palette-secondary-main)',
              '--IconButton-size': '40px',
              '--mui-palette-secondary-main': '#32383e',
            }}>
            <CreateIcon sx={{ fill: '#107569' }} />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}

TableCustomRow.propTypes = {
  classes: PropTypes.object,
};

export default TableCustomRow;
