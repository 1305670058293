import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import { withStyles } from 'tss-react/mui';
import {
  Avatar,
  AvatarGroup,
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
class ConfigCompleted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.view !== 'Configuration') return null;
    const { classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <Box className={classes.box}>
          <Box className={classes.box_title}>
            <Grid
              container
              direction="row"
              sx={{
                paddingTop: 1,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <Grid item>
                <Typography className={classes.typo_title} variant="h6">
                  premium support
                </Typography>
                <Box className={classes.box_avatars}>
                  <AvatarGroup spacing="medium">
                    <Avatar
                      alt="Remy Sharp"
                      className={classes.avatar}
                      src="/images/avatars/avatar_1.png"
                    />
                    <Avatar
                      alt="Remy Sharp"
                      className={classes.avatar}
                      src="/images/avatars/avatar_2.png"
                    />
                    <Avatar
                      alt="Remy Sharp"
                      className={classes.avatar}
                      src="/images/avatars/avatar_3.png"
                    />
                  </AvatarGroup>
                </Box>
              </Grid>
              <Grid item>
                <MoreVertIcon className={classes.icon} />
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.box_content}>
            <Typography className={classes.typo_content} variant="button">
              Pink is obviously a better color. Everyone’s born confident, and
              everything’s taken away from you.
            </Typography>
            <Divider className={classes.divider} />
          </Box>
          <Box className={classes.box_bottom}>
            <Box className={classes.box_bottom_text}>
              <Typography className={classes.typo_bottom_up} variant="button">
                3
              </Typography>
              <Typography className={classes.typo_bottom_down} variant="button">
                Participants
              </Typography>
            </Box>
            <Box className={classes.box_bottom_text}>
              <Typography className={classes.typo_bottom_up} variant="button">
                22.11.21
              </Typography>
              <Typography className={classes.typo_bottom_down} variant="button">
                Due date
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    );
  }
}

ConfigCompleted.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(ConfigCompleted, useStyles);
