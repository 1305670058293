import React from 'react';
import PropTypes from 'prop-types';
import { getSliderStyle, TinyText } from './utils_setting';
import { Box, Slider } from '@mui/material';

class SliderSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { position, title } = this.props;
    return (
      <>
        <TinyText>{title}</TinyText>
        <Slider
          aria-label="time-indicator"
          max={100}
          min={0}
          onChange={(_, position) => this.props.setState({ position })}
          size="small"
          step={1}
          sx={t => getSliderStyle(t)}
          value={position}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: -2,
          }}>
          <TinyText>{`${position} %`}</TinyText>
          <TinyText>{`${100 - position} %`}</TinyText>
        </Box>
      </>
    );
  }
}

SliderSelection.propTypes = {
  classes: PropTypes.object,
};

export default SliderSelection;
