import { short_patients } from '../../PatientClinics/components';

export const comorbidities = [
  { field: 'group', headerName: 'Group', width: 75, edit: true, align: 'left' },
  {
    field: 'subgroup_organ',
    headerName: 'Sub-Group Organ',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 75,
    edit: true,
    align: 'left',
    multiline: true,
  },
  {
    field: 'tot_files',
    headerName: 'Total files',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_files'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_series',
    headerName: 'Total series',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_series'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_edfs',
    headerName: 'Total edfs',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_edfs'] = parseInt(value);
      return row;
    },
  },
];
export const medication = [
  {
    field: 'date',
    headerName: 'Date',
    width: 157,
    align: 'left',
    edit: true,
    type: 'date',
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'active_ingredient',
    headerName: 'Active Ingredient',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'active_ingredient_unknown',
    headerName: 'Unknown',
    width: 75,
    edit: true,
    type: 'boolean',
    align: 'left',
  },
  {
    field: 'tot_files',
    headerName: 'Total files',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_files'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_series',
    headerName: 'Total series',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_series'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_edfs',
    headerName: 'Total edfs',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_edfs'] = parseInt(value);
      return row;
    },
  },
];
export const edss = [
  {
    field: 'sequence_number',
    headerName: 'Sequence Number',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'date',
    headerName: 'Date',
    width: 157,
    align: 'left',
    edit: true,
    type: 'date',
  },
  {
    field: 'score',
    headerName: 'Score',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'fs1_visual',
    headerName: 'Visual',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'fs2_brainstem',
    headerName: 'Brainstem',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'fs3_pyramidal',
    headerName: 'Pyramidal',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'fs4_cerebellar',
    headerName: 'Cerebellar',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'fs5_sensory',
    headerName: 'Sensory',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'fs6_bowel_bladder',
    headerName: 'Bowel Bladder',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'fs7_cerebral',
    headerName: 'Cerebral',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'tot_files',
    headerName: 'Total files',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_files'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_series',
    headerName: 'Total series',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_series'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_edfs',
    headerName: 'Total edfs',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_edfs'] = parseInt(value);
      return row;
    },
  },
];
export const results = [
  {
    field: 'caseId',
    headerName: 'caseId',
    width: 75,
    align: 'left',
  },
  {
    field: 'methodId',
    headerName: 'method Id',
    width: 75,
    align: 'left',
  },
  {
    field: 'method',
    headerName: 'Methodology',
    width: 75,
    align: 'left',
  },
  {
    field: 'method_abrivation',
    headerName: 'Method Abrivation',
    width: 75,
    align: 'left',
  },
  {
    field: 'test_material',
    headerName: 'Test Material',
    width: 75,
    align: 'left',
  },
  {
    field: 'result_unit',
    headerName: 'Result Unit',
    width: 75,
    align: 'left',
  },
  {
    field: 'result_value',
    headerName: 'Result Value',
    width: 75,
    align: 'left',
  },
  {
    field: 'order_date',
    headerName: 'Order Date',
    width: 75,
    type: 'date',
    align: 'left',
  },
  {
    field: 'result_date',
    headerName: 'Result Date',
    width: 150,
    type: 'date',
    align: 'left',
  },
  {
    field: 'test_type',
    headerName: 'Test Type',
    width: 75,
    align: 'left',
  },
  {
    field: 'orderId',
    headerName: 'Order Id',
    width: 75,
    align: 'left',
  },
];
export const immunotherapy = [
  {
    field: 'sequence_number',
    headerName: 'Sequence Number',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'start_date',
    headerName: 'Start Date',
    width: 157,
    align: 'left',
    edit: true,
    type: 'date',
  },
  {
    field: 'stop_date',
    headerName: 'Stop Date',
    width: 157,
    align: 'left',
    edit: true,
    type: 'date',
  },
  {
    field: 'stop_reason',
    headerName: 'Stop Reason',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'stop_reason_infection_susceptibility_to_infection',
    headerName: 'SR Infection',
    width: 75,
    edit: true,
    type: 'boolean',
    align: 'left',
  },
  {
    field: 'stop_reason_insufficient_efficacy',
    headerName: 'SR Efficacy',
    width: 75,
    edit: true,
    type: 'boolean',
    align: 'left',
  },
  {
    field: 'stop_reason_intolerance_side_effects',
    headerName: 'SR Side Effects',
    width: 75,
    edit: true,
    type: 'boolean',
    align: 'left',
  },
  {
    field: 'stop_reason_patient_request_external_reasons',
    headerName: 'SR External',
    width: 75,
    edit: true,
    type: 'boolean',
    align: 'left',
  },
  {
    field: 'stop_reason_safety',
    headerName: 'SR Safety',
    width: 75,
    edit: true,
    type: 'boolean',
    align: 'left',
  },
  {
    field: 'stop_reason_therapy_change',
    headerName: 'SR Therapy Change',
    width: 75,
    edit: true,
    type: 'boolean',
    align: 'left',
  },
  {
    field: 'tot_files',
    headerName: 'Total files',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_files'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_series',
    headerName: 'Total series',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_series'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_edfs',
    headerName: 'Total edfs',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_edfs'] = parseInt(value);
      return row;
    },
  },
];
export const msfc = [
  {
    field: 'sequence_number',
    headerName: 'Sequence Number',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'date',
    headerName: 'Date',
    width: 157,
    align: 'left',
    edit: true,
    type: 'date',
  },
  {
    field: 'walk_test_aid',
    headerName: 'Walk AID',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'walk_test_time_1',
    headerName: 'Walk T1',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'walk_test_time_2',
    headerName: 'Walk T2',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'peg_test_handedness',
    headerName: 'PEG Handedness',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'peg_test_right_time_1',
    headerName: 'PEG Right T1',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'peg_test_right_time_2',
    headerName: 'PEG Right T2',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'peg_test_left_time_1',
    headerName: 'PEG Left T1',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'peg_test_left_time_2',
    headerName: 'PEG Left T2',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'tot_files',
    headerName: 'Total files',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_files'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_series',
    headerName: 'Total series',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_series'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_edfs',
    headerName: 'Total edfs',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_edfs'] = parseInt(value);
      return row;
    },
  },
];

export const getColumnList = table => {
  let columns = null;
  if (table == 'comorbidities') {
    columns = comorbidities.map(x => {
      return { key: x.field, value: x.headerName, parent: 'Comorbidity' };
    });
  } else if (table === 'medication') {
    columns = medication.map(x => {
      return {
        key: x.field,
        value: x.headerName,
        parent: 'Concomitant Medication',
      };
    });
  } else if (table === 'edss') {
    columns = edss.map(x => {
      return { key: x.field, value: x.headerName, parent: 'EDSS' };
    });
  } else if (table === 'immunotherapy') {
    columns = immunotherapy.map(x => {
      return { key: x.field, value: x.headerName, parent: 'Immunotherapy' };
    });
  } else if (table === 'msfc') {
    columns = msfc.map(x => {
      return { key: x.field, value: x.headerName, parent: 'MSFC' };
    });
  } else if (table === 'patient') {
    columns = short_patients.map(x => {
      return { key: x.field, value: x.headerName, parent: 'Patient' };
    });
  } else if (table === 'results') {
    columns = results.map(x => {
      return { key: x.field, value: x.headerName, parent: 'Result' };
    });
  }  else {
    columns = [];
  }
  if (table !== 'patient') {
    const new_patients = short_patients.map(x => {
      return { key: x.field, value: x.headerName, parent: 'Patient' };
    });
    columns = new_patients.concat(columns);
  }
  return columns;
};
