import React from 'react';
import PropTypes from 'prop-types';
import AaocaResults from './AaocaResults';
import { Grid } from '@mui/material';
import AaocaUpload from './AaocaUpload';
import AaocaSetting from './AaocaSetting';
import AaocaBottom from './AaocaBottom';
import AaocaAccess from './AaocaAccess';

class CardiologyAAOCA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      value: 0,
      files: [],
      disabled_all: true,
      running: false,
      enabled: false,
    };
    this.holder = {
      reset: null,
      setTotal: null,
      startUpload: null,
      startRun: null,
    };
  }
  componentDidUpdate() {
    const { disabled_all, value } = this.state;
    console.log({ disabled_all, value });
    if (disabled_all && value !== 0) {
      this.setState({ value: 0 });
    } else if (!disabled_all && value === 0) {
      this.setState({ value: 50 });
    }
  }
  handleStart = () => {
    this.setState({ running: true, active: 1 });
    this.holder.startUpload();
  };
  handleRun = () => {
    this.setState({ active: 2 });
    this.holder.startRun();
  };
  render() {
    let { value, files, disabled_all, active } = this.state;
    if (!files.length) value = 0;
    return (
      <Grid
        container
        direction="column"
        spacing={2}
        sx={{
          justifyContent: 'center',
          alignItems: 'stretch',
          paddingBottom: 5,
        }}>
        <Grid item xs={12}>
          <AaocaAccess
            active={0 === active}
            disabled_all={disabled_all}
            enabled={this.state.enabled}
            full_run={this.state.running}
            handleAddError={this.props.handleAddError}
            handleAddSuccess={this.props.handleAddSuccess}
            holder={this.holder}
            setState={state => this.setState(state)}
          />
        </Grid>
        <Grid item xs={12}>
          <AaocaUpload
            active={1 === active}
            disabled_all={disabled_all}
            enabled={this.state.enabled}
            full_run={this.state.running}
            handleFinshed={this.handleRun}
            holder={this.holder}
            setState={state => this.setState(state)}
          />
        </Grid>
        <Grid item xs={12}>
          <AaocaSetting
            active={active > 0 ? true : false}
            disabled_all={disabled_all}
            files={files}
            full_run={this.state.running}
            setState={state => this.setState(state)}
            value={value}
          />
        </Grid>
        <Grid item xs={12}>
          <AaocaResults
            active={2 === active}
            disabled_all={disabled_all}
            full_run={this.state.running}
            handleRun={this.handleStart}
            holder={this.holder}
            setState={state => this.setState(state)}
            value={value}
          />
        </Grid>
        <Grid item xs={12}>
          <AaocaBottom
            active={active > 0 ? true : false}
            disabled_all={disabled_all}
          />
        </Grid>
      </Grid>
    );
  }
}

CardiologyAAOCA.propTypes = {
  classes: PropTypes.object,
};

export default CardiologyAAOCA;
