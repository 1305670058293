import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import MemoryIcon from '@mui/icons-material/Memory';
import { SearchAITrainerModels } from 'components';
import TotalEarning from './TotalEarning';
class ConfigTrainAI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ai_trainer: null,
    };
  }
  handleSelect = selected => {
    console.log({ selected });
  };
  render() {
    if (this.props.view !== 'Configuration') return null;
    const { ai_trainer } = this.state;
    return (
      <>
        <Card sx={{ margin: 1, marginTop: 0 }}>
          <CardContent>
            <Grid
              container
              direction="row"
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <Grid item>
                <MemoryIcon />
              </Grid>
              <Grid item>
                <Typography
                  component="h2"
                  gutterBottom
                  sx={{ fontWeight: '500' }}
                  variant="subtitle2">
                  Select AI model that you want to train
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid container pt={2}>
              <Grid item xs={12}>
                <SearchAITrainerModels
                  external_selected={ai_trainer}
                  handleAddSelected={this.handleSelect}
                  handleSetSelected={this.handleSelect}
                  no_icon
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <TotalEarning {...this.props} />
      </>
    );
  }
}

ConfigTrainAI.propTypes = {
  classes: PropTypes.object,
};

export default ConfigTrainAI;
