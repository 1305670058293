import { createText, getConfig } from '../../utils_blocks';
import { getBlockConfig } from '../../utils_svg';
import { Block } from '../base';

class LogicBlock extends Block {
  constructor(
    x,
    y,
    self,
    width = 170,
    height = 90,
    config = getBlockConfig('Logic')
  ) {
    const contentHeight = 40; // 40px for top, 60px for bottom
    const bottomHeight = 40;
    super(
      x,
      y,
      width,
      height,
      config,
      contentHeight,
      bottomHeight,
      200,
      120,
      '#eceff1'
    );
    this.addPort('Input data', false, 20, '#cddc39'); // Add port on the left side
    this.addPort('Accepted', true, 60, '#ff9800'); // Add port on the left side
    this.addPort('Rejected', true, 100, 'red'); // Add port on the left side
  }

  drawContent(ctx) {
    super.drawContent(ctx);
    const { scaleX, scaleY } = this.getScalingFactor();
    const padding = 20 * scaleX;
    const topSeparatorY = this.y + this.topHeight; // Y-coordinate of the top separator
    // const bottomSeparatorY = this.y + this.height - this.bottomHeight * scaleY; // Y-coordinate of the bottom separator

    // Calculate position for "MRI Segmentation" title
    const titleSeparatorY = topSeparatorY + this.topHeight;
    const titleY = titleSeparatorY - 15 * scaleY; // Moved down 5 pixels from previous position

    // Draw  title
    const config = getConfig(this.x + padding, titleY, '#000');
    config.font = `${14 * scaleY}px Arial`;
    createText(ctx, 'Accept 90%', config);
  }

  drawBottom(ctx) {
    const postSeparatorY = super.drawBottom(ctx);
    const { scaleX, scaleY } = this.getScalingFactor();
    // const yTopBottom = super.drawBottom(ctx);
    const padding = 20 * scaleX;
    const config = getConfig(this.x + padding, postSeparatorY + 20, '#000');
    config.font = `${14 * scaleY}px Arial`;
    createText(ctx, 'Reject', config);
  }
}

export default LogicBlock;
