import React from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { logout } from 'redux-store/actions/loginActions';
import { updateUser } from 'redux-store/actions/userActions';
import {
  isAuthenticated,
  getParamsUrl,
  GetData,
  calc_image_size,
  IsInvalid,
} from 'helpers';
import { QueryMe } from 'graphql/Users';
import SnackMessage from 'components/SnackMessage';

const LayoutEmpty = ({ children }) => {
  return <React.Fragment>{children}</React.Fragment>;
};

class RouteWithLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photo: null,
      loading: false,
      setting: null,
      empty: false,
      path: null,
      width: 0,
      height: 0,
      error: '',
      success: '',
      warning: '',
      success_type: 'success',
    };
  }

  componentDidUpdate() {
    const { path, user } = this.props;
    let url = getParamsUrl(this.props);
    if (url) {
      const parts = path.split('/');
      if (parts.length > 1) {
        url = `/${parts[1]}`;
      }
    }
    if (user && user.avatar === 'image_avatar') {
      this.updateUserAvatar();
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  updateUserAvatar = () => {
    const { user } = this.props;
    if (user === undefined || !user || user.id === undefined || user.id < 1) {
      return;
    }
    const { loading } = this.state;
    if (loading || user.avatar !== 'image_avatar') return;
    const { photo } = user;
    this.setState({ loading: true });
    (async () => {
      if (photo === undefined || !photo || photo === '') {
        QueryMe()
          .then(res => {
            const data = GetData(res);
            const { ok, user: new_user, errors } = data.me;
            if (ok) {
              let city = '';
              let country = '';
              if (new_user.address && new_user.address.city) {
                city = new_user.address.city;
              }
              if (new_user.address && new_user.address.country) {
                country = new_user.address.country;
              }
              this.props.updateUser({ ...user, ...new_user, city, country });
              this.setState({ photo: new_user.photo, loading: false });
            } else {
              throw errors;
            }
          })
          .catch(errors => {
            console.log({ errors });
            this.setState({ loading: false });
          });
      }
    })();
  };
  handleAddError = error => {
    if (
      error.indexOf(
        'Client never received a response, or request never left'
      ) !== -1
    ) {
      error = 'The server is down';
    }
    this.setState({ error });
  };
  handleAddSuccess = (success, success_type = 'success') => {
    this.setState({ success, success_type });
  };
  handleAddWarning = warning => {
    this.setState({ warning });
  };
  handleCloseError = () => {
    this.setState({ error: '' });
  };
  handleCloseSuccess = () => {
    this.setState({ success: '' });
  };
  render() {
    const {
      component: Component,
      user,
      public_access,
      logout,
      isDesktop,
      isMobile,
      ReactGA4,
      ...rest
    } = this.props;
    let { layout: Layout } = this.props;
    if (IsInvalid(Layout)) {
      Layout = LayoutEmpty;
    }
    const { height, width, success, error, warning } = this.state;
    let ready = false;
    if (!public_access) {
      ready = isAuthenticated(user, logout);
    } else {
      ready = public_access;
    }
    return ready ? (
      <Route
        {...rest}
        render={matchProps => (
          <Layout handleAddError={this.handleAddError} ReactGA4={ReactGA4}>
            <Component
              {...matchProps}
              handleAddError={this.handleAddError}
              handleAddSuccess={this.handleAddSuccess}
              handleAddWarning={this.handleAddWarning}
              height={height}
              isAuthenticated={this.props.idUser > 0 ? true : false}
              isDesktop={isDesktop}
              isMobile={isMobile}
              ReactGA4={ReactGA4}
              user={user}
              width={width}
            />
            <SnackMessage
              handleClose={this.handleCloseError}
              message_text={error !== '' ? error : 'Unknown error'}
              open={error && error !== '' ? true : false}
              type="error"
            />
            <SnackMessage
              handleClose={this.handleCloseSuccess}
              location={{
                vertical: 'top',
                horizontal: 'left',
              }}
              message_text={success !== '' ? success : 'Unknown success'}
              open={success && success !== '' ? true : false}
              type={this.state.success_type}
            />
            <SnackMessage
              handleClose={() => this.setState({ warning: '' })}
              location={{
                vertical: 'top',
                horizontal: 'left',
              }}
              message_text={warning !== '' ? warning : 'Unknown warning'}
              open={warning && warning !== '' ? true : false}
              type="warning"
            />
          </Layout>
        )}
      />
    ) : (
      <Redirect to="/" />
    );
  }
}

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  history: PropTypes.object,
  layout: PropTypes.any,
  path: PropTypes.string,
  public_access: PropTypes.bool,
  user: PropTypes.object,
};
RouteWithLayout.defaultProp = {
  layout: null,
};
const mapStateToProps = state => {
  let size_avatar = 0;
  const { user } = state.info_user;
  let idUser = -1;
  if (user) {
    size_avatar = calc_image_size(user.avatar);
    idUser = user.id;
  }
  return {
    user,
    isDesktop: state.layout.isDesktop,
    isMobile: state.layout.isMobile,
    size_avatar,
    idUser,
  };
};
export default connect(mapStateToProps, { logout, updateUser })(
  withRouter(RouteWithLayout)
);
