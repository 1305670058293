import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import Chart from 'react-apexcharts';
import CheckIcon from '@mui/icons-material/Check';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ProgressUndefined from './ProgressUndefined';
import { chart } from './utils';
class AaocaResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option_left: {
        ...chart,
        series: [0],
        labels: ['Presence'],
      },
      option_center: {
        ...chart,
        series: [0],
        labels: ['Origins'],
      },
      option_right: {
        ...chart,
        series: [0],
        labels: ['Risk'],
        pathRadius: '10', // P
        grid: {
          padding: {
            top: -10,
            left: -40,
            right: -40,
            bottom: -30,
          },
        },
      },
      running: false,
      finished: false,
      diagnosis: '',
    };
    const { holder } = props;
    if (holder) {
      holder.reset = () => {
        console.log('run - reset');
        const { option_left, option_center, option_right } = this.state;
        this.setState({
          finished: false,
          option_left: { ...option_left, series: [0] },
          option_center: { ...option_center, series: [0] },
          option_right: { ...option_right, series: [0] },
          diagnosis: '',
        });
      };
      holder.startRun = () => this.handleRun();
    }
  }
  componentDidUpdate() {
    const { disabled_all } = this.props;
    const { diagnosis } = this.state;
    console.log({ disabled_all, diagnosis, f: this.props.holder.reset });
    if (disabled_all && diagnosis !== '' && this.props.holder.reset) {
      this.props.holder.reset();
    }
  }
  generateCSV = () => {
    const { option_left, option_center, option_right, diagnosis } = this.state;
    const data = {
      presence: option_left.series[0],
      origin: option_center.series[0],
      risk: option_right.series[0],
    };

    // Step 1: Create CSV content
    let headers = Object.keys(data).join(','); // "a,b,series"
    headers = `${headers},diagnosis`;
    const values = [
      data.presence,
      data.origin,
      data.risk,
      diagnosis, // Join series array with a delimiter (e.g., '|')
    ].join(','); // "1,2,a|b"

    const csvContent = `${headers}\n${values}`; // Combine headers and values

    // Step 2: Create a Blob
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Step 3: Create a link and trigger download
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = 'aaoca_results.csv'; // Filename for the download
    link.style.display = 'none';

    document.body.appendChild(link); // Append link to body
    link.click(); // Trigger click
    document.body.removeChild(link); // Remove link after download
  };
  handleRun = () => {
    const { running } = this.state;
    if (running) return;
    this.setState({ running: true });
    if (this.props.holder.setTotal) {
      this.props.holder.setTotal(1);
    }
    (async () => {
      setTimeout(() => {
        console.log('This message appears after 3 seconds!');
        const { option_left, option_center, option_right } = this.state;
        this.setState({
          running: false,
          finished: true,
          option_left: { ...option_left, series: [30] },
          option_center: { ...option_center, series: [70] },
          option_right: { ...option_right, series: [50] },
          diagnosis: 'No coronary anomalies (AAOCA) have been detected.',
        });
        this.props.setState({ running: false, active: 0 });
      }, 3000);
    })();
  };
  render() {
    const { diagnosis, finished, running } = this.state;
    const { value, full_run, active } = this.props;
    const box = {
      paddingLeft: '40px',
      paddingRight: '40px',
      gap: '24px',
      width: '100%',
      display: 'flex',
      '-moz-box-pack': 'center',
      justifyContent: 'center',
      alignItems: 'center',
    };
    const divider = {
      margin: '0px',
      flexShrink: '0',
      borderWidth: '0px thin 0px 0px',
      borderColor: 'rgba(145 158 171 / 0.5)',
      height: 'auto',
      alignSelf: 'stretch',
      borderStyle: 'dashed',
    };
    let ComponentProgress = null;
    if (running) {
      ComponentProgress = <ProgressUndefined />;
    }
    return (
      <Paper
        disabled={active || full_run}
        sx={{
          backgroundImage: 'none',
          overflow: 'hidden',
          position: 'relative',
          borderRadius: '16px',
          width: '100%',
          border: `${active ? '2px' : '1px'} solid ${
            active ? 'green' : full_run ? '#f5f5f5' : ''
          }`,
        }}>
        <Grid
          container
          direction="row"
          item
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 1,
          }}
          xs={12}>
          <Grid item xs={2}>
            <Button
              disabled={running || !value || full_run}
              onClick={this.props.handleRun}
              startIcon={<PlayArrowIcon />}
              sx={{
                textTransform: 'none',
                backgroundColor: '#43a047',
                '&:hover': {
                  backgroundColor: '#66bb6a', // Hover background color
                },
              }}
              variant="contained">
              Run
            </Button>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                width: '100%',
                textAlign: 'center',
                pt: 1,
                fontWeight: 600,
              }}>
              Probabilites of AAOCA
            </Typography>
          </Grid>
          <Grid
            container
            item
            sx={{ justifyContent: 'flex-end', alignItems: 'center' }}
            xs={2}>
            <Button
              disabled={running || !finished || full_run}
              endIcon={<CloudDownloadIcon />}
              onClick={this.generateCSV}
              sx={{
                textTransform: 'none',
                backgroundColor: '#039be5',
                '&:hover': {
                  backgroundColor: '#29b6f6', // Hover background color
                },
              }}
              variant="contained">
              Export
            </Button>
          </Grid>
        </Grid>
        {ComponentProgress}
        <Stack sx={{ flexDirection: 'row' }}>
          <Box sx={box}>
            <Grid container direction="column">
              <Grid item>
                <Chart
                  options={this.state.option_left}
                  series={this.state.option_left.series}
                  type="radialBar"
                />
              </Grid>
              <Grid
                container
                direction="row"
                item
                sx={{
                  position: 'relative',
                  top: -20,
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  paddingLeft: 0,
                  paddingRight: 1,
                }}
                xs={12}>
                <Grid item sx={{ fontWeight: 600 }}>
                  Normal
                </Grid>
                <Grid item sx={{ fontWeight: 600 }}>
                  AAOCA
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Divider orientation="vertical" sx={divider} />
          <Box sx={box}>
            <Grid container direction="column">
              <Grid item>
                <Chart
                  options={this.state.option_center}
                  series={this.state.option_center.series}
                  type="radialBar"
                />
              </Grid>
              <Grid
                container
                direction="row"
                item
                sx={{
                  position: 'relative',
                  top: -20,
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  paddingLeft: 1,
                  paddingRight: 0,
                }}
                xs={12}>
                <Grid item sx={{ fontWeight: 600, maxWidth: 50 }}>
                  Right Sinus
                </Grid>
                <Grid item sx={{ fontWeight: 600, maxWidth: 50 }}>
                  Left Sinus
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Divider orientation="vertical" sx={divider} />
          <Box
            sx={{
              ...box,
              direction: 'column',
              paddingLeft: '20px',
              paddingRight: '20px',
            }}>
            <Grid container direction={'column'}>
              <Grid item>
                <Chart
                  options={this.state.option_right}
                  series={this.state.option_right.series}
                  type="radialBar"
                />
              </Grid>
              <Grid
                container
                direction="row"
                item
                sx={{
                  position: 'relative',
                  top: -20,
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
                xs={12}>
                <Grid item sx={{ fontWeight: 600 }}>
                  Low
                </Grid>
                <Grid item sx={{ fontWeight: 600 }}>
                  High
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Stack>
        <Grid container p={2}>
          <Grid
            container
            direction="row"
            item
            sx={{
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}>
            <Grid item sx={{ width: 30 }}>
              <CheckIcon sx={{ color: 'green' }} />
            </Grid>
            <Grid item sx={{ width: 90, color: 'green', fontWeight: 600 }}>
              Diagnosis:
            </Grid>
            <Grid item xs>
              {diagnosis !== '' ? (
                <Typography sx={{ color: 'blue', fontWeight: 600 }}>
                  {diagnosis}
                </Typography>
              ) : (
                <Typography sx={{ color: 'red' }}>
                  Please run the AAOCA model to get the diagnosis of your input
                  data
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

AaocaResults.propTypes = {
  classes: PropTypes.object,
};

export default AaocaResults;
