import { getBlockConfig } from '../../utils_svg';
import { Block } from '../base';

class DatasetBlock extends Block {
  constructor(
    x,
    y,
    self = null,
    width = 250,
    height = 200,
    config = getBlockConfig('Dataset')
  ) {
    const contentHeight = 120; // 30px for top
    const bottomHeight = 90;
    super(x, y, width, height, config, contentHeight, bottomHeight, 300, 250);
    this.self = self;
    this.images = [
      {
        px: 20,
        src: `${process.env.PUBLIC_URL}/images/mri/T1_sagittal.jpeg`,
      },
      {
        px: 110,
        src: `${process.env.PUBLIC_URL}/images/mri/FLAIR_Axial.jpeg`,
      },
      {
        px: 200,
        src: `${process.env.PUBLIC_URL}/images/mri/T2_axial.jpeg`,
      },
    ].map(b => ({
      px: b.px,
      x: b.px,
      y,
      width: 80,
      height: 80,
      image: null,
      src: b.src,
    }));
    this.addPort('Output data', true);
    this.addPort('Input data', false); // Add port on the right side
  }

  drawContent(ctx) {
    super.drawContent(ctx);
    const { scaleX, scaleY } = this.getScalingFactor();
    const padding = 20;
    const imageStartY = this.y + 50 * scaleY; // Remains at 50 (10px up from original position)
    const imageHeight = (this.contentHeight - 5) / 2; // Calculation remains the same

    // Draw image placeholders
    this.images.forEach(img => {
      img.x = img.px * scaleX;
      img.y = imageStartY;
      img.width = 80 * scaleX;
      img.height = imageHeight;

      ctx.fillStyle = '#e0e0e0';
      ctx.fillRect(this.x + img.x, img.y, img.width, img.height);
      const padding = 3;
      if (!img.image && img.src) {
        // Create and load the image
        const image = new Image();
        image.src = img.src; // Replace with the path to your JPEG

        // Draw the image when it's loaded
        image.onload = () => {
          img.image = image;
          if (this.self.props.reRender) this.self.props.reRender();
          else if (this.self.reRender) this.self.reRender();
          else console.log('reRender not found');
        };
      } else if (img.image) {
        ctx.drawImage(
          img.image,
          this.x + img.x + padding,
          img.y + padding,
          img.width - 2 * padding,
          img.height - 2 * padding
        );
      }
    });

    // Draw "Cardiac Study" text below images
    ctx.font = '14px Arial';
    ctx.fillStyle = '#808080'; // Gray color
    const textY = imageStartY + imageHeight + 20; // Moved down 5 more pixels (from 15 to 20)
    ctx.fillText('Brain Study', this.x + padding, textY);
  }

  drawBottom(ctx) {
    const drawBottom = super.drawBottom(ctx);
    const { scaleX, scaleY } = this.getScalingFactor();
    // const padding = 20;
    const stats = [
      { label: 'Items', value: '2,313', x: 20 * scaleX },
      { label: 'Completed', value: '21.1k', x: 110 * scaleX },
      { label: 'In progress', value: '223', x: 200 * scaleX },
    ];

    stats.forEach(stat => {
      const subBlockWidth = 80 * scaleX;
      const subBlockHeight = (this.bottomHeight - 30) * scaleY; // Reduced by 20px (from -10 to -30)
      const subBlockX = this.x + stat.x;
      const subBlockY = drawBottom + 15 * scaleY; // Moved down by 10px to center vertically
      const cornerRadius = 5;

      // Draw sub-block background with rounded corners
      ctx.fillStyle = '#f5f5f5';
      ctx.beginPath();
      ctx.moveTo(subBlockX + cornerRadius, subBlockY);
      ctx.lineTo(subBlockX + subBlockWidth - cornerRadius, subBlockY);
      ctx.arcTo(
        subBlockX + subBlockWidth,
        subBlockY,
        subBlockX + subBlockWidth,
        subBlockY + cornerRadius,
        cornerRadius
      );
      ctx.lineTo(
        subBlockX + subBlockWidth,
        subBlockY + subBlockHeight - cornerRadius
      );
      ctx.arcTo(
        subBlockX + subBlockWidth,
        subBlockY + subBlockHeight,
        subBlockX + subBlockWidth - cornerRadius,
        subBlockY + subBlockHeight,
        cornerRadius
      );
      ctx.lineTo(subBlockX + cornerRadius, subBlockY + subBlockHeight);
      ctx.arcTo(
        subBlockX,
        subBlockY + subBlockHeight,
        subBlockX,
        subBlockY + subBlockHeight - cornerRadius,
        cornerRadius
      );
      ctx.lineTo(subBlockX, subBlockY + cornerRadius);
      ctx.arcTo(
        subBlockX,
        subBlockY,
        subBlockX + cornerRadius,
        subBlockY,
        cornerRadius
      );
      ctx.closePath();
      ctx.fill();

      // Calculate vertical center of sub-block
      const subBlockCenterY = subBlockY + subBlockHeight / 2;

      // Draw the number (value) in bold and 1.2em size
      ctx.font = `bold ${17.8 * scaleY}px Arial`; // Increased from 16.8px to 17.8px
      ctx.fillStyle = '#000';
      const valueWidth = ctx.measureText(stat.value).width;
      const valueHeight = 17.8; // Adjusted to match new font size
      ctx.fillText(
        stat.value,
        subBlockX + (subBlockWidth - valueWidth) / 2,
        subBlockCenterY - valueHeight / 2 + 4 // Moved down 4px total
      );

      // Draw the label
      ctx.font = `${12 * scaleY}px Arial`;
      const labelWidth = ctx.measureText(stat.label).width;
      // const labelHeight = 12; // Approximate height of the text
      ctx.fillText(
        stat.label,
        subBlockX + (subBlockWidth - labelWidth) / 2,
        subBlockCenterY + valueHeight / 2 + 5 // 5px gap between value and label
      );
    });
  }
}

export default DatasetBlock;
