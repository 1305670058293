import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, styled } from '@mui/material';
import {
  Autocomplete,
  Slider,
  TableCell as MuiTableCell,
  TableRow,
  TextField,
  IconButton as MuiIconButton,
} from '@mui/material';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  ChipButtom,
  getIconStatus,
  picker_style,
  StackProgress,
  TypoProgress,
} from '../styles';
const TableCell = styled(MuiTableCell)(({ theme }) => ({
  padding: theme.spacing(1, 1),
  backgroundColor: '#eceff1',
}));
const IconButton = styled(MuiIconButton)(() => ({
  borderRadius: '8px',
  height: 'var(--IconButton-size)',
  width: 'var(--IconButton-size)',
  color: 'var(--mui-palette-secondary-main)',
  '--IconButton-size': '40px',
  '--mui-palette-secondary-main': '#32383e',
}));
class TableCustomRowEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      row: props.row ? { ...props.row } : null,
      created: null,
    };
  }
  componentDidMount() {
    const { row } = this.state;
    if (row) return;
    this.setState({ row: { ...this.props.row } });
  }
  render() {
    const { row } = this.state;
    const { user, phone, progress, status, created } = row;
    return (
      <TableRow>
        <TableCell />
        <TableCell>
          <TextField
            onChange={event => {
              this.setState({
                row: {
                  ...row,
                  user: { ...row.user, name: event.target.value },
                },
              });
            }}
            value={user.name}
          />
        </TableCell>
        <TableCell>
          <StackProgress>
            <Slider
              aria-label="time-indicator"
              max={100}
              min={0}
              onChange={(_, progress) =>
                this.setState({ row: { ...row, progress } })
              }
              size="small"
              step={1}
              sx={{ width: 180 }}
              value={progress}
            />
            <TypoProgress component="p" variant="body2">
              {`${progress}%`}
            </TypoProgress>
          </StackProgress>
        </TableCell>
        <TableCell>
          <TextField
            onChange={() => {
              this.setState({
                row: {
                  ...row,
                  phone,
                },
              });
            }}
            sx={{
              minWidth: 150,
            }}
            value={phone}
          />
        </TableCell>
        <TableCell>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {/* <DemoContainer components={['DatePicker']}> */}
            <DatePicker
              // label="Controlled picker"
              format="DD/MM/YYYY"
              onChange={created => {
                this.setState({
                  row: {
                    ...row,
                    created: created.format('MMM D, YYYY h:mm A'),
                  },
                });
              }}
              sx={picker_style}
              value={dayjs(created, 'MMM D, YYYY h:mm A')}
            />
            {/* </DemoContainer> */}
          </LocalizationProvider>
        </TableCell>
        <TableCell>
          <Autocomplete
            disablePortal
            onChange={(event, status) =>
              this.setState({ row: { ...row, status } })
            }
            options={['Active', 'Pending', 'Blocked']}
            renderInput={params => (
              <TextField {...params} label="" sx={{ width: 150 }} />
            )}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              return (
                <Box
                  component="li"
                  key={key}
                  {...optionProps}
                  sx={{
                    '& > img': { mr: 2, flexShrink: 0 },
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}>
                  <ChipButtom
                    color="secondary"
                    icon={getIconStatus(option)}
                    label={option}
                    size="small"
                    sx={{ width: 200 }}
                    variant="outlined"
                  />
                </Box>
              );
            }}
            sx={{ width: '100%' }}
            value={status}
          />
        </TableCell>
        <TableCell>
          <Grid
            container
            direction="row"
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: 100,
            }}>
            <Grid item>
              <IconButton
                color="secondary"
                onClick={() => this.props.handleClose(this.props.row)}
                size="medium">
                <CloseIcon sx={{ fill: 'red' }} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                color="secondary"
                onClick={() => this.props.handleSave(this.props.row, row)}
                size="medium">
                <SaveIcon sx={{ fill: 'blue' }} />
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
}

TableCustomRowEdit.propTypes = {
  classes: PropTypes.object,
};

export default TableCustomRowEdit;
