import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButton, CircularProgress } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import BackupIcon from '@mui/icons-material/Backup';
import { green } from '@mui/material/colors';
import { v1 as uuid } from 'uuid';
import BadgeIcon from '../../Components/BadgeIcon';
const useStyles = () => ({
  fabProgress: {
    color: green[800],
    position: 'absolute',
    top: 7,
    left: 8,
    zIndex: 1,
  },
  wrapper: {
    padding: 0,
    margin: 0,
    // position: 'relative',
  },
});
class ToggleButtonUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {
      classes,
      Icon,
      uploading,
      accept,
      // height,
      disabled,
      count,
    } = this.props;
    if (!Icon) {
      Icon = BackupIcon;
    }
    // let style = null;
    // if (height) {
    //   style = { height, maxHeight: height };
    // }
    const id_button = uuid();
    return (
      <ToggleButton
        aria-label="centered"
        disabled={disabled}
        sx={{ paddingTop: count ? 0 : '7px' }}
        value={this.props.name}>
        <input
          accept={accept}
          hidden
          id={id_button}
          onChange={this.props.handleSelectFile}
          style={{ display: 'none', border: 0 }}
          type="file"
        />
        <label
          htmlFor={id_button}
          style={{ margin: 0, borderRadius: 0, padding: 0, border: 0 }}>
          <BadgeIcon color={this.props.color} count={count} Icon={Icon} />
          {uploading && (
            <CircularProgress className={classes.fabProgress} size={30} />
          )}
        </label>
      </ToggleButton>
    );
  }
}

ToggleButtonUpload.propTypes = {
  classes: PropTypes.object,
};
ToggleButtonUpload.defaultProps = {
  count: 0,
  Icon: null,
  name: 'upload',
  uploading: false,
  disabled: false,
  accept: '.csv',
  handleSelectFile: () => '',
  color: undefined,
};
export default withStyles(ToggleButtonUpload, useStyles);
