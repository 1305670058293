import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@mui/material';
import { SliderSelection } from '../components';
import Statistics from './Statistics';

class ConfigLogic extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: 'test', label: 'another', position: 90 };
  }
  handleChange = event => {
    console.log({ t: event.target.value });
    this.setState({ value: event.target.value });
  };
  render() {
    if (this.props.view !== 'Configuration') return null;
    const { position } = this.state;
    return (
      <>
        <Card sx={{ height: '100%', margin: 1, marginTop: 0 }}>
          <CardContent>
            <SliderSelection
              position={position}
              setState={state => this.setState(state)}
              title="Accept/Reject distribution:"
            />
          </CardContent>
        </Card>
        <Statistics {...this.props} />
      </>
    );
  }
}

ConfigLogic.propTypes = {
  classes: PropTypes.object,
};

export default ConfigLogic;
