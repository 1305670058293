import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Grid } from '@mui/material';
import { ButtonDownloadType, ButtonFileUpload } from 'components';
import SaveIcon from '@mui/icons-material/Save';
import { GetInputConsent } from './utils';
import {
  MutationAddPatientConsents,
  MutationUploadPatientConsentsCSV,
} from 'graphql/Anonymization';
import { GetData, ServerErrorsString } from 'helpers';
import LoadingButton from '@mui/lab/LoadingButton';
class EditPatientConsentToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
    };
  }
  handleSaveChanges = () => {
    const { saving } = this.state;
    const { inputs, errors } = GetInputConsent(this.props);
    if (errors.length) {
      this.props.handleAddError(ServerErrorsString(errors));
      return;
    }
    if (saving || !inputs.length) return;
    this.setState({ saving: true });
    (async () => {
      MutationAddPatientConsents(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, patient_consents } = data.addPatientConsents;
          if (ok) {
            this.setState({ saving: false });
            this.props.handleAddPatientConsents(patient_consents, true);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleUploadSelectFile = event => {
    const { files } = event.target;
    if (!files.length) {
      this.props.handleAddError('Select file first');
      return;
    }
    this.setState({ uploading: true });
    (async () => {
      MutationUploadPatientConsentsCSV(files[0], this.handleProgress)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, patient_consents } = data.uploadPatientConsents;
          if (ok) {
            this.setState({
              uploading: false,
            });
            this.props.handleAddPatientConsents(patient_consents);
            if (errors && errors.length) {
              throw errors;
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { rows_edited } = this.props;
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          pb={1}>
          <Grid item xs={3}>
            <ButtonGroup exclusive="false" size="small" value="save">
              <LoadingButton
                disabled={!rows_edited.length}
                loading={this.state.saving}
                onClick={this.handleSaveChanges}
                variant="outlined">
                <SaveIcon
                  style={{ color: rows_edited.length ? 'green' : 'gray' }}
                />
              </LoadingButton>
              <ButtonDownloadType key="encryptions" type="patient_consents" />
              <ButtonFileUpload
                handleSelectFile={this.handleUploadSelectFile}
                uploading={this.state.uploading}
              />
            </ButtonGroup>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

EditPatientConsentToolbar.propTypes = {
  classes: PropTypes.object,
};

export default EditPatientConsentToolbar;
