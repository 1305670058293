import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Grid } from '@mui/material';
import {
  ButtonDownloadType,
  ButtonGroupLoading,
  ButtonReloadDelete,
  DialogDeleteWarning,
  IconButtonLoad,
  SearchText,
  SnackMessage,
} from 'components';
import SaveIcon from '@mui/icons-material/Save';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  MutationDeleteSequenceErrors,
  MutationSyncSequenceErrors,
} from 'graphql/Logs';
import { ServerErrorsString, GetData, IsInvalid } from 'helpers';
import { withStyles } from 'tss-react/mui';
import { useStylesToolBars } from 'common';
import { withRouter } from 'react-router-dom';
import ExploreIcon from '@mui/icons-material/Explore';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LinkIcon from '@mui/icons-material/Link';
import {
  MutationAddSequencePrediction,
  MutationRunSequencePrediction,
} from 'graphql/Series/sequences';

class ToolBarSequenceErrors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_delete: false,
      deleting: false,
      uploading: false,
      loading_run_sequence: false,
      loading_accept_sequence: false,
      loading_sync_sequence: false,
      warning_text: '',
    };
  }
  handleDeleteSelected = () => {
    const { deleting } = this.state;
    const { sequence_errors, selected } = this.props.state;
    if (deleting) return;
    this.setState({ deleting: true });
    (async () => {
      const ids = selected.map(x => x.id);
      MutationDeleteSequenceErrors(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteSequenceErrors;
          if (ok) {
            this.setState({ deleting: false, open_delete: false });
            this.props.setState({
              selected: [],
              sequence_errors: sequence_errors.filter(
                x => ids.indexOf(x.id) === -1
              ),
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSaveRules = () => {
    console.log('handleSaveRules');
  };
  handleSelectFile = () => {
    console.log('handleSelectFile');
  };
  handleOpenSeries = () => {
    const { selected, row_highlight } = this.props.state;
    const { idSeries } = row_highlight;
    if (idSeries && !selected.length) {
      this.props.history.push(`/image_analysis/series/${idSeries}/view`);
    } else if (selected.length) {
      // const ids_patients = selected.map(x => x.idPatient).filter(x => x);
      // this.props.setPatientsIds(ids_patients);
      // this.props.history.push('/image_analysis/patients/group');
    }
  };
  handleOpenExplorerSeries = () => {
    const { row_highlight } = this.props.state;
    const { series } = row_highlight;
    console.log({ row_highlight });
    if (IsInvalid(series)) return;
    const { locations } = series;
    if (locations && locations.length) {
      this.props.history.push(`/explorer/folder/${locations[0].idFolder}`);
    }
  };
  handleRunSequenceClassification = () => {
    let { selected, sequence_errors, total } = this.props.state;
    const { loading_run_sequence } = this.state;
    if (loading_run_sequence) return;
    this.setState({ loading_run_sequence: true });
    (async () => {
      const inputs = selected.map(x => {
        return {
          id: x.id,
          idSeries: x.idSeries,
          count: x.count,
          PatientID: x.PatientID,
          AccessionNumber: x.AccessionNumber,
          StudyInstanceUID: x.StudyInstanceUID,
          AcquisitionDate: x.AcquisitionDate,
          ContrastBolusAgent: x.ContrastBolusAgent,
          EchoTime: x.EchoTime,
          EchoTrainLength: x.EchoTrainLength,
          FlipAngle: x.FlipAngle,
          ImageType: x.ImageType,
          InversionTime: x.InversionTime,
          Modality: x.Modality,
          MRAcquisitionType: x.MRAcquisitionType,
          PhotometricInterpretation: x.PhotometricInterpretation,
          PixelBandwidth: x.PixelBandwidth,
          PixelSpacing: x.PixelSpacing,
          ProtocolName: x.ProtocolName,
          RepetitionTime: x.RepetitionTime,
          ScanOptions: x.ScanOptions,
          ScanningSequence: x.ScanningSequence,
          SequenceName: x.SequenceName,
          SequenceVariant: x.SequenceVariant,
          SeriesDescription: x.SeriesDescription,
          SliceThickness: x.SliceThickness,
          SeriesInstanceUID: x.SeriesInstanceUID,
        };
      });
      MutationRunSequencePrediction(inputs)
        .then(res => {
          const data = GetData(res);
          const {
            ok,
            sequence_errors: fails,
            total: corrects,
            errors,
          } = data.runSequencePrediction;
          if (ok) {
            this.setState({ loading_run_sequence: false });
            const ids_errors = fails.map(x => x.id);
            selected = selected.filter(x => ids_errors.indexOf(x.id) !== -1);
            this.props.setState({
              selected,
              sequence_errors: sequence_errors.filter(
                x => ids_errors.indexOf(x.id) === -1
              ),
              total: total - corrects,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading_run_sequence: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAcceptSequenceClassification = () => {
    let { selected, sequence_errors, total } = this.props.state;
    const { loading_accept_sequence } = this.state;
    if (loading_accept_sequence) return;
    this.setState({ loading_accept_sequence: true });
    (async () => {
      const inputs = selected.map(x => {
        return {
          id: x.id,
          idSeries: x.idSeries,
          idSeqPrediction: x.idSeqPrediction,
        };
      });
      MutationAddSequencePrediction(inputs)
        .then(res => {
          const data = GetData(res);
          const {
            ok,
            sequence_errors: fails,
            total: corrects,
            errors,
          } = data.addSequencePrediction;
          if (ok) {
            this.setState({ loading_accept_sequence: false });
            const ids_errors = fails.map(x => x.id);
            selected = selected.filter(x => ids_errors.indexOf(x.id) !== -1);
            this.props.setState({
              selected,
              sequence_errors: sequence_errors.filter(
                x => ids_errors.indexOf(x.id) === -1
              ),
              total: total - corrects,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading_accept_sequence: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSyncSequenceClassification = () => {
    const { page, limit } = this.props.state;
    const { loading_sync_sequence } = this.state;
    if (loading_sync_sequence) return;
    this.setState({ loading_sync_sequence: true });
    (async () => {
      MutationSyncSequenceErrors(page, limit)
        .then(res => {
          const data = GetData(res);
          const {
            ok,
            sequence_errors,
            warnings,
            total,
            errors,
          } = data.syncSequenceErrors;
          if (ok) {
            console.log({ warnings });
            this.setState({
              warning_text: warnings,
              loading_sync_sequence: false,
            });
            this.props.setState({
              selected: [],
              sequence_errors,
              total,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading_sync_sequence: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  getQuery = () => {
    if (this.props.holder.getSearchText) {
      return { text: this.props.holder.getSearchText() };
    }
    return null;
  };
  render() {
    const { classes } = this.props;
    const {
      loading_accept_sequence,
      loading_run_sequence,
      loading_sync_sequence,
      warning_text,
    } = this.state;
    const { rows_edited, selected, row_highlight, loading } = this.props.state;
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          justifyContent="space-between"
          pb={1}
          pt={1}
          xs={12}>
          <Grid item md={4} sm={5.5} xs={12}>
            <ButtonGroup
              className={classes.root}
              exclusive="false"
              size="small"
              value="center">
              <Button
                disabled={!rows_edited.length || this.state.saving}
                onClick={this.handleSaveRules}>
                <SaveIcon
                  style={{ color: rows_edited.length ? 'blue' : 'gray' }}
                />
              </Button>
              <ButtonDownloadType
                inputs={this.getQuery()}
                key="sequences"
                type="sequence_errors"
              />
              <Button disabled={!row_highlight} onClick={this.handleOpenSeries}>
                <LaunchIcon
                  style={{ color: row_highlight ? 'green' : 'gray' }}
                />
              </Button>
              <Button
                disabled={!row_highlight}
                onClick={this.handleOpenExplorerSeries}>
                <ExploreIcon
                  style={{ color: row_highlight ? 'blue' : 'gray' }}
                />
              </Button>
              <ButtonGroupLoading
                disabled={!selected.length}
                icon={
                  <PlayArrowIcon
                    style={{ color: selected.length ? 'blue' : 'gray' }}
                  />
                }
                loading={loading_run_sequence}
                onClick={this.handleRunSequenceClassification}
                tooltip="Re-run sequence classification"
              />
              <ButtonGroupLoading
                disabled={!selected.length}
                icon={
                  <CheckCircleOutlineIcon
                    style={{ color: selected.length ? 'green' : 'gray' }}
                  />
                }
                loading={loading_accept_sequence}
                onClick={this.handleAcceptSequenceClassification}
                placement="top"
                tooltip="Accept predicted sequence classification"
              />
            </ButtonGroup>
          </Grid>
          <Grid item md xs>
            <SearchText
              handleSearchText={text => this.props.handleReload(text)}
              holder={this.props.holder}
              id="id-search-sequence-errors"
              loading={this.props.loading}
              placeholder="Search sequence errors..."
              size="small"
            />
          </Grid>
          <Grid
            alignItems="center"
            container
            direction="row"
            item
            justifyContent="flex-end"
            mr={1}
            xs={2.5}>
            <IconButtonLoad
              handleClick={this.handleSyncSequenceClassification}
              icon={<LinkIcon style={{ color: 'blue' }} />}
              left_right
              loading={loading_sync_sequence}
              small
              tooltip="Sync sequence classifications"
            />
            <ButtonReloadDelete
              className={classes.margin}
              handleClearSelected={() => this.props.setState({ selected: [] })}
              handleDeleteSelected={() => this.setState({ open_delete: true })}
              handleReloadTable={() => this.props.handleReload()}
              loading_table={loading}
              numSelected={selected.length}
              small
              tooltip="Reload rules"
              width={75}
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
        <SnackMessage
          handleClose={() => this.setState({ warning_text: '' })}
          message_text={warning_text}
          open={warning_text !== '' ? true : false}
          type="warning"
        />
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_delete: false })}
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text="You are about to delete these sequences, this action cannot be undone"
          title_text="Delete Selected Sequences Errors"
        />
      </React.Fragment>
    );
  }
}

ToolBarSequenceErrors.propTypes = {
  classes: PropTypes.object,
  selected: PropTypes.array,
};

ToolBarSequenceErrors.defaultProps = {
  selected: [],
  rows_edited: [],
  handleDeleteSelected: () => '',
};

export default withStyles(withRouter(ToolBarSequenceErrors), useStylesToolBars);
