import React from 'react';
import PropTypes from 'prop-types';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import TuneIcon from '@mui/icons-material/Tune';
import ArchiveIcon from '@mui/icons-material/Archive';
const views = [
  { label: 'Setting', Icon: SettingsIcon },
  { label: 'Configuration', Icon: TuneIcon },
  { label: 'Additional', Icon: ArchiveIcon },
];
class SettingBottom extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: 0 };
  }

  render() {
    const { view } = this.props;
    const index = views.findIndex(x => x.label === view);
    return (
      <Paper
        elevation={3}
        sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
        <BottomNavigation
          onChange={(event, value) => {
            this.props.handleView(views[value].label);
          }}
          showLabels
          value={index}>
          {views.map((view, index) => {
            const { Icon, label } = view;
            return (
              <BottomNavigationAction
                icon={<Icon />}
                key={`index-navigation-${index}`}
                label={label}
              />
            );
          })}
        </BottomNavigation>
      </Paper>
    );
  }
}

SettingBottom.propTypes = {
  classes: PropTypes.object,
};

export default SettingBottom;
