import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_SSR_BIOMARKER = gql`
  query($name: String, $page: Int, $limit: Int) {
    ssrImagingBiomarker(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      biomarkers {
        id
        idPatient
        idCase
        infarct_core
        penumbra
        ich_volume
        aspects
        large_vessel_occlusion
        wmh_volume
        atrophy
        createdAt
        updatedAt
        patient {
          id
          PatientID
        }
      }
    }
  }
`;

export const QueryStrokeBiomarkers = (query_inputs, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SSR_BIOMARKER),
    variables: {
      query_inputs,
      page,
      limit,
    },
  });
};

const MUTATION_ADD_SSR_BIOMARKER = gql`
  mutation($inputs: [InputSsrImagingBiomarker]!) {
    addSsrImagingBiomarkers(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      total
      biomarkers {
        id
        idPatient
        idCase
        infarct_core
        penumbra
        ich_volume
        aspects
        large_vessel_occlusion
        wmh_volume
        atrophy

        patient {
          id
          PatientID
        }
      }
    }
  }
`;

export const MutationAddSsrBiomarke = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_SSR_BIOMARKER),
    variables: {
      inputs,
    },
  });
};

const MUTATION_REMOVE_STROKE_BIOMARKER = gql`
  mutation($ids: [LongLong!]!) {
    deleteStrokeBiomarker(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveStrokeBiomarker = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_STROKE_BIOMARKER),
    variables: {
      ids,
    },
  });
};
/* 
const MUTATION_REMOVE_STROKE_THROBOLYSIS = gql`
  mutation($ids: [LongLong!]!) {
    deleteStrokeThromboProcedure(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveStrokeThrombolysis = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_STROKE_THROBOLYSIS),
    variables: {
      ids,
    },
  });
};

const MUTATION_REMOVE_STROKE_TREATMENT = gql`
  mutation($ids: [LongLong!]!) {
    deleteStrokeTreatment(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveStrokeTreatment = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_STROKE_TREATMENT),
    variables: {
      ids,
    },
  });
};

const SEARCH_SSR_ENCOUNTER = gql`
  query($phrase: String, $page: Int, $limit: Int) {
    searchSsrEncounters(phrase: $phrase, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      encounters {
        id
        idPatient
        idCase
        followimage_type
        follow_mra
        follow_cta
        follow_ultrasound
        follow_dsa
        follow_tte
        follow_tee
        follow_holter
        follow_holter_length
        mca
        aca
        pca
        vertebrobasilar
        ocular
        etiology_toast
        etiology_toast_other
        etiology
        etiology_other
        stroke_pre
        tia_pre
        ich_pre
        hypertension
        diabetes
        hyperlipidemia
        smoking
        atrialfib
        chd
        prostheticvalves
        lowoutput
        pad
        sleep_average
        sleep_lastnight
        snoring
        daytime_sleepiness
        glucose
        cholesterol_total
        cholesterol_ldl
        creatinine_ldl
        nih_24h
        ich_sympt_hospital
        ich_sympt_hosp_date
        stroke_hospital
        stroke_hospital_date
        angioedema
        seizure_hospital
        seizure_hosp_date
        decompression
        decompression_date
        death_hospital
        death_hospital_date
        death_hospital_cause
        cea_symptomatic
        cea_symptomatic_date
        cas_symptomatic
        cas_symptomatic_date
        endorevasc_other
        surgrevasc_other
        revasc_other_date
        revasc_other_specify
        pfo_closure
        pfo_closure_date
        discharge_destinat
        discharge_date
        duration_hospital
        hosp_comment
        patient {
          id
          PatientID
        }
      }
    }
  }
`;
export const QuerySearchStrokeEncounter = (phrase, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(SEARCH_SSR_ENCOUNTER),
    variables: {
      phrase,
      page,
      limit,
    },
  });
};
 */
