import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import ToolBarMapping from '../ToolBarMapping';

class ClinicalVariableMaps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (!this.props.isActive) return null;
    return (
      <div style={{ padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ToolBarMapping
              disabled={this.state.deleting}
              handleAddError={this.props.handleAddError}
              handleOpenCreate={() => this.setState({ open_create: true })}
              handleOpenEdit={this.handleOpenEdit}
              selected={this.state.selected}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

ClinicalVariableMaps.propTypes = {
  classes: PropTypes.object,
};

export default ClinicalVariableMaps;
