import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import { Button, ButtonGroup } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { DivGrow, SearchInput } from 'components';

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    height: '42px',
    // marginTop: theme.spacing(2),
    margin: theme.spacing(1),
  },
  searchButton: {
    height: '42px',
    margin: theme.spacing(1),
  },
  textTop: {
    marginBottom: theme.spacing(2),
  },
}));

const TeamsProjectToolBar = props => {
  const {
    className,
    handleOpenCreateTeam,
    handleTeamSearch,
    // eslint-disable-next-line no-unused-vars
    handleOpenAddTeams,
    selected,
    handleOpenEditSelected,
    admin,
    loading,
    ...rest
  } = props;

  const { classes } = useStyles();
  let edit_component = null;
  if (selected.length) {
    edit_component = (
      <ButtonGroup exclusive="false" size="small" value="center">
        <Button disabled={!selected.length} onClick={handleOpenEditSelected}>
          <EditIcon style={{ color: selected.length ? 'red' : 'gray' }} />
        </Button>
      </ButtonGroup>
    );
  }
  let ComponentButtonCreate = null;
  if (admin) {
    ComponentButtonCreate = (
      <Button
        color="primary"
        disabled={loading}
        onClick={handleOpenCreateTeam}
        variant="contained">
        Create Team
      </Button>
    );
  }
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          disabled={loading}
          onChange={handleTeamSearch}
          placeholder="Search team by name..."
        />
        {edit_component}
        <DivGrow />
        {ComponentButtonCreate}
      </div>
    </div>
  );
};

TeamsProjectToolBar.propTypes = {
  className: PropTypes.string,
};

export default TeamsProjectToolBar;
