import React from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableContainer,
} from '@mui/material';

import {
  cell,
  cell_action,
  cell_check,
  cell_check_icon,
  CheckBoxSyan,
  stableSort,
} from '../styles';
import { rowsPerPageSmall } from 'helpers';
import TableCustomRow from './TableCustomRow';
import TableCustomRowEdit from './TableCustomRowEdit';

class TableCustom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          user: { name: 'Fran Perez', email: 'fran.perez@domain.com' },
          progress: 50,
          phone: '(815) 704-0045',
          created: 'Nov 11, 2024 7:52 PM',
          status: 'Active',
        },
        {
          user: { name: 'Penjani Inyene', email: 'penjani.inyene@domain.com' },
          progress: 100,
          phone: '(803) 937-8925',
          created: 'Nov 11, 2024 5:52 PM',
          status: 'Active',
        },
        {
          user: { name: 'Carson Darrin', email: 'carson.darrin@domain.com' },
          progress: 10,
          phone: '(715) 278-5041',
          created: 'Nov 10, 2024 7:52 PM',
          status: 'Pending',
        },
        {
          user: {
            name: 'Siegbert Gottfried',
            email: 'siegbert.gottfried@domain.com',
          },
          progress: 0,
          phone: '(603) 766-0431',
          created: 'Nov 10, 2024 1:52 PM',
          status: 'Blocked',
        },
        {
          user: {
            name: 'Miron Vitold',
            email: 'miron.vitold@domain.com',
          },
          progress: 50,
          phone: '(425) 434-5535',
          created: 'Nov 9, 2024 6:52 PM',
          status: 'Active',
        },
        {
          user: {
            name: 'John Doe',
            email: 'john.doe@domain.com',
          },
          progress: 50,
          phone: '(425) 555-5555',
          created: 'Nov 9, 2022 6:52 PM',
          status: 'Blocked',
        },
      ],
      selected: [],
      page: 0,
      limit: 10,
      rows_editing: [],
    };
  }
  componentDidMount() {
    this.props.setState({
      all: this.state.data.length,
      active: this.state.data.filter(d => d.status === 'Active').length,
      pending: this.state.data.filter(d => d.status === 'Pending').length,
      blocked: this.state.data.filter(d => d.status === 'Blocked').length,
    });
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  };
  IsSelected = row => {
    const { selected } = this.state;
    return selected.findIndex(r => r.user.name === row.user.name) !== -1;
  };
  IsEditing = row => {
    const { rows_editing } = this.state;
    return rows_editing.findIndex(r => r.user.name === row.user.name) !== -1;
  };
  handleCheckbox = (row, all) => {
    let { selected } = this.state;
    if (all) {
      if (selected.length) {
        selected = [];
      } else {
        selected = [...this.state.data];
      }
    } else {
      const index = selected.findIndex(r => r.user.name === row.user.name);
      if (index === -1) selected.push(row);
      else selected.splice(index, 1);
    }
    this.setState({ selected });
  };
  handleEdit = row => {
    if (this.IsEditing(row)) return;
    const { rows_editing } = this.state;
    rows_editing.push(row);
    this.setState({ rows_editing });
  };
  handleSave = (row, new_row) => {
    let { rows_editing, data } = this.state;
    let index = rows_editing.findIndex(r => r.user.name === row.user.name);
    if (index !== -1) {
      rows_editing.splice(index, 1);
    }
    index = data.findIndex(r => r.user.name === row.user.name);
    if (index !== -1) {
      data[index] = { ...new_row };
    }
    this.setState({ rows_editing, data }, () => {
      this.props.setState({
        all: this.state.data.length,
        active: this.state.data.filter(d => d.status === 'Active').length,
        pending: this.state.data.filter(d => d.status === 'Pending').length,
        blocked: this.state.data.filter(d => d.status === 'Blocked').length,
      });
    });
  };
  handleClose = row => {
    let { rows_editing } = this.state;
    const index = rows_editing.findIndex(r => r.user.name === row.user.name);
    if (index !== -1) {
      rows_editing.splice(index, 1);
    }
    this.setState({ rows_editing });
  };
  render() {
    const { selected, page, limit } = this.state;
    const {
      textsearchs,
      search,
      status,
      height_table,
      count_searchs,
    } = this.props;
    console.log({ textsearchs, search, status, height_table, count_searchs });
    let data = this.state.data.filter(d =>
      status === 'All' ? true : d.status === status
    );
    if (count_searchs && !search) {
      if (textsearchs.email) {
        data = data.filter(d =>
          d.user.name.toLowerCase().includes(textsearchs.email.toLowerCase())
        );
      }
      if (textsearchs.phone) {
        data = data.filter(d =>
          d.phone.toLowerCase().includes(textsearchs.phone.toLowerCase())
        );
      }
    }
    data = stableSort(data, this.state.menu_selected);
    return (
      <>
        <TableContainer
          sx={{
            height: height_table,
            overflowY: 'auto', // Enables vertical scrolling
            overflow: 'auto',
          }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={cell_check}>
                  <CheckBoxSyan
                    checked={data.length && selected.length === data.length}
                    indeterminate={
                      selected.length > 0 && selected.length < data.length
                    }
                    onClick={() => this.handleCheckbox(null, true)}
                    sx={cell_check_icon}
                  />
                </TableCell>
                <TableCell sx={cell}>Name</TableCell>
                <TableCell sx={cell}>Quota</TableCell>
                <TableCell sx={{ ...cell, minWidth: 150 }}>
                  Phone number
                </TableCell>
                <TableCell sx={{ ...cell, minWidth: 180 }}>Create at</TableCell>
                <TableCell sx={{ ...cell, width: 100, minWidth: 100 }}>
                  Status
                </TableCell>
                <TableCell sx={cell_action} />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => {
                const idSelected = this.IsSelected(row);
                if (this.IsEditing(row)) {
                  return (
                    <TableCustomRowEdit
                      handleClose={this.handleClose}
                      handleSave={this.handleSave}
                      idSelected={idSelected}
                      key={index}
                      row={row}
                    />
                  );
                } else {
                  return (
                    <TableCustomRow
                      handleEdit={this.handleEdit}
                      idSelected={idSelected}
                      key={index}
                      row={row}
                      setState={state => this.setState(state)}
                    />
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider />
        <TablePagination
          component="div"
          count={data.length}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={rowsPerPageSmall}
        />
      </>
    );
  }
}

TableCustom.propTypes = {
  classes: PropTypes.object,
};
TableCustom.defaultProps = {
  status: 'All',
};
export default TableCustom;
